import axios from "../axios";

class CustomerService {
  static Instance() {
    return new CustomerService();
  }

  getCustomerList(request) {
    return axios.post("api/customer/list", request);
  }

  getCountryListData(searchValue) {
    return axios.get(`api/address/search/country?Country=${searchValue}`);
  }

  getCityList(params) {
    let url = "api/address/city/list?";

    if (params) {
      url += `StateID=${params}`;
    }

    return axios.get(url);
  }

  getStateList(country) {
    return axios.get(`api/address/search/states?country=${country}`);
  }

  saveCustomerNewAccountData(payload) {
    return axios.post("api/address/debtor", payload);
  }

  saveDebtorCustomerAccountData(payload) {
    return axios.post("api/customer", payload);
  }

  getCustomerAccountDetails(primaryIdentifier) {
    return axios.get(
      `api/customer/details?PrimaryIdentifier=${primaryIdentifier}`
    );
  }

  getAccountIDDetails() {
    return axios.get("api/customer/getAccountNumber");
  }

  getCustomerAddress(primaryIdentifier) {
    return axios.get(
      `api/address/debtor/list?PrimaryIdentifier=${primaryIdentifier}`
    );
  }

  getCustomerContactList(primaryIdentifier) {
    return axios.get(
      `/api/contact/debtor/list?PrimaryIdentifier=${primaryIdentifier}&ConsumerType=Consumer`
    );
  }

  getSampleTemplate() {
    return axios.get("/api/customer/import/template/download", {
      responseType: "arraybuffer",
    });
  }

  getCustomerCategoryList(payload) {
    return axios.get(`/api/customer/category/list`, payload);
  }

  getSampleTemplateOfAddress() {
    return axios.get("/api/address/import/template/download", {
      responseType: "arraybuffer",
    });
  }

  getSampleTemplateOfContacts() {
    return axios.get("/api/contact/import/template/download", {
      responseType: "arraybuffer",
    });
  }

  getCustomerDetails(primaryIdentifier) {
    return axios.get(
      `/api/customer/card/details?PrimaryIdentifier=${primaryIdentifier}`
    );
  }

  getForecastDate(productId, quantity) {
    return axios.get(
      `/api/order/forecast/date?ProductID=${productId}&Quantity=${quantity}`
    );
  }

  getCustomerEnquiryList(payload) {
    return axios.post(`api/customer/account/company/first`, payload);
  }

  exportCustomerList(payload) {
    return axios.post("/api/customer/list/export", payload, {
      responseType: "arraybuffer",
    });
  }

  exportAddressList(primaryIdentifier) {
    return axios.get(
      `/api/address/list/export?PrimaryIdentifier=${primaryIdentifier}`,
      {
        responseType: "arraybuffer",
      }
    );
  }

  exportContactList(primaryIdentifier) {
    return axios.get(
      `/api/contact/list/export?PrimaryIdentifier=${primaryIdentifier}`,
      {
        responseType: "arraybuffer",
      }
    );
  }

  importCustomerList(payload) {
    return axios.post("api/customer/import", payload, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
  }

  importAddressList(primaryIdentifier, payload) {
    return axios.post(
      `api/address/import?PrimaryIdentifier=${primaryIdentifier}`,
      payload,
      {
        headers: {
          "Content-type": "multipart/form-data",
        },
      }
    );
  }

  importContactList(primaryIdentifier, payload) {
    return axios.post(
      `api/contact/import?PrimaryIdentifier=${primaryIdentifier}`,
      payload,
      {
        headers: {
          "Content-type": "multipart/form-data",
        },
      }
    );
  }

  getCustomerDataDropDownList(payload) {
    return axios.post("api/customer/account/company", payload);
  }

  updateCustomerDetails(payload) {
    return axios.put(`api/customer`, payload);
  }

  addCustomerContactData(payload) {
    return axios.post("/api/contact", payload);
  }

  updateCustomerContactData(payload) {
    return axios.put("/api/contact", payload);
  }

  addCustomerAddressData(payload) {
    return axios.post("/api/address/debtor", payload);
  }

  updateCustomerAddressData(payload) {
    return axios.put("api/address/debtor", payload);
  }

  getCustomerOrderData(primaryIdentifier, payload) {
    return axios.post(
      `/api/order/list?PrimaryIdentifier=${primaryIdentifier}`,
      payload
    );
  }

  updatePreviewCard(primaryIdentifier, payload) {
    return axios.put(
      `/api/customer/preview/card?PrimaryIdentifier=${primaryIdentifier}`,
      payload
    );
  }

  updateDiscountCard(primaryIdentifier, payload) {
    return axios.put(
      `/api/customer/discount/card?PrimaryIdentifier=${primaryIdentifier}`,
      payload
    );
  }

  getAccountList() {
    return axios.post("api/customer/account/company", { accountId: "" });
  }
}
export default CustomerService.Instance();
