import React, { memo } from "react";
import { Line } from "@ant-design/charts";
import dayjs from "dayjs";
import { Empty } from "antd";
import ChartLoading from "../../Common/ChartLoading";

const MonthlyOrderChart = memo(({ data, loading, height, showIn }) => {
  let formattedData = [];

  if (loading) {
    return <ChartLoading height={height} />;
  }

  if (data.length > 0) {
    formattedData = data.map((item) => {
      const [date, value] = Object.entries(item)[0];
      return {
        date: date ? dayjs(date).format("MMMM") : "NA",
        Orders: parseFloat(value),
      };
    });
  } else {
    return (
      <div
        className={`flex items-center justify-center w-full font-semibold`}
        style={{ height: height }}
      >
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No data found"
        />
      </div>
    );
  }

  if (showIn === "Percentage") {
    const total = formattedData.reduce((acc, item) => acc + item.Orders, 0);
    formattedData = formattedData.map((item) => ({
      ...item,
      Orders: Math.floor((item.Orders / total) * 100),
    }));
  }

  const maxOrders = Math.max(...formattedData.map((item) => item.Orders));

  const config = {
    data: formattedData.filter((i) => i.Orders !== null),
    xField: "date",
    yField: "Orders",
    tooltip: {
      showMarkers: false,
      shared: true,
    },
    point: {
      size: 5,
      shape: "circle",
    },
    yAxis: {
      label: {
        formatter: (val) => (showIn === "Percentage" ? `${val}%` : val),
      },
      min: 0,
      max: showIn === "Percentage" ? 100 : maxOrders,
    },
    tooltip: {
      showTitle: false,
      formatter: (data) => {
        const obj = formattedData.find((i) => i.date === data.date);

        return {
          name: obj.date,
          value: showIn === "Percentage" ? `${obj.Orders}%` : obj.Orders,
        };
      },
    },
  };

  return <Line {...config} />;
});

export default MonthlyOrderChart;
