import { CloseOutlined, EyeFilled, FolderOpenFilled } from "@ant-design/icons";
import {
  Alert,
  Drawer,
  Image,
  message,
  Modal,
  Spin,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import CustomButton from "../../Common/CustomButton";
import OrderManagementService from "../../services/OrderManagement/OrderManagementService";
import ProductionServices from "../../services/Production/ProductionServices";
import { previewableFileFormats } from "../../utils/commonUtils";

const AttachmentDrawer = ({ open, onClose, data }) => {
  const [attachmentsList, setAttachmentsList] = useState([]);
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState("");
  const [imageUrl, setImageUrl] = useState(null);

  const isPdf =
    imageUrl && (imageUrl.includes(".pdf") || imageUrl.includes(".PDF"));

  const handleClose = () => {
    onClose();
  };

  const fetchAttachmentsList = async () => {
    if (!data.ordNum) {
      return;
    }

    setIsLoading(true);
    try {
      // if (data?.fromOrderManagement) {
      //   const res = await OrderManagementService.getFileDetailsData(
      //     data?.primaryIdentifier,
      //     {
      //       pageRequest: {
      //         currentPage: 1,
      //         pageSize: 100,
      //       },
      //       sortRequest: {
      //         key: "",
      //         direction: true,
      //       },
      //       filterRequest: {
      //         startDate: "",
      //         endDate: "",
      //         fileName: "",
      //         category: "",
      //       },
      //     }
      //   );

      //   setAttachmentsList(res?.data?.records);
      // } else {
      //   const res = await ProductionServices.getAttachmentsOfOrder(
      //     data?.ordNum,
      //     "Production"
      //   );

      //   setAttachmentsList(res?.data);
      // }

      const res = await OrderManagementService.getFileDetailsData(
        data?.primaryIdentifier || data?.orderPrimaryIdentifier,
        {
          pageRequest: {
            currentPage: 1,
            pageSize: 100,
          },
          sortRequest: {
            key: "",
            direction: true,
          },
          filterRequest: {
            startDate: "",
            endDate: "",
            fileName: "",
            category: "",
          },
        }
      );

      setAttachmentsList(res?.data?.records || []);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error?.response?.data?.message);
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went wrong!"
      );
    }
  };

  const getImage = async (data, index) => {
    setFileLoading(index);

    try {
      const res = await ProductionServices.getPreviewFile(
        data?.category,
        data?.fileName
      );

      setImageUrl(res?.data);

      if (
        !previewableFileFormats.some((i) => res?.data.toLowerCase().includes(i))
      ) {
        message.info("Preview is not available for this file format.");
      } else {
        setImageModalOpen(true);
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went wrong!"
      );
    } finally {
      setFileLoading("");
    }
  };

  useEffect(() => {
    if (open && data) {
      fetchAttachmentsList();
    }
  }, [open, data]);

  const getImageUrl = async (data, index) => {
    setFileLoading(index);

    try {
      const res = await ProductionServices.getPreviewFile(
        data?.category,
        data?.fileName
      );

      setImageUrl(res?.data);
      openAttachment(res?.data);
    } catch (error) {
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went wrong!"
      );
    } finally {
      setFileLoading("");
    }
  };

  const openAttachment = (url) => {
    if (url) {
      window.open(url, "_blank");
    } else {
      message.info("unable to open attachment in new tab");
    }
  };

  const columns = [
    {
      title: "Type",
      dataIndex: "category",
      key: "category",
      align: "center",
      width: 200,
      render: (text) => (
        <Typography.Text ellipsis={{ tooltip: text }}>{text}</Typography.Text>
      ),
    },
    {
      title: "File Name",
      dataIndex: "description",
      key: "description",
      align: "center",
      render: (text) => (
        <Typography.Text ellipsis={{ tooltip: text }} className="px-2">
          {text}
        </Typography.Text>
      ),
    },
    {
      title: "File",
      dataIndex: "file",
      key: "custRequiredDate",
      align: "center",
      render: (_, record, index) => (
        <div className="flex flex-col gap-1">
          <CustomButton
            loading={fileLoading === index}
            icon={<EyeFilled />}
            onClick={() => getImage(record, index)}
          >
            View
          </CustomButton>
          <CustomButton
            loading={fileLoading === index}
            icon={<FolderOpenFilled />}
            onClick={() => getImageUrl(record, index)}
          >
            Open
          </CustomButton>
        </div>
      ),
      width: 100,
    },
  ].filter((col) => !col.hidden);

  return (
    <Drawer
      open={open}
      width={attachmentsList.length > 0 ? 600 : 400}
      onClose={handleClose}
      title="All Attachments"
      destroyOnClose
    >
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spin />
        </div>
      ) : attachmentsList.length === 0 ? (
        <Alert
          type="info"
          message="No attachment found for the selected order"
          showIcon
        />
      ) : (
        <Table
          bordered
          dataSource={attachmentsList}
          columns={columns}
          pagination={false}
          tableLayout="fixed"
        />
      )}

      {isPdf ? (
        <Modal
          open={isImageModalOpen}
          onCancel={() => {
            setImageModalOpen(false);
            setImageUrl(null);
          }}
          className="relative min-h-screen top-5 custom-modal"
          footer={null}
          width={1900}
          destroyOnClose
          closeIcon={false}
        >
          <iframe
            src={imageUrl}
            title="Preview"
            height={820}
            className="min-w-full"
          />

          <CustomButton
            className="absolute border-white -top-2 -right-2 hover:bg-opacity-100"
            type="cancel"
            icon={<CloseOutlined />}
            onClick={() => {
              setImageModalOpen(false);
              setImageUrl(null);
            }}
          />
        </Modal>
      ) : (
        <Image
          preview={{
            visible: isImageModalOpen,
            src: imageUrl,
            onVisibleChange: (value) => {
              setImageModalOpen(value);
            },
          }}
          alt={data?.description}
          style={{ maxWidth: "100%" }}
          className="object-center"
        />
      )}
    </Drawer>
  );
};

export default AttachmentDrawer;
