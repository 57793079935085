import React, { memo } from "react";
import { Bullet } from "@ant-design/plots";
import { Empty } from "antd";
import ChartLoading from "../../Common/ChartLoading";

const ActualCapacityChart = memo(({ data, loading, height }) => {
  let formattedData = [];

  if (loading) {
    return <ChartLoading height={height} />;
  }

  if (data.length !== 0) {
    formattedData = data.flatMap(({ name, capacityResponse }) => [
      {
        title: name.length > 16 ? `${name.slice(0, 16)}...` : name,
        ["Actual Capacity"]: [capacityResponse.actualCapacity],
        ["Occupied Capacity"]: [capacityResponse.occupiedCapacity],
        target: capacityResponse.actualCapacity,
        fullName: name,
      },
    ]);
  } else {
    return (
      <div
        className={`flex items-center justify-center w-full font-semibold`}
        style={{ height: height }}
      >
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No data found"
        />
      </div>
    );
  }

  const config = {
    data: formattedData,
    measureField: "Occupied Capacity",
    rangeField: "Actual Capacity",
    targetField: "target",
    xField: "title",
    color: {
      range: "#9e9d9d",
      measure: "#5B8FF9",
      target: "#9e9d9d",
    },
    xAxis: {
      line: null,
      label: {
        autoRotate: true,
      },
    },
    label: false,
    yAxis: {
      position: "left",
    },
    layout: "vertical",
    tooltip: {
      title: (text) => {
        const obj = formattedData.find((i) => i.title === text);

        return obj.fullName;
      },
      shared: true,
      customItems: (data) => {
        const obj = formattedData.find(
          (i) => i.title === data?.[0]?.data?.title
        );

        let color;

        if (obj) {
          const occupiedCapacity = obj["Occupied Capacity"][0];
          const actualCapacity = obj["Actual Capacity"][0];

          if (occupiedCapacity < actualCapacity) {
            color = "#5B8FF9";
          } else if (occupiedCapacity === actualCapacity) {
            color = "#62daab";
          } else {
            color = "#FFA500";
          }
        }

        return [
          {
            name: "Actual Capacity",
            color: "#9e9d9d",
            value: obj?.["Actual Capacity"]?.[0],
          },
          {
            name: "Occupied Capacity",
            color: color,
            value: obj?.["Occupied Capacity"]?.[0],
          },
        ];
      },
    },
    legend: {
      custom: true,
      position: "top",
      items: [
        {
          value: "Actual Capacity",
          name: "Actual Capacity",
          marker: {
            symbol: "square",
            style: { fill: "#9e9d9d", r: 5 },
          },
        },
        {
          value: "Occupied Capacity",
          name: "Underutilized Capacity",
          marker: {
            symbol: "square",
            style: { fill: "#5B8FF9", r: 5 },
          },
        },
        {
          value: "Occupied Capacity",
          name: "Fully utilized Capacity",
          marker: {
            symbol: "square",
            style: { fill: "#62daab", r: 5 },
          },
        },
        {
          value: "Occupied Capacity",
          name: "Overutilized Capacity",
          marker: {
            symbol: "square",
            style: { fill: "#FFA500", r: 5 },
          },
        },
      ],
    },
    bulletStyle: {
      measure: (data) => {
        const obj = formattedData.find((i) => i.title === data?.title);

        if (obj) {
          const occupiedCapacity = obj["Occupied Capacity"][0];
          const actualCapacity = obj["Actual Capacity"][0];

          if (occupiedCapacity < actualCapacity) {
            return { fill: "#5B8FF9" };
          } else if (occupiedCapacity === actualCapacity) {
            return { fill: "#62daab" };
          } else {
            return { fill: "#FFA500" };
          }
        }
        return { fill: "#5B8FF9" };
      },
    },
  };

  return <Bullet {...config} />;
});

export default ActualCapacityChart;
