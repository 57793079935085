import { Typography } from "antd";
import React from "react";
import { Handle, Position } from "reactflow";
import { useDarkMode } from "../../../Providers/DarkModeContext";

const { Text, Paragraph } = Typography;

const CustomOrderStatusNode = ({
  data,
  selectedNode,
  id,
  currentProduct,
  isFlowFinalized,
  capacityFilter,
}) => {
  const { isDarkMode } = useDarkMode();

  const selected =
    !isFlowFinalized &&
    !data.isDisabled?.includes(currentProduct?.code) &&
    selectedNode?.id === id;

  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: "#555" }}
        isConnectable={false}
      />

      <Handle
        type="source"
        id="right"
        position={Position.Right}
        style={{ background: "#555" }}
        isConnectable={false}
      />

      <div
        onContextMenu={(e) => {
          e.preventDefault();
          return;
        }}
        className="relative cursor-pointer inline-block min-w-[13rem] max-w-[13rem] !z-10"
      >
        <div
          className={`task-node h-[5.3rem] border border-gray-600 ${
            selected ? "!border-[#3377a7]" : ""
          } ${
            data?.isDisabled?.includes(currentProduct?.code)
              ? isDarkMode
                ? "!border-gray-600"
                : "!border-gray-300"
              : ""
          } text-center`}
        >
          <Text
            className={`pl-[6px] text-[12px] bg-gray-600/70 text-white ${
              selected ? "!bg-[#3377a7]" : ""
            } ${
              data.isDisabled?.includes(currentProduct?.code)
                ? isDarkMode
                  ? "!bg-gray-500 opacity-40 !text-gray-400"
                  : "!bg-gray-300 "
                : ""
            } py-1`}
          >
            {data?.orderStatusId}
          </Text>

          <Paragraph
            className="flex-1 flex items-center justify-center px-[6px] text-center text-[12px] pt-[10px]"
            ellipsis={{ tooltip: true, rows: 2 }}
          >
            {data?.label.trim(" ")}
          </Paragraph>
        </div>
      </div>

      <Handle
        type="source"
        id="bottom"
        position={Position.Bottom}
        style={{ background: "#555" }}
        isConnectable={false}
      />
    </>
  );
};

export default CustomOrderStatusNode;
