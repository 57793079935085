import { ConfigProvider, theme } from "antd";
import React from "react";
import { initDB } from "react-indexed-db-hook";
import APSLayout from "./Layout/APSLayout";
import { useDarkMode } from "./Providers/DarkModeContext";

initDB({
  name: "ProductGroup",
  version: 1,
  objectStoresMeta: [
    {
      store: "history",
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "nodes", keypath: "nodes", options: { unique: false } },
        { name: "edges", keypath: "edges", options: { unique: false } },
        { name: "action", keypath: "action", options: { unique: false } },
      ],
    },
  ],
});

function App() {
  const { isDarkMode } = useDarkMode();
  const { darkAlgorithm, defaultAlgorithm } = theme;

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: isDarkMode ? "#4096ff" : "#1677ff",
          fontFamily: "Inter",
        },
        algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
        components: {
          Slider: {
            trackBg: "#91caff",
          },
          Statistic: {
            contentFontSize: 15,
          },
          Popconfirm: {
            colorBgContainer: "#fff",
          },
        },
      }}
      componentSize="small"
    >
      <APSLayout />
    </ConfigProvider>
  );
}

export default App;
