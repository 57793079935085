import { Breadcrumb, Typography } from "antd";
import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";
import { routesConfig } from "../config/routes.config";
import { AuthContext } from "../Providers/AuthProvider";
import { useDarkMode } from "../Providers/DarkModeContext";
import { getDarkModeColor } from "../utils/darkModeHelper";

const CustomBreadcrumb = ({ separator = "/" }) => {
  const { isDarkMode } = useDarkMode();
  const { userDetails } = useContext(AuthContext);
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const breadcrumbs = useReactRouterBreadcrumbs(routesConfig);
  const prevNavSearchParams =
    JSON.parse(sessionStorage.getItem("prevNavSearchParams")) || {};

  useEffect(() => {
    prevNavSearchParams[pathname] = pathname + search;
    sessionStorage.setItem(
      "prevNavSearchParams",
      JSON.stringify(prevNavSearchParams)
    );
  }, [search, pathname]);

  const handleBreadcrumbClick = (breadcrumbKey) => {
    const targetUrl = prevNavSearchParams[breadcrumbKey] || breadcrumbKey;
    navigate(targetUrl);
  };

  const renderBreadcrumbItems = () => {
    const showHomeBreadcrumb = userDetails?.menu?.items.some(
      (i) => i.label === "Home"
    );

    return breadcrumbs
      .filter((breadcrumb) => showHomeBreadcrumb || breadcrumb.key !== "/")
      .map((breadcrumb, index, arr) => (
        <Breadcrumb.Item
          key={index}
          onClick={() => {
            if (arr.length - 1 !== index) {
              handleBreadcrumbClick(breadcrumb.key);
            }
          }}
          className={`${
            arr.length - 1 !== index ? "cursor-pointer" : "cursor-text"
          }`}
        >
          <Typography
            style={{
              color:
                index === breadcrumbs.length - 1
                  ? ""
                  : getDarkModeColor(isDarkMode, "#1677ff", "#4096ff"),
            }}
          >
            {breadcrumb.breadcrumb}
          </Typography>
        </Breadcrumb.Item>
      ));
  };

  return (
    <Breadcrumb className="pl-1 mb-3" separator={separator}>
      {renderBreadcrumbItems()}
    </Breadcrumb>
  );
};

export default CustomBreadcrumb;
