import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomBreadcrumb from "../../../Common/CustomBreadcrumb";
import {
  Checkbox,
  Form,
  Input,
  message,
  Popconfirm,
  Select,
  Table,
} from "antd";
import CustomButton from "../../../Common/CustomButton";
import {
  CheckOutlined,
  DeleteOutlined,
  PlusOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { getDarkModeColor } from "../../../utils/darkModeHelper";
import { useDarkMode } from "../../../Providers/DarkModeContext";
import { validateTextInput } from "../../../utils/validationsHelper";
import { useForm, useWatch } from "antd/es/form/Form";
import ProductService from "../../../services/ProductBuilder/ProductService";
import TotalListEntries from "../../../Common/TotalListEntries";
import { isEqual } from "lodash";

const items = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Products",
    path: "/inventory/product/all?activeTab=product",
  },
  {
    title: "Product Builder",
  },
];

const ProductConfigBuilderPage = () => {
  const [listLoading, setListLoading] = useState(false);
  const [productListLoading, setProductListLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState({});
  const [list, setList] = useState([]);
  const [initialList, setInititalList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const { isDarkMode } = useDarkMode();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const productId = searchParams.get("productId");

  const [form] = useForm();

  const attributeLabel = useWatch("attributeLabel", form);
  const attributeKey = useWatch("attributeKey", form);
  const parentConfiguration = useWatch("parentConfiguration", form);
  const isActive = useWatch("isActive", form);

  useEffect(() => {
    const fetchData = async () => {
      await fetchProductData();

      if (productId) {
        handleSelectProduct(productId);
      }
    };

    fetchData();
  }, [productId]);

  useEffect(() => {
    // to show the alert for unsaved changes
    window.addEventListener("beforeunload", handleUnsavedChanges);

    return () => {
      window.removeEventListener("beforeunload", handleUnsavedChanges);
    };
  }, [unsavedChanges]);

  const handleUnsavedChanges = (event) => {
    if (unsavedChanges) {
      event.returnValue =
        "All your changes will be unsaved. Are you sure you want to leave?";
    }
  };

  const fetchProductData = async () => {
    setProductListLoading(true);
    try {
      const { data } = await ProductService.getProductDataList();

      if (data) {
        const filteredData = data?.map((i) => ({ label: i.name, value: i.id }));

        setProductList(filteredData);
      }
    } catch (error) {
      console.log(error);
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went wrong"
      );
    } finally {
      setProductListLoading(false);
    }
  };

  const fetchConfigList = async (value) => {
    if (!value) return;

    setListLoading(true);
    try {
      let attributeLabel = [];
      let attributeKey = [];
      let parentConfiguration = [];
      let isActive = [];

      const { data } = await ProductService.getProductConfigData(value);

      data?.forEach((item, index) => {
        attributeLabel[index] = item.attributeLabel;
        attributeKey[index] = item.attributeKey;
        parentConfiguration[index] = item.parentConfigurationId || null;
        isActive[index] = item.isActive;
      });

      setInititalList(data || []);
      setList(data || []);

      form.setFieldsValue({
        attributeKey,
        attributeLabel,
        parentConfiguration,
        isActive,
      });
    } catch (error) {
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went wrong"
      );
    } finally {
      setListLoading(false);
    }
  };

  const handleSelectProduct = async (value) => {
    if (!value) return;

    setSelectedProduct(parseInt(value));
    fetchConfigList(value);
    setSearchParams("");
  };

  const handleAddConfig = () => {
    if (!selectedProduct) return;

    setUnsavedChanges(true);

    let newConfig = {
      id: list.length + 1,
      productId: selectedProduct,
      attributeHeader: null,
      attributeKey: null,
      attributeLabel: null,
      attributeInputType: "string",
      isHidden: false,
      isActive: false,
      parentConfigurationId: null,
      options: [],
      type: "Save",
    };

    setList((prev) => [...prev, newConfig]);
  };

  const handleUpdateRow = (data, index, type) => {
    form
      .validateFields([
        ["attributeLabel", index],
        ["attributeKey", index],
      ])
      .then(async (values) => {
        setIsSubmitting({ [`update${index}`]: true });

        const payload = { ...data };

        try {
          const { data } =
            type === "Save"
              ? await ProductService.addNewProductConfig(payload)
              : await ProductService.updateProductConfig(payload);

          if (data) {
            await fetchConfigList(selectedProduct);

            if (list?.length === 1) {
              setUnsavedChanges(false);
            }

            if (list?.length > 1 && list?.some((i) => i?.type !== "Save")) {
              setUnsavedChanges(false);
            }

            message.success("Updated configuration successfully!");
          }
        } catch (error) {
          console.log(error);
          message.error(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : "Something went wrong"
          );
        } finally {
          setListLoading(false);
          setIsSubmitting({ [`update${index}`]: false });
        }
      })
      .catch((errorInfo) => {
        const errorFields = errorInfo.errorFields;

        if (errorFields.length > 0) {
          const fieldName = errorFields[0].name;
          form.scrollToField(fieldName, {
            behavior: "smooth",
            inline: "center",
            skipOverflowHiddenElements: true,
          });
        }
      });
  };

  const handleDeleteRow = async (index) => {
    const item = list[index];

    if (!item || item.id === null) return;

    setListLoading(true);
    setIsSubmitting({ [`delete${index}`]: true });

    const clearFormFields = () => {
      attributeLabel[index] = undefined;
      attributeKey[index] = undefined;
      parentConfiguration[index] = undefined;
      isActive[index] = false;

      form.setFieldsValue({
        attributeKey,
        attributeLabel,
        parentConfiguration,
        isActive,
      });
    };

    try {
      if (item.type === "Save") {
        setList((prev) => prev.filter((_, i) => i !== index));
        clearFormFields();

        if (list?.length === 1) {
          setUnsavedChanges(false);
        }

        if (list?.length > 1 && list?.some((i) => i?.type !== "Save")) {
          setUnsavedChanges(false);
        }

        message.success("Deleted configuration successfully!");
      } else {
        const res = await ProductService.deleteProductConfig(item.id);

        if (res.status === 200) {
          await fetchConfigList(selectedProduct);
          clearFormFields();

          message.success("Deleted configuration successfully!");
        }
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went wrong"
      );
    } finally {
      setIsSubmitting({ [`delete${index}`]: false });
      setListLoading(false);
    }
  };

  const getFormValueOfIndex = (index) => {
    return {
      ...list[index],
      attributeLabel: attributeLabel?.[index],
      attributeKey: attributeKey?.[index],
      parentConfigurationId: parentConfiguration?.[index],
      isActive: isActive?.[index],
    };
  };

  const checkIsFieldsTouched = (index) => {
    return !form.isFieldsTouched([
      ["attributeLabel", index],
      ["attributeKey", index],
      ["parentConfiguration", index],
      ["isActive", index],
    ]);
  };

  const columns = [
    {
      title: "S No.",
      key: "srNum",
      render: (d, r, index) => index + 1,
      width: 80,
    },
    {
      title: "Attribute name",
      dataIndex: "attributeLabel",
      render: (data, _, index) => (
        <Form.Item
          name={["attributeLabel", index]}
          rules={[
            { required: true, message: "Enter attribute name" },
            { validator: validateTextInput("Attribute Name") },
          ]}
        >
          <Input size="middle" placeholder="Enter attribute name" />
        </Form.Item>
      ),
    },
    {
      title: "Attribute key",
      dataIndex: "attributeKey",
      render: (data, _, index) => (
        <Form.Item
          name={["attributeKey", index]}
          rules={[
            { required: true, message: "Enter attribute key" },
            { validator: validateTextInput("Attribute key") },
          ]}
        >
          <Input size="middle" placeholder="Enter attribute key" />
        </Form.Item>
      ),
    },
    {
      title: "Parent configuration",
      dataIndex: "attribute-key",
      render: (data, record, index) => (
        <Form.Item name={["parentConfiguration", index]} required={false}>
          <Select
            allowClear
            size="middle"
            disabled={!list[index]?.attributeLabel}
            placeholder="Select parent configuration"
            className="w-full"
            options={configurationOptions.filter((i) => i.value !== record.id)}
          />
        </Form.Item>
      ),
    },
    {
      title: "Active",
      dataIndex: "isActive",
      width: 100,
      align: "center",
      render: (text, _, index) => (
        <Form.Item name={["isActive", index]} valuePropName="checked">
          <Checkbox />
        </Form.Item>
      ),
    },
    {
      title: "Options",
      dataIndex: "options",
      align: "center",
      width: 150,
      render: (_, record) => (
        <CustomButton
          icon={<SettingOutlined />}
          disabled={record?.type === "Save"}
          type="primary"
          onClick={() =>
            navigate("/inventory/product/product-builder/options", {
              state: {
                productInfo: productList.find(
                  (i) => i.value === selectedProduct
                ),
                configInfo: record,
              },
            })
          }
        >
          Options
        </CustomButton>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      align: "center",
      width: 250,
      render: (d, record, index) => (
        <div className="flex items-center justify-center gap-3">
          <CustomButton
            icon={<CheckOutlined />}
            loading={isSubmitting[`update${index}`]}
            disabled={
              isEqual(initialList[index], getFormValueOfIndex(index)) ||
              isSubmitting[`delete${index}`]
            }
            type="primary"
            onClick={() =>
              handleUpdateRow(
                getFormValueOfIndex(index),
                index,
                record?.type === "Save" ? "Save" : "Update"
              )
            }
          >
            {record?.type === "Save" ? "Save" : "Update"}
          </CustomButton>

          <Popconfirm
            title="Are you sure?"
            placement="topLeft"
            description="This action is irreversible. Do you still want to delete?"
            onConfirm={() => handleDeleteRow(index)}
            okText="Yes"
            cancelText="No"
            color={isDarkMode && "#282928"}
          >
            <CustomButton
              disabled={isSubmitting[`update${index}`]}
              loading={isSubmitting[`delete${index}`]}
              danger
              icon={<DeleteOutlined />}
              type="primary"
            >
              Delete
            </CustomButton>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const configurationOptions = list
    ?.map((i) => ({
      label: i.attributeLabel,
      value: i.id,
    }))
    ?.filter((i) => i.value && i.label);

  return (
    <div className="p-2 mt-4">
      <CustomBreadcrumb items={items} />

      <Select
        loading={productListLoading}
        value={selectedProduct}
        options={productList}
        onChange={handleSelectProduct}
        size="middle"
        placeholder="Select Product"
        className="my-2 min-w-[10rem]"
      />

      <Form form={form}>
        <Table
          columns={columns}
          className="mt-4"
          size="large"
          listLoading={listLoading}
          dataSource={list}
          tableLayout="fixed"
          bordered
          footer={() =>
            selectedProduct && (
              <CustomButton
                loading={listLoading}
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => handleAddConfig()}
              >
                Add Configuration
              </CustomButton>
            )
          }
          pagination={false}
          rowClassName={(_, index) =>
            index % 2 !== 0 ? getDarkModeColor(isDarkMode, "bg-gray-100") : ""
          }
        />
      </Form>
    </div>
  );
};

export default ProductConfigBuilderPage;
