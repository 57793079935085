import React from "react";
import ReactDOM from "react-dom/client";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.snow.css";
import { BrowserRouter as Router } from "react-router-dom";
import history from "../src/helpers/history";
import App from "./App";
import { AuthProvider } from "./Providers/AuthProvider";
import { DarkModeProvider } from "./Providers/DarkModeContext";
import { LocalStorageProvider } from "./Providers/LocalStorageProvider";
import { ScreenSizeProvider } from "./Providers/ScreenSizeProvider";
import { TourProvider } from "./Providers/TourProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <DarkModeProvider>
          <ScreenSizeProvider>
            <TourProvider>
              <LocalStorageProvider>
                <App history={history} />
              </LocalStorageProvider>
            </TourProvider>
          </ScreenSizeProvider>
        </DarkModeProvider>
      </AuthProvider>
    </Router>
  </React.StrictMode>
);
