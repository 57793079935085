import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";

const ViewInfoButton = ({ title, extraClassNames, ...otherProps }) => {
  return (
    <Tooltip title={title} {...otherProps}>
      <InfoCircleOutlined
        className={`${extraClassNames} text-blue-500 cursor-pointer hover:text-blue-300`}
      />
    </Tooltip>
  );
};

export default ViewInfoButton;
