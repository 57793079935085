import {
  CheckOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  FileDoneOutlined,
} from "@ant-design/icons";
import { Button, Drawer, Form, Input, Tooltip, message } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import RoleSearch from "../../Common/RoleSearch";
import UserCategorySearch from "../../Common/UserCategorySearch";
import ManageUserService from "../../services/User/ManageUserService";
import CustomButton from "../../Common/CustomButton";
import {
  validateEmail,
  validateTextInput,
} from "../../utils/validationsHelper";

const ManageUserDrawer = ({
  initialValue,
  changeStatus,
  onClose,
  onSuccess,
  updatePassword,
  setUpdatePassword,
}) => {
  const [form] = useForm();
  const [categoryData, setCategoryData] = useState([]);
  const [selectCategory, setSelectedCategory] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (changeStatus === "edit") {
      form.setFieldsValue({
        ...initialValue,
        roles: initialValue.roles.map((item) => item.roleID),
      });
    } else {
      form.resetFields();
    }
  }, [initialValue, changeStatus]);

  const onFinish = (values) => {
    setIsLoading(true);

    if (changeStatus == "edit") {
      const payload = {
        ...initialValue,
        ...values,
        roles: values.roles.map((item) => ({ roleID: item })),
      };

      ManageUserService.updateUserRoleData(payload)
        .then((res) => {
          setIsLoading(false);
          message.success("User Role Updated successfully");
          form.resetFields();
          onClose();
          onSuccess();
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          error?.response?.data?.message
            ? message.error(error?.response?.data?.message)
            : message.error("Something went wrong");
        });
    } else if (changeStatus === "add") {
      const payload = {
        ...values,
        roles: values.roles.map((item) => ({ roleID: item })),
      };

      ManageUserService.saveUserServicesData(payload)
        .then((res) => {
          setIsLoading(false);
          message.success("User Add Successfully");
          form.resetFields();
          onClose();
          onSuccess();
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          error?.response?.data?.message
            ? message.error(error?.response?.data?.message)
            : message.error("Something went wrong");
        });
    }
  };

  return (
    <Drawer
      title={
        changeStatus === "add"
          ? "Add User"
          : changeStatus === "edit"
          ? "Edit User"
          : ""
      }
      width={400}
      destroyOnClose={true}
      onClose={onClose}
      open={!!changeStatus}
      bodyStyle={{
        padding: "4px 16px",
      }}
    >
      <div className="rounded-md border-black-600">
        <div>
          <Form
            form={form}
            name="form_item_path"
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: "Enter first name",
                },
                { validator: validateTextInput("First name") },
              ]}
            >
              <Input size="medium" placeholder="Enter first name" />
            </Form.Item>

            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[
                { required: false },
                { validator: validateTextInput("Last name") },
              ]}
            >
              <Input size="medium" placeholder="Enter last name" />
            </Form.Item>

            <Form.Item
              name="emailAddress"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Enter email",
                },
                { validator: validateEmail },
              ]}
            >
              <Input size="medium" placeholder="Enter email" />
            </Form.Item>

            <Form.Item
              name="userCategory"
              label="Category"
              rules={[
                {
                  required: true,
                  message: "Select category",
                },
              ]}
            >
              <UserCategorySearch
                value={selectCategory}
                onChange={setSelectedCategory}
                categoryData={categoryData}
                setCategoryData={setCategoryData}
              />
            </Form.Item>

            <Form.Item
              name="roles"
              label="Role"
              rules={[
                {
                  required: true,
                  message: "Select role",
                },
              ]}
            >
              <RoleSearch />
            </Form.Item>

            {changeStatus === "edit" && updatePassword && (
              <Form.Item
                name="password"
                label="New Password"
                rules={[
                  {
                    required: true,
                    message: "Enter new password",
                  },
                  {
                    validator: (_, value) => {
                      const containsNumeric = /[0-9]/.test(value);
                      const containsAlphabetic = /[a-zA-Z]/.test(value);

                      if (containsNumeric && containsAlphabetic) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        "Password must contain both alphabetic and numeric characters"
                      );
                    },
                  },
                ]}
              >
                <Input.Password
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  size="medium"
                  placeholder="Enter new password"
                  minLength={8}
                  type="password"
                />
              </Form.Item>
            )}

            {changeStatus === "edit" && (
              <Tooltip
                title={
                  updatePassword
                    ? "Do you want to cancel the password update"
                    : "Would you like to update your password"
                }
              >
                <CustomButton
                  className="m-1"
                  onClick={() => setUpdatePassword(!updatePassword)}
                >
                  {updatePassword ? "Cancel" : "Update Password"}
                </CustomButton>
              </Tooltip>
            )}

            {changeStatus === "add" && (
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: changeStatus == "edit" ? false : true,
                    message: changeStatus == "add" ? "Enter password" : "",
                  },
                  {
                    validator: (_, value) => {
                      const containsNumeric = /[0-9]/.test(value);
                      const containsAlphabetic = /[a-zA-Z]/.test(value);

                      if (containsNumeric && containsAlphabetic) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        "Password must contain both alphabetic and numeric characters"
                      );
                    },
                  },
                ]}
              >
                <Input.Password
                  size="medium"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  placeholder="Enter password"
                  minLength={8}
                  type="password"
                />
              </Form.Item>
            )}

            <div className="flex justify-end gap-2 mt-5">
              <CustomButton
                type="primary"
                htmlType="submit"
                loading={isLoading}
                icon={<CheckOutlined />}
              >
                Submit
              </CustomButton>
              <CustomButton
                type="cancel"
                onClick={onClose}
                icon={<CloseOutlined />}
              >
                Cancel
              </CustomButton>
            </div>
          </Form>
        </div>
      </div>
    </Drawer>
  );
};

export default ManageUserDrawer;
