import { Tag } from "antd";
import React, { useState } from "react";
import { BaseEdge, EdgeLabelRenderer, getBezierPath } from "reactflow";
import { useDarkMode } from "../../../Providers/DarkModeContext";

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
}) => {
  const { isDarkMode } = useDarkMode();

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleDropdownClick = (visible) => {
    setIsDropdownVisible(visible);
  };

  const content = (
    <>
      {data.actions &&
        data.actions.length > 0 &&
        data.actions.map((action) => (
          <Tag key={action} className="select-none cursor-text">
            {action}
          </Tag>
        ))}
    </>
  );

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            pointerEvents: "all",
          }}
          className="nodrag nopan"
        >
          {/* {data.actions ? (
            <Popover
              placement="right"
              content={content}
              trigger="hover"
              open={isDropdownVisible}
              onOpenChange={handleDropdownClick}
              overlayInnerStyle={{ padding: 8 }}
            >
              <CustomButton
                type="dashed"
                className={` ${getDarkModeColor(
                  isDarkMode,
                  "bg-gray-200 border-black/40",
                  "text-white bg-[#1b1d1e] border-gray-600"
                )} !rounded-full `}
                icon={<CaretDownOutlined />}
              />
            </Popover>
          ) : (
            <></>
          )} */}
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export default CustomEdge;
