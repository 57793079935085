import React, { useEffect, useState } from "react";
import { Select, message } from "antd";
import ManageUserService from "../services/User/ManageUserService";

const RoleSearch = ({ value, onChange }) => {
  const [roleData, setRoleData] = useState([]);

  useEffect(() => {
    ManageUserService.getUserRoleData()
      .then((res) => {
        setRoleData(
          res.data.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        );
      })
      .catch((error) => {
        error?.response?.data?.message
          ? message.error(error?.response?.data?.message)
          : message.error("Something went Wrong");
      });
  }, []);

  const filterOption = (input, option) =>
    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <Select
      size="medium"
      placeholder="Select role"
      mode="multiple"
      showSearch
      allowClear="true"
      options={roleData}
      value={value}
      onChange={onChange}
      filterOption={filterOption}
    />
  );
};

export default RoleSearch;
