import {
  FileExcelOutlined,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Badge, Skeleton, Tabs, message } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import CustomBreadcrumb from "../../Common/CustomBreadcrumb";
import CustomButton from "../../Common/CustomButton";
import OrderManagementService from "../../services/OrderManagement/OrderManagementService";
import ProductService from "../../services/ProductBuilder/ProductService";
import OrderConfigure from "./OrderConfigure";
import OrderManagementSearchOrderNum from "./OrderManagementSearchOrderNum";

const items = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Order Management",
  },
];

const OrderManagement = () => {
  const [configureTabData, setConfigureTabData] = useState([]);
  const [activeTabLabel, setActiveTabLabel] = useState(
    configureTabData[0]?.name || ""
  );
  const [searchParams, setSearchParams] = useSearchParams({
    activeTab: activeTabLabel,
  });
  const [badgeCount, setBadgeCount] = useState({});
  const [isViewsLoading, setIsViewsLoading] = useState(false);
  const [isTableDataLoading, setIsTableDataLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadOnSave, setIsLoadOnSave] = useState(false);
  const [isOpenOrderItemsDrawer, setIsOpenOrderItemsDrawer] = useState(false);
  const [showButtons, setShowButtons] = useState(null);
  const [productsList, setProductsList] = useState([]);
  const location = useLocation();

  const filterType = location?.state?.type;
  const [list, setList] = useState({
    request: {
      pageRequest: {
        currentPage: 1,
        pageSize: 50,
      },
      sortRequest: {
        key: "",
        direction: true,
      },
      filterRequest: {
        refNum: null,
        startDate: "",
        endDate: "",
        type: filterType,
        productName: "",
      },
    },
    response: {
      records: [],
      totalRecords: 0,
    },
  });
  const [selectedOrderNum, setSelectedOrderNum] = useState(0);
  const [ordNum, setOrdNum] = useState("");
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetchData();
  }, [isLoadOnSave, filterType]);

  const refresh = () => {
    const newFilterRequest = {
      refNum: null,
      startDate: "",
      endDate: "",
      type: null,
    };

    setList((prev) => {
      return {
        ...prev,
        request: {
          ...prev.request,
          filterRequest: newFilterRequest,
        },
      };
    });
    setIsLoading(true);
  };

  useEffect(() => {
    //fetchProductGroupList()
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.has("activeTab")) {
      searchParams.set("activeTab", activeTabLabel);
      const newurl = `${window.location.protocol}//${window.location.host}${
        window.location.pathname
      }?${searchParams.toString()}`;
      window.history.replaceState({ path: newurl }, "", newurl);
    }
  }, []);

  const fetchCountData = async (request) => {
    try {
      const { data } = await OrderManagementService.getOrderCountData(request);

      setBadgeCount(data);
    } catch (error) {
      if (error?.response?.data?.message) {
        message.error(error.response.data.message);
      } else {
        message.error("Something went wrong");
      }
    }
  };

  const fetchData = async () => {
    setIsViewsLoading(true);

    try {
      const [
        pageDetailsRes,
        orderViewListRes,
        orderCountDataRes,
        productsList,
      ] = await Promise.all([
        OrderManagementService.getPageDetails(),
        OrderManagementService.getOrderViewList(),
        OrderManagementService.getOrderCountData(list.request.filterRequest),
        ProductService.getProductDataList(),
      ]);

      const buttonConfigureData =
        pageDetailsRes.data?.orderManagementPageComponentsResponse;

      const filteredConfigureData = orderViewListRes.data?.map((item) => ({
        ...item,
        disabled: false,
      }));

      const filteredProductsList = productsList?.data?.map((i) => ({
        label: i.name,
        value: i.name,
      }));

      const initialActiveTab =
        searchParams.get("activeTab") || orderViewListRes.data[0]?.name || "";

      setShowButtons(buttonConfigureData);

      setConfigureTabData(filteredConfigureData);

      setSearchParams({ activeTab: initialActiveTab }, { replace: true });

      setActiveTabLabel(initialActiveTab);

      setIsViewsLoading(false);

      setBadgeCount(orderCountDataRes.data);

      setProductsList(filteredProductsList);
    } catch (error) {
      setIsViewsLoading(false);
      if (error?.response?.data?.message) {
        message.error(error.response.data.message);
      } else {
        message.error("Something went wrong");
      }
    }
  };

  const toggleOrderItemsDrawer = (value) => {
    setIsOpenOrderItemsDrawer(value);
  };

  const clearTabSelection = () => {
    setActiveTabLabel("");
  };
  const getOrderTabDetails = (order) => {
    let filteredActiveLabel = configureTabData.find(
      (el) => el.primaryIdentifier === order.primaryIdentifierTab
    ).name;
    let { filterRequest, pageRequest, sortRequest } = list.request;
    filterRequest.startDate = "";
    filterRequest.endDate = "";
    filterRequest.refNum = order.quoteRefnum;
    pageRequest.currentPage = order.selectedPage;
    pageRequest.pageSize = order.pageSize;
    sortRequest.key = "";
    sortRequest.direction = true;
    clearTabSelection();
    setActiveTabLabel(filteredActiveLabel);
    setList(list);
    setSelectedOrderNum(parseInt(order.ordnum));
    setOrdNum(order.ordnum);

    if (window.location.search) {
      let searchParams = new URLSearchParams(window.location.search);
      searchParams.set("activeTab", filteredActiveLabel);
      searchParams.set("currentPage", order.selectedPage);
      searchParams.set("selectedOrderNum", order.ordnum);
      let newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        searchParams.toString();
      window.history.pushState({ path: newurl }, "", newurl);
    }

    OrderManagementService.getOrderCountData(filterRequest)
      .then((res) => {
        setBadgeCount(res.data);
      })
      .catch((error) => {
        error?.response?.data?.message
          ? message.error(error?.response?.data?.message)
          : message.error("Something went wrong");
      });

    toggleOrderItemsDrawer(false);
  };

  const handleExport = async () => {
    const primaryIdentifier = configureTabData.find(
      (item) => item.name === activeTabLabel
    ).primaryIdentifier;

    try {
      const response = await OrderManagementService.exportOrderList(
        primaryIdentifier
      );

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const tabName = activeTabLabel.toUpperCase();

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${tabName}-ORDERS-LIST.xlsx`;
      link.click();
    } catch (error) {
      console.error(error?.response?.data?.message);
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went wrong!"
      );
    }
  };

  return (
    <div className="p-2 mt-4">
      <div className="flex justify-between">
        <CustomBreadcrumb items={items} />

        <span className="flex items-center gap-3">
          {showButtons?.exportButton && list.response.records.length > 0 && (
            <CustomButton
              type="dashed"
              onClick={handleExport}
              className="hover:!bg-green-400 hover:!border-green-400 hover:!text-white"
              icon={<FileExcelOutlined />}
            >
              Export
            </CustomButton>
          )}

          {showButtons?.refreshButton && (
            <CustomButton
              icon={<SyncOutlined />}
              type="primary"
              onClick={() => refresh()}
            >
              Refresh
            </CustomButton>
          )}

          {showButtons?.searchButton && (
            <CustomButton
              icon={<SearchOutlined />}
              type="primary"
              onClick={() => toggleOrderItemsDrawer(true)}
            >
              Search
            </CustomButton>
          )}
        </span>
      </div>

      <div className="mt-2 ">
        {isViewsLoading ? (
          Array(5)
            .fill("")
            .map((i) => (
              <Skeleton.Button
                active={true}
                size="default"
                className="mx-0.5 mb-[10px]"
              />
            ))
        ) : (
          <Tabs
            className="font-semibold"
            activeKey={activeTabLabel}
            titleFontSize={30}
            onChange={(activeTab) => {
              setSearchParams({ activeTab }, { replace: true });
              setActiveTabLabel(activeTab);
              setList((prev) => ({
                ...prev,
                request: {
                  ...prev.request,
                  filterRequest: {
                    refNum: null,
                    startDate: "",
                    endDate: "",
                    type: filterType,
                  },
                },
              }));
            }}
            type="card"
            items={configureTabData.map((item, index) => ({
              label: (
                <div className="flex gap-1 select-none" key={index}>
                  {item.name}

                  <Badge
                    count={
                      Object.keys(badgeCount).includes(item.name)
                        ? badgeCount[item.name]
                        : "0"
                    }
                    showZero
                    color="blue"
                    style={{ borderRadius: "4px", color: "white" }}
                  />
                </div>
              ),
              key: item.name,
              disabled: isTableDataLoading && activeTabLabel !== item.name,
            }))}
          />
        )}
      </div>

      {configureTabData.map(
        (item) =>
          item.name === activeTabLabel && (
            <div key={item.primaryIdentifier} className="relative">
              <OrderConfigure
                isLoadOnSave={isLoadOnSave}
                setIsLoadOnSave={setIsLoadOnSave}
                tableConfigData={item}
                list={list}
                setList={setList}
                showButtons={showButtons}
                selectedOrderNum={selectedOrderNum}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                refresh={refresh}
                activeTab={activeTabLabel}
                isTableDataLoading={isTableDataLoading}
                setIsTableDataLoading={setIsTableDataLoading}
                productsList={productsList}
                fetchCountData={fetchCountData}
              />
            </div>
          )
      )}

      <OrderManagementSearchOrderNum
        isOpen={isOpenOrderItemsDrawer}
        toggle={toggleOrderItemsDrawer}
        getOrderTabDetails={getOrderTabDetails}
        searchText={searchText}
        setSearchText={setSearchText}
        setIsLoading={setIsLoading}
      />
    </div>
  );
};

export default OrderManagement;
