import { Card, Col, Form, Input, Row, Select } from "antd";
import React from "react";
import { NumericFormat } from "react-number-format";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import CustomButton from "../../../../Common/CustomButton";
import { useWatch } from "antd/es/form/Form";
import ProductSelectFormItem from "../Common/ProductSelectFormItem";

const AjProductDetails = ({
  previewOrder,
  editData,
  selectedAccount,
  renderConfigOptions,
  fetchForecastData,
  form,
  productList,
  productListLoading,
  forecastedDate,
  productID,
  productCost,
  configOptions,
  accessoriesPrice,
  setAccessoriesPrice,
  calculateAllPrice,
}) => {
  const barFinishValue = useWatch("barFinish", form);
  const blackingPlateValue = useWatch("blackingPlate", form);
  const indicatorsPlateValue = useWatch("indicators", form);

  return (
    <Card type="inner" title="Product Details" className="mt-2">
      <Row gutter={[16, 10]}>
        <Col sm={24} xs={24} md={24} lg={8} xl={6} className="-mb-2">
          <ProductSelectFormItem
            previewOrder={previewOrder}
            editData={editData}
            selectedAccount={selectedAccount}
            renderConfigOptions={renderConfigOptions}
            fetchForecastData={fetchForecastData}
            form={form}
            productList={productList}
            isLoading={productListLoading}
            forecastedDate={forecastedDate}
            showLabel={true}
            setAccessoriesPrice={setAccessoriesPrice}
          />
        </Col>

        {/* {previewOrder !== "preview" && (
          <Col lg={2} xl={2} className="-mb-2">
            <Form.Item
              label="Copy"
              name="copy"
              rules={[
                {
                  required: true,
                  message: "Select count",
                },
              ]}
            >
              <Select
                size="medium"
                placeholder="Select copy"
                disabled={
                  (previewOrder === "preview" && !editData) || !selectedAccount
                }
                options={Array.from({ length: 10 }, (v, i) => ({
                  label: i + 1,
                  value: i + 1,
                }))}
              />
            </Form.Item>
          </Col>
        )} */}

        <Col sm={24} xs={24} md={24} lg={8} xl={4} className="-mb-2">
          <Form.Item
            label="Make"
            name="make"
            rules={[
              {
                required: true,
                message: "Select make",
              },
            ]}
          >
            <Select
              showSearch
              size="medium"
              placeholder="Select make"
              disabled={
                (previewOrder === "preview" && !editData) || !selectedAccount
              }
              onChange={() => {
                form.setFieldValue("accessoriesInfo", undefined);
                setAccessoriesPrice({});
              }}
              options={configOptions["make"]?.filter((i) => i?.show)}
            />
          </Form.Item>
        </Col>

        <Col sm={24} xs={24} md={24} lg={8} xl={12} className="-mb-2">
          <Form.Item
            label="Model"
            name="model"
            rules={[
              {
                required: true,
                message: "Select model",
              },
            ]}
          >
            <Select
              showSearch
              size="medium"
              placeholder="Select model"
              disabled={
                (previewOrder === "preview" && !editData) || !selectedAccount
              }
              options={configOptions["model"]?.filter((i) => i?.show)}
            />
          </Form.Item>
        </Col>

        <Col sm={24} xs={24} md={24} lg={8} xl={4} className="xl:mt-3">
          <Form.Item label="Bar Finish">
            <Input.Group compact>
              <Form.Item
                name="barFinish"
                noStyle
                rules={[
                  {
                    required: false,
                    message: "Select bar finish",
                  },
                ]}
              >
                <Select
                  showSearch
                  size="medium"
                  style={{
                    width: barFinishValue === "Custom Colour" ? "50%" : "100%",
                  }}
                  placeholder="Select bar finish"
                  disabled={
                    (previewOrder === "preview" && !editData) ||
                    !selectedAccount
                  }
                  options={configOptions["barFinish"]?.filter((i) => i?.show)}
                />
              </Form.Item>

              {barFinishValue === "Custom Colour" && (
                <Form.Item
                  name="customBarFinish"
                  noStyle
                  rules={[
                    { required: false, message: "Enter custom bar finish" },
                  ]}
                >
                  <Input
                    style={{ width: "50%" }}
                    disabled={
                      (previewOrder === "preview" && !editData) ||
                      !selectedAccount
                    }
                    size="medium"
                    placeholder="Enter custom bar finish"
                  />
                </Form.Item>
              )}
            </Input.Group>
          </Form.Item>
        </Col>

        <Col sm={24} xs={24} md={24} lg={8} xl={4} className="xl:mt-3">
          <Form.Item
            label="Number Plate"
            name="numberPlate"
            rules={[
              {
                required: false,
                message: "Select number plate",
              },
            ]}
          >
            <Select
              showSearch
              size="medium"
              placeholder="Select number plate"
              disabled={
                (previewOrder === "preview" && !editData) || !selectedAccount
              }
              options={configOptions["numberPlate"]?.filter((i) => i?.show)}
            />
          </Form.Item>
        </Col>

        <Col sm={24} xs={24} md={24} lg={8} xl={4} className="xl:mt-3">
          <Form.Item label="Backing Plate Colour">
            <Input.Group compact>
              <Form.Item
                name="blackingPlate"
                noStyle
                rules={[
                  {
                    required: false,
                    message: "Select backing plate colour",
                  },
                ]}
              >
                <Select
                  showSearch
                  size="medium"
                  style={{
                    width:
                      blackingPlateValue === "Custom Colour" ? "50%" : "100%",
                  }}
                  placeholder="Select backing plate colour"
                  disabled={
                    (previewOrder === "preview" && !editData) ||
                    !selectedAccount
                  }
                  options={configOptions["blackingPlate"]?.filter(
                    (i) => i?.show
                  )}
                />
              </Form.Item>

              {blackingPlateValue === "Custom Colour" && (
                <Form.Item
                  name="customblackingPlateColor"
                  noStyle
                  rules={[{ required: false, message: "Enter custom color" }]}
                >
                  <Input
                    style={{ width: "50%" }}
                    disabled={
                      (previewOrder === "preview" && !editData) ||
                      !selectedAccount
                    }
                    size="medium"
                    placeholder="Enter custom color"
                  />
                </Form.Item>
              )}
            </Input.Group>
          </Form.Item>
        </Col>

        <Col sm={24} xs={24} md={24} lg={8} xl={4} className="xl:mt-3">
          <Form.Item label="Indicators">
            <Input.Group compact>
              <Form.Item
                name="indicators"
                noStyle
                rules={[
                  {
                    required: false,
                    message: "Select indicators",
                  },
                ]}
              >
                <Select
                  showSearch
                  size="medium"
                  style={{
                    width:
                      indicatorsPlateValue === "Custom (See Notes)"
                        ? "50%"
                        : "100%",
                  }}
                  placeholder="Select indicators"
                  disabled={
                    (previewOrder === "preview" && !editData) ||
                    !selectedAccount
                  }
                  options={configOptions["indicators"]?.filter((i) => i?.show)}
                />
              </Form.Item>

              {indicatorsPlateValue === "Custom (See Notes)" && (
                <Form.Item
                  name="customIndicators"
                  noStyle
                  rules={[
                    { required: false, message: "Enter custom indicators" },
                  ]}
                >
                  <Input
                    style={{ width: "50%" }}
                    disabled={
                      (previewOrder === "preview" && !editData) ||
                      !selectedAccount
                    }
                    size="medium"
                    placeholder="Enter custom indicators"
                  />
                </Form.Item>
              )}
            </Input.Group>
          </Form.Item>
        </Col>

        <Col sm={24} xs={24} md={24} lg={8} xl={4} className="xl:mt-3">
          <Form.Item
            label="Integrated Lights"
            name="integratedLights"
            rules={[
              {
                required: false,
                message: "Select integrated lights",
              },
            ]}
          >
            <Select
              showSearch
              size="medium"
              placeholder="Select integrated lights"
              disabled={
                (previewOrder === "preview" && !editData) || !selectedAccount
              }
              options={configOptions["integratedLights"]?.filter(
                (i) => i?.show
              )}
            />
          </Form.Item>
        </Col>

        <Col sm={24} xs={24} md={24} lg={8} xl={4} className="xl:mt-3">
          <Form.Item
            name="productCost"
            rules={[
              {
                required: false,
              },
            ]}
            className="sr-only"
          >
            <Input size="middle" disabled />
          </Form.Item>

          <div className={`text-end ${productCost ? "" : "sr-only"}`}>
            <p className="text-[15px] mb-2">Cost</p>

            <NumericFormat
              prefix="$"
              className="font-semibold"
              value={productCost}
              displayType="text"
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={true}
            />
          </div>
        </Col>
      </Row>

      <Form.List name="accessoriesInfo">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Row gutter={16} key={key} className="relative">
                <Col sm={16} xs={16} md={16} lg={4} xl={4}>
                  <Form.Item
                    {...restField}
                    name={[name, "accessories"]}
                    label={index === 0 && "Accessories"}
                    rules={[
                      {
                        required: false,
                        message: "Please select an accessory",
                      },
                    ]}
                  >
                    <Select
                      disabled={
                        (previewOrder === "preview" && !editData) ||
                        !selectedAccount
                      }
                      size="middle"
                      placeholder="Select an accessory"
                      options={configOptions["accessories"]?.filter(
                        (i) => i?.show
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col sm={16} xs={16} md={16} lg={3} xl={3}>
                  <Form.Item
                    {...restField}
                    name={[name, "description"]}
                    label={index === 0 && "Description"}
                    rules={[
                      {
                        required: false,
                        message: "Please provide a description",
                      },
                    ]}
                  >
                    <Select
                      disabled={
                        (previewOrder === "preview" && !editData) ||
                        !selectedAccount
                      }
                      size="middle"
                      placeholder="Select a description"
                      options={configOptions["description"]?.filter(
                        (i) => i?.show
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col sm={16} xs={16} md={16} lg={3} xl={3}>
                  <Form.Item
                    {...restField}
                    name={[name, "lightLocation"]}
                    label={index === 0 && "Location"}
                    rules={[
                      {
                        required: false,
                        message: "Please select a location",
                      },
                    ]}
                  >
                    <Select
                      disabled={
                        (previewOrder === "preview" && !editData) ||
                        !selectedAccount
                      }
                      size="middle"
                      placeholder="Select a location"
                      options={configOptions["lightLocation"]?.filter(
                        (i) => i?.show
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col sm={16} xs={16} md={16} lg={4} xl={4}>
                  <Form.Item
                    {...restField}
                    name={[name, "lightLayout"]}
                    label={index === 0 && "Layout"}
                    rules={[
                      {
                        required: false,
                        message: "Please select a layout",
                      },
                    ]}
                  >
                    <Select
                      size="middle"
                      disabled={
                        (previewOrder === "preview" && !editData) ||
                        !selectedAccount
                      }
                      placeholder="Select a layout"
                      options={configOptions["lightLayout"]?.filter(
                        (i) => i?.show
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col sm={16} xs={16} md={16} lg={4} xl={4}>
                  <Form.Item
                    {...restField}
                    name={[name, "lightMounting"]}
                    label={index === 0 && "Mounting"}
                    rules={[
                      {
                        required: false,
                        message: "Please select a mounting option",
                      },
                    ]}
                  >
                    <Select
                      size="middle"
                      disabled={
                        (previewOrder === "preview" && !editData) ||
                        !selectedAccount
                      }
                      placeholder="Select a mounting option"
                      options={configOptions["lightMounting"]?.filter(
                        (i) => i?.show
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col sm={16} xs={16} md={16} lg={2} xl={2} className="relative">
                  <Form.Item
                    {...restField}
                    name={[name, "number"]}
                    label={index === 0 && "Number"}
                    rules={[
                      {
                        required: false,
                        message: "Please select a number",
                      },
                    ]}
                  >
                    <Select
                      size="middle"
                      disabled={
                        (previewOrder === "preview" && !editData) ||
                        !selectedAccount
                      }
                      placeholder="Select a number"
                      options={Array.from({ length: 12 }, (v, i) => ({
                        label: i + 1,
                        value: i + 1,
                      }))}
                    />
                  </Form.Item>

                  {!(
                    (previewOrder === "preview" && !editData) ||
                    !selectedAccount
                  ) && (
                    <div
                      className={`absolute ${
                        index === 0 ? "top-[1.6rem]" : "top-0"
                      } -right-8`}
                    >
                      <CustomButton
                        danger
                        type="link"
                        size="middle"
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          remove(name);
                          setAccessoriesPrice((prev) => {
                            const newPrices = { ...prev };

                            delete newPrices[name];

                            for (
                              let i = name + 1;
                              i <= Object.keys(prev).length;
                              i++
                            ) {
                              if (newPrices[i] !== undefined) {
                                newPrices[i - 1] = newPrices[i];
                                delete newPrices[i];
                              }
                            }

                            return newPrices;
                          });
                          calculateAllPrice();
                        }}
                      />
                    </div>
                  )}
                </Col>

                <div
                  className={`absolute font-medium right-3 ${
                    index === 0 ? "top-7" : "top-1"
                  }`}
                >
                  <NumericFormat
                    prefix="$"
                    value={accessoriesPrice[name] ? accessoriesPrice[name] : 0}
                    className="font-semibold"
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </div>
              </Row>
            ))}

            {productID &&
              (previewOrder !== "preview" || editData) &&
              selectedAccount && (
                <Form.Item className="mt-2 w-fit">
                  <CustomButton
                    type="primary"
                    onClick={() => {
                      add({
                        accessories: configOptions["accessories"]?.filter(
                          (i) => i?.show
                        )[0]?.value,
                        description: configOptions["description"]?.filter(
                          (i) => i?.show
                        )[0]?.value,
                        lightLocation: configOptions["lightLocation"]?.filter(
                          (i) => i?.show
                        )[0]?.value,
                        lightLayout: configOptions["lightLayout"]?.filter(
                          (i) => i?.show
                        )[0]?.value,
                        lightMounting: configOptions["lightMounting"]?.filter(
                          (i) => i?.show
                        )[0]?.value,
                        number: 1,
                      });
                    }}
                    icon={<PlusOutlined />}
                    className="!h-[1.7rem] w-fit"
                  >
                    Add accessories
                  </CustomButton>
                </Form.Item>
              )}
          </>
        )}
      </Form.List>
    </Card>
  );
};

export default AjProductDetails;
