import axios from "../axios";

class ProductionService {
  static Instance() {
    return new ProductionService();
  }

  getAllProductGroup(token) {
    return axios.get(`api/product/group/list`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  getProductGroupList() {
    return axios.get(`api/product/group/list`);
  }

  getProcessLineDropdown(id) {
    return axios.get(
      `/api/productionCapacity/processLine/dropdown?productGroupID=${id}`
    );
  }

  changeCapacityForSingleDay(payload) {
    return axios.post("/api/productionCapacity/change/for/single/day", payload);
  }

  saveByDays(payload) {
    return axios.post("/api/productionCapacity/save", payload);
  }

  getProductionCapacityByDates(id, date, navigation) {
    return axios.get(
      `/api/productionCapacity/by-dates?productGroupID=${id}&ProductionDate=${date}&Navigation=${navigation}`
    );
  }

  manageSchedulerJobUpdate(identifier, type, orderJobId) {
    return axios.put(
      `/api/production/status?PrimaryIdentifier=${identifier}&Action=${type}&OrderJobID=${orderJobId}`
    );
  }

  getAttachmentsOfOrder(orderNum, type) {
    return axios.get(`/api/file/details?OrdNum=${orderNum}&Type=${type}`);
  }

  getPreviewFile(type, fileName) {
    return axios.get(`/api/file/preview?Type=${type}&fileName=${fileName}`);
  }

  saveDefaultCapacity(payload) {
    return axios.put("/api/productionCapacity/default", payload);
  }

  updateDefaultCapacity(payload) {
    return axios.put("/api/productionCapacity/default", payload);
  }

  getProductionScheduleData(productGroupID) {
    return axios.post(
      `/api/production/schedule?ProductGroupID=${productGroupID}`
    );
  }

  getSearchResultsForScheduler(searchedText) {
    return axios.post(`/api/production/details?searchParam=${searchedText}`);
  }

  reScheduleProductionLine(isRebuild, scheduleDays, productGroupID) {
    return axios.put(
      `/api/production/schedule/slots/reschedule?IsRebuild=${isRebuild}&ScheduleDays=${scheduleDays}&ProductGroupID=${productGroupID}`
    );
  }

  putOrderOnhold(PrimaryIdentifier) {
    return axios.put(
      `/api/order/set/onhold?PrimaryIdentifier=${PrimaryIdentifier}`
    );
  }
  scheduleAfterOnHold(){
    return axios.put(`/api/production/schedule/slots/reschedule?IsRebuild=true&ScheduleDays=7&ProductGroupID=2`)
  }
  getOrderOnHoldList() {
    return axios.get("/api/order/get/onhold/list");
  }
  updateScheduleOrderJobPriority(request) {
    return axios.put("api/production/job/priority", request);
  }

  reScheduleItem({ productionScheduleID, scheduleOn }) {
    return axios.post(
      `/api/production/change/date?productionScheduleID=${productionScheduleID}&scheduleOn=${scheduleOn}`,
      {}
    );
  }
}
export default ProductionService.Instance();
