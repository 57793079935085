import React from "react";
import { Drawer, Descriptions, Button, Input, Select } from "antd";
import CustomSwitch from "../../../Common/CustomSwitch";
import { RedoOutlined } from "@ant-design/icons";

const FiltersDrawer = ({
  filters,
  open,
  onClose,
  setFilters,
  allViews,
  fetchAllStatus,
}) => {
  const resetFilters = () => {
    setFilters({
      modal: true,
      capacityEnabled: false,
      filterView: null,
    });

    fetchAllStatus();
  };

  return (
    <Drawer
      title="Filters"
      onClose={onClose}
      open={open}
      width={400}
      destroyOnClose
      bodyStyle={{
        padding: "16px",
      }}
      extra={
        <Button icon={<RedoOutlined />} onClick={() => resetFilters()}>
          Reset Filters
        </Button>
      }
    >
      <Descriptions bordered size="small" column={1}>
        <Descriptions.Item
          label="Filter View"
          contentStyle={{ width: "14rem" }}
        >
          <Select
            options={allViews}
            placeholder="Select View"
            size="middle"
            allowClear
            value={filters.filterView}
            onChange={async (value) => {
              if (filters.filterView !== null) {
                await fetchAllStatus();
              }

              setFilters((prev) => ({
                ...prev,
                filterView: value,
              }));
            }}
            className="w-full"
          />
        </Descriptions.Item>

        <Descriptions.Item label="Capacity Enabled">
          <CustomSwitch
            size="default"
            noTooltip
            active={filters.capacityEnabled}
            onChange={() =>
              setFilters((prev) => ({
                ...prev,
                capacityEnabled: !filters.capacityEnabled,
              }))
            }
          />
        </Descriptions.Item>
      </Descriptions>
    </Drawer>
  );
};

export default FiltersDrawer;
