import {
  EditOutlined,
  EnvironmentOutlined,
  MessageOutlined,
  PhoneOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Descriptions,
  Skeleton,
  Tabs,
  Typography,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CustomBreadcrumb from "../../Common/CustomBreadcrumb";
import CustomButton from "../../Common/CustomButton";
import { AuthContext } from "../../Providers/AuthProvider";
import { useDarkMode } from "../../Providers/DarkModeContext";
import CustomerService from "../../services/CustomerService/CustomerService";
import { getDarkModeColor } from "../../utils/darkModeHelper";
import CustomerAddress from "./CustomerAddress";
import CustomerContact from "./CustomerContact";
import CustomerGeneralDetails from "./CustomerGeneralDetails";
import CustomerOrders from "./CustomerOrders";
import ManageCustomerAccount from "./ManageCustomerAccount";

const CustomerEnquiry = () => {
  const { userDetails } = useContext(AuthContext);
  const { isDarkMode } = useDarkMode();
  const { isExternalUser } = userDetails;
  const [customerData, setCustomerData] = useState([]);
  const [editData, setEditData] = useState({});
  const [editAction, setEditAction] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams({
    activeTab: "General",
  });
  const [data, setData] = useState(null);
  const [primaryIdentifier, setPrimaryIdentifier] = useState("");
  const [searchAccountId, setSearchAccountId] = useState("");
  const [searchAccountDetailsOptions, setSearchAccountDetailsOptions] =
    useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [otherLoading, setOtherLoading] = useState("");

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const ulid = url.searchParams.get("key");

  const getCustomerData = async (ulid) => {
    setIsLoading(true);

    try {
      const res = await CustomerService.getCustomerAccountDetails(ulid);
      const data = res.data;
      setCustomerData(data);
      setSearchAccountId(
        `${res.data.debtorResponse.accountID} | ${res.data.debtorResponse.company}`
      );
      setPrimaryIdentifier(res?.data?.debtorResponse?.primaryIdentifier);
    } catch (error) {
      message.error(
        error?.response?.data?.message ||
          "Unable to fetch data right now. Try again later!"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = () => {
    handleSearchFunction();

    if (ulid) {
      setPrimaryIdentifier(ulid);
      getCustomerData(ulid);
    } else {
      handleAccountSelect();
    }
  };

  const fetchDetailsData = async () => {
    setIsLoading(true);
    try {
      const res = await CustomerService.getCustomerDetails(ulid);

      setData(res.data);
    } catch (error) {
      console.log(error);
      message.error(
        error?.response?.data?.message ||
          "Unable to fetch data right now. Try again later!"
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();

    if (ulid) {
      fetchDetailsData();
    }
  }, [ulid]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.has("activeTab")) {
      searchParams.set("activeTab", "General");
      const newurl = `${window.location.protocol}//${window.location.host}${
        window.location.pathname
      }?${searchParams.toString()}`;
      window.history.replaceState({ path: newurl }, "", newurl);
    }
  }, []);

  const handleAccountSelect = async (data, option) => {
    setOtherLoading("options-fetch");

    const payload = {
      accountId: option?.key || "",
    };

    try {
      const res = await CustomerService.getCustomerEnquiryList(payload);
      let ULID = res.data[0].primaryIdentifier;

      if (ULID) {
        setPrimaryIdentifier(ULID);
        setSearchParams(
          {
            key: ULID,
            activeTab: "General",
          },
          { replace: true }
        );

        await getCustomerData(ULID);
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message ||
          "Unable to fetch account details right now. Try again later!"
      );
    } finally {
      setOtherLoading("");
    }
  };

  const handleEditFunction = (customerData) => {
    setEditAction("edit");
    setEditData(customerData);
  };

  const handleSearchFunction = (text) => {
    clearTimeout(handleSearchFunction.debounce);

    handleSearchFunction.debounce = setTimeout(() => {
      const payload = {
        accountId: text || "",
      };

      CustomerService.getCustomerDataDropDownList(payload)
        .then((res) => {
          setSearchAccountDetailsOptions(
            res.data.map((item) => ({
              value: `${item.accountID} | ${item.accountName}`,
              label: `${item.accountID} | ${item.accountName}`,
              key: item.accountID,
            }))
          );
        })
        .catch((error) => {
          message.error(
            error?.response?.data?.message ||
              "Unable to fetch options right now. Try again later!"
          );
        });
    }, 300);
  };

  const tabsItem = [
    {
      key: "General",
      label: "General",
      children: (
        <CustomerGeneralDetails
          primaryIdentifier={primaryIdentifier}
          data={data}
          isLoading={isLoading}
          fetchData={fetchDetailsData}
        />
      ),
    },
    {
      key: "Orders",
      label: "Orders",
      children: <CustomerOrders primaryIdentifier={primaryIdentifier} />,
    },

    {
      key: "Addresses",
      label: "Addresses",
      children: <CustomerAddress primaryIdentifier={primaryIdentifier} />,
    },
    {
      key: "Contacts",
      label: "Contacts",
      children: (
        <CustomerContact
          primaryIdentifier={primaryIdentifier}
          customerData={customerData}
        />
      ),
    },
  ];

  const items = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "Customer",
      path: "/customer/list",
    },
    {
      title: "Customer Enquiry",
    },
  ];

  return (
    <>
      {customerData && (
        <div className="p-2 mt-4 enquiry">
          <div className="flex flex-wrap items-start justify-between">
            <CustomBreadcrumb items={items} />

            {!isExternalUser && searchAccountDetailsOptions.length > 0 && (
              <AutoComplete
                defaultValue={searchAccountId}
                placeholder={"Search account"}
                className="w-full md:w-[15rem]"
                size="middle"
                options={searchAccountDetailsOptions}
                onSearch={handleSearchFunction}
                onSelect={handleAccountSelect}
                suffixIcon={<SearchOutlined />}
              />
            )}
          </div>

          <Descriptions
            bordered
            column={1}
            className="mt-4"
            size="small"
            contentStyle={{ width: "85%" }}
          >
            <Descriptions.Item
              contentStyle={{ fontWeight: 600 }}
              label="Customer name"
            >
              {isLoading ? (
                <Skeleton.Input active size="small" block />
              ) : (
                <div className="flex items-center justify-between">
                  <Typography className="flex items-center">
                    {customerData?.debtorResponse?.company
                      ? customerData?.debtorResponse?.company
                      : "NA"}
                  </Typography>

                  {isExternalUser === false && (
                    <CustomButton
                      type="primary"
                      onClick={() => handleEditFunction(customerData)}
                      icon={<EditOutlined />}
                    >
                      Edit
                    </CustomButton>
                  )}
                </div>
              )}
            </Descriptions.Item>

            <Descriptions.Item label="General contact">
              {isLoading ? (
                <Skeleton.Input active size="small" block />
              ) : (
                <div className="flex items-center gap-3">
                  <Typography className="flex items-center">
                    <PhoneOutlined rotate={"90"} className="mr-2" />
                    <a
                      className={getDarkModeColor(
                        isDarkMode,
                        "!text-blue-500",
                        "!text-blue-500"
                      )}
                      href={`tel:${customerData?.addressResponse?.phone}`}
                    >
                      {customerData?.addressResponse?.phone}
                    </a>
                  </Typography>

                  <Typography className="flex items-center">
                    <MessageOutlined className="mr-2" />
                    {customerData?.addressResponse?.emailAddress ? (
                      <a
                        className={getDarkModeColor(
                          isDarkMode,
                          "!text-blue-500",
                          "!text-blue-500"
                        )}
                        href={`mailto:${customerData?.addressResponse?.emailAddress}`}
                      >
                        {customerData?.addressResponse?.emailAddress}
                      </a>
                    ) : (
                      "NA"
                    )}
                  </Typography>
                </div>
              )}
            </Descriptions.Item>

            <Descriptions.Item label="Billing address">
              {isLoading ? (
                <Skeleton.Input active size="small" block />
              ) : (
                <Typography className="flex items-center">
                  <EnvironmentOutlined className="mr-1" />
                  <a
                    className={getDarkModeColor(
                      isDarkMode,
                      "!text-blue-500",
                      "!text-blue-500"
                    )}
                    target="blank"
                    href={`https://www.google.com/maps?q=${customerData?.addressResponse?.country},${customerData?.addressResponse?.state},${customerData?.addressResponse?.city},${customerData?.addressResponse?.postCode}`}
                  >
                    {`${customerData?.addressResponse?.country}, ${customerData?.addressResponse?.state}, ${customerData?.addressResponse?.city}, ${customerData?.addressResponse?.postCode}`}
                  </a>
                </Typography>
              )}
            </Descriptions.Item>
          </Descriptions>

          <Tabs
            type="card"
            className="!mb-0 mt-4 relative"
            items={tabsItem}
            activeKey={searchParams.get("activeTab")}
            onChange={(activeTab) => {
              searchParams.set("activeTab", activeTab);
              setSearchParams(searchParams);
            }}
          />

          {
            <ManageCustomerAccount
              onSuccess={() => {
                fetchData();
              }}
              editAction={editAction}
              editData={editData}
              onClose={() => {
                setEditAction(null);
                setEditData(null);
              }}
            />
          }
        </div>
      )}
    </>
  );
};

export default CustomerEnquiry;
