import {
  CheckOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Card, Tooltip, Typography, Upload, message } from "antd";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../../Common/CustomBreadcrumb";
import CustomButton from "../../../Common/CustomButton";
import { AuthContext } from "../../../Providers/AuthProvider";
import OrderManagementService from "../../../services/OrderManagement/OrderManagementService";
import { exportErrorExcel } from "../../../utils/exportErrors";
import ErrorImportTable from "./ErrorImportTable";
import UploadAttachmentTable from "./UploadAttachmentTable";

const items = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Import Orders",
  },
];

const ImportOrdersPage = () => {
  let { userPrimaryRole } = useContext(AuthContext);
  let isAdmin = userPrimaryRole?.toLowerCase()?.includes("admin");
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState("");
  const [canSelectFile, setCanSelectFile] = useState(false);
  const [errorForExcelFile, setErrorForExcelFile] = useState([]);
  const [orderListForAttachment, setOrderListForAttachment] = useState(null);

  const downloadSampleTemplate = async () => {
    setIsLoading(true);
    try {
      const response = await OrderManagementService.getSampleTemplate();

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "ORDERS-LIST-TEMPLATE.xlsx";
      link.click();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error downloading file:", error);
      message.error(
        error?.response?.data?.message ||
          "Unable to download right now. Try again later!"
      );
    }
  };

  const handleCheckData = async () => {
    const formData = new FormData();

    formData.append("File", fileList[0]);
    formData.append("IsDryRun", true);

    setUploading("check-list");
    try {
      const res = await OrderManagementService.importOrdersList(formData);

      if (res?.data?.importDataResponse.hasError) {
        setErrorForExcelFile(res?.data.importDataResponse.rows);
        exportErrorExcel(
          res?.data.importDataResponse.rows,
          "ORDERS-LIST-ERRORS.xlsx",
          "order"
        );
      } else {
        setCanSelectFile(true);
        message.success("The file has passed validation.");
      }
    } catch (error) {
      console.error(error?.response?.data?.message);
      message.error(
        error?.response?.data?.message ||
          "Unable to check file right now. Try again later!"
      );
    } finally {
      setUploading("");
    }
  };

  const handleCreate = async () => {
    const formData = new FormData();

    formData.append("File", fileList[0]);
    formData.append("IsDryRun", false);

    setUploading("upload-file");
    try {
      const res = await OrderManagementService.importOrdersList(formData);
      setOrderListForAttachment(res?.data);
      setUploading(false);
      message.success("Orders created! Add Attachments if needed.");
    } catch (error) {
      setUploading(false);
      console.error(error?.response?.data?.message);
      message.error(
        error?.response?.data?.message ||
          "Unable to create orders right now. Try again later!"
      );
    } finally {
      setUploading("");
    }
  };

  return (
    <div className="p-2 mt-4">
      <CustomBreadcrumb items={items} />

      <Card
        title="Excel Import"
        size="default"
        className="mt-4 drop-shadow-sm"
        extra={
          <CustomButton
            size="middle"
            icon={<DownloadOutlined />}
            loading={isLoading}
            type="secondary"
            className="!my-2"
            onClick={downloadSampleTemplate}
          >
            Download Template
          </CustomButton>
        }
      >
        <div className="flex flex-wrap items-center justify-between w-full gap-y-4 gap-x-8">
          <div className="flex items-center">
            <Upload
              accept=".xlsx,.csv"
              className="flex flex-wrap items-center gap-3"
              maxCount={1}
              fileList={fileList}
              itemRender={(_, file) => {
                return (
                  <div className="flex items-center justify-between dark:border-gray-600 gap-3 py-[4px] border border-gray-300 px-3 rounded-md">
                    <Typography.Text
                      ellipsis={{ tooltip: true }}
                      rootClassName="!max-w-[8rem] md:!max-w-[15rem]"
                    >
                      {file?.name}
                    </Typography.Text>

                    <Tooltip title="Remove file">
                      <DeleteOutlined
                        className="text-base transition duration-200 cursor-pointer hover:text-blue-400"
                        onClick={() => {
                          setFileList([]);
                          setCanSelectFile(false);
                          setErrorForExcelFile([]);
                        }}
                      />
                    </Tooltip>
                  </div>
                );
              }}
              onRemove={() => {
                setFileList([]);
                setCanSelectFile(false);
                setErrorForExcelFile([]);
              }}
              beforeUpload={(file) => {
                setFileList([file]);
                setCanSelectFile(false);
                setErrorForExcelFile([]);
                return false;
              }}
            >
              <CustomButton size="middle" icon={<CloudUploadOutlined />}>
                {fileList.length === 0 ? "Select File" : "Change File"}
              </CustomButton>
            </Upload>

            {fileList.length === 0 && (
              <div className="hidden text-blue-500 md:block">
                No file Selected
              </div>
            )}
          </div>

          {fileList.length !== 0 && (
            <div className="flex flex-wrap items-center gap-2">
              <CustomButton
                size="middle"
                type="green"
                onClick={handleCheckData}
                disabled={canSelectFile || errorForExcelFile.length > 0}
                loading={uploading === "check-file"}
                icon={
                  !canSelectFile ? <InfoCircleOutlined /> : <CheckOutlined />
                }
              >
                Check File
              </CustomButton>

              <CustomButton
                size="middle"
                type="primary"
                onClick={handleCreate}
                disabled={!canSelectFile}
                loading={uploading === "upload-file"}
                icon={<PlusOutlined />}
              >
                Create customers
              </CustomButton>
            </div>
          )}
        </div>

        {errorForExcelFile.length === 0 && (
          <Typography className="mt-2 font-medium text-gray-400">
            Note: Maximum 500 rows are accepted at once
          </Typography>
        )}

        {errorForExcelFile.length > 0 && (
          <ErrorImportTable dataSource={errorForExcelFile} />
        )}
      </Card>

      {orderListForAttachment !== null && (
        <div className="w-2/3 mt-6 drop-shadow-lg">
          <UploadAttachmentTable
            dataSource={orderListForAttachment?.orderRequests}
            primaryIdentifierLists={orderListForAttachment?.order}
            onClose={() => {
              setFileList([]);
              setCanSelectFile(false);
              setErrorForExcelFile([]);
              setOrderListForAttachment(null);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ImportOrdersPage;
