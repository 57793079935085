import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Empty,
  Form,
  Input,
  Radio,
  Select,
  Spin,
  message,
} from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import CustomButton from "../../Common/CustomButton";
import OrderManagementService from "../../services/OrderManagement/OrderManagementService";

const { TextArea } = Input;

const OrderChecklistDrawer = ({
  isDrawerOpen,
  onClose,
  orderDetails,
  type,
}) => {
  const [loading, setLoading] = useState("");
  const [checklistDetails, setChecklistDetails] = useState([]);
  const [form] = useForm();

  const fetchChecklistDetails = async () => {
    setLoading("list-fetch");

    try {
      const { data } = await OrderManagementService.getChecklistDetails(
        orderDetails.checklistID
      );

      if (type === "response") {
        await fetchChecklistResponseDetails();
      }

      setChecklistDetails(data);
    } catch (error) {
      console.error(error);
      message.error(
        error?.response?.data?.message ||
          "Unable to get checklist details. Try again later!"
      );
    } finally {
      setLoading("");
    }
  };

  const fetchChecklistResponseDetails = async () => {
    if (!orderDetails.primaryIdentifier && !orderDetails.checklistID) {
      return;
    }

    try {
      const { data } =
        await OrderManagementService.getAttachedChecklistResponse(
          orderDetails.primaryIdentifier,
          orderDetails.checklistID,
          orderDetails.status
        );

      const formValues = {};

      data?.forEach(({ checkListOption, response }) => {
        const { id, checkListType } = checkListOption;

        if (checkListType === "DATETIME") {
          formValues[id] = dayjs(response);
        } else if (response.includes(",")) {
          formValues[id] = response.split(", ").map((item) => item.trim());
        } else {
          formValues[id] = response;
        }
      });

      form.setFieldsValue(formValues);
    } catch (error) {
      console.error(error);
      message.error(
        error?.response?.data?.message ||
          "Unable to get checklist response details. Try again later!"
      );
    }
  };

  useEffect(() => {
    if (isDrawerOpen && orderDetails?.checklistID) {
      fetchChecklistDetails();
    }
  }, [isDrawerOpen, orderDetails]);

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleFinish = async (values) => {
    setLoading("submit");

    const updatedValues = [];

    Object.entries(values).map(([optionId, value]) => {
      const updatedValue = Array.isArray(value) ? value.join(", ") : value;

      updatedValues.push({ [optionId]: updatedValue });
    });

    try {
      await OrderManagementService.submitChecklistDetails({
        response: updatedValues,
        orderItemID: orderDetails.jobDetails[0]?.orderItem,
        checklistID: orderDetails?.checklistID,
      });

      message.success("Checklist passed successfully!");
      handleClose();
    } catch (error) {
      console.error(error);
      message.error(
        error?.response?.data?.message ||
          "Unable to submit the checklist. Try again later!"
      );
    } finally {
      setLoading("");
    }
  };

  const renderField = (item) => {
    let { checkListType, options, description, id } = item;

    options = options ? JSON.parse(options) : [];

    switch (checkListType) {
      case "TEXTAREA":
        return (
          <Form.Item
            key={id}
            label={description}
            name={id}
            rules={[{ required: true, message: "This field is required" }]}
          >
            <TextArea rows={4} placeholder="Enter here" />
          </Form.Item>
        );

      case "RADIO":
        return (
          <Form.Item
            key={id}
            label={description}
            name={id}
            rules={[{ required: true, message: "Please select an option" }]}
          >
            <Radio.Group
              className="flex flex-col gap-2"
              options={options.map((opt) => ({
                label: opt.label,
                value: opt.value,
              }))}
            />
          </Form.Item>
        );

      case "CHECKBOX":
        return (
          <Form.Item
            key={id}
            label={description}
            name={id}
            rules={[
              { required: true, message: "Please select at least one option" },
            ]}
          >
            <Checkbox.Group
              className="flex flex-col gap-2"
              options={options.map((opt) => ({
                label: opt.label,
                value: opt.value,
              }))}
            />
          </Form.Item>
        );

      case "DATETIME":
        return (
          <Form.Item
            key={id}
            label={description}
            name={id}
            rules={[
              { required: true, message: "Please select a date and time" },
            ]}
          >
            <DatePicker format="DD-MM-YYYY" className="w-full" size="middle" />
          </Form.Item>
        );

      case "DROPDOWN":
        return (
          <Form.Item
            key={id}
            label={description}
            name={id}
            rules={[{ required: true, message: "Please select an option" }]}
          >
            <Select
              placeholder="Select an option"
              options={options.map((opt) => ({
                label: opt.label,
                value: opt.value,
              }))}
            />
          </Form.Item>
        );

      default:
        return null;
    }
  };

  return (
    <Drawer
      title={checklistDetails?.name || "Checklist"}
      open={isDrawerOpen}
      onClose={handleClose}
      width={500}
      className="checklist"
      destroyOnClose
    >
      {loading === "list-fetch" ? (
        <div className="flex items-center justify-center my-4">
          <Spin />
        </div>
      ) : (
        <Form
          form={form}
          disabled={
            type === "view" || type === "response" || loading === "submit"
          }
          onFinish={handleFinish}
          layout="vertical"
        >
          {checklistDetails?.checkListOptions?.length ? (
            checklistDetails?.checkListOptions?.map((item, index, arr) => (
              <React.Fragment key={item.id}>
                {renderField(item)}

                {arr.length - 1 !== index && <Divider className="mt-4 mb-3" />}
              </React.Fragment>
            ))
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No questions found in this checklist."
            />
          )}

          {type !== "view" && type !== "response" && (
            <div className="flex justify-end gap-2 my-6">
              <Form.Item>
                <CustomButton
                  type="primary"
                  htmlType="submit"
                  icon={<CheckOutlined />}
                >
                  Submit
                </CustomButton>
              </Form.Item>

              <Form.Item>
                <CustomButton
                  type="cancel"
                  onClick={handleClose}
                  icon={<CloseOutlined />}
                >
                  Cancel
                </CustomButton>
              </Form.Item>
            </div>
          )}
        </Form>
      )}
    </Drawer>
  );
};

export default OrderChecklistDrawer;
