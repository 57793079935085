import React, { memo } from "react";
import { Divider, Typography } from "antd";

const TabsNode = memo(({ data, id }) => {
  return (
    <div
      style={{
        width: data.show > 1 ? 300 * data.show - 92 : 208 * data.show,
      }}
      className={`relative flex items-center justify-center`}
    >
      <Divider className="!border-gray-600">
        <Typography className="font-semibold">{data.label}</Typography>
      </Divider>

      <Divider
        type="vertical"
        className="h-[0.5rem] !border-gray-600 top-7 absolute -left-2"
      />
      <Divider
        type="vertical"
        className="h-[0.5rem] !border-gray-600 top-7 absolute -right-2"
      />
    </div>
  );
});

export default TabsNode;
