import React, { useState } from "react";
import BG from "../../assets/bg.png";
import LoginCard from "./LoginCard";
import ForgetPasswordCard from "./ForgetPasswordCard";
import ResetPasswordCard from "./ResetPasswordCard";
import { Switch } from "antd";
import { useDarkMode } from "../../Providers/DarkModeContext";
import { getDarkModeColor } from "../../utils/darkModeHelper";
import { FaMoon, FaSun } from "react-icons/fa";
import DarkModeToggler from "../../Common/DarkModeToggler";

const LoginPage = () => {
  const { isDarkMode, toggleDarkMode } = useDarkMode();
  const [currentCard, setCurrentCard] = useState("login");

  return (
    <div
      className={`flex items-center justify-center w-full h-screen ${getDarkModeColor(
        isDarkMode,
        "bg-gray-100"
      )}`}
      style={{
        backgroundImage: `url(${BG})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="absolute top-5 right-5">
        <DarkModeToggler />
      </div>

      <div
        className={`flex items-center justify-center ${getDarkModeColor(
          isDarkMode,
          "bg-white",
          "!bg-[#181a1b]"
        )}  rounded-2xl w-fit drop-shadow-md`}
      >
        <div className="relative flex flex-col w-full md:w-[33rem] lg:w-[30rem] items-center justify-center px-8 py-16 text-center md:px-2">
          {currentCard === "login" && (
            <LoginCard setCurrentCard={setCurrentCard} />
          )}

          {currentCard === "forgetPassword" && (
            <ForgetPasswordCard setCurrentCard={setCurrentCard} />
          )}

          {currentCard === "resetPassword" && (
            <ResetPasswordCard setCurrentCard={setCurrentCard} />
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
