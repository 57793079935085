import {
  CheckOutlined,
  CommentOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  FileTextOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Popover,
  Radio,
  Table,
  Tag,
} from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../Common/CustomBreadcrumb";
import CustomButton from "../../Common/CustomButton";
import ToolTipButton from "../../Common/ToolTipButton";
import { useDarkMode } from "../../Providers/DarkModeContext";
import TicketService from "../../services/Ticket/TicketService";
import { getDarkModeColor } from "../../utils/darkModeHelper";
import { formatDate } from "../../utils/dateHelper";
import ManageTicketsDrawer from "./ManageTicketsDrawer";
import TicketCommentsDrawer from "./TicketCommentsDrawer";
import TicketsAttachmentDrawer from "./TicketsAttachmentDrawer";

const items = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Ticket",
  },
];

export const priorityOptions = [
  { label: "High", value: 1 },
  { label: "Medium", value: 2 },
  { label: "Low", value: 3 },
];

const statusOptions = [
  { label: "NEW", value: 1 },
  { label: "OPEN", value: 2 },
  { label: "COMPLETED", value: 3 },
  { label: "CANCELLED", value: 4 },
];

const TicketsPage = () => {
  const { isDarkMode } = useDarkMode();

  const [listFetching, setListFetching] = useState(false);
  const [loading, setLoading] = useState("");
  const [showHiddenColumns, setShowHiddenColumns] = useState(true);
  const [statusPopover, setStatusPopover] = useState("");

  const [descriptionModal, setDescriptionModal] = useState({
    open: false,
    data: null,
  });

  const [manageDrawer, setManageDrawer] = useState({
    openType: null,
    initalValue: null,
  });
  const [ticketsDrawer, setTicketsDrawer] = useState({
    open: false,
    initalValue: null,
  });

  const [ticketsList, setTicketsList] = useState({
    request: {
      pageRequest: {
        currentPage: 1,
        pageSize: 10,
      },
      sortRequest: {
        key: "",
        direction: true,
      },
      filterRequest: {
        ticketNo: "",
        description: "",
        title: "",
        priority: [],
        status: []
      },
    },
    response: {
      records: [],
      totalRecords: 0,
    },
  });

  const [statusForm] = useForm();

  useEffect(() => {
    fetchTickets();
  }, []);

  const fetchTickets = async (paginationDetails = {}) => {
    setListFetching(true);

    try {
      const updatedRequest = {
        ...ticketsList.request,
        pageRequest: {
          ...ticketsList.request.pageRequest,
          ...paginationDetails,
        },
        filterRequest: {
          ...ticketsList.request.filterRequest,
          priority: paginationDetails?.filters?.priority,
          status: paginationDetails?.filters?.status
        }
      };

      const res = await TicketService.getTicketList(updatedRequest);

      setTicketsList((prev) => ({
        ...prev,
        request: updatedRequest,
        response: {
          records: res?.data?.records || [],
          totalRecords: res?.data?.totalRecords || 0,
        },
      }));
    } catch (error) {
      console.error(error);
      message.error(
        error?.response?.data?.message ||
          "Something went wrong while fetching tickets."
      );
    } finally {
      setListFetching(false);
    }
  };

  const handleFiltersChange = (pagination, filters, sorter, extra) => {
    fetchTickets({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
      filters,
      sorter,
    });
  };

  const handleStatusUpdate = async (values, record) => {
    setLoading(`status-update`);

    try {
      await TicketService.updateTicket({ ...record, ...values });

      setStatusPopover("");
      fetchTickets();

      statusForm.resetFields();

      message.success("Updated status successfully!");
    } catch (error) {
      console.log(error);
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went Wrong"
      );
    } finally {
      setLoading("");
    }
  };

  const handleDeleteTicket = async (record, index, type) => {
    setLoading(`${type}-${index}`);

    try {
      await TicketService.deleteTicket(record);
    } catch (error) {
      console.log(error);
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went Wrong"
      );
    } finally {
      setLoading("");
    }
  };

  const columns = [
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      sorter: (a, b) => a.priority - b.priority,
      width: 130,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div className="p-4 w-[13rem]">
          <Checkbox.Group
            value={selectedKeys}
            onChange={(value) => setSelectedKeys(value ? value : [])}
            className="flex flex-col gap-1 mb-2"
          >
            {priorityOptions.map((item) => (
              <Checkbox value={item.value}>{item.label}</Checkbox>
            ))}
          </Checkbox.Group>

          <div className="flex items-center gap-2">
            <CustomButton
              type="primary"
              onClick={() => confirm()}
              size="small"
              className="flex-1"
            >
              Search
            </CustomButton>

            <CustomButton
              onClick={() => {
                setSelectedKeys([]);
                confirm();
                clearFilters();
              }}
              size="small"
              className="flex-1"
            >
              Reset
            </CustomButton>
          </div>
        </div>
      ),
      onFilter: (value, record) => record.priority === value,
      render: (priority, record, index) => {
        let color = "";
        let text = "";

        switch (priority) {
          case 1:
            color = "volcano";
            text = "High";
            break;
          case 2:
            color = "gold";
            text = "Medium";
            break;
          case 3:
            color = "blue";
            text = "Low";
            break;
        }

        const content = (
          <Form
            form={statusForm}
            onFinish={(values) => handleStatusUpdate(values, record)}
            layout="vertical"
          >
            <Form.Item
              name="priority"
              rules={[
                {
                  required: true,
                  message: "Select priority to update",
                },
              ]}
            >
              <Radio.Group className="flex flex-col gap-1.5">
                {priorityOptions.map(
                  (status) =>
                    text !== status.label && (
                      <Radio key={status.value} value={status.value}>
                        {status.label}
                      </Radio>
                    )
                )}
              </Radio.Group>
            </Form.Item>

            <div className="flex items-center justify-end mt-4">
              <CustomButton
                type="primary"
                loading={loading === "status-update"}
                htmlType="submit"
                icon={<CheckOutlined />}
              >
                Update
              </CustomButton>
            </div>
          </Form>
        );

        return (
          <Popover
            open={statusPopover === `priority-${index}`}
            onOpenChange={(value) => {
              if (!value) {
                setStatusPopover("");
                statusForm.resetFields();
              } else {
                setStatusPopover(`priority-${index}`);
              }
            }}
            content={content}
            title="Change Priority"
            trigger="click"
            destroyTooltipOnHide
          >
            <Tag color={color} className="mr-0" style={{ cursor: "pointer" }}>
              {text}
            </Tag>
          </Popover>
        );
      },
    },
    {
      title: "Ticket No.",
      dataIndex: "ticketNo",
      key: "ticketNo",
      sorter: (a, b) => a.ticketNo - b.ticketNo,
      width: 150,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div className="p-4">
          <Input
            placeholder="Search"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            className="block mb-2"
            type="number"
          />

          <div className="flex items-center gap-2">
            <CustomButton
              type="primary"
              onClick={() => confirm()}
              size="small"
              className="flex-1"
            >
              Search
            </CustomButton>

            <CustomButton
              onClick={() => {
                setSelectedKeys([]);
                confirm();
                clearFilters();
              }}
              size="small"
              className="flex-1"
            >
              Reset
            </CustomButton>
          </div>
        </div>
      ),
      onFilter: (value, record) => record.ticketNo == value,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 200,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div className="p-4">
          <Input
            placeholder="Search"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            className="block mb-2"
          />

          <div className="flex items-center gap-2">
            <CustomButton
              type="primary"
              onClick={() => confirm()}
              size="small"
              className="flex-1"
            >
              Search
            </CustomButton>

            <CustomButton
              onClick={() => {
                setSelectedKeys([]);
                confirm();
                clearFilters();
              }}
              size="small"
              className="flex-1"
            >
              Reset
            </CustomButton>
          </div>
        </div>
      ),
      onFilter: (value, record) =>
        record.title.toString().toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 200,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div className="p-4">
          <Input.TextArea
            rows={4}
            placeholder="Search"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            className="block mb-2"
          />

          <div className="flex items-center gap-2">
            <CustomButton
              type="primary"
              onClick={() => confirm()}
              size="small"
              className="flex-1"
            >
              Search
            </CustomButton>

            <CustomButton
              onClick={() => {
                setSelectedKeys([]);
                confirm();
                clearFilters();
              }}
              size="small"
              className="flex-1"
            >
              Reset
            </CustomButton>
          </div>
        </div>
      ),
      onFilter: (value, record) =>
        record.description
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      render: (text) => (
        <p
          onClick={() =>
            setDescriptionModal({
              open: true,
              data: text,
            })
          }
          className="w-full text-blue-500 underline cursor-pointer"
        >
          {text.length > 38 ? `${text.slice(0, 38)}...` : text}
        </p>
      ),
    },
    {
      title: "Raised By",
      dataIndex: "user",
      key: "user",
      render: (text, record) =>
        `${record.user?.firstName} ${record.user?.lastName}`,
    },
    {
      title: "Raised On",
      dataIndex: "dateAdded",
      key: "dateAdded",
      sorter: (a, b) => new Date(a.dateAdded) - new Date(b.dateAdded),
      render: (date) => formatDate(date),
    },
    {
      title: "Updated On",
      dataIndex: "updatedDate",
      key: "updatedDate",
      render: (date) => formatDate(date),
      hidden: showHiddenColumns,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 130,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div className="p-4 w-[13rem]">
          <Checkbox.Group
            value={selectedKeys}
            onChange={(value) => setSelectedKeys(value ? value : [])}
            className="flex flex-col gap-1 mb-2"
          >
            {statusOptions.map((item) => (
              <Checkbox value={item.value}>{item.label}</Checkbox>
            ))}
          </Checkbox.Group>

          <div className="flex items-center gap-2">
            <CustomButton
              type="primary"
              onClick={() => confirm()}
              size="small"
              className="flex-1"
            >
              Search
            </CustomButton>

            <CustomButton
              onClick={() => {
                setSelectedKeys([]);
                confirm();
                clearFilters();
              }}
              size="small"
              className="flex-1"
            >
              Reset
            </CustomButton>
          </div>
        </div>
      ),
      onFilter: (value, record) => record.status === value,
      render: (status, record, index) => {
        let color;
        let text;

        switch (status) {
          case 1:
            text = "NEW";
            color = "cyan";
            break;
          case 2:
            text = "OPEN";
            color = "processing";
            break;
          case 3:
            text = "COMPLETED";
            color = "green";
            break;
          case 4:
            text = "CANCELLED";
            color = "volcano";
            break;
        }

        const content = (
          <Form
            form={statusForm}
            onFinish={(values) => handleStatusUpdate(values, record)}
            layout="vertical"
          >
            <Form.Item
              name="status"
              rules={[
                {
                  required: true,
                  message: "Select status to update",
                },
              ]}
            >
              <Radio.Group className="flex flex-col gap-1.5">
                {statusOptions.map(
                  (status) =>
                    text?.toUpperCase() !== status.label && (
                      <Radio key={status.value} value={status.value}>
                        {status.label}
                      </Radio>
                    )
                )}
              </Radio.Group>
            </Form.Item>

            <div className="flex items-center justify-end mt-4">
              <CustomButton
                type="primary"
                loading={loading === "status-update"}
                htmlType="submit"
                icon={<CheckOutlined />}
              >
                Update
              </CustomButton>
            </div>
          </Form>
        );

        return (
          <Popover
            open={statusPopover === index}
            onOpenChange={(value) => {
              if (!value) {
                setStatusPopover("");
                statusForm.resetFields();
              } else {
                setStatusPopover(index);
              }
            }}
            content={content}
            title="Change Status"
            trigger="click"
            destroyTooltipOnHide
          >
            <Tag color={color} className="mr-0" style={{ cursor: "pointer" }}>
              {text?.toUpperCase()}
            </Tag>
          </Popover>
        );
      },
      align: "center",
    },
    {
      title: "View",
      dataIndex: "attachments",
      key: "attachments",
      render: (_, record) => (
        <ToolTipButton
          tooltipHeader="Attachments"
          tooltipPlacement="left"
          icon={<FileTextOutlined />}
          onClick={() => {
            setTicketsDrawer({
              open: true,
              initalValue: record,
            });
          }}
        />
      ),
      align: "center",
      width: 80,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 100,
      render: (_, record, index) => {
        return (
          <div className="space-x-2">
            <ToolTipButton
              tooltipHeader="Edit ticket"
              tooltipPlacement="left"
              onClick={() => {
                setManageDrawer({
                  openType: "edit",
                  initalValue: record,
                });
              }}
              icon={<EditOutlined />}
              type="primary"
            />

            <ToolTipButton
              tooltipHeader="Comments"
              tooltipPlacement="top"
              onClick={() => {
                setManageDrawer({
                  openType: "commentOpen",
                  initalValue: record,
                });
              }}
              icon={<CommentOutlined />}
              type="secondary"
            />

            {/* <Popconfirm
              okText="Yes"
              cancelText="No"
              title="Are you sure you want to delete this ticket?"
              onConfirm={() => handleDeleteTicket(record, index, "delete")}
            >
              <CustomButton
                className="ms-2"
                icon={<DeleteOutlined />}
                loading={loading === `delete-${index}`}
                type="primary"
                danger
              >
                Delete
              </CustomButton>
            </Popconfirm> */}
          </div>
        );
      },
    },
  ].filter((col) => !col.hidden);

  return (
    <div className="p-2 my-4">
      <div className="flex items-start justify-between w-full mb-2">
        <CustomBreadcrumb items={items} />

        <div className="flex flex-row gap-2 mb-2">
          <CustomButton
            onClick={() => setShowHiddenColumns(!showHiddenColumns)}
            type="secondary"
            icon={
              showHiddenColumns ? <EyeOutlined /> : <EyeInvisibleOutlined />
            }
          >
            {showHiddenColumns ? "Show all columns" : "Hide columns"}
          </CustomButton>

          <CustomButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setManageDrawer({
                openType: "add",
                initalValue: null,
              });
            }}
          >
            Add
          </CustomButton>
        </div>
      </div>

      <Table
        size="large"
        bordered={"1px solid black"}
        loading={listFetching}
        dataSource={ticketsList.response.records}
        columns={columns}
        tableLayout="fixed"
        onChange={handleFiltersChange}
        scroll={{
          x: showHiddenColumns ? 1200 : 1400,
        }}
        rowClassName={(record, index) =>
          index % 2 !== 0 ? getDarkModeColor(isDarkMode, "bg-gray-100") : ""
        }
        pagination={{
          pageSize: ticketsList.request.pageRequest.pageSize,
          current: ticketsList.request.pageRequest.currentPage,
          total: ticketsList.response.totalRecords,
          showSizeChanger: true,
        }}
      />

      <TicketsAttachmentDrawer
        ticketsList={ticketsList}
        open={ticketsDrawer.open}
        data={ticketsDrawer.initalValue}
        onClose={() =>
          setTicketsDrawer({
            open: false,
            initalValue: null,
          })
        }
      />

      <ManageTicketsDrawer
        open={
          manageDrawer.openType === "add" || manageDrawer.openType === "edit"
        }
        openType={manageDrawer.openType}
        initalData={manageDrawer.initalValue}
        onClose={() =>
          setManageDrawer({
            openType: null,
            initalValue: null,
          })
        }
        refetch={() => {
          fetchTickets();
        }}
      />

      <TicketCommentsDrawer
        open={manageDrawer.openType === "commentOpen"}
        onClose={() =>
          setManageDrawer({
            openType: null,
            initalValue: null,
          })
        }
        initalData={manageDrawer.initalValue}
      />

      <Modal
        open={descriptionModal.open}
        closeIcon={false}
        cancelButtonProps={{ className: "hidden" }}
        onOk={() => setDescriptionModal({ open: false, data: null })}
        onCancel={() => setDescriptionModal({ open: false, data: null })}
      >
        {descriptionModal.data}
      </Modal>
    </div>
  );
};

export default TicketsPage;