import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Table, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../Providers/AuthProvider";
import ManageOrganizationService from "../../services/Organization/ManageOrganizationService";
import ManageOrganizationAddresses from "./ManageOrganizationAddresses";
import CustomButton from "../../Common/CustomButton";
import { getDarkModeColor } from "../../utils/darkModeHelper";
import { useDarkMode } from "../../Providers/DarkModeContext";
import { useScreenSize } from "../../Providers/ScreenSizeProvider";

const OrganizationAddresses = ({ primaryIdentifier }) => {
  const { isDarkMode } = useDarkMode();
  const currentScreenSize = useScreenSize();

  const [isTableDataLoading, setIsTableDataLoading] = useState(false);
  const [changeStatus, setChangeStatus] = useState("");
  const [addressData, setAddressData] = useState([]);
  const [editAddressData, setEditAddressData] = useState(null);

  const { userDetails } = useContext(AuthContext);
  const { isExternalUser } = userDetails;
  let { userPrimaryRole } = useContext(AuthContext);
  let isAdmin = userPrimaryRole?.toLowerCase()?.includes("admin");
  const [accountId, setAccountId] = useState("");

  const handleMultipleAddresses = () => {
    setChangeStatus("add");
    setEditAddressData(null);
  };

  const fetchData = (primaryIdentifier) => {
    setIsTableDataLoading(true);
    ManageOrganizationService.getOrganizationAddressList(primaryIdentifier)
      .then((res) => {
        setAccountId(res.data[0]?.consumerID || res.data[1]?.consumerID);
        setAddressData(res.data);
        setIsTableDataLoading(false);
      })
      .catch((error) => {
        console.log(error);
        error?.response?.data?.message
          ? message.error(error?.response?.data?.message)
          : message.error("Something went Wrong");

        setIsTableDataLoading(false);
      });
  };

  useEffect(() => {
    if (primaryIdentifier) {
      fetchData(primaryIdentifier);
    }
  }, [primaryIdentifier]);

  const columns = [
    {
      title: "Address",
      dataIndex: "addressLine1",
      render: (_, record) => {
        return (
          <span>{`${record.addressLine1}  ${record.addressLine2}  ${record.addressLine3}  ${record.addressLine4} `}</span>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "emailAddress",
    },
    {
      title: "City",
      dataIndex: "city",
    },
    {
      title: "State",
      dataIndex: "state",
    },
    {
      title: "Post Code",
      dataIndex: "postCode",
      align: "center",
    },
    {
      title: "Country",
      dataIndex: "country",
    },
    {
      title: "Action",
      align: "center",
      dataIndex: "action",
      render: (_, record) => {
        return (
          isExternalUser === false && (
            <CustomButton
              type="primary"
              icon={<EditOutlined />}
              onClick={() => {
                setEditAddressData(record);
                setChangeStatus("edit");
              }}
            >
              Edit
            </CustomButton>
          )
        );
      },
    },
  ];

  return (
    <div className="relative">
      {addressData && (
        <div>
          <div className="flex justify-end mb-2">
            {isAdmin && isExternalUser === false && (
              <CustomButton
                type="primary"
                className="absolute -top-12"
                icon={<PlusOutlined />}
                onClick={handleMultipleAddresses}
              >
                Add
              </CustomButton>
            )}
          </div>

          <Table
            loading={isTableDataLoading}
            scroll={{
              x: currentScreenSize < 1024 ? 1400 : 1000,
            }}
            tableLayout="fixed"
            columns={columns}
            dataSource={addressData}
            rowClassName={(record, index) =>
              index % 2 !== 0 ? getDarkModeColor(isDarkMode, "bg-gray-100") : ""
            }
          />
        </div>
      )}
      <ManageOrganizationAddresses
        accountId={accountId}
        initialValue={editAddressData}
        onClose={() => {
          setChangeStatus(null);
          setEditAddressData(null);
        }}
        changeStatus={changeStatus}
        onSuccess={() => {
          if (primaryIdentifier) {
            fetchData(primaryIdentifier);
          }
        }}
      />
    </div>
  );
};

export default OrganizationAddresses;
