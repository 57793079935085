import React, { memo } from "react";
import { Bar, Column } from "@ant-design/charts";
import { Empty } from "antd";
import ChartLoading from "../../Common/ChartLoading";

const BestProductChart = memo(({ data, loading, height }) => {
  let formattedData = [];

  if (loading) {
    return <ChartLoading height={height} />;
  }

  if (Object.keys(data).length !== 0) {
    formattedData = Object.entries(data).map(([product, count]) => ({
      product: product.length > 16 ? `${product.slice(0, 16)}...` : product,
      count,
      fullName: product,
    }));
  } else {
    return (
      <div
        className={`flex items-center justify-center w-full font-semibold`}
        style={{ height: height }}
      >
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No data found"
        />
      </div>
    );
  }

  const maxCount = Math.max(...formattedData.map((entry) => entry.count));

  const config = {
    data: formattedData.filter((i) => i.count !== null && i.count !== 0),
    xField: "product",
    yField: "count",
    yAxis: { label: { formatter: (v) => `${v} units` } },
    color: (data) => {
      const obj = formattedData.find((i) => i.product === data.product);

      return obj.count === maxCount ? "#62daab" : "#5B8FF9";
    },
    tooltip: {
      showTitle: false,
    },
  };

  return <Column {...config} height={height} />;
});

export default BestProductChart;
