import { Checkbox, Descriptions, Drawer } from "antd";
import React from "react";

const ProductGroupSettingsDrawer = ({
  open,
  onCancel,
  allStatuses,
  setNodes,
  onHideOrderStatus,
}) => {
  const statusNameList = allStatuses
    .map((i) => {
      if (i.type !== "startingNode") return;

      return {
        label: `${i.id}. ${i.data.label}`,
        value: !i?.isStatusHidden,
        id: i.id,
      };
    })
    .filter((i) => i);

  const handleClose = () => {
    onCancel();
  };

  const handleHideStatus = (value, id) => {
    let currStatus = allStatuses.find((i) => i.id == id);

    currStatus.isStatusHidden = value;

    currStatus.jobStatus = currStatus.jobStatus.map((i) => ({
      ...i,
      isStatusHidden: value,
    }));

    setNodes((prev) =>
      prev.map((i) => {
        if (i.id == currStatus.id) {
          return currStatus;
        } else return i;
      })
    );

    onHideOrderStatus(currStatus, value);
  };

  return (
    <Drawer title="Product Group Setting" open={open} onClose={handleClose}>
      <Descriptions
        title="Enable statuses"
        contentStyle={{ width: "20px" }}
        bordered
        column={1}
      >
        {statusNameList.map((status) => (
          <Descriptions.Item key={status.label} label={status.label}>
            <Checkbox
              checked={status.value}
              onChange={(e) => handleHideStatus(!e.target.checked, status.id)}
            />
          </Descriptions.Item>
        ))}
      </Descriptions>
    </Drawer>
  );
};

export default ProductGroupSettingsDrawer;
