import { Button, Checkbox, DatePicker, Input, Radio, Select } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const FilterDropdown = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  placeholder = "Search",
  type = "text",
  options = [],
}) => {
  const [textAreaValue, setTextAreaValue] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setSelectedKeys(value ? [value] : []);
  };

  const handleInputNumberChange = (e) => {
    const { value } = e.target;
    setSelectedKeys(value ? [Number(value)] : []);
  };

  const handleTextAreaChange = (e) => {
    const { value } = e.target;
    setTextAreaValue(value);
  };

  const handleConfirmTextArea = () => {
    const valuesArray = textAreaValue
      .split(",")
      .map((item) => item.trim())
      .filter((item) => item);
    setSelectedKeys(valuesArray);
    confirm();
  };

  const handleSelectChange = (value) => {
    setSelectedKeys([value]);
  };

  const handleDateRangeChange = (dates) => {
    if (dates) {
      setSelectedKeys([
        dayjs(dates[0]).format("DD-MM-YYYY"),
        dayjs(dates[1]).format("DD-MM-YYYY"),
      ]);
    } else {
      setSelectedKeys([]);
    }
  };

  const handleRadioChange = (e) => {
    setSelectedKeys([e.target.value]);
  };

  const handleCheckboxChange = (checkedValues) => {
    setSelectedKeys(checkedValues);
  };

  const handleFromDateChange = (date) => {
    const formattedDate = date ? date.format("DD-MM-YYYY") : "";
    setFromDate(date);
    setSelectedKeys([formattedDate, toDate ? toDate.format("DD-MM-YYYY") : ""]);
  };

  const handleToDateChange = (date) => {
    const formattedDate = date ? date.format("DD-MM-YYYY") : "";
    setToDate(date);
    setSelectedKeys([
      fromDate ? fromDate.format("DD-MM-YYYY") : "",
      formattedDate,
    ]);
  };

  const renderInputField = () => {
    switch (type) {
      case "select":
        return (
          <Select
            size="middle"
            placeholder={placeholder}
            value={selectedKeys[0]}
            onChange={handleSelectChange}
            className="w-full mb-2"
            options={options}
            showSearch
            filterOption={(input, option) =>
              option?.label.toLowerCase().includes(input.toLowerCase()) ?? false
            }
          />
        );
      case "dateRange":
        return (
          <RangePicker
            size="middle"
            value={
              selectedKeys.length === 2 && selectedKeys[0] && selectedKeys[1]
                ? [
                    dayjs(selectedKeys[0], "DD-MM-YYYY"),
                    dayjs(selectedKeys[1], "DD-MM-YYYY"),
                  ]
                : null
            }
            onChange={handleDateRangeChange}
            className="w-full mb-2"
            format="DD-MM-YYYY"
            allowClear={false}
          />
        );
      case "textarea":
        return (
          <TextArea
            size="middle"
            placeholder={placeholder}
            value={textAreaValue}
            onChange={handleTextAreaChange}
            rows={3}
            className="mb-2"
            allowClear={false}
          />
        );
      case "radio":
        return (
          <Radio.Group
            size="middle"
            onChange={handleRadioChange}
            value={selectedKeys[0]}
            className="mb-2"
          >
            {options.map((option) => (
              <Radio className="block" key={option.value} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </Radio.Group>
        );
      case "checkbox":
        return (
          <Checkbox.Group
            value={selectedKeys}
            onChange={handleCheckboxChange}
            className="mb-2 space-y-1"
          >
            {options.map((option) => (
              <Checkbox
                className="w-full"
                key={option.value}
                value={option.value}
              >
                {option.label}
              </Checkbox>
            ))}
          </Checkbox.Group>
        );
      case "fromTo":
        return (
          <div className="flex gap-2 mb-2">
            <DatePicker
              size="middle"
              placeholder="From"
              value={fromDate ? dayjs(fromDate) : null}
              onChange={handleFromDateChange}
              className="w-full"
              allowClear={false}
              format="DD-MM-YYYY"
              disabledDate={(current) =>
                current && current.isAfter(toDate, "day")
              }
            />
            <DatePicker
              size="middle"
              placeholder="To"
              value={toDate ? dayjs(toDate) : null}
              onChange={handleToDateChange}
              className="w-full"
              allowClear={false}
              format="DD-MM-YYYY"
              disabledDate={(current) =>
                current && current.isBefore(fromDate, "day")
              }
            />
          </div>
        );
      case "number":
        return (
          <Input
            size="middle"
            placeholder={placeholder}
            value={selectedKeys[0]}
            onChange={handleInputNumberChange}
            onPressEnter={() => confirm()}
            className="mb-2"
            type="number"
            allowClear={false}
          />
        );
      case "text":
      default:
        return (
          <Input
            size="middle"
            placeholder={placeholder}
            value={selectedKeys[0]}
            onChange={handleInputChange}
            onPressEnter={() => confirm()}
            className="mb-2"
            allowClear={false}
          />
        );
    }
  };

  return (
    <div className="p-2 w-[15rem]">
      {renderInputField()}
      <div className="flex gap-2">
        <Button
          type="primary"
          onClick={() => {
            if (type === "textarea") {
              handleConfirmTextArea();
            } else {
              confirm();
            }
          }}
          size="small"
          className="w-1/2"
        >
          Ok
        </Button>
        <Button
          onClick={() => {
            clearFilters && clearFilters();
            setSelectedKeys([]);
            setTextAreaValue("");
            setFromDate(null);
            setToDate(null);
            confirm();
          }}
          size="small"
          className="w-1/2"
        >
          Reset
        </Button>
      </div>
    </div>
  );
};

export default FilterDropdown;
