import {
  CheckOutlined,
  CloseOutlined,
  CommentOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Drawer, Form, Input, message, Select, Upload } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import CustomButton from "../../Common/CustomButton";
import ToolTipButton from "../../Common/ToolTipButton";
import TicketService from "../../services/Ticket/TicketService";
import { validateTextInput } from "../../utils/validationsHelper";
import TicketCommentsDrawer from "./TicketCommentsDrawer";
import { priorityOptions } from "./TicketsPage";

const ManageTicketsDrawer = ({
  open,
  openType,
  initalData,
  onClose,
  refetch,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [commentsDrawerOpen, setCommentsDrawerOpen] = useState(false);

  const [form] = useForm();

  useEffect(() => {
    if (openType === "edit" && initalData) {
      form.setFieldsValue(initalData);
    }
  }, [open, initalData]);

  const handleClose = () => {
    onClose();
    setIsSubmitting(false);
    form.resetFields();
  };

  const handleCommentsDrawerClose = () => {
    setCommentsDrawerOpen(false);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src && file.originFileObj instanceof Blob) {
      src = URL.createObjectURL(file.originFileObj);
    }
    if (file.type === "application/pdf") {
      window.open(src, "_blank");
    } else if (file.type.startsWith("image/")) {
      const imgWindow = window.open("", "_blank");
      imgWindow?.document.write(`<img src="${src}" />`);
    } else {
      message.info("The selected file format cannot be previewed.");
    }
  };

  const onFinish = async (values) => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("priority", values.priority);
    formData.append("description", values.description);
    formData.append("status", 1);

    if (values.files?.fileList?.length) {
      values.files.fileList.forEach((file) => {
        formData.append("FileUploadRequest.Files", file?.originFileObj);
      });
    } else {
      formData.append("FileUploadRequest.Files", []);
    }

    formData.append("FileUploadRequest.Category", "SUPPORT_TICKET");

    if (openType !== "add") {
      formData.append("ticketNo", initalData.ticketNo);
      formData.append("primaryIdentifier", initalData.primaryIdentifier);

      if (initalData.attachmentIDs) {
        formData.append("attachmentIDs", initalData.attachmentIDs);
      }
    }

    try {
      let method;
      if (openType === "add") {
        method = () => TicketService.createTicket(formData);
      } else {
        method = () => TicketService.updateTicket(formData);
      }

      await method();

      handleClose();
      refetch();
      message.success(
        `${openType === "add" ? "Added" : "Updated"} ticket successfully!`
      );
    } catch (error) {
      console.log(error);
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went Wrong"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Drawer
      title={openType === "add" ? "Add ticket" : `Edit ticket`}
      open={open}
      onClose={handleClose}
      bodyStyle={{
        padding: "12px 20px",
      }}
      destroyOnClose
      extra={
        openType === "edit" && (
          <ToolTipButton
            tooltipHeader="Comments"
            tooltipPlacement="left"
            type="secondary"
            onClick={() => setCommentsDrawerOpen(!commentsDrawerOpen)}
            icon={<CommentOutlined />}
          />
        )
      }
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        {openType === "edit" && (
          <Form.Item
            name="ticketNo"
            label="Ticket Number"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input disabled size="medium" placeholder="Enter ticket number" />
          </Form.Item>
        )}

        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: "Please enter title",
            },
            { validator: validateTextInput("Title", 2, 30) },
          ]}
        >
          <Input size="medium" placeholder="Enter name" />
        </Form.Item>

        <Form.Item
          name="priority"
          label="Priority"
          rules={[
            {
              required: true,
              message: "Please select priority",
            },
          ]}
        >
          <Select
            placeholder="Select Status"
            size="middle"
            options={priorityOptions}
          />
        </Form.Item>

        <Form.Item
          name="description"
          label="Enter Description"
          rules={[
            {
              required: true,
              message: "Please enter description",
            },
            { validator: validateTextInput("Description", 2, 1024) },
          ]}
        >
          <Input.TextArea
            rows={3}
            size="medium"
            placeholder="Enter description"
          />
        </Form.Item>

        <Form.Item
          label="Attachments"
          style={{ width: "100%" }}
          name={"files"}
          rules={[
            {
              required: false,
              message: "Upload Attachment",
            },
          ]}
        >
          <Upload
            accept=".jpg, .jpeg, .png"
            listType="picture"
            beforeUpload={() => false}
            multiple
            onPreview={onPreview}
          >
            <CustomButton type="secondary" icon={<UploadOutlined />}>
              Upload
            </CustomButton>
          </Upload>
        </Form.Item>

        <div className="flex justify-end gap-2 mt-10">
          <CustomButton
            type="primary"
            htmlType="submit"
            loading={isSubmitting}
            icon={<CheckOutlined />}
          >
            Submit
          </CustomButton>

          <CustomButton
            type="cancel"
            onClick={handleClose}
            disabled={isSubmitting}
            icon={<CloseOutlined />}
          >
            Cancel
          </CustomButton>
        </div>
      </Form>

      <TicketCommentsDrawer
        open={commentsDrawerOpen}
        onClose={handleCommentsDrawerClose}
        initalData={initalData}
      />
    </Drawer>
  );
};

export default ManageTicketsDrawer;
