import { blue } from "@ant-design/colors";
import {
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Card,
  Col,
  DatePicker,
  Empty,
  Form,
  Input,
  message,
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import { useWatch } from "antd/es/form/Form";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../Common/CustomBreadcrumb";
import CustomButton from "../../Common/CustomButton";
import Markdown from "../../Common/Markdown";
import ShowAddressDetails from "../../Common/ShowAddressDetails";
import { useAuth } from "../../Providers/AuthProvider";
import { useScreenSize } from "../../Providers/ScreenSizeProvider";
import CustomerService from "../../services/CustomerService/CustomerService";
import OrderManagementService from "../../services/OrderManagement/OrderManagementService";
import ProductService from "../../services/ProductBuilder/ProductService";
import ManageCustomerAccount from "../Customer/ManageCustomerAccount";
import ManageCustomerContacts from "../Customer/ManageCustomerContacts";
import DeliveryAddressesDrawer from "./DeliveryAddressesDrawer";
import AjCustomerAndChassisNo from "./OrderManagmentComponents/AJ/AjCustomerAndChassisNo";
import AjFittings from "./OrderManagmentComponents/AJ/AjFittings";
import AjProductDetails from "./OrderManagmentComponents/AJ/AjProductDetails";
import AttachmentCard from "./OrderManagmentComponents/Common/AttachmentCard";
import DuratrayProductDetails from "./OrderManagmentComponents/Duratray/DuratrayProductDetails";
import OrderSuccessPage from "./OrderSuccessPage";

function OrderQuoteCreate() {
  const currentScreenSize = useScreenSize();
  const { currentURL } = useAuth();

  const [customerList, setCustomerList] = useState([]);
  const [customerContactList, setCustomerContactList] = useState([]);
  const [selectedContactDetails, setSelectedContactDetails] = useState({});
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [billingAddress, setBillingAddress] = useState(null);
  const [productList, setProductList] = useState([]);
  const [previewOrder, setPreviewOrder] = useState(null);
  const [orderType, setOrderType] = useState(null);
  const [orderResponseData, setOrderResponseData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [resultIndication, setResultIndication] = useState(false);
  const [noContactButton, showNoContactButton] = useState({
    showButton: false,
    showDrawer: false,
  });
  const [addCustomerDrawer, setAddCustomerDrawer] = useState({
    open: false,
    data: false,
  });
  const [IsCardView, setIsCardView] = useState(false);
  const [openAddressListDrawer, setOpenAddressListDrawer] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [orderDetailsData, setOrderDetailsData] = useState(null);
  const [editData, setEditData] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [validateOrderJob, setValidateOrderJob] = useState({
    quoteRefNumValue: "",
    quoteRefInvalid: false,
    jobRefNumValue: "",
    jobRefInvalid: false,
    vinNumberValue: "",
    vinNumberInvalid: false,
  });
  const [forecastedDate, setForecastedDate] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [quantityTimeout, setQuantityTimeout] = useState(null);
  const [accountSearchTimeout, setAccountSearchTimeout] = useState(null);
  const [viewType, setViewType] = useState(null);
  const [loadingOrderData, setLoadingOrderData] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [configOptions, setConfigOptions] = useState({});
  const [accessoriesPrice, setAccessoriesPrice] = useState({});
  const [productListLoading, setProductListLoading] = useState(false);
  const [restrictAutoSelect, setRestrictAutoSelect] = useState(true);
  const [formFieldsTouched, setFormFieldsTouched] = useState(false);

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const productID = useWatch("productID", form);
  const productQuantity = useWatch("quantity", form);
  const productCost = useWatch("productCost", form);
  const copy = useWatch("copy", form);
  const makeValue = useWatch("make", form);
  const model = useWatch("model", form);
  const barFinish = useWatch("barFinish", form);
  const numberPlate = useWatch("numberPlate", form);
  const blackingPlate = useWatch("blackingPlate", form);
  const indicators = useWatch("indicators", form);
  const integratedLights = useWatch("integratedLights", form);

  const params = new URLSearchParams(window.location.search);

  let paramsKey;

  if (params.has("key")) {
    paramsKey = params.get("key");
  } else {
    paramsKey = "";
  }

  const fetchOrderDetails = async (orderKey) => {
    try {
      const res = await ProductService.getOrderDetails(orderKey);
      setOrderDetailsData(res.data);
      setSelectedAccount(res.data.debtors.primaryIdentifier);

      fetchForecastData(res?.data?.productDetails?.id, res?.data?.quantity);

      const customerResponse =
        await CustomerService.getCustomerDataDropDownList({ accountID: "" });
      const filteredList = customerResponse?.data?.map((i) => ({
        label: `${i.accountID} | ${i.accountName}`,
        value: `${i.accountID} | ${i.accountName}`,
        key: i.primaryIdentifier,
        accountID: i.accountID,
      }));
      setCustomerList(filteredList);

      const contactResponse = await CustomerService.getCustomerContactList(
        res.data.debtors.primaryIdentifier
      );
      setCustomerContactList(contactResponse.data);
      setSelectedContactDetails(res.data.contact);

      const convertNullToUndefined = (value) => {
        if (value === "null") {
          return undefined;
        } else return value;
      };

      let barFinishValue =
        res.data?.productInfo[0]?.customBarFinish !== "null"
          ? "Custom Colour"
          : res.data?.productInfo[0]?.barFinish;

      let blackingPlateValue =
        res.data?.productInfo[0]?.customblackingPlateColor !== "null"
          ? "Custom Colour"
          : res.data?.productInfo[0]?.blackingPlate;

      let indicatorsValue =
        res.data?.productInfo[0]?.customIndicators !== "null"
          ? "Custom (See Notes)"
          : res.data?.productInfo[0]?.indicators;

      const attributesInfoDetails = res?.data?.attributesInfo
        ? JSON.parse(res?.data?.attributesInfo)
        : [];

      form.setFieldsValue({
        primaryIdentifier: `${res.data.debtors.accountID} | ${res.data.debtors.company}`,
        contactName: res.data.contact.name,
        contactEmail: res.data.contact.email,
        contactPhone: res.data.contact.mobile,
        quoteRefNum: res.data.quoteRefNum,
        jobRefNum: res.data.jobRefNum,
        internalIdentificationNum: res.data.internalIdentificationNum,
        productID: res.data.productDetails.id,
        price: res.data.price,
        quantity: res.data.quantity || 1,
        notes: res.data.notes,
        requiredDate: res?.data?.required
          ? dayjs(res?.data?.required)
          : undefined,
        productCost: parseInt(
          res.data?.productInfo[0]?.unitPrice || res.data?.unitPrice
        ),
        make: convertNullToUndefined(res.data?.productInfo[0]?.make),
        model: convertNullToUndefined(res.data?.productInfo[0]?.model),
        barFinish: convertNullToUndefined(barFinishValue),
        numberPlate: res.data?.productInfo[0]?.numberPlate,
        blackingPlate: convertNullToUndefined(blackingPlateValue),
        indicators: convertNullToUndefined(indicatorsValue),
        integratedLights: convertNullToUndefined(
          res.data?.productInfo[0]?.integratedLights
        ),
        accessoriesInfo: JSON.parse(res.data.accessoriesInfo),
        customblackingPlateColor: convertNullToUndefined(
          res.data?.productInfo[0]?.customblackingPlateColor
        ),
        customIndicators: convertNullToUndefined(
          res.data?.productInfo[0]?.customIndicators
        ),
        customBarFinish: convertNullToUndefined(
          res.data?.productInfo[0]?.customBarFinish
        ),
        fittingOption1:
          attributesInfoDetails?.length > 0
            ? convertNullToUndefined(attributesInfoDetails[0]?.fittingOption1)
            : undefined,
        fittingOption2:
          attributesInfoDetails?.length > 0
            ? convertNullToUndefined(attributesInfoDetails[0]?.fittingOption2)
            : undefined,
        endCustomerName:
          attributesInfoDetails?.length > 0
            ? convertNullToUndefined(attributesInfoDetails[0]?.endCustomerName)
            : undefined,
        vehicleChasisNo:
          attributesInfoDetails?.length > 0
            ? convertNullToUndefined(attributesInfoDetails[0]?.vehicleChasisNo)
            : undefined,
      });

      const allProductLists = await fetchProducts(res.data.debtors.accountID);

      renderConfigOptions(res.data.productDetails.id, allProductLists);

      configOptionsWithConditions(res.data?.productInfo[0]?.make);

      let accessoriesPriceObj = {};

      if (res.data?.accessoriesInfo) {
        JSON.parse(res.data?.accessoriesInfo).map((item, index) => {
          accessoriesPriceObj = {
            ...accessoriesPriceObj,
            [index]: parseInt(item.price),
          };
        });
      }

      setAccessoriesPrice(accessoriesPriceObj);
      addressFunction(res.data.billingAddress, res.data.shippingAddress);
    } catch (error) {
      console.log(error);
      error?.response?.data?.message
        ? message.error(error?.response?.data?.message)
        : message.error("Something went wrong");
    }
  };

  const fetchCustomerData = async () => {
    try {
      const response = await CustomerService.getCustomerDataDropDownList({
        accountID: "",
      });
      const filteredList = response?.data?.map((i) => ({
        label: `${i.accountID} | ${i.accountName}`,
        value: `${i.accountID} | ${i.accountName}`,
        key: i.primaryIdentifier,
        accountID: i.accountID,
      }));
      setCustomerList(filteredList);
    } catch (error) {
      console.log(error);
      error?.response?.data?.message
        ? message.error(error?.response?.data?.message)
        : message.error("Something went wrong");
    }
  };

  const configOptionsWithConditions = (currMakeValue) => {
    setConfigOptions((prevConfigOptions) => {
      const newConfigOptions = Object.keys(prevConfigOptions).reduce(
        (acc, key) => {
          acc[key] = prevConfigOptions[key].map((option) => {
            if (option.condition.length === 0) {
              return option;
            }

            if (option.condition.includes(currMakeValue)) {
              return { ...option, show: true };
            }

            return { ...option, show: false };
          });
          return acc;
        },
        {}
      );

      setDefaultOptions(Object.keys(configOptions), newConfigOptions);
      return newConfigOptions;
    });
  };

  const fetchForecastData = async (productId, quantity) => {
    try {
      const currQuantity =
        quantity === undefined || quantity === null || quantity.length === 0
          ? "1"
          : quantity;

      const res = await CustomerService.getForecastDate(
        productId,
        currQuantity
      );
      setForecastedDate(res.data);
    } catch (error) {
      console.log(error);
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went Wrong"
      );
    }
  };

  const fetchProducts = async (accountID) => {
    setProductListLoading(true);

    let filteredlist = [];

    try {
      const res = await ProductService.getProductWithConfigDataList(accountID);
      filteredlist = res.data?.map((i) => ({
        label: i.productName,
        value: i.productID,
        configurations: i.configurations,
      }));

      setProductList(filteredlist);
    } catch (error) {
      console.log(error);
      error?.response?.data?.message
        ? message.error(error?.response?.data?.message)
        : message.error("Something went wrong");
    } finally {
      setProductListLoading(false);
    }

    return filteredlist;
  };

  useEffect(() => {
    const initialize = async () => {
      form.setFieldValue("copy", 1);

      if (params.has("key")) {
        setLoadingOrderData(true);

        const orderKey = params.get("key");
        setPreviewOrder("preview");

        await fetchOrderDetails(orderKey);

        setLoadingOrderData(false);
      } else {
        if (orderDetailsData && !params.has("key")) {
          refreshFunction();
        }
        setRestrictAutoSelect(false);
        await fetchCustomerData();
      }
    };

    initialize();
  }, [viewType, paramsKey, previewOrder]);

  useEffect(() => {
    if (!restrictAutoSelect) {
      configOptionsWithConditions(makeValue);
    }
  }, [productID, makeValue]);

  useEffect(() => {
    const currObj = {
      model,
      barFinish,
      numberPlate,
      blackingPlate,
      indicators,
      integratedLights,
    };

    if (Object.keys(currObj).some((i) => i === undefined || i === null)) {
      return;
    }

    let currentCost = 0;

    if (Object.keys(configOptions).length > 0) {
      Object.keys(currObj)?.forEach((field) => {
        configOptions[field]?.forEach((option) => {
          Object.values(currObj).map((value) => {
            if (option.value === value) {
              currentCost += option.price;
            }
          });
        });
      });
    } else {
      currentCost = parseInt(productCost);
    }

    if (restrictAutoSelect && !paramsKey) {
      currentCost += parseInt(productCost);
    }

    const currentProductCost =
      parseInt(currentCost) * (copy ? parseInt(copy) : 1);

    form.setFieldValue("productCost", currentProductCost);
  }, [
    copy,
    model,
    barFinish,
    numberPlate,
    blackingPlate,
    indicators,
    integratedLights,
    productQuantity,
  ]);

  useEffect(() => {
    if (forecastedDate && form.getFieldValue("requiredDate") !== undefined) {
      handleDateChange(
        forecastedDate,
        dayjs(form.getFieldValue("requiredDate")).format("YYYY-MM-DD")
      );
    }
  }, [forecastedDate, form.getFieldValue("requiredDate")]);

  const items = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: previewOrder == "preview" ? "View" : "Create",
    },
  ];

  const handleQuantityChange = (e) => {
    clearTimeout(quantityTimeout);

    const newTimeout = setTimeout(() => {
      fetchForecastData(productID, e.target.value);
    }, 500);

    setQuantityTimeout(newTimeout);
  };

  const handleChange = (data) => {
    setFormFieldsTouched(true);

    const accessorieFields = [
      "accessories",
      "description",
      "lightLayout",
      "lightLocation",
      "lightMounting",
    ];

    if (data.length < 2) {
      setIsLoading(false);
      if (!params.has("key") || editData) {
        if (data[0].name[0] === "quoteRefNum") {
          if (data[0].value == "") {
            setValidateOrderJob({
              ...validateOrderJob,
              quoteRefNumValue: "",
              quoteRefInvalid: false,
            });
          }
        }
        if (data[0].name[0] === "jobRefNum") {
          if (data[0].value == "") {
            setValidateOrderJob({
              ...validateOrderJob,
              jobRefNumValue: "",
              jobRefInvalid: false,
            });
          }
        }

        if (data[0].name[0] === "contactName") {
          let filteredContactDetails = customerContactList.find(
            (el) => el.primaryIdentifier === data[0].value
          );
          setSelectedContactDetails(filteredContactDetails);
          form.setFieldsValue({
            contactEmail: filteredContactDetails.email,
            contactPhone: filteredContactDetails.mobile,
          });
        }
      }
    }

    if (data[0].name[0] === "accessoriesInfo") {
      let currentAccCost = 0;

      let accessoriesFormItems;

      let index = data[0].name[1];

      if (data[0].value.length === 0) return;

      if (index === undefined) {
        accessoriesFormItems = data[0].value[data[0].value.length - 1];

        accessorieFields?.forEach((field) => {
          configOptions[field]?.forEach((option) => {
            Object.values(accessoriesFormItems).map((value) => {
              if (option.value === value) {
                currentAccCost += option.price;
              }
            });
          });
        });

        setAccessoriesPrice((prev) => ({
          ...prev,
          [data[0].value.length - 1]:
            currentAccCost * accessoriesFormItems.number,
        }));
      } else {
        accessoriesFormItems = form.getFieldValue("accessoriesInfo")[index];

        accessorieFields?.forEach((field) => {
          configOptions[field].forEach((option) => {
            Object.values(accessoriesFormItems).map((value) => {
              if (option.value === value) {
                currentAccCost += option.price;
              }
            });
          });
        });

        setAccessoriesPrice((prev) => ({
          ...prev,
          [index]: currentAccCost * accessoriesFormItems.number,
        }));
      }
    }
  };

  const addressFunction = (address1, address2) => {
    setBillingAddress({
      contactPersonName: address1.contactPersonName,
      phone: address1.phone,
      emailAddress: address1.emailAddress,
      company: address1.company,
      addressLine1: address1.addressLine1,
      addressLine2: address1.addressLine2,
      addressLine3: address1.addressLine3,
      addressLine4: address1.addressLine4,
      city: address1.city,
      state: address1.state,
      postCode: address1.postCode,
      primaryIdentifier: address1.primaryIdentifier,
    });
    setDeliveryAddress({
      contactPersonName: address2?.contactPersonName,
      phone: address2.phone,
      emailAddress: address2.emailAddress,
      company: address2.company,
      addressLine1: address2.addressLine1,
      addressLine2: address2.addressLine2,
      addressLine3: address2.addressLine3,
      addressLine4: address2.addressLine4,
      city: address2.city,
      state: address2.state,
      postCode: address2.postCode,
      primaryIdentifier: address2.primaryIdentifier,
    });
  };

  const checkRefNumber = async (value) => {
    if (!value || value.length === 0) return;

    try {
      const res = await OrderManagementService.getQuoteRefNumExists(value);

      if (res.data) {
        if (value === orderDetailsData?.quoteRefNum) {
          setValidateOrderJob({
            ...validateOrderJob,
            quoteRefNumValue: value,
            quoteRefInvalid: false,
          });
        } else {
          setValidateOrderJob({
            ...validateOrderJob,
            quoteRefNumValue: value,
            quoteRefInvalid: true,
          });
        }
      } else {
        setValidateOrderJob({
          ...validateOrderJob,
          quoteRefNumValue: value,
          quoteRefInvalid: false,
        });
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const checkJobNumber = async (value) => {
    if (!value || value.length === 0) return;

    try {
      const res = await OrderManagementService.getJobRefNumExists(value);

      if (res.data) {
        if (value === orderDetailsData?.jobRefNum) {
          setValidateOrderJob({
            ...validateOrderJob,
            jobRefNumValue: value,
            jobRefInvalid: false,
          });
        } else {
          setValidateOrderJob({
            ...validateOrderJob,
            jobRefNumValue: value,
            jobRefInvalid: true,
          });
        }
      } else {
        setValidateOrderJob({
          ...validateOrderJob,
          jobRefNumValue: value,
          jobRefInvalid: false,
        });
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const checkVinNumber = async (value) => {
    if (!value || value.length === 0) return;

    try {
      const res = await OrderManagementService.checkVinNumberExists(value);

      if (res.data) {
        if (value === orderDetailsData?.internalIdentificationNum) {
          setValidateOrderJob({
            ...validateOrderJob,
            vinNumberInvalid: true,
            vinNumberValue: value,
          });
        } else {
          setValidateOrderJob({
            ...validateOrderJob,
            vinNumberInvalid: true,
            vinNumberValue: value,
          });
        }
      } else {
        setValidateOrderJob({
          ...validateOrderJob,
          vinNumberInvalid: false,
          vinNumberValue: value,
        });
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  // might need in future
  // const OnQuoteNumChange = (value) => {
  //   clearTimeout(timeoutId);
  //   const newTimeoutId = setTimeout(() => {
  //     if (value.length > 0) {
  //       checkRefNumber(value);
  //     }
  //   }, 2000);
  //   setTimeoutId(newTimeoutId);
  // };

  // const OnJobNumChange = (value) => {
  //   clearTimeout(timeoutId);
  //   const newTimeoutId = setTimeout(() => {
  //     if (value.length > 0) {
  //       checkJobNumber(value);
  //     }
  //   }, 2000);
  //   setTimeoutId(newTimeoutId);
  // };

  const refreshFunction = () => {
    setSelectedAccount(null);
    setOrderDetailsData(null);
    setOrderResponseData(null);
    setViewType(null);
    setFileList([]);
    setRestrictAutoSelect(true);
    setEditData(false);
    setResultIndication(false);
    setSelectedAccount(null);
    setCustomerContactList([]);
    setShowWarning(false);
    setOrderType(null);
    setIsCardView(false);
    setPreviewOrder(null);
    setDeliveryAddress(null);
    setBillingAddress(null);
    setForecastedDate(null);
    setCustomerContactList([]);
    setConfigOptions({});
    showNoContactButton({
      showButton: false,
      showDrawer: false,
    });
    setValidateOrderJob({
      quoteRefNumValue: "",
      quoteRefInvalid: false,
      jobRefNumValue: "",
      jobRefInvalid: false,
      vinNumberValue: false,
      vinNumberInvalid: false,
    });
    setAccessoriesPrice({});
    form.resetFields();
  };

  const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(reader.error);
      reader.readAsDataURL(file);
    });
  };

  const onFinish = async (value) => {
    setIsLoading(true);

    const {
      make,
      model,
      barFinish,
      numberPlate,
      blackingPlate,
      indicators,
      integratedLights,
      accessoriesInfo,
      productCost,
      primaryIdentifier,
      endCustomerName,
      vehicleChasisNo,
      customblackingPlateColor,
      customIndicators,
      customBarFinish,
      fittingOption1,
      fittingOption2,
      attachment,
      ...rest
    } = value;

    const checkNull = (value) =>
      value === undefined || value === "" ? null : value;

    const productInfoRequiredFields = [
      make,
      model,
      barFinish,
      numberPlate,
      blackingPlate,
      indicators,
      integratedLights,
    ];

    let currFileList = [];

    if (fileList.length > 0) {
      const filePromises = fileList.map((i) =>
        readFileAsDataURL(i.originFileObj)
      );
      currFileList = await Promise.all(filePromises);
    }

    const payload = {
      ...rest,
      accessoriesInfo: accessoriesInfo
        ? JSON.stringify(
            accessoriesInfo.map((item, index) => ({
              ...item,
              price: accessoriesPrice[index] ? accessoriesPrice[index] : 0,
            }))
          )
        : null,
      productInfo: currentURL.includes("aj")
        ? JSON.stringify({
            make: checkNull(make),
            model: checkNull(model),
            barFinish: checkNull(barFinish),
            numberPlate: checkNull(numberPlate),
            blackingPlate: customblackingPlateColor
              ? customblackingPlateColor
              : checkNull(blackingPlate),
            indicators: customIndicators
              ? customIndicators
              : checkNull(indicators),
            integratedLights: checkNull(integratedLights),
            unitPrice: checkNull(productCost),
            customIndicators: checkNull(customIndicators),
            customblackingPlateColor: checkNull(customblackingPlateColor),
            customBarFinish: checkNull(customBarFinish),
            subTotal: calculateSubTotal(),
            tax: calculateTax(),
            total: calculateTotal(),
          })
        : null,
      attributesInfo:
        endCustomerName || vehicleChasisNo
          ? JSON.stringify([
              {
                endCustomerName: checkNull(endCustomerName),
                vehicleChasisNo: checkNull(vehicleChasisNo),
                fittingOption1: checkNull(fittingOption1),
                fittingOption2: checkNull(fittingOption2),
              },
            ])
          : null,
      FileUploadRequest: {
        Files: currFileList.length > 0 ? currFileList : null,
        Category: orderType,
      },
      unitPrice: productCost,
      quantity: productQuantity ? productQuantity : 1,
      tax: calculateTax(),
      subTotal: calculateSubTotal(),
      total: calculateTotal(),
      primaryIdentifier: selectedAccount,
      shippingAddressPrimaryIdentifier: deliveryAddress.primaryIdentifier,
      contactPrimaryIdentifier: selectedContactDetails.primaryIdentifier,
    };

    if (!validateOrderJob.quoteRefInvalid && !validateOrderJob.jobRefInvalid) {
      setValidateOrderJob({
        ...validateOrderJob,
        quoteRefNumValue: value.quoteRefNum,
      });

      if (editData) {
        const orderPrimaryIdentifier = params.get("key");
        OrderManagementService.updateOrderCreateData(
          payload,
          orderPrimaryIdentifier
        )
          .then((res) => {
            message.success(`${orderType} updated successfully`);
            refreshFunction();
            setIsLoading(false);
            setResultIndication(true);
            setIsCardView(true);
            setOrderResponseData(res.data.orderDetails);
          })
          .catch((error) => {
            setIsLoading(false);
            error?.response?.data?.message
              ? message.error(error?.response?.data?.message)
              : message.error("Something went wrong");
          });
      } else {
        OrderManagementService.saveOrderCreateData(payload)
          .then((res) => {
            message.success(`${orderType} saved successfully`);
            refreshFunction();
            setIsLoading(false);
            setResultIndication(true);
            setIsCardView(true);
            setOrderResponseData(res.data);
          })
          .catch((error) => {
            setIsLoading(false);
            error?.response?.data?.message
              ? message.error(error?.response?.data?.message)
              : message.error("Something went wrong");
          });
      }
    } else {
      if (validateOrderJob.quoteRefInvalid) {
        message.error("Enter the valid reference number");
      }
      if (validateOrderJob.jobRefInvalid) {
        message.error("Enter the valid job number");
      }
    }
  };

  const toggleAddressesDrawer = (value) => {
    setOpenAddressListDrawer(value);
  };

  const onFileChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;

    if (!src && file.originFileObj instanceof Blob) {
      src = URL.createObjectURL(file.originFileObj);
    }

    if (file.type === "application/pdf") {
      window.open(src, "_blank");
    } else if (file.type.startsWith("image/")) {
      const imgWindow = window.open("", "_blank");
      imgWindow?.document.write(`<img src="${src}" />`);
    } else {
      message.info("The selected file format cannot be previewed.");
    }
  };

  const handleDateChange = (currforecastedDate, dateString) => {
    const selectedDate = dateString;

    if (currforecastedDate) {
      if (selectedDate < dayjs(currforecastedDate).format("YYYY-MM-DD")) {
        setShowWarning(true);
      } else {
        setShowWarning(false);
      }
    }
  };

  const getCustomerOfSelectedAccount = (primaryIdentifier) => {
    CustomerService.getCustomerContactList(primaryIdentifier)
      .then((res) => {
        if (res.data.length === 0) {
          setCustomerContactList([]);
          message.info(
            "No contact found! Add contact to continue creating order"
          );
          showNoContactButton((prev) => ({
            ...prev,
            showButton: true,
          }));
          form.resetFields(["contactName", "contactEmail", "contactPhone"]);
        } else {
          setCustomerContactList(res.data);
          setSelectedContactDetails(res.data[0]);
          form.setFieldsValue({
            contactName: res.data[0].name,
            contactEmail: res.data[0].email,
            contactPhone: res.data[0].mobile,
          });
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : "Something went wrong"
        );
      });
  };

  const handleSearchAccount = async (value) => {
    const debounce = (func, delay) => {
      return function (...args) {
        clearTimeout(accountSearchTimeout);

        let newTimeoutId = setTimeout(() => func.apply(this, args), delay);

        setAccountSearchTimeout(newTimeoutId);
      };
    };

    const debouncedSearch = debounce(async (accountId) => {
      try {
        const res = await CustomerService.getCustomerDataDropDownList({
          accountId: accountId,
        });

        const filteredReq = res?.data?.map((i) => ({
          label: `${i.accountID} | ${i.accountName}`,
          value: `${i.accountID} | ${i.accountName}`,
          key: i.primaryIdentifier,
          accountID: i.accountID,
        }));

        setCustomerList(filteredReq);
      } catch (error) {
        console.log(error);
        message.error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : "Something went wrong!"
        );
      }
    }, 500);

    debouncedSearch(value);
  };

  const handleAccountSelect = (_, option) => {
    const primaryIdentifier = option.key;

    try {
      setSelectedAccount(primaryIdentifier);

      fetchProducts(option.accountID);

      showNoContactButton({
        showButton: false,
        showDrawer: false,
      });

      getCustomerOfSelectedAccount(primaryIdentifier);

      CustomerService.getCustomerAccountDetails(primaryIdentifier)
        .then((res) => {
          addressFunction(res.data.addressResponse, res.data.addressResponse);
        })
        .catch((error) => {
          message.error(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : "Something went wrong"
          );
        });
    } catch (error) {
      console.log(error);
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went wrong!"
      );
    }
  };

  const calculateSubTotal = () => {
    let subTotal = 0;

    if (!isNaN(productCost)) {
      subTotal += parseInt(productCost);
    }

    Object.values(accessoriesPrice).forEach((price) => {
      const numericPrice = parseFloat(price);
      if (!isNaN(numericPrice)) {
        subTotal += numericPrice;
      }
    });

    return subTotal * (productQuantity ? parseInt(productQuantity) : 1);
  };

  const calculateTax = () => {
    const DURATRAY_TAX_RATE = 0;
    const AJ_TAX_RATE = 0.1;

    const TAX_RATE = currentURL.includes("aj")
      ? AJ_TAX_RATE
      : DURATRAY_TAX_RATE;

    return calculateSubTotal() * TAX_RATE;
  };

  const calculateTotal = () => {
    return calculateSubTotal() + calculateTax();
  };

  const calculateAllPrice = () => {
    calculateSubTotal();
    calculateTax();
    calculateTotal();
  };

  const normalizeQuoteJobRefValue = (value) => {
    const filteredValue = value.replace(/[^a-zA-Z0-9\s]/g, "").replace(" ", "");
    return filteredValue;
  };

  const renderConfigOptions = (value, productsList) => {
    const selectedProduct = productsList?.find((i) => i.value === value);

    selectedProduct?.configurations?.map((item) => {
      let newItem = {
        [item.attributeKey]: item.options.map((i) => ({
          label: i.optionLabel,
          value: i.optionLabel,
          condition: i.condition1,
          show: item.attributeKey === "make" ? true : false,
          price: i.unitPrice,
        })),
      };

      if (!restrictAutoSelect && item.attributeKey === "make") {
        form.setFieldValue("make", newItem["make"][0].value);
      }

      setConfigOptions((prev) => ({
        ...prev,
        ...newItem,
      }));
    });
  };

  const setDefaultOptions = (keys, options) => {
    if (!restrictAutoSelect) {
      keys.forEach((key) => {
        if (key === "make") return;

        form.setFieldValue(key, options[key].filter((i) => i.show)[0]?.value);
      });
    }
  };

  const priceColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "60%",
      render: (text) => <span className="font-semibold">{text}</span>,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "center",
      width: "40%",
      render: (text) => {
        return (
          <NumericFormat
            prefix={"$"}
            value={text || 0}
            className="mr-1 font-semibold"
            displayType={"text"}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={true}
          />
        );
      },
      align: "right",
    },
  ];

  const priceData = [
    { name: "Sub-total", price: calculateSubTotal() },
    { name: "Packaging & Handling Charges", price: 0 },
    { name: "GST", price: calculateTax() },
    { name: "Order Total", price: calculateTotal() },
  ];

  if (loadingOrderData) {
    return (
      <div className="flex items-center justify-center h-screen-190px">
        <Spin size="default" />
      </div>
    );
  }

  if (resultIndication) {
    return (
      <OrderSuccessPage
        validateOrderJob={validateOrderJob}
        refreshFunction={refreshFunction}
        setResultIndication={setResultIndication}
        setIsCardView={setIsCardView}
        orderType={orderType}
        orderDetailsData={orderDetailsData}
        navigate={navigate}
        setViewType={setViewType}
        params={params}
        orderResponseData={orderResponseData}
        setOrderDetailsData={setOrderDetailsData}
        createNewOrder={() => {
          form.resetFields();
          setCustomerContactList([]);
        }}
      />
    );
  }

  return (
    <div className="p-2 mt-4">
      <div className="flex justify-between ">
        <CustomBreadcrumb items={items} />

        {orderDetailsData?.isEditable && !editData && !resultIndication && (
          <CustomButton
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              setEditData(true);
              setRestrictAutoSelect(false);
            }}
          >
            Edit {orderDetailsData?.statusID === 100 ? "Quote" : "Order"}
          </CustomButton>
        )}
      </div>

      {!IsCardView && (
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          onFieldsChange={handleChange}
          onFinishFailed={({ errorFields }) => {
            form.scrollToField(errorFields[0].name[0], {
              behavior: "smooth",
              block: "center",
            });
          }}
        >
          <Card
            type="inner"
            title="Customer Details"
            extra={
              previewOrder !== "preview" &&
              !editData && (
                <CustomButton
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() =>
                    setAddCustomerDrawer((prev) => ({
                      open: true,
                      data: null,
                    }))
                  }
                >
                  New customer
                </CustomButton>
              )
            }
          >
            {(customerList || []) && (
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                  <Form.Item
                    name="primaryIdentifier"
                    label="Account"
                    rules={[
                      {
                        required: true,
                        message: "Select customer account!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      size="medium"
                      placeholder="Select an account"
                      className="!w-full"
                      disabled={previewOrder === "preview" && !editData}
                      options={customerList}
                      notFoundContent={
                        <Empty
                          description="No account found"
                          className="py-4"
                        />
                      }
                      onSearch={handleSearchAccount}
                      onSelect={handleAccountSelect}
                    />
                  </Form.Item>
                </Col>

                {noContactButton.showButton && selectedAccount && (
                  <div className="flex items-center mt-4 ml-3">
                    <CustomButton
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() =>
                        showNoContactButton((prev) => ({
                          ...prev,
                          showDrawer: true,
                        }))
                      }
                    >
                      Add contact
                    </CustomButton>
                  </div>
                )}
              </Row>
            )}

            {selectedAccount && (
              <>
                <Row gutter={[16, 16]} className="mb-1">
                  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <Form.Item
                      name="contactName"
                      label="Name"
                      rules={[
                        {
                          required: true,
                          message: "Select customer contact name!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select contact name"
                        size="medium"
                        disabled={previewOrder === "preview" && !editData}
                        suffixIcon={
                          <DownOutlined
                            style={{ color: blue[5], pointerEvents: "none" }}
                          />
                        }
                        options={customerContactList.map((i) => ({
                          label: i.name,
                          value: i.primaryIdentifier,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <Form.Item
                      name="contactEmail"
                      label="Email"
                      rules={[
                        {
                          required: true,
                          pattern: /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                          message: "Enter contact Email!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter Email"
                        readOnly
                        className="cursor-not-allowed"
                        size="medium"
                        disabled={previewOrder === "preview" && !editData}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <Form.Item
                      name="contactPhone"
                      label="Phone"
                      rules={[
                        {
                          required: true,
                          message: "Enter contact phone!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter Phone"
                        readOnly
                        className="cursor-not-allowed"
                        size="medium"
                        disabled={previewOrder === "preview" && !editData}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                {currentURL.includes("aj") && (
                  <>
                    <AjCustomerAndChassisNo
                      editData={editData}
                      previewOrder={previewOrder}
                    />

                    <AjFittings
                      editData={editData}
                      previewOrder={previewOrder}
                    />
                  </>
                )}
              </>
            )}

            {/* address details */}
            {selectedAccount && billingAddress && deliveryAddress && (
              <div className="grid gap-5 mt-2 mb-2 xl:grid-cols-2 place-items-center justify-items-center">
                <ShowAddressDetails
                  type="inner"
                  title="Billing address"
                  address={billingAddress}
                  isAccountSelected={false}
                  toggleAddressesDrawer={toggleAddressesDrawer}
                />

                <ShowAddressDetails
                  type="inner"
                  title="Shipping address"
                  address={deliveryAddress}
                  isAccountSelected={
                    (previewOrder !== "preview" || editData) && selectedAccount
                  }
                  toggleAddressesDrawer={toggleAddressesDrawer}
                />
              </div>
            )}
          </Card>

          <Card
            type="inner"
            title="Order Details"
            style={{
              marginTop: "10px",
            }}
          >
            <Row gutter={[16, 4]} className="-mb-2">
              <Col xl={6} lg={6} md={8} sm={24} xs={24}>
                <div>
                  <Form.Item
                    label="Reference Number"
                    name="quoteRefNum"
                    rules={[
                      {
                        required: !currentURL.includes("aj"),
                        message: "Enter reference number",
                      },
                    ]}
                    hasFeedback={
                      validateOrderJob.quoteRefInvalid ||
                      (!validateOrderJob.quoteRefInvalid &&
                        validateOrderJob.quoteRefNumValue?.length > 0)
                    }
                    validateStatus={
                      validateOrderJob.quoteRefInvalid ? "error" : "success"
                    }
                    normalize={(value) => normalizeQuoteJobRefValue(value)}
                  >
                    <Input
                      placeholder="Enter reference number"
                      size="medium"
                      onBlur={(e) =>
                        checkRefNumber(e.target.value.replace(/\s/g, ""))
                      }
                      onPressEnter={(e) => {
                        e.preventDefault();
                        checkRefNumber(e.target.value.replace(/\s/g, ""));
                      }}
                      disabled={previewOrder === "preview" || !selectedAccount}
                    />
                  </Form.Item>

                  {validateOrderJob.quoteRefInvalid && (
                    <p className="-mt-2 font-light text-red-500">
                      Reference number already exist!
                    </p>
                  )}
                </div>
              </Col>

              <Col xl={6} lg={6} md={8} sm={24} xs={24}>
                <Form.Item
                  label="Job/PO Number"
                  name="jobRefNum"
                  normalize={(value) => normalizeQuoteJobRefValue(value)}
                  hasFeedback={
                    validateOrderJob.jobRefInvalid ||
                    (!validateOrderJob.jobRefInvalid &&
                      validateOrderJob.jobRefNumValue.length > 0)
                  }
                  validateStatus={
                    validateOrderJob.jobRefInvalid ? "error" : "success"
                  }
                  help={
                    validateOrderJob.jobRefInvalid
                      ? "Job number already exist!"
                      : ""
                  }
                >
                  <Input
                    placeholder="Enter job number"
                    size="medium"
                    onBlur={(e) =>
                      checkJobNumber(e.target.value.replace(/\s/g, ""))
                    }
                    onPressEnter={(e) => {
                      e.preventDefault();
                      checkJobNumber(e.target.value.replace(/\s/g, ""));
                    }}
                    disabled={
                      (previewOrder === "preview" && !editData) ||
                      !selectedAccount
                    }
                  />
                </Form.Item>
              </Col>

              <Col xl={6} lg={6} md={8} sm={24} xs={24}>
                <Form.Item
                  label="Vin/Stock Number"
                  name="internalIdentificationNum"
                  normalize={(value) => normalizeQuoteJobRefValue(value)}
                  hasFeedback={
                    validateOrderJob.vinNumberInvalid ||
                    (!validateOrderJob.vinNumberInvalid &&
                      validateOrderJob.vinNumberValue.length > 0)
                  }
                  validateStatus={
                    validateOrderJob.vinNumberInvalid ? "warning" : "success"
                  }
                  help={
                    validateOrderJob.vinNumberInvalid
                      ? "This Vin/Stock number already exist!"
                      : ""
                  }
                >
                  <Input
                    placeholder="Enter vin/stock number"
                    size="medium"
                    onBlur={(e) =>
                      checkVinNumber(e.target.value.replace(/\s/g, ""))
                    }
                    onPressEnter={(e) => {
                      e.preventDefault();
                      checkVinNumber(e.target.value.replace(/\s/g, ""));
                    }}
                    disabled={
                      (previewOrder === "preview" && !editData) ||
                      !selectedAccount
                    }
                  />
                </Form.Item>
              </Col>

              <Col xl={6} lg={6} md={8} sm={24} xs={24}>
                <Form.Item
                  label="Required Date"
                  name="requiredDate"
                  className="!w-full"
                  rules={[
                    {
                      required: false,
                      message: "Select required date",
                    },
                  ]}
                  validateStatus={showWarning ? "warning" : ""}
                >
                  <DatePicker
                    disabledDate={(current) =>
                      current && dayjs(current).isBefore(dayjs(), "day")
                    }
                    size="middle"
                    className="!w-full"
                    onChange={(date, dateString) =>
                      handleDateChange(forecastedDate, dateString)
                    }
                    disabled={
                      (previewOrder === "preview" && !editData) ||
                      !selectedAccount
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          {previewOrder !== "preview" && (
            <AttachmentCard
              onPreview={onPreview}
              setFileList={setFileList}
              fileList={fileList}
              onFileChange={onFileChange}
              selectedAccount={selectedAccount}
              previewOrder={previewOrder}
              editData={editData}
            />
          )}

          {currentURL.includes("aj") ? (
            <AjProductDetails
              previewOrder={previewOrder}
              editData={editData}
              selectedAccount={selectedAccount}
              renderConfigOptions={renderConfigOptions}
              fetchForecastData={fetchForecastData}
              form={form}
              productList={productList}
              productListLoading={productListLoading}
              forecastedDate={forecastedDate}
              productID={productID}
              productCost={productCost}
              configOptions={configOptions}
              accessoriesPrice={accessoriesPrice}
              setAccessoriesPrice={setAccessoriesPrice}
              calculateAllPrice={calculateAllPrice}
            />
          ) : (
            <DuratrayProductDetails
              previewOrder={previewOrder}
              editData={editData}
              selectedAccount={selectedAccount}
              renderConfigOptions={renderConfigOptions}
              fetchForecastData={fetchForecastData}
              form={form}
              productList={productList}
              productListLoading={productListLoading}
              forecastedDate={forecastedDate}
              productID={productID}
              handleQuantityChange={handleQuantityChange}
              productCost={productCost}
              setAccessoriesPrice={setAccessoriesPrice}
            />
          )}

          <div className="mt-5 mb-10">
            <Row gutter={[16, 16]}>
              <Col
                xl={16}
                lg={24}
                md={24}
                sm={24}
                xs={24}
                className="custom-markdown"
              >
                <Form.Item name="notes">
                  <Markdown
                    previewOrder={
                      previewOrder === "preview" && editData
                        ? null
                        : previewOrder
                    }
                    showBorder={true}
                  />
                </Form.Item>
              </Col>

              <Col xl={8} lg={24} md={24} sm={24} xs={24} className="space-y-3">
                <Table
                  size="small"
                  columns={priceColumns}
                  dataSource={priceData}
                  pagination={false}
                  showHeader={false}
                  bordered
                  tableLayout="fixed"
                />

                {previewOrder !== "preview" && (
                  <CustomButton
                    id="createOrder"
                    size="middle"
                    block
                    htmlType="submit"
                    type="primary"
                    disabled={isLoading || !selectedAccount}
                    icon={
                      isLoading && orderType === "Order" ? (
                        <LoadingOutlined />
                      ) : (
                        <CheckOutlined />
                      )
                    }
                    onClick={() => setOrderType("Order")}
                  >
                    Save as Order
                  </CustomButton>
                )}

                {previewOrder !== "preview" && (
                  <CustomButton
                    id="createOrder"
                    htmlType="submit"
                    size="middle"
                    block
                    type="primary"
                    disabled={isLoading || !selectedAccount}
                    icon={
                      isLoading && orderType === "Quote" ? (
                        <LoadingOutlined />
                      ) : (
                        <CheckOutlined />
                      )
                    }
                    onClick={() => setOrderType("Quote")}
                  >
                    Save as Quote
                  </CustomButton>
                )}

                {orderDetailsData?.isEditable && editData && (
                  <CustomButton
                    htmlType="submit"
                    type="primary"
                    size="middle"
                    block
                    loading={isLoading}
                    disabled={
                      formFieldsTouched !== true &&
                      orderDetailsData?.shippingAddress?.primaryIdentifier ===
                        deliveryAddress?.primaryIdentifier
                    }
                    icon={<CheckOutlined />}
                    onClick={() =>
                      setOrderType(
                        orderDetailsData?.statusID === 100 ? "Quote" : "Order"
                      )
                    }
                  >
                    Update{" "}
                    {orderDetailsData?.statusID === 100 ? "Quote" : "Order"}
                  </CustomButton>
                )}

                {editData && (
                  <CustomButton
                    type="cancel"
                    size="middle"
                    block
                    icon={<CloseOutlined />}
                    disabled={isLoading}
                    onClick={async () => {
                      setEditData(false);
                      setLoadingOrderData(true);
                      setFormFieldsTouched(false);
                      await fetchOrderDetails(params.get("key"));
                      setLoadingOrderData(false);
                    }}
                  >
                    Cancel
                  </CustomButton>
                )}
              </Col>
            </Row>
          </div>
        </Form>
      )}

      <ManageCustomerContacts
        onSuccess={() => {
          getCustomerOfSelectedAccount(selectedAccount);
          showNoContactButton({
            showButton: false,
            showDrawer: false,
          });
        }}
        accountId={
          selectedAccount &&
          customerList
            .find((i) => i.key === selectedAccount)
            ?.value.split(" | ")[0]
        }
        changeStatus={noContactButton.showDrawer && "add"}
        initialValue={null}
        onClose={() => {
          showNoContactButton((prev) => ({
            ...prev,
            showDrawer: false,
          }));
        }}
      />

      {deliveryAddress && (
        <DeliveryAddressesDrawer
          openAddressListDrawer={openAddressListDrawer}
          toggleAddressesDrawer={toggleAddressesDrawer}
          selectedAccount={selectedAccount}
          setBillingAddress={setBillingAddress}
          deliveryAddress={deliveryAddress}
          setDeliveryAddress={setDeliveryAddress}
          billingAddress={billingAddress}
          setIsLoading={setIsLoading}
        />
      )}

      <ManageCustomerAccount
        onSuccess={() => {
          fetchCustomerData();
        }}
        editAction={addCustomerDrawer.open && "add"}
        onClose={() => {
          setAddCustomerDrawer({
            open: false,
            data: null,
          });
        }}
      />
    </div>
  );
}

export default OrderQuoteCreate;
