import axios from "../axios";
class ManageOrganizationService {
  static Instance() {
    return new ManageOrganizationService();
  }

  editOrgnaiztionData(payload) {
    return axios.put("/api/organisation", payload);
  }
  addOrganizationData(payload) {
    return axios.post("/api/organisation", payload);
  }

  getOrganizationData() {
    return axios.get("/api/organisation");
  }

  getOrganizationDetailsData(primaryIdentifier) {
    return axios.get(
      `/api/organisation/details?PrimaryIdentifier=${primaryIdentifier}`
    );
  }
  getOrganizationAddressList(primaryidentifier) {
    return axios.get(
      `/api/address/organisation/list?PrimaryIdentifier=${primaryidentifier}`
    );
  }

  getOrganizationContactList(primaryidentifier) {
    return axios.get(
      `/api/contact/organisation/list?PrimaryIdentifier=${primaryidentifier}&ConsumerType=Consumer`
    );
  }

  uploadLogoFile(primaryIdentifier, payload) {
    return axios.post(
      `api/attachment/upload/logo?PrimaryIdentifier=${primaryIdentifier}`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
}
export default ManageOrganizationService.Instance();
