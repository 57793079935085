import axios from "../axios";
class TicketService {
  static Instance() {
    return new TicketService();
  }

  getTicketList(payload) {
    return axios.post("api/ticket/list", payload);
  }

  createTicket(payload) {
    return axios.post("api/ticket", payload, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
  }

  updateTicket(payload) {
    return axios.put("api/ticket", payload, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
  }

  deleteTicket(payload) {
    return axios.delete("api/ticket", payload);
  }

  getAttachments(attachmentId) {
    return axios.get(`api/attachment/fetch?attachmentId=${attachmentId}`);
  }

  getPreviewFile(type, fileName) {
    return axios.get(`/api/file/preview?Type=${type}&fileName=${fileName}`);
  }

  getTicketComments(primaryIdentifier) {
    return axios.get(
      `/api/ticket/comments?primaryIdentifier=${primaryIdentifier}`
    );
  }

  addTicketComment(payload) {
    return axios.post(`/api/ticket/comments`, payload);
  }

  editTicketComment(payload) {
    return axios.put(`/api/ticket/comments`, payload);
  }
}
export default TicketService.Instance();
