import React from "react";
import { Tooltip } from "antd";
import CustomButton from "./CustomButton";

const ToolTipButton = ({
  tooltipPlacement,
  tooltipHeader,
  children,
  ...buttonProps
}) => {
  return (
    <Tooltip
      trigger="hover"
      placement={tooltipPlacement ? tooltipPlacement : "top"}
      title={tooltipHeader}
    >
      <CustomButton {...buttonProps}>{children}</CustomButton>
    </Tooltip>
  );
};

export default ToolTipButton;
