import { DatePicker, Table, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Common/CustomButton";
import FilterDropdown from "../../Common/FilterDropdown";
import TotalListEntries from "../../Common/TotalListEntries";
import { useDarkMode } from "../../Providers/DarkModeContext";
import CustomerService from "../../services/CustomerService/CustomerService";
import { getDarkModeColor } from "../../utils/darkModeHelper";
import { formatDateMonth } from "../../utils/dateHelper";

const { RangePicker } = DatePicker;

const CustomerOrders = ({ primaryIdentifier }) => {
  const { isDarkMode } = useDarkMode();
  const [isTableDataLoading, setIsTableDataLoading] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const navigate = useNavigate();
  const [list, setList] = useState({
    request: {
      pageRequest: {
        currentPage: 1,
        pageSize: 10,
      },
      sortRequest: {
        key: "",
        direction: true,
      },
      filterRequest: {
        refNum: "",
        startDate: "",
        endDate: "",
      },
    },
    response: {
      records: [],
      totalRecords: 0,
    },
  });

  const handleFiltersChange = (pagination, filters, sorter, extra) => {
    const { request } = list;

    const requestFilters = Object.entries(filters).reduce(
      (acc, [key, value]) => {
        if (key === "ordDate" && value && value.length === 2) {
          acc["startDate"] = value[0];
          acc["endDate"] = value[1];
        } else if (value) {
          acc[key] = value[0];
        }
        return acc;
      },
      {}
    );

    getCustomerOrderList(primaryIdentifier, {
      ...request,
      filterRequest: { ...request.filterRequest, ...requestFilters },
      sortRequest: {
        key: sorter.field,
        direction: sorter.order === "ascend",
      },
      pageRequest: {
        currentPage: pagination.current,
        pageSize: pagination.pageSize,
      },
    });
  };

  const getCustomerOrderList = async (primaryIdentifier, request) => {
    setIsTableDataLoading(true);

    try {
      if (primaryIdentifier) {
        const { data } = await CustomerService.getCustomerOrderData(
          primaryIdentifier,
          request
        );
        const modifiedData = {
          ...data,

          records: data.records.map((record) => ({
            ...record,
            ordDate: record.ordDate ? formatDateMonth(record.ordDate) : "NA",
          })),
        };

        setList((prev) => {
          return {
            ...prev,
            response: modifiedData,
          };
        });
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message ||
          "Unable to fetch orders right now. Try again later."
      );
    } finally {
      setIsTableDataLoading(false);
    }
  };

  useEffect(() => {
    const { request } = list;
    getCustomerOrderList(primaryIdentifier, request);
  }, [primaryIdentifier]);

  const orderDetailsNavigateFunction = (records) => {
    navigate(`order-enquiry?key=${records?.ordNum}&navigationType=current`);
  };

  const columns = [
    {
      title: "Reference Number",
      dataIndex: "quoteRefNum",
      key: "refNum",
      align: "center",
      filterDropdown: (props) => (
        <FilterDropdown {...props} placeholder="Search ref number" />
      ),
      sorter: (a, b) => a.ordNum - b.ordNum,
      render: (values, records) => {
        return (
          <CustomButton
            type="primary"
            onClick={() => orderDetailsNavigateFunction(records)}
          >
            {values}
          </CustomButton>
        );
      },
    },

    {
      title: "Order Date",
      dataIndex: "ordDate",
      key: "ordDate",
      align: "center",
      filterDropdown: (props) => (
        <FilterDropdown
          {...props}
          placeholder="Select dates"
          type="dateRange"
        />
      ),
    },

    {
      title: "Status",
      dataIndex: "statusName",
      key: "statusName",
      sorter: (a, b) => a.statusName.length - b.statusName,
      align: "center",
      render: (values) => {
        return <Tag>{values}</Tag>;
      },
    },
  ];

  let tableStartingIndex =
    (list.response.currentPage - 1) * list.response.pageSize + 1;

  if (
    list.response.totalRecords <
    list.response.pageSize * list.response.currentPage
  ) {
    tableStartingIndex =
      (list.response.currentPage - 1) * list.response.pageSize + 1;
  }

  return (
    <div>
      <div className="rounded ">
        <TotalListEntries
          checkNullCondition={list.response.records.length > 0}
          startingIndex={tableStartingIndex}
          totalEntries={list.response.totalRecords}
          currentPage={list.response.currentPage}
          pageSize={list.response.pageSize}
        />

        <div className="mt-2">
          <Table
            pagination={{
              pageSize: list.request.pageSize,
              current: list.request.currentPage,
              total: list.response.totalRecords,
              showSizeChanger: true,
            }}
            columns={columns}
            loading={isTableDataLoading}
            dataSource={list.response.records}
            size="small"
            scroll={{ x: true }}
            tableLayout="fixed"
            bordered={"1px solid black"}
            onChange={handleFiltersChange}
            rowClassName={(record, index) =>
              index % 2 !== 0 ? getDarkModeColor(isDarkMode, "bg-gray-100") : ""
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerOrders;
