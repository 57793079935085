import { CloseOutlined } from "@ant-design/icons";
import { Image, Modal, message } from "antd";
import React from "react";
import CustomButton from "../../Common/CustomButton";

const OrderAttachmentPreviewModal = ({
  onCancel,
  isModalOpen,
  previewData,
  setPreviewModal,
}) => {
  const isPdf =
    previewData &&
    (previewData.includes(".pdf") || previewData.includes(".PDF"));
  const isExcelFile = previewData && previewData.includes(".xlsx");

  if (isExcelFile) {
    message.info("This file type cannot be previewed!");
    return <></>;
  }

  return (
    <>
      {isPdf ? (
        <Modal
          open={isModalOpen}
          onCancel={onCancel}
          className="relative min-h-screen top-5 custom-modal"
          footer={null}
          width={1900}
          destroyOnClose
          closeIcon={false}
        >
          <iframe
            src={previewData}
            title="Preview"
            height={820}
            className="min-w-full"
          />

          <CustomButton
            className="absolute border-white -top-2 -right-2 hover:bg-opacity-100"
            type="cancel"
            icon={<CloseOutlined />}
            onClick={onCancel}
          />
        </Modal>
      ) : (
        <Image
          preview={{
            visible: isModalOpen,
            src: previewData,
            onVisibleChange: (value) => {
              setPreviewModal(value);
            },
          }}
          style={{ maxWidth: "100%" }}
          className="object-center"
        />
      )}
    </>
  );
};

export default OrderAttachmentPreviewModal;
