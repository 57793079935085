import {
  EyeInvisibleOutlined,
  EyeOutlined,
  FileAddOutlined,
  FileExcelOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Table, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomBreadcrumb from "../../Common/CustomBreadcrumb";
import CustomButton from "../../Common/CustomButton";
import FilterDropdown from "../../Common/FilterDropdown";
import TotalListEntries from "../../Common/TotalListEntries";
import { AuthContext } from "../../Providers/AuthProvider";
import { useDarkMode } from "../../Providers/DarkModeContext";
import { useScreenSize } from "../../Providers/ScreenSizeProvider";
import CustomerService from "../../services/CustomerService/CustomerService";
import { getDarkModeColor } from "../../utils/darkModeHelper";
import ManageCustomerAccount from "./ManageCustomerAccount";

const CustomerListPage = ({ id }) => {
  const { isDarkMode } = useDarkMode();
  const currentScreenSize = useScreenSize();
  const navigate = useNavigate();
  const [IsColumnsHide, setIsColumnHide] = useState(true);

  const [list, setList] = useState({
    request: {
      pageRequest: {
        currentPage: 1,
        pageSize: 10,
      },
      sortRequest: {
        key: "",
        direction: true,
      },
      filterRequest: {
        address: "",
        state: "",
        company: "",
        postCode: "",
        accountID: "",
        debtorCategoryID: 0,
        accountStatusID: 0,
        accountStatus: "",
        city: "",
        refNum: "",
      },
    },
    response: {
      records: [],
      totalRecords: 0,
    },
  });

  const [editAction, setEditAction] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isTableDataLoading, setIsTableDataLoading] = useState(false);
  let { userPrimaryRole } = useContext(AuthContext);
  let isAdmin = userPrimaryRole?.toLowerCase()?.includes("admin");
  const [isExporting, setIsExporting] = useState(false);

  const getCustomerList = async (request) => {
    setIsTableDataLoading(true);

    try {
      const { data } = await CustomerService.getCustomerList(request);
      setList((prev) => {
        return {
          ...prev,
          response: data,
        };
      });
    } catch (error) {
      message.error(
        error?.response?.data?.message ||
          "Unable to fetch customer list right now. Try again later."
      );
    } finally {
      setIsTableDataLoading(false);
    }
  };

  useEffect(() => {
    const { request } = list;
    getCustomerList(request);
  }, []);

  useEffect(() => {
    const params = searchParams.get("status");

    if (params == "createNewAccount") {
      setEditAction("add");
    } else if (params === "edit") {
      setEditAction("edit");
    } else {
      handleDrawerClose();
    }
  }, [searchParams]);

  const handleCreateNewAccount = () => {
    setSearchParams({ status: "createNewAccount" });
  };

  const handleButtonNavigate = (primaryIdentifier) => {
    navigate(
      `/customer-list/enquiry?key=${primaryIdentifier}&activeTab=General`
    );
  };

  const handleExport = async () => {
    const payload = list.request;

    setIsExporting(true);

    try {
      const response = await CustomerService.exportCustomerList(payload);

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "CUSTOMERS-LIST.xlsx";
      link.click();
    } catch (error) {
      console.error(error?.response?.data?.message);
      message.error(
        error?.response?.data?.message ||
          "Unable to export customer list. Try again later!"
      );
    } finally {
      setIsExporting(false);
    }
  };

  const handleExtendedColumn = () => {
    setIsColumnHide(!IsColumnsHide);
  };

  const handleDrawerClose = () => {
    setSearchParams({});
    setEditAction(null);
  };

  const columns = [
    {
      title: "Account",
      dataIndex: "accountID",
      align: "center",
      width: 50,
      filterDropdown: (props) => (
        <FilterDropdown {...props} placeholder="Search account" />
      ),
      render: (values, records) => {
        return (
          <>
            <CustomButton
              type="primary"
              onClick={() => handleButtonNavigate(records.primaryIdentifier)}
            >
              {values}
            </CustomButton>
          </>
        );
      },
      sorter: (a, b) => a.accountID - b.accountID,
    },
    {
      title: "Ref Number",
      dataIndex: "refNum",
      align: "center",
      width: 130,
      filterDropdown: (props) => (
        <FilterDropdown {...props} placeholder="Search ref number" />
      ),
      render: (value, record) =>
        value && value != "0" ? (
          <>
            <CustomButton
              type="primary"
              onClick={() => handleButtonNavigate(record.primaryIdentifier)}
            >
              {value}
            </CustomButton>
          </>
        ) : (
          <>-</>
        ),
      sorter: (a, b) => a.refNum - b.refNum,
    },
    {
      title: "Name",
      dataIndex: "company",
      width: 200,
      filterDropdown: (props) => (
        <FilterDropdown {...props} placeholder="Search company" />
      ),

      sorter: (a, b) => a.company.length - b.company.length,
    },
    {
      title: "Address",
      key: "address",
      render: (_, record) => (
        <span>
          {record?.country ? record?.country : ""}
          {record?.state ? `, ${record?.state}` : ""}
          {record?.city ? `, ${record?.city}` : ""}
          {record?.postCode ? `, ${record?.postCode}` : ""}
        </span>
      ),
      width: 300,
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      width: 150,
    },
    {
      title: "Email",
      dataIndex: "emailAddress",
      key: "emailAddress",
      width: 250,
      hidden: IsColumnsHide,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      hidden: IsColumnsHide,
      align: "center",
      width: 200,
    },
    {
      title: "Post Code",
      dataIndex: "postCode",
      key: "postCode",
      hidden: IsColumnsHide,
      width: 200,
      align: "center",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      hidden: IsColumnsHide,
      width: 200,
    },
  ].filter((col) => !col.hidden);

  const handleFiltersChange = (pagination, filters, sorter, extra) => {
    const { request } = list;

    const requestFilters = Object.keys(filters).reduce((acc, key) => {
      if (filters[key]) {
        acc[key] = filters[key][0];
      }
      return acc;
    }, {});

    setList((prev) => ({
      ...prev,
      request: {
        ...prev.request,
        pageRequest: {
          currentPage: pagination.current,
          pageSize: pagination.pageSize,
        },
      },
    }));

    getCustomerList({
      ...request,
      filterRequest: requestFilters,
      sortRequest: { key: sorter.field, direction: sorter.order === "ascend" },
      pageRequest: {
        currentPage: pagination.current,
        pageSize: pagination.pageSize,
      },
    });
  };

  let tableStartingIndex =
    (list.response.currentPage - 1) * list.response.pageSize + 1;

  if (
    list.response.totalRecords <
    list.response.pageSize * list.response.currentPage
  ) {
    tableStartingIndex =
      (list.response.currentPage - 1) * list.response.pageSize + 1;
  }

  const items = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "Customer",
    },
  ];

  return (
    <div className="p-2 mt-4">
      <CustomBreadcrumb items={items} />

      <div className="flex flex-wrap items-end justify-between mb-1">
        <TotalListEntries
          checkNullCondition={list.response.records.length > 0}
          startingIndex={tableStartingIndex}
          totalEntries={list.response.totalRecords}
          currentPage={list.response.currentPage}
          pageSize={list.response.pageSize}
        />

        <div className="flex flex-row gap-2 mb-2">
          <CustomButton
            className="hover:!bg-cyan-400 hover:!border-cyan-400 hover:!text-white"
            type="dashed"
            onClick={() => navigate("/customer-list/import-customer")}
            icon={<FileAddOutlined />}
          >
            Import
          </CustomButton>

          {list.response.records.length > 0 && (
            <CustomButton
              type="dashed"
              onClick={handleExport}
              loading={isExporting}
              className="hover:!bg-green-400 hover:!border-green-400 hover:!text-white"
              icon={<FileExcelOutlined />}
            >
              Export
            </CustomButton>
          )}

          {isAdmin && (
            <CustomButton
              id="addCustomer"
              type="primary"
              onClick={handleCreateNewAccount}
              icon={<PlusOutlined />}
            >
              Create
            </CustomButton>
          )}

          <CustomButton
            onClick={handleExtendedColumn}
            type="primary"
            icon={IsColumnsHide ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          >
            {IsColumnsHide ? "Extend" : "Hide"}
          </CustomButton>
        </div>
      </div>

      <Table
        pagination={{
          pageSize: list.response.pageSize,
          current: list.response.currentPage,
          total: list.response.totalRecords,
          showSizeChanger: true,
        }}
        loading={isTableDataLoading}
        scroll={{
          x: currentScreenSize < 1024 ? (IsColumnsHide ? 1400 : 1800) : 1000,
        }}
        size="small"
        bordered={true}
        dataSource={list.response.records}
        columns={columns}
        onChange={handleFiltersChange}
        className="divide-y divide-gray-200"
        rowClassName={(record, index) =>
          index % 2 !== 0 ? getDarkModeColor(isDarkMode, "bg-gray-100") : ""
        }
      />

      <ManageCustomerAccount
        onSuccess={() => {
          const { request } = list;
          getCustomerList(request);
        }}
        editAction={editAction}
        onClose={handleDrawerClose}
      />
    </div>
  );
};

export default CustomerListPage;
