import {
  Alert,
  Button,
  Checkbox,
  Drawer,
  Form,
  Image,
  Input,
  Row,
  Select,
  Typography,
  Upload,
  message,
} from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import ViewInfoButton from "../../Common/ViewInfoButton";
import ProductService from "../../services/ProductBuilder/ProductService";
import CustomButton from "../../Common/CustomButton";
import {
  CheckOutlined,
  CloseOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";
import { validateTextInput } from "../../utils/validationsHelper";

const unitOptions = [
  {
    label: "Units per day",
    value: "Per_Day",
  },
];

const ManageProduct = ({
  changeStatus,
  onClose,
  initialValue,
  onSuccess,
  productGroupDropdownData,
}) => {
  const [form] = useForm();
  const [productGroupListData, setProductGroupListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (changeStatus === "edit") {
      form.setFieldsValue(initialValue);
    } else {
      form.setFieldValue("unit", unitOptions[0].value);
    }
  }, [initialValue, changeStatus]);

  useEffect(() => {
    if (!!changeStatus) {
      ProductService.getProductGroupPageData()
        .then((res) => {
          setProductGroupListData(res.data);
        })
        .catch((error) => {
          error?.response?.data?.message
            ? message.error(error?.response?.data?.message)
            : message.error("Something went wrong");
        });
    }
  }, []);

  const onFinish = (values) => {
    const formData = new FormData();

    Object.keys(values).forEach((key) => {
      if (key === "productImage") {
        formData.append(
          "fileUploadRequest.files",
          values.productImage ? values.productImage?.file : null
        );
        formData.append("fileUploadRequest.category", "Product");
      } else {
        formData.append(key, values[key]);
      }
    });

    formData.append("sortIndex", "1");

    if (initialValue && changeStatus === "edit") {
      formData.append("id", initialValue.id);
    }

    if (changeStatus === "add") {
      setIsLoading(true);
      ProductService.saveProductData(formData)
        .then((res) => {
          message.success("Product Added Successfully");
          setIsLoading(false);
          handleClose();
          onSuccess();
        })
        .catch((error) => {
          setIsLoading(false);
          error?.response?.data?.message
            ? message.error(error?.response?.data?.message)
            : message.error("Something went wrong");
        });
    } else if (changeStatus === "edit") {
      setIsLoading(true);
      ProductService.editProductData(formData)
        .then((res) => {
          message.success("Product Updated Successfully");
          setIsLoading(false);
          handleClose();
          onSuccess();
        })
        .catch((error) => {
          setIsLoading(false);
          error?.response?.data?.message
            ? message.error(error?.response?.data?.message)
            : message.error("Something went wrong");
        });
    }
  };

  const validateMinValue = (rule, value) => {
    const minValue = 1;
    if (value && value < minValue) {
      return Promise.reject(`Minimum value is ${minValue}`);
    }
    return Promise.resolve();
  };

  const onFileChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;

    if (!src && file.originFileObj instanceof Blob) {
      src = URL.createObjectURL(file.originFileObj);
    }

    if (file.type === "application/pdf") {
      window.open(src, "_blank");
    } else if (file.type.startsWith("image/")) {
      const imgWindow = window.open("", "_blank");
      imgWindow?.document.write(`<img src="${src}" />`);
    } else {
      message.info("The selected file format cannot be previewed.");
    }
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
    setFileList([]);
  };

  return (
    <Drawer
      title={
        changeStatus === "add"
          ? "Add Product"
          : changeStatus === "edit"
          ? "Edit Product"
          : ""
      }
      width={400}
      onClose={handleClose}
      open={!!changeStatus}
      bodyStyle={{
        padding: "4px 16px",
      }}
      destroyOnClose={true}
    >
      <div className="rounded-md border-black-600 ">
        <div>
          <Form
            form={form}
            name="form_item_path"
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="productGroupID"
              label="Product group"
              rules={[
                {
                  required: true,
                  message: "Select product group",
                },
              ]}
            >
              <Select
                size="medium"
                placeholder="Select product group"
                mode="single"
                allowClear="true"
                options={productGroupDropdownData}
              ></Select>
            </Form.Item>

            <Form.Item
              name="name"
              label="Product name"
              rules={[
                {
                  required: true,
                  message: "Enter product name",
                },
                { validator: validateTextInput("Product name") },
              ]}
            >
              <Input size="medium" placeholder="Enter product name" />
            </Form.Item>

            <Form.Item
              name="code"
              label="Product code"
              rules={[
                {
                  required: true,
                  message: "Enter product code",
                },
                {
                  pattern: /^[A-Z]+$/,
                  message: "Product code should contain only alphabets",
                },
              ]}
            >
              <Input
                name="Code"
                size="medium"
                maxLength={5}
                disabled={changeStatus === "edit"}
                onChange={(e) => {
                  const upperCaseValue = e.target.value.toUpperCase();
                  form.setFieldsValue({ code: upperCaseValue });
                }}
                placeholder="Enter product code"
              />
            </Form.Item>

            <Form.Item
              name="capacity"
              label="Daily Production Capacity"
              rules={[
                {
                  required: true,
                  message: "Enter per day capacity",
                },
                { validator: validateMinValue },
              ]}
            >
              <Input
                type="number"
                min={1}
                size="medium"
                placeholder="Enter per day capacity"
              />
            </Form.Item>

            <Form.Item
              name="unit"
              label="Units"
              rules={[
                {
                  required: true,
                  message: "Select unit type",
                },
              ]}
            >
              <Select
                size="medium"
                placeholder="Select unit type"
                options={unitOptions}
              />
            </Form.Item>

            <Form.Item
              label="Product Image"
              name="productImage"
              rules={[
                {
                  required: false,
                  message: "Select product Image",
                },
              ]}
              className="w-full"
            >
              <Upload
                listType="picture"
                accept="image/*"
                fileList={fileList}
                maxCount={1}
                onChange={onFileChange}
                onPreview={onPreview}
                onRemove={() => {
                  setFileList([]);
                }}
                beforeUpload={(file) => {
                  setFileList([file]);
                  return false;
                }}
              >
                <CustomButton
                  className=""
                  type="primary"
                  icon={<CloudUploadOutlined />}
                >
                  {changeStatus === "edit" ? "Change image" : "Select File"}
                </CustomButton>
              </Upload>
            </Form.Item>

            {changeStatus === "edit" && (
              <div className="w-full mt-5">
                <Image
                  width="100%"
                  height="8rem"
                  src={initialValue.productAttachment || "/no_image.png"}
                  className="object-cover"
                />
              </div>
            )}

            <Row span={10} className="flex flex-col mt-4">
              <Typography level={5} className="flex justify-between">
                Is visible on production environment ?
                <ViewInfoButton
                  extraClassNames="text-lg"
                  placement="left"
                  title="Ensure the visibility of this product on the product builder within the production environment."
                />
              </Typography>
              <Form.Item
                initialValue={true}
                valuePropName="checked"
                name="isVisibleOnProductBuilderToInternalUserOnProdEnvironment"
              >
                <Checkbox defaultChecked>To Internal User</Checkbox>
              </Form.Item>
              <Form.Item
                initialValue={true}
                valuePropName="checked"
                name="isVisibleOnProductBuilderToExternalCustomerOnProdEnvironment"
              >
                <Checkbox defaultChecked>To External Customer</Checkbox>
              </Form.Item>
            </Row>

            <Row className="flex flex-col" span={10}>
              <Typography level={5} className="flex justify-between">
                Is visible on test environment ?
                <ViewInfoButton
                  extraClassNames="text-lg"
                  placement="left"
                  title="Enable the visibility of this product within the test environment on the product builder."
                />
              </Typography>
              <Form.Item
                initialValue={true}
                valuePropName="checked"
                name="isVisibleOnProductBuilderToInternalUserOnTestEnvironment"
              >
                <Checkbox defaultChecked>To Internal User</Checkbox>
              </Form.Item>
              <Form.Item
                initialValue={true}
                valuePropName="checked"
                name="isVisibleOnProductBuilderToExternalCustomerOnTestEnvironment"
              >
                <Checkbox defaultChecked>To External Customer</Checkbox>
              </Form.Item>
              <Row className="flex flex-col" span={10}>
                <Typography level={5} className="flex justify-between">
                  Mark as Active ?
                  <ViewInfoButton
                    extraClassNames="text-lg"
                    placement="left"
                    title="Mark active means it is ready to deploy this product in the production environment."
                  />
                </Typography>

                <Form.Item
                  valuePropName="checked"
                  initialValue={true}
                  name="isActive"
                >
                  <Checkbox defaultChecked>YES</Checkbox>
                </Form.Item>
              </Row>
            </Row>

            <div className="flex justify-end gap-2 mt-5">
              <CustomButton
                type="primary"
                htmlType="submit"
                loading={isLoading}
                icon={<CheckOutlined />}
              >
                Submit
              </CustomButton>

              <CustomButton
                type="cancel"
                onClick={handleClose}
                icon={<CloseOutlined />}
              >
                Cancel
              </CustomButton>
            </div>
          </Form>
        </div>
      </div>
    </Drawer>
  );
};

export default ManageProduct;
