import axios from "../axios";
class Roles {
  static Instance() {
    return new Roles();
  }

  getPriviledgesList() {
    return axios.get("api/privilege/list");
  }

  updatePrivilege(payload) {
    return axios.put("api/privilege", payload);
  }

  addPrivilege(payload) {
    return axios.post("api/privilege", payload);
  }

  getUserRoleList(request) {
    return axios.post("api/admin/user/roleList", request);
  }
  addUserRoleData(payload) {
    return axios.post("/api/admin/role", payload);
  }

  updateUserRole(payload) {
    return axios.put("api/admin/role", payload);
  }

  updateUserRoleIsPrimary(primaryIDentifier, RoleID) {
    return axios.post(
      `api/admin/user/activeRole?PrimaryIdentifier=${primaryIDentifier}&RoleID=${RoleID}`
    );
  }

  updateUserIsActive(primaryIDentifier) {
    return axios.post(
      `api/admin/user/change/status?PrimaryIdentifier=${primaryIDentifier}`
    );
  }

  getPrivilegeGroupSearchList(value) {
    return axios.get(`api/privilege/group/name?GroupName=${value}`);
  }

  getPrivilegeGroupList(value) {
    return axios.get(`api/privilege/group/list?GroupName=${value}`);
  }
}
export default Roles.Instance();
