import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { Drawer, Form, Input, Row, Select, Tooltip, message } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import ProductionService from "../../../services/Production/ProductionServices";
import "./styles.css";
import CustomButton from "../../../Common/CustomButton";

const DefaultCapacityDrawer = ({
  open,
  onClose,
  dropdownList,
  fetchList,
  product,
}) => {
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        processLineID: dropdownList.map((line) => line.value),
      });
    }
  }, [open]);

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const handleSubmit = async (values) => {
    const payload = {
      processLineIDs: values.processLineID,
      capacity: values.capacity,
      processLineName: "",
      autoRefill: "",
      productionDates: [""],
      startDate: "",
      endDate: "",
      productGroupID: product,
    };

    setIsLoading(true);
    try {
      await ProductionService.saveDefaultCapacity(payload);
      setIsLoading(false);
      fetchList(product);
      handleClose();
      message.success("Updated default capacity successfully!");
    } catch (error) {
      setIsLoading(false);
      console.error(error?.response?.data?.message);
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went wrong!"
      );
    }
  };

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        handleSubmit(values);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSelectAll = () => {
    const allProcessLines = dropdownList.map((line) => line.value);
    form.setFieldValue("processLineID", allProcessLines);
  };

  const handleDeselectAll = () => {
    form.setFieldValue("processLineID", []);
  };

  return (
    <Drawer
      open={open}
      width={320}
      onClose={handleClose}
      title="Configure Default Capacity"
      destroyOnClose
    >
      <Form
        name="capacityForm"
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          name="processLineID"
          label="Status"
          rules={[
            {
              required: true,
              message: "Please select status",
            },
          ]}
        >
          <Select
            size="medium"
            mode="multiple"
            placeholder="Select status"
            className="max-h-[10rem] overflow-y-auto"
            options={[{ label: "Select All", value: "all" }, ...dropdownList]}
            allowClear={{
              clearIcon: (
                <Tooltip trigger="hover" title="Remove All">
                  <DeleteOutlined className="text-blue-600" />
                </Tooltip>
              ),
            }}
            onSelect={(value) => {
              if (value === "all") {
                handleSelectAll();
              }
            }}
            onDeselect={(value) => {
              if (value === "all") {
                handleDeselectAll();
              }
            }}
          />
        </Form.Item>

        <Form.Item
          name="capacity"
          label="Default Capacity"
          rules={[
            {
              required: true,
              message: "Please enter capactiy",
            },
          ]}
        >
          <Input
            size="medium"
            type="number"
            min={0}
            placeholder="Enter default capacity"
          />
        </Form.Item>

        <Row className="justify-end w-full gap-3 mt-10">
          <CustomButton
            type="primary"
            loading={isLoading}
            htmlType="submit"
            icon={<CheckOutlined />}
          >
            Submit
          </CustomButton>

          <CustomButton
            type="cancel"
            onClick={handleClose}
            icon={<CloseOutlined />}
          >
            Cancel
          </CustomButton>
        </Row>
      </Form>
    </Drawer>
  );
};

export default DefaultCapacityDrawer;
