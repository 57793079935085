import { DownOutlined, FileTextOutlined, UpOutlined } from "@ant-design/icons";
import { Collapse, Table, Tag } from "antd";
import React, { useState } from "react";
import CustomButton from "../../Common/CustomButton";
import { useDarkMode } from "../../Providers/DarkModeContext";
import { useScreenSize } from "../../Providers/ScreenSizeProvider";
import { formatDateMonth, isToday } from "../../utils/dateHelper";
import ManageJobPriority from "./ManageJobPriority";

function ScheduleItem({
  activeTabLabel,
  item,
  columns,
  schedulerDataFetch,
  selectedProductGroup,
  userRole,
  index,
}) {
  const { isDarkMode } = useDarkMode();
  const currentScreenSize = useScreenSize();
  const [openJobPriorityDrawer, setOpenJobPriorityDrawer] = useState(false);
  const [selectedScheduledItem, setSelectedScheduledItem] = useState([]);

  const openJobPriorityChange = (item, value) => {
    setSelectedScheduledItem(item.productionScheduleOrders);
    setOpenJobPriorityDrawer(value);
  };

  return (
    <>
      <Collapse
        expandIcon={({ isActive }) =>
          isActive ? <UpOutlined /> : <DownOutlined />
        }
        expandIconPosition="start"
        defaultActiveKey={
          userRole.includes("worker")
            ? [item?.scheduleOn]
            : isToday(item.scheduleOn)
            ? [item?.scheduleOn]
            : []
        }
        items={[
          {
            key: item?.scheduleOn,
            label: (
              <div className="text-sky-500">
                {item.scheduleOn ? (
                  isToday(item.scheduleOn) ? (
                    <p>
                      {formatDateMonth(item.scheduleOn)}
                      <span
                        className={`ml-2 text-xs ${
                          isDarkMode ? " text-gray-300" : "text-gray-900"
                        }`}
                      >
                        ( Today )
                      </span>
                    </p>
                  ) : (
                    formatDateMonth(item.scheduleOn)
                  )
                ) : (
                  "NA"
                )}
              </div>
            ),
            extra: (
              <div>
                <Tag onClick={(e) => e.stopPropagation()}>
                  {item?.productionScheduleOrders?.length} Quantity{" "}
                  {activeTabLabel === "OnHold" ? "on hold" : "Scheduled"}
                </Tag>

                {activeTabLabel !== "OnHold" &&
                  !userRole.includes("worker") && (
                    <CustomButton
                      className={"ms-2"}
                      type="primary"
                      size="small"
                      icon={<FileTextOutlined />}
                      onClick={(e) => {
                        e.stopPropagation();
                        openJobPriorityChange(item, true);
                      }}
                    >
                      Change Job's Priority
                    </CustomButton>
                  )}
              </div>
            ),
            children: (
              <>
                <Table
                  pagination={false}
                  dataSource={item?.productionScheduleOrders}
                  columns={columns}
                  tableLayout="fixed"
                  scroll={{
                    x: 1000,
                  }}
                />
              </>
            ),
          },
        ]}
      />
      <ManageJobPriority
        schedulerDataFetch={schedulerDataFetch}
        selectedProductGroup={selectedProductGroup}
        openJobPriorityDrawer={openJobPriorityDrawer}
        selectedScheduledItem={selectedScheduledItem}
        setSelectedScheduledItem={setSelectedScheduledItem}
        onClose={() => {
          setOpenJobPriorityDrawer(false);
        }}
      />
    </>
  );
}

export default ScheduleItem;
