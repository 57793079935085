import {
  CloseCircleOutlined,
  CloudUploadOutlined,
  EditOutlined,
  FileTextOutlined,
  FormOutlined,
  OrderedListOutlined,
} from "@ant-design/icons";
import { DatePicker, Table, Tag, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Common/CustomButton";
import FilterDropdown from "../../Common/FilterDropdown";
import ToolTipButton from "../../Common/ToolTipButton";
import TotalListEntries from "../../Common/TotalListEntries";
import { AuthContext } from "../../Providers/AuthProvider";
import { useDarkMode } from "../../Providers/DarkModeContext";
import { useLocalStorage } from "../../Providers/LocalStorageProvider";
import { useScreenSize } from "../../Providers/ScreenSizeProvider";
import OrderManagementService from "../../services/OrderManagement/OrderManagementService";
import { getDarkModeColor } from "../../utils/darkModeHelper";
import { formatDateMonth } from "../../utils/dateHelper";
import AttachmentDrawer from "../Scheduler/AttachmentDrawer";
import ManageConfigure from "./ManageConfigure";
import OrderAttachmentDrawer from "./OrderAttachmentDrawer";
import OrderCancelDrawer from "./OrderCancelDrawer";
import OrderNotesDrawer from "./OrderNotesDrawer";
import OrderStatusDrawer from "./OrderStatusDrawer";
import ViewOrderChecklistsDrawer from "./ViewOrderChecklistsDrawer";

const { RangePicker } = DatePicker;

const OrderConfigure = ({
  tableConfigData,
  isLoadOnSave,
  setIsLoadOnSave,
  list,
  setList,
  showButtons,
  setIsLoading,
  isLoading,
  refresh,
  activeTab,
  isTableDataLoading,
  setIsTableDataLoading,
  productsList,
  fetchCountData,
}) => {
  const { isDarkMode } = useDarkMode();
  const currentScreenSize = useScreenSize();
  const [changeStatus, setChangeStatus] = useState(null);
  let { userPrimaryRole } = useContext(AuthContext);
  let isAdmin = userPrimaryRole?.toLowerCase()?.includes("admin");
  const [updateStatus, setUpdateStatus] = useState(false);
  const [cancelLoadingStates, setCancelLoadingStates] = useState([]);
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [identifier, setIdentifier] = useState(null);
  const [orderNumber, setOrderNumber] = useState(null);
  const [orderUploadAttachmentDrawer, setOrderUploadAttachmentDrawer] =
    useState(false);
  const [isCancelOrderDrawer, setIsCancelOrderDrawer] = useState(false);
  const [currentOrderStatus, setCurrentOrderStatus] = useState([]);
  const [orderStatusDrawerType, setOrderStatusDrawerType] = useState(null);
  const [manageDrawer, setManageDrawer] = useState({
    open: null,
    data: null,
  });

  const { getValueFromLocalStorage, setValueInLocalStorage } =
    useLocalStorage();
  const pageRequest = getValueFromLocalStorage("pageRequest");
  const sortRequest = getValueFromLocalStorage("sortRequest");

  useEffect(() => {
    const { request } = list;

    if (pageRequest) {
      request.pageRequest = pageRequest;
    }

    if (sortRequest) {
      request.sortRequest = sortRequest;
    }

    getConfigureData(request, tableConfigData?.primaryIdentifier);
  }, [isLoadOnSave, updateStatus, isLoading]);

  const getConfigureData = async (request, primaryIdentifier) => {
    setIsLoading(false);
    try {
      setIsTableDataLoading(true);

      const { data } = await OrderManagementService.getOrderTableData(
        request,
        primaryIdentifier
      );

      fetchCountData(request.filterRequest);

      setList((prev) => {
        return {
          ...prev,
          request: request,
          response: data,
        };
      });
      setIsTableDataLoading(false);
    } catch (error) {
      error?.response?.data?.message
        ? message.error(error?.response?.data?.message)
        : message.error("Something went wrong");
    }
  };

  const handleFiltersChange = (pagination, filters, sorter) => {
    const { request } = list;

    let { filterRequest } = request;

    filterRequest = { ...filterRequest, ...filters };

    const requestFilters = Object.keys(filterRequest).reduce((acc, key) => {
      const value =
        filters[key] && filters[key][0] !== null ? filters[key][0] : "";
      acc[key] = value;
      return acc;
    }, {});

    if (filters.ordDate?.length === 2) {
      requestFilters.startDate = filters.ordDate[0];
      requestFilters.endDate = filters.ordDate[1];
    } else {
      requestFilters.startDate = "";
      requestFilters.endDate = "";
    }

    setValueInLocalStorage("pageRequest", {
      currentPage: 1,
      pageSize: pagination.pageSize,
    });

    if (Object.keys(sorter).length > 0) {
      setValueInLocalStorage("sortRequest", {
        key: sorter.field,
        direction: sorter.order === "ascend",
      });
    }

    getConfigureData(
      {
        ...request,
        filterRequest: requestFilters,
        sortRequest: {
          key: sorter.field,
          direction: sorter.order === "ascend",
        },
        pageRequest: {
          currentPage: pagination.current,
          pageSize: pagination.pageSize,
        },
      },
      tableConfigData?.primaryIdentifier
    );
  };

  const orderDetailsNavigateFunction = (records) => {
    navigate(`enquiry?key=${records?.ordNum}&navigationType=current`);
  };

  let columns = [
    {
      title: "Reference Number",
      dataIndex: "quoteRefNum",
      key: "refNum",
      align: "center",
      defaultSortOrder:
        sortRequest?.key === "quoteRefNum"
          ? sortRequest?.direction
            ? "ascend"
            : "descend"
          : null,
      defaultFilteredValue: "",
      filterDropdown: (props) => (
        <FilterDropdown {...props} placeholder="Search ref number" />
      ),
      sorter: (a, b) => a.ordNum - b.ordNum,
      render: (values, records) => {
        return (
          <>
            {showButtons?.enquiryButton ? (
              <CustomButton
                type="primary"
                onClick={() => orderDetailsNavigateFunction(records)}
              >
                {values ? values : "NA"}
              </CustomButton>
            ) : (
              <Tag color="blue" className="text-[14px]">
                {values}
              </Tag>
            )}
          </>
        );
      },
    },
    {
      title: "Product Name",
      dataIndex: "product",
      key: "productName",
      align: "center",
      filterDropdown: (props) => (
        <FilterDropdown
          {...props}
          placeholder="Select product"
          options={productsList}
          type="select"
        />
      ),
    },
    {
      title: "Order Date",
      dataIndex: "ordDate",
      key: "ordDate",
      align: "center",
      render: (text) => (text ? formatDateMonth(text) : "NA"),
      defaultSortOrder:
        sortRequest?.key === "ordDate"
          ? sortRequest?.direction
            ? "ascend"
            : "descend"
          : null,
      filterDropdown: (props) => <FilterDropdown {...props} type="dateRange" />,
      sorter: (a, b) => a.ordDate - b.ordDate,
    },
    {
      title: "Required Date",
      dataIndex: "required",
      key: "required",
      align: "center",
      defaultSortOrder:
        !sortRequest || !sortRequest.key
          ? "descend"
          : sortRequest.key === "required"
          ? sortRequest?.direction
            ? "ascend"
            : "descend"
          : null,
      render: (text) => (text ? formatDateMonth(text) : "N/A"),
      sorter: (a, b) => a.required - b.required,
    },
    {
      title: "Cancel Reason",
      dataIndex: "cancelReason",
      key: "cancelReason",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "statusName",
      key: "statusName",
      align: "center",
      defaultSortOrder:
        sortRequest?.key === "statusName"
          ? sortRequest?.direction
            ? "ascend"
            : "descend"
          : null,
      hidden: !showButtons?.statusButton,
      sorter: (a, b) => a.statusID - b.statusID,
      render: (_, records) => {
        return (
          <>
            <CustomButton
              icon={<EditOutlined />}
              disabled={activeTab === "Cancelled"}
              onClick={() => {
                setIsDrawerOpen(true);
                setIdentifier(records?.primaryIdentifier);
                setOrderNumber(records?.quoteRefNum);
                setOrderStatusDrawerType("write");
              }}
            >
              {records?.statusName.length > 24
                ? `${records?.statusName.slice(0, 24)}...`
                : records?.statusName}
            </CustomButton>
          </>
        );
      },
    },
    {
      title: "View",
      dataIndex: "view",
      key: "view",
      align: "center",
      hidden: !showButtons?.viewAttachmentButton && !showButtons?.notesButton,
      render: (_, record) => (
        <div className="space-x-2">
          {showButtons?.viewAttachmentButton && (
            <ToolTipButton
              tooltipHeader="Attachments"
              tooltipPlacement="left"
              icon={<FileTextOutlined />}
              onClick={() =>
                setManageDrawer({
                  open: "attachments",
                  data: record,
                })
              }
            />
          )}

          {showButtons?.notesButton && (
            <ToolTipButton
              tooltipHeader="Notes"
              tooltipPlacement="top"
              icon={<FormOutlined />}
              onClick={() =>
                setManageDrawer({
                  open: "notes",
                  data: { ...record, activeTab: activeTab },
                })
              }
            />
          )}

          {showButtons?.viewCheckListButton && (
            <ToolTipButton
              tooltipHeader="Checklist"
              tooltipPlacement="right"
              icon={<OrderedListOutlined />}
              onClick={() =>
                setManageDrawer({
                  open: "checklist",
                  data: record,
                })
              }
            />
          )}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      hidden:
        !showButtons?.uploadAttachmentButton && !showButtons?.cancelOrderButton,
      render: (_, record, index) => {
        return (
          <div className="flex flex-wrap justify-center gap-4">
            {showButtons?.uploadAttachmentButton && (
              <CustomButton
                icon={<CloudUploadOutlined />}
                onClick={() => {
                  setOrderUploadAttachmentDrawer(true);
                  setCurrentOrderStatus(record);
                }}
              >
                Upload
              </CustomButton>
            )}

            {showButtons?.cancelOrderButton && activeTab !== "Cancelled" ? (
              record.isCancelDisabled ? (
                <ToolTipButton
                  type="orderCancel"
                  disabled={true}
                  icon={<CloseCircleOutlined />}
                  tooltipHeader="Order cannot be canceled once in production."
                >
                  Cancel Order
                </ToolTipButton>
              ) : (
                <CustomButton
                  type="orderCancel"
                  onClick={() => {
                    setIsCancelOrderDrawer(true);
                    setCurrentOrderStatus(record);
                  }}
                  icon={<CloseCircleOutlined />}
                  loading={cancelLoadingStates[index]}
                >
                  {record.statusID === 100 ? "Cancel Quote" : "Cancel Order"}
                </CustomButton>
              )
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
  ].filter((col) => !col.hidden);

  if (tableConfigData?.name !== "Cancelled") {
    columns = columns.filter((col) => col.key !== "cancelReason");
  }

  let tableStartingIndex =
    (list.response.currentPage - 1) * list.response.pageSize + 1;

  if (
    list.response.totalRecords <
    list.response.pageSize * list.response.currentPage
  ) {
    tableStartingIndex =
      (list.response.currentPage - 1) * list.response.pageSize + 1;
  }

  return (
    <>
      {/*  {isAdmin && (
        <div className="absolute right-0 mb-2 -top-12">
          <ToolTipButton
            tooltipHeader={`Configure ${tableConfigData?.name}`}
            tooltipPlacement="left"
            onClick={() => {
              setChangeStatus("configure");
            }}
            type="primary"
            className="mr-3"
            icon={<SettingOutlined />}
          >
            Configure
          </ToolTipButton>
        </div>
      )} */}

      <TotalListEntries
        checkNullCondition={list.response.records.length > 0}
        startingIndex={tableStartingIndex}
        totalEntries={list.response.totalRecords}
        currentPage={list.response.currentPage}
        pageSize={list.response.pageSize}
      />

      <Table
        pagination={{
          pageSize: list.request.pageRequest.pageSize,
          current: list.request.pageRequest.currentPage,
          total: list.response.totalRecords,
          showSizeChanger: true,
        }}
        columns={columns}
        loading={isTableDataLoading}
        dataSource={list.response.records}
        scroll={{
          x: currentScreenSize < 1300 ? 1800 : true,
        }}
        tableLayout="fixed"
        size="small"
        bordered={"1px solid black"}
        onChange={handleFiltersChange}
        rowClassName={(record, index) =>
          index % 2 !== 0 ? getDarkModeColor(isDarkMode, "bg-gray-100") : ""
        }
      />
      <ManageConfigure
        tableConfigData={tableConfigData ? tableConfigData : ""}
        changeStatus={changeStatus}
        onClose={() => {
          setChangeStatus(null);
        }}
        isLoadOnSave={isLoadOnSave}
        setIsLoadOnSave={setIsLoadOnSave}
      />

      <OrderStatusDrawer
        updateStatus={updateStatus}
        setUpdateStatus={setUpdateStatus}
        orderNumber={orderNumber}
        identifier={identifier}
        isDrawerOpen={isDrawerOpen}
        onCancel={() => {
          setIsDrawerOpen(false);
          setOrderStatusDrawerType(null);
        }}
        orderStatusDrawerType={orderStatusDrawerType}
        refresh={refresh}
      />

      <OrderAttachmentDrawer
        activeTab={activeTab}
        isDrawerOpen={orderUploadAttachmentDrawer}
        onCancel={() => {
          setOrderUploadAttachmentDrawer(false);
          setCurrentOrderStatus([]);
        }}
        currentOrderStatus={currentOrderStatus}
      />

      <OrderCancelDrawer
        isDrawerOpen={isCancelOrderDrawer}
        onCancel={() => {
          setIsCancelOrderDrawer(false);
          setCurrentOrderStatus([]);
        }}
        currentOrderStatus={currentOrderStatus}
        setIsLoadOnSave={setIsLoadOnSave}
        isLoadOnSave={isLoadOnSave}
      />

      <AttachmentDrawer
        open={manageDrawer.open === "attachments"}
        onClose={() =>
          setManageDrawer({
            open: null,
            data: null,
          })
        }
        data={{
          ...manageDrawer.data,
          fromOrderManagement: true,
          activeTab: activeTab,
        }}
      />

      <OrderNotesDrawer
        open={manageDrawer.open === "notes"}
        onClose={() =>
          setManageDrawer({
            open: null,
            data: null,
          })
        }
        initalData={manageDrawer.data}
      />

      <ViewOrderChecklistsDrawer
        open={manageDrawer.open === "checklist"}
        onClose={() =>
          setManageDrawer({
            open: null,
            data: null,
          })
        }
        data={manageDrawer.data}
      />
    </>
  );
};

export default OrderConfigure;
