import {
  CloudUploadOutlined,
  DeleteOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Descriptions, Form, Tooltip, Upload } from "antd";
import React from "react";
import CustomButton from "../../../../Common/CustomButton";

const AttachmentCard = ({
  onPreview,
  setFileList,
  fileList,
  onFileChange,
  selectedAccount,
  previewOrder,
  editData,
}) => {
  return (
    <Descriptions bordered className="my-3">
      <Descriptions.Item
        label="Attachment"
        labelStyle={{
          paddingTop: "4px",
          paddingBottom: "4px",
          fontWeight: 700,
        }}
        contentStyle={{ paddingTop: "4px", paddingBottom: "4px" }}
      >
        <Form.Item
          name="attachment"
          rules={[
            {
              required: false,
              message: "Select attachment",
            },
          ]}
          className="w-full custom-upload"
        >
          <Upload
            accept=".xlsx, .csv, .pdf, .jpg, .jpeg, .png"
            itemRender={(_, file) => {
              return (
                <div className="flex items-center justify-between py-[4px] border border-gray-300 px-3 rounded-md">
                  <p>
                    {file?.name.length > 16
                      ? `${file?.name.slice(0, 16)}...`
                      : file?.name}
                  </p>

                  <div className="space-x-2">
                    <Tooltip title="Preview">
                      <EyeOutlined
                        className="text-base transition duration-200 cursor-pointer hover:text-blue-400"
                        onClick={() => onPreview(file)}
                      />
                    </Tooltip>

                    <Tooltip title="Remove file">
                      <DeleteOutlined
                        className="text-base transition duration-200 cursor-pointer hover:text-blue-400"
                        onClick={() => setFileList([])}
                      />
                    </Tooltip>
                  </div>
                </div>
              );
            }}
            fileList={fileList}
            previewFile={true}
            maxCount={1}
            onChange={onFileChange}
            beforeUpload={(file) => {
              setFileList([file]);
              return false;
            }}
            className="!w-full"
          >
            {!fileList.length > 0 && (
              <CustomButton
                size="middle"
                type="secondary"
                disabled={
                  (previewOrder === "preview" && !editData) || !selectedAccount
                }
                className="w-full"
                icon={<CloudUploadOutlined />}
              >
                Select File
              </CustomButton>
            )}
          </Upload>
        </Form.Item>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default AttachmentCard;
