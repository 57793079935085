import { EditOutlined, PlusOutlined, SettingOutlined } from "@ant-design/icons";
import { Input, Table, Tooltip, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Common/CustomButton";
import ToolTipButton from "../../Common/ToolTipButton";
import { AuthContext } from "../../Providers/AuthProvider";
import { useDarkMode } from "../../Providers/DarkModeContext";
import ProductService from "../../services/ProductBuilder/ProductService";
import { getDarkModeColor } from "../../utils/darkModeHelper";
import ManageProductGroup from "./ManageProductGroup";

const ProductGroupPage = () => {
  const { isDarkMode } = useDarkMode();
  const [isTableDataLoading, setIsTableDataLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const [changeStatus, setChangeStatus] = useState(null);
  let { userPrimaryRole } = useContext(AuthContext);
  let isAdmin = userPrimaryRole?.toLowerCase()?.includes("admin");
  const navigate = useNavigate();

  const [list, setList] = useState({
    request: {
      pageRequest: {
        currentPage: 1,
        pageSize: 10,
      },
      sortRequest: {
        key: "",
        direction: true,
      },
      filterRequest: {},
    },
    response: {
      records: [],
      totalRecords: 0,
    },
  });

  useEffect(() => {
    const { request } = list;
    getProductGroupData(request);
  }, []);

  const getProductGroupData = async (request) => {
    try {
      setIsTableDataLoading(true);
      const { data } = await ProductService.getProductGroupPageData(request);

      setList((prev) => {
        setIsTableDataLoading(false);
        return {
          ...prev,
          response: {
            records: data,
          },
        };
      });
    } catch (error) {
      error?.response?.data?.message
        ? message.error(error?.response?.data?.message)
        : message.error("Something went wrong");
    }
  };

  const filterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div className="p-4">
      <Input
        placeholder="Search"
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => confirm()}
        className="block mb-2"
      />
      <CustomButton
        type="primary"
        onClick={() => confirm()}
        size="small"
        className="mr-2"
        style={{ width: 90 }}
      >
        Search
      </CustomButton>
      <CustomButton
        onClick={() => {
          clearFilters && clearFilters();
          setSelectedKeys([]);
          confirm();
        }}
        size="small"
        style={{ width: 90 }}
      >
        Reset
      </CustomButton>
    </div>
  );

  const handleNavigateProductBuilderPage = (productDetails) => {
    navigate(`/products/configure-group-status`, {
      state: productDetails,
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      align: "center",
    },
    {
      title: "Total Products",
      dataIndex: "totalProductCount",
      key: "totalProductCount",
      width: 300,
      align: "center",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 400,
      align: "center",
      render: (_, record) => {
        return (
          <div className="flex items-center justify-center gap-2">
            <Tooltip
              trigger="hover"
              title="Configure master flow"
              placement="left"
            >
              <CustomButton
                id="configureProductGroup"
                type="primary"
                icon={<SettingOutlined />}
                onClick={() => handleNavigateProductBuilderPage(record)}
              >
                Configure
              </CustomButton>
            </Tooltip>

            <Tooltip
              trigger="hover"
              title="Edit group details"
              placement="right"
            >
              <CustomButton
                type="primary"
                icon={<EditOutlined />}
                onClick={() => {
                  setEditData(record);
                  setChangeStatus("edit");
                }}
              >
                Edit
              </CustomButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      {isAdmin && list.response.records.length === 0 && (
        <ToolTipButton
          id="addProductGroup"
          tooltipHeader="Add product group"
          tooltipPlacement="left"
          onClick={() => {
            setChangeStatus("add");
            setEditData(null);
          }}
          type="primary"
          className="absolute right-0 mb-3 -top-12"
          icon={<PlusOutlined />}
        >
          Add
        </ToolTipButton>
      )}

      <Table
        size="small"
        bordered={"1px solid black"}
        dataSource={list?.response?.records?.filter(
          (i) => i.productGroupID !== 0
        )}
        columns={columns}
        scroll={{ x: true }}
        tableLayout="fixed"
        pagination={{ showSizeChanger: true }}
        loading={isTableDataLoading}
        rowClassName={(record, index) =>
          index % 2 !== 0 ? getDarkModeColor(isDarkMode, "bg-gray-100") : ""
        }
      />

      <ManageProductGroup
        changeStatus={changeStatus}
        editData={editData}
        onClose={() => {
          setEditData(null);
          setChangeStatus(null);
        }}
        onSuccess={() => {
          const { request } = list;
          getProductGroupData(request);
        }}
      />
    </div>
  );
};

export default ProductGroupPage;
