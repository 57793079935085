import { AutoComplete, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import CustomerService from "../services/CustomerService/CustomerService";

const CountrySelect = ({ value, onChange, onSelect, currentStatus, form }) => {
  const [countryList, setCountryList] = useState([]);
  const [countrySelected, setCountrySelected] = useState("");

  const searchCountries = async (searchValue) => {
    if (searchValue !== undefined) {
      CustomerService.getCountryListData(searchValue)
        .then((res) => {
          const data = res?.data?.map((item) => ({
            value: item.name,
            label: item.name,
          }));

          setCountryList(data);
        })
        .catch((err) => {
          message.error(err.message ? err.message : "Something went wrong");
        });
    } else {
      setCountryList([]);
    }
  };

  useEffect(() => {
    if (currentStatus === "edit" && value) {
      setCountrySelected(value);
      onChange(value);
      handleCountrySelect(value);
    }

    if (!value && countrySelected.length === 0) {
      searchCountries("");
    }
  }, [value]);

  useEffect(() => {
    if (
      currentStatus !== "edit" &&
      countryList.length > 0 &&
      !countrySelected
    ) {
      const defaultCountry = countryList[0].value;
      setCountrySelected(defaultCountry);
      onChange(defaultCountry);
      handleCountrySelect(defaultCountry);
    }
  }, [countryList, currentStatus]);

  const handleCountrySelect = (value) => {
    setCountrySelected(value);
    CustomerService.getStateList(value)
      .then((response) => {
        onSelect(value, response.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : "Something went wrong"
        );
      });
  };

  return (
    <Select
      placeholder="Select Country"
      size="medium"
      value={value}
      onChange={onChange}
      showSearch={true}
      onSearch={searchCountries}
      onSelect={(value) => {
        handleCountrySelect(value);
        form.resetFields(["state"]);
      }}
      options={countryList}
      onBlur={() => searchCountries("")}
    />
  );
};

export default CountrySelect;
