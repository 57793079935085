import { Button } from "antd";
import React from "react";
import { useDarkMode } from "../Providers/DarkModeContext";
import { getDarkModeColor } from "../utils/darkModeHelper";

const CustomButton = ({ type, className, children, ...otherProps }) => {
  const { isDarkMode } = useDarkMode();

  const disabledButtonClass = isDarkMode
    ? "disabled:!bg-[#262626] disabled:border-white/10 disabled:text-white/30 disabled:cursor-not-allowed transition duration-150"
    : "disabled:!bg-[rgba(0,0,0,0.04)] disabled:border-gray-300 disabled:text-[rgba(0,0,0,0.25)] disabled:cursor-not-allowed transition duration-150";
  const getButtonStyles = () => {
    switch (type) {
      case "cancel":
        return "text-white bg-gray-400 hover:text-white hover:bg-opacity-70 disabled:cursor-not-allowed";
      case "secondary":
        return `hover:!bg-blue-500 hover:!text-white !z-50 text-blue-400 border-blue-400 ${disabledButtonClass}`;
      case "tertiary":
        return `hover:!bg-[var(--tertiary-color)] hover:!text-white text-[var(--tertiary-color)] border-[var(--tertiary-color)] ${disabledButtonClass}`;
      case "quaternary":
        return `hover:!bg-var(--quaternary-color) hover:!text-white text-[var(--quaternary-color)] border-[var(--quaternary-color)] ${disabledButtonClass}`;
      case "green":
        return `hover:!bg-green-400 text-white flex items-center !bg-green-500 border-green-500 ${disabledButtonClass}`;
      case "orderCancel":
        return getDarkModeColor(
          isDarkMode,
          `text-black-300 border border-gray-300 hover:text-red-400 hover:border-red-400 ${disabledButtonClass}`,
          `border border-gray-600 hover:text-red-400 hover:border-red-400 ${disabledButtonClass}`
        );
      default:
        return "";
    }
  };

  return (
    <Button
      className={`${className} ${getButtonStyles()}`}
      type={type || "default"}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
