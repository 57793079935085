import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Card,
  Checkbox,
  Drawer,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Tag,
} from "antd";
import React, { useEffect } from "react";
import { BsArrowUpRight } from "react-icons/bs";
import CustomButton from "../../../Common/CustomButton";
import { validateTextInput } from "../../../utils/validationsHelper";

const ManageOrderStatusDrawer = ({
  visible,
  onCancel,
  onAddNode,
  form,
  minLength,
  data,
  onEditNode,
  type,
  addPriorOrderStatus,
  alertVisible,
  isFlowFinalized,
  checklistOptions,
}) => {
  useEffect(() => {
    if (data?.data && type !== "prior") {
      form.setFieldsValue({
        ...data?.data,
      });
    }
  }, [data]);

  const validateStatusId = (_, value) => {
    if (value) {
      if (type === "add") {
        if (value % 100 === 0) {
          if (value >= parseInt(minLength) + 100) {
            return Promise.resolve();
          }
          return Promise.reject(
            `Minimum allowed value is ${parseInt(minLength) + 100}.`
          );
        } else {
          return Promise.reject("Value must be a multiple of 100.");
        }
      } else if (type === "prior") {
        if (value % 100 === 0) {
          if (value > data.prev.id && value < data.current.id) {
            return Promise.resolve();
          }
          return Promise.reject(
            `Value must be between ${data.prev.id} - ${data.current.id}.`
          );
        } else {
          return Promise.reject("Value must be a multiple of 100.");
        }
      } else {
        return Promise.resolve();
      }
    } else {
      return Promise.reject(`Status Id is required.`);
    }
  };

  const handleAddNode = async (values) => {
    try {
      const updatedValues = {
        ...values,
        isCheckListCompusory: !!values?.checkListID,
      };

      if (type === "add") {
        onAddNode(updatedValues);
      } else if (type === "prior") {
        addPriorOrderStatus({
          ...updatedValues,
          prev: data.prev,
          current: data.current,
        });
      } else {
        onEditNode(data.id, updatedValues);
      }
    } catch (error) {
      console.error("Validation failed:", error);
    } finally {
      form.resetFields();
      handleOnClose();
    }
  };

  const handleOnClose = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Drawer
      open={visible}
      onClose={handleOnClose}
      width={360}
      title={type !== "edit" ? "Add Order Status" : "Edit Order Status"}
      destroyOnClose
    >
      <Card title="Actions" type="inner">
        <Row className="justify-between gap-4 flex-nowrap">
          <Tag className="w-full font-semibold text-center uppercase">
            Start
          </Tag>

          <div className="flex items-center justify-center -ml-2">
            <BsArrowUpRight className="mt-px rotate-45" size={16} />
          </div>

          <Tag className="w-full font-semibold text-center uppercase">
            Complete
          </Tag>
        </Row>
      </Card>

      <Form
        form={form}
        layout="vertical"
        className="mt-3 mb-6"
        onFinish={handleAddNode}
      >
        {/* {type === "add" && alertVisible && (
          <Alert
            type="warning"
            showIcon
            message="Please check before submitting – editing is disabled for this product group while there are active orders placed within it"
            className="!mb-4"
            closable
            afterClose={() => setAlertVisible(false)}
          />
        )} */}

        <Form.Item
          name="orderStatusId"
          label="Status Id"
          className={`${isFlowFinalized ? "sr-only" : "flex-1"}`}
          rules={[
            {
              required: true,
              validator: validateStatusId,
            },
          ]}
        >
          <InputNumber
            size="medium"
            className={`min-w-full ${type === "edit" && "!text-black/80"}`}
            type="number"
            step={100}
            placeholder="Status Id"
            formatter={(value) => (value ? `${Math.floor(value)}` : "")}
          />
        </Form.Item>

        <Form.Item
          name="label"
          label="Status name"
          className="flex-1"
          rules={[
            {
              required: true,
              message: "Status Name is required",
            },
            { validator: validateTextInput("Status Name", 2, 100) },
          ]}
        >
          <Input size="medium" placeholder="Status Name" />
        </Form.Item>

        <Form.Item name="checkListID" label="Checklist" initialValue={null}>
          <Select
            options={checklistOptions || []}
            size="medium"
            placeholder="Select checklist"
            allowClear
          />
        </Form.Item>

        <Row className={`${isFlowFinalized ? "sr-only" : "mt-4"}`}>
          <Form.Item
            name="isProductionLine"
            valuePropName="checked"
            required={false}
            initialValue={false}
            className="select-none"
          >
            <Checkbox>Enable Capacity</Checkbox>
          </Form.Item>

          <Form.Item
            name="isAttachmentCompulsory"
            valuePropName="checked"
            required={false}
            initialValue={false}
            className="select-none"
          >
            <Checkbox>Is Attachment Compulsory?</Checkbox>
          </Form.Item>
        </Row>

        <Row className="justify-end w-full gap-3 mt-10">
          <CustomButton
            size="small"
            htmlType="submit"
            icon={<CheckOutlined />}
            type="primary"
          >
            Submit
          </CustomButton>

          <CustomButton
            type="cancel"
            onClick={handleOnClose}
            icon={<CloseOutlined />}
          >
            Cancel
          </CustomButton>
        </Row>
      </Form>
    </Drawer>
  );
};

export default ManageOrderStatusDrawer;
