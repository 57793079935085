import { Breadcrumb, Button, Card, Col, Row, Table, Tabs } from "antd";
import React, { useEffect } from "react";
import StockGroupsPage from "../stock/StockGroupsPage";
import ProductPage from "./ProductPage";
import ProductGroupPage from "./ProductGroupPage";
import { Link, useSearchParams } from "react-router-dom";
import CustomBreadcrumb from "../../Common/CustomBreadcrumb";

const items = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Product",
  },
];

const productsAllTabs = [
  {
    label: "Product Group",
    key: "product-group-page",
    children: <ProductGroupPage />,
  },
  {
    label: "Product",
    key: "product",
    children: <ProductPage />,
  },
];

const Products = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    activeTab: "product-group-page",
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.has("activeTab")) {
      searchParams.set("activeTab", "product-group-page");
      const newurl = `${window.location.protocol}//${window.location.host}${
        window.location.pathname
      }?${searchParams.toString()}`;
      window.history.replaceState({ path: newurl }, "", newurl);
    }
  }, []);

  return (
    <div className="p-2 mt-4">
      <CustomBreadcrumb items={items} />

      <Tabs
        activeKey={searchParams.get("activeTab")}
        titleFontSize={30}
        onChange={(activeTab) => {
          searchParams.set("activeTab", activeTab);
          setSearchParams(searchParams);
        }}
        type="card"
        items={productsAllTabs}
      />
    </div>
  );
};

export default Products;
