import { message } from "antd";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import AuthServices from "../services/Authentication/AuthServices";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState({
    isLoading: true,
    userDetails: null,
    userPrimaryRole: null,
    organizationLogoUrl: "",
    primaryColor: "",
    secondaryColor: "",
    tertiaryColor: "",
    quaternaryColor: "",
  });

  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    let token = localStorage.getItem("APS-TOKEN");

    if (!token) {
      setAuthData({
        isLoading: false,
        userDetails: null,
        userPrimaryRole: null,
        organizationLogoUrl: null,
        primaryColor: "",
        secondaryColor: "",
        tertiaryColor: "",
        quaternaryColor: "",
      });

      return;
    }

    try {
      const [
        userDetailsResponse,
        userPrimaryRoleResponse,
        organizationLogoResponse,
      ] = await Promise.all([
        AuthServices.getLoggedInUserData(),
        AuthServices.getUserPrimaryRole(),
        AuthServices.getOrganizationLogo(),
      ]);
      localStorage.setItem("userRole", userPrimaryRoleResponse.data);
      const currVersion = localStorage.getItem("version");

      if (currVersion && currVersion !== userDetailsResponse?.data?.version) {
        localStorage.removeItem("APS-TOKEN");
        localStorage.removeItem("prodGroup");
        localStorage.removeItem("useRole");
        window.location.replace("/login");
      } else {
        localStorage.setItem("version", userDetailsResponse?.data?.version);
      }

      setAuthData({
        isLoading: false,
        userDetails: userDetailsResponse.data,
        userPrimaryRole: userPrimaryRoleResponse.data,
        organizationLogoUrl: organizationLogoResponse.data,
        primaryColor: userDetailsResponse.data.colors
          ? userDetailsResponse.data.colors[0]
          : "#fff",
        secondaryColor: userDetailsResponse.data.colors
          ? userDetailsResponse.data.colors[1]
          : "#fff",
        tertiaryColor: userDetailsResponse.data.colors
          ? userDetailsResponse.data.colors[2]
          : "#fff",
        quaternaryColor: userDetailsResponse.data.colors
          ? userDetailsResponse.data.colors[3]
          : "#fff",
      });
    } catch (error) {
      error?.response?.data?.message
        ? message.error(error?.response?.data?.message)
        : message.error("Something went wrong");
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const refetchData = () => fetchData();

  const currentURL = window.location.href?.split("/")[2];

  const handleLogout = () => {
    const darkMode = localStorage.getItem("darkMode");
    const pageRequest = localStorage.getItem("pageRequest");
    const sortRequest = localStorage.getItem("sortRequest");

    localStorage.clear();
    sessionStorage.clear();
    message.success("Logout Successfully");
    navigate("/login");
    refetchData();

    localStorage.setItem("pageRequest", pageRequest);
    localStorage.setItem("sortRequest", sortRequest);
    localStorage.setItem("darkMode", darkMode);
  };

  return (
    <AuthContext.Provider
      value={{ ...authData, refetchData, currentURL, handleLogout }}
    >
      {!authData.isLoading && children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
