import { getErrorExcel } from "../services/CommonService";

export const exportErrorExcel = async (errors, fileName, moduleName) => {
  try {
      const response = await getErrorExcel(moduleName, errors);

    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};
