import React, { useState } from "react";
import { Drawer, Table, Tag, message } from "antd";
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ProductionServices from "../../services/Production/ProductionServices";
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { formatDateMonth } from "../../utils/dateHelper";
import CustomButton from "../../Common/CustomButton";

function ManageJobPriority({
  schedulerDataFetch,
  selectedProductGroup,

  openJobPriorityDrawer,
  onClose,
  selectedScheduledItem,
  setSelectedScheduledItem,
}) {
  const [loadingJob, setLoadingJob] = useState(false);
  const columns = [
    { title: "Priority", render: (t, r, index) => index + 1, align: "center" },
    {
      title: "Order Date",
      dataIndex: "ordDate",
      key: "ordDate",
      align: "center",
      render: (values, record) => {
        return values ? <p> {formatDateMonth(values)}</p> : "NA";
      },
    },
    {
      title: "Reference Number",
      dataIndex: "quoteRefNum",
      key: "quoteRefNum",
      align: "center",
      render: (values, records) => {
        return (
          <>
            <Tag color={"#0275d8"} type="primary">
              {values ? values : "NA"}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Job Number",
      dataIndex: "jobRefNum",
      key: "jobRefNum",
      align: "center",
      render: (values, records) => {
        return (
          <>
            {values ? (
              <Tag color={"#5bc0de"} type="primary">
                {values}
              </Tag>
            ) : null}
          </>
        );
      },
    },
    {
      title: "ECD",
      dataIndex: "expectedCompletionDate",
      key: "expectedCompletionDate",
      align: "center",
      render: (values, record) => {
        return values ? <p> {formatDateMonth(values)}</p> : "NA";
      },
    },

    {
      title: "Product Name",
      dataIndex: "product",
      key: "product",
      render: (values, records) => {
        return (
          <>
            <p>{values ? values : "NA"}</p>
          </>
        );
      },
    },
  ];
  const Row = (props) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });
    const style = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && {
          ...transform,
          scaleY: 1,
        }
      ),
      transition,
      cursor: "move",
      ...(isDragging
        ? {
            position: "relative",
            zIndex: 9999,
          }
        : {}),
    };
    return (
      <tr
        {...props}
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
      />
    );
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    })
  );
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setSelectedScheduledItem((prev) => {
        const activeIndex = prev.findIndex((i) => i.orderJobID === active.id);
        const overIndex = prev.findIndex((i) => i.orderJobID === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  const updateJobPriority = () => {
    let scheduleOrderList = [];
    (selectedScheduledItem || []).forEach((job, index) => {
      scheduleOrderList.push({
        productionTowerID: job.productionTowerID,
        sequence: index + 1,
      });
    });
    ProductionServices.updateScheduleOrderJobPriority(scheduleOrderList)
      .then((res) => {
        message.success("Job's priority updated");
        schedulerDataFetch();
        onClose();
        setLoadingJob(false);
      })
      .catch((error) => {
        error?.response?.data?.message
          ? message.error(error?.response?.data?.message)
          : message.error("Something went Wrong");
        setLoadingJob(false);
      });
  };
  return (
    <Drawer
      title="Change Job's Priority"
      placement="right"
      destroyOnClose={true}
      onClose={onClose}
      open={openJobPriorityDrawer}
      width={750}
    >
      <DndContext
        sensors={sensors}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={onDragEnd}
      >
        <SortableContext
          // rowKey array
          items={selectedScheduledItem.map((i) => i.orderJobID)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: {
                row: Row,
              },
            }}
            rowKey="orderJobID"
            columns={columns}
            dataSource={selectedScheduledItem}
          />
        </SortableContext>
      </DndContext>

      <div className="flex justify-center">
        <CustomButton
          className="m-1"
          type="primary"
          size="small"
          onClick={() => {
            setLoadingJob(true);
            updateJobPriority();
          }}
          icon={loadingJob ? <LoadingOutlined /> : <CheckOutlined />}
        >
          Save
        </CustomButton>
        <CustomButton
          className="m-1"
          type="cancel"
          size="small"
          icon={<CloseOutlined />}
          ghost
          onClick={onClose}
        >
          Cancel
        </CustomButton>
      </div>
    </Drawer>
  );
}

export default ManageJobPriority;
