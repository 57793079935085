import { Breadcrumb, Card, Tabs } from "antd";
import React, { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Customer from "../Customer/Customer";
import User from "./User";
import CustomBreadcrumb from "../../Common/CustomBreadcrumb";

const UserDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    activeTab: "Staff",
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.has("activeTab")) {
      searchParams.set("activeTab", "Staff");
      const newurl = `${window.location.protocol}//${window.location.host}${
        window.location.pathname
      }?${searchParams.toString()}`;
      window.history.replaceState({ path: newurl }, "", newurl);
    }
  }, []);

  const items = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "User",
    },
  ];
  const productsAllTabs = [
    {
      label: "Staff",
      key: "Staff",
    },
    {
      label: "Customer",
      key: "Customer",
    },
  ];

  return (
    <div className="p-2 mt-4 ">
      <CustomBreadcrumb items={items} />

      <Tabs
        className="!mt-4"
        titleFontSize={30}
        onChange={(activeTab) => {
          searchParams.set("activeTab", activeTab);
          setSearchParams(searchParams);
        }}
        activeKey={searchParams.get("activeTab")}
        type="card"
        items={productsAllTabs}
      />
      <Card>
        {searchParams.get("activeTab") === "Staff" ? <User /> : null}
        {searchParams.get("activeTab") === "Customer" ? <Customer /> : null}
      </Card>
    </div>
  );
};

export default UserDetails;
