import { Form, Select } from "antd";
import { formatDateMonth } from "../../../../utils/dateHelper";
import { useAuth } from "../../../../Providers/AuthProvider";

const ProductSelectFormItem = ({
  previewOrder,
  editData,
  selectedAccount,
  renderConfigOptions,
  fetchForecastData,
  form,
  productList,
  isLoading,
  forecastedDate,
  showLabel,
  setAccessoriesPrice,
}) => {
  const { currentURL } = useAuth();

  return (
    <>
      <Form.Item
        label={showLabel && "Product"}
        name="productID"
        rules={[
          {
            required: true,
            message: "Select product",
          },
        ]}
      >
        <Select
          loading={isLoading}
          placeholder="Select product"
          size="medium"
          disabled={
            (previewOrder === "preview" && !editData) || !selectedAccount
          }
          onChange={(value) => {
            renderConfigOptions(value, productList);

            fetchForecastData(value, "1");
            form.setFieldsValue({
              price: "",
              quantity: "1",
            });
            form.setFieldValue("accessoriesInfo", undefined);
            setAccessoriesPrice({});
          }}
          options={productList}
        />
      </Form.Item>

      {forecastedDate && (
        <p className="-mt-1 mb-1 text-[12px] font-medium italic">{`( Expected completion date: ${formatDateMonth(
          forecastedDate
        )} )`}</p>
      )}
    </>
  );
};

export default ProductSelectFormItem;
