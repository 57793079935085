import { Descriptions, Form, Radio } from "antd";
import React from "react";

const AjFittings = ({ previewOrder, editData }) => {
  return (
    <Descriptions bordered className="mt-2 mb-1">
      <Descriptions.Item
        label="Fitting"
        labelStyle={{
          paddingTop: "4px",
          paddingBottom: "4px",
          fontWeight: 700,
        }}
        contentStyle={{ paddingTop: "4px", paddingBottom: "4px" }}
      >
        <div className="flex flex-col items-start -mb-2 divide-y-2 lg:divide-y-0 lg:divide-x-2 lg:gap-3 lg:items-center lg:flex-row">
          <Form.Item name="fittingOption1">
            <Radio.Group
              disabled={previewOrder === "preview" && !editData}
              className="flex items-center gap-3 lg:gap-8"
            >
              <Radio value="Freight">Freight</Radio>
              <Radio value="Collect">Collect</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item name="fittingOption2">
            <Radio.Group
              disabled={previewOrder === "preview" && !editData}
              className="flex items-center gap-3 lg:pl-6 lg:gap-8"
            >
              <Radio value="Workshop">Workshop</Radio>
              <Radio value="CustomerSite">Customer Site</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default AjFittings;
