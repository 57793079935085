import {
  CheckCircleOutlined,
  CloseOutlined,
  PlayCircleOutlined,
  StepForwardOutlined,
} from "@ant-design/icons";
import { Card, Drawer, Popconfirm, Table, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import CustomButton from "../../Common/CustomButton";
import OrderManagementService from "../../services/OrderManagement/OrderManagementService";
import OrderAttachmentDrawer from "./OrderAttachmentDrawer";
import OrderChecklistDrawer from "./OrderChecklistDrawer";
import OrderStatusComponent from "./OrderStatusComponent";

const OrderStatusDrawer = ({
  onCancel,
  isDrawerOpen,
  identifier,
  orderNumber,
  updateStatus,
  setUpdateStatus,
  orderStatusDrawerType,
  refresh,
}) => {
  const [loadingStates, setLoadingStates] = useState([]);
  const [orderDetailsLoading, setOrderDetailsLoading] = useState(false);
  const [orderRefNumber, setOrderRefNumber] = useState("");
  const [loadingSkipStates, setLoadingSkipStates] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [orderStatusHistory, setOrderStatusHistory] = useState([]);
  const [isCancelledOrder, setIsCancelledOrder] = useState(null);
  const [jobDetailsData, setJobDetailsData] = useState(null);
  const [isAttachmentDrawerOpen, setIsAttachmentDrawerOpen] = useState({
    open: false,
    data: null,
  });
  const [isChecklistDrawerOpen, setIsChecklistDrawerOpen] = useState({
    open: false,
    data: null,
  });

  useEffect(() => {
    const fetchOrderDetails = async () => {
      if (identifier && isDrawerOpen) {
        setOrderDetailsLoading(true);

        try {
          const [orderJobDetailsRes, orderStatusHistoryRes] = await Promise.all(
            [
              OrderManagementService.getOrderJobDetails(identifier),
              OrderManagementService.getOrderStatusHistory(identifier),
            ]
          );

          setIsCancelledOrder(orderJobDetailsRes.data.isCancelled);
          setOrderRefNumber(orderJobDetailsRes.data.orderRefNumber);
          setOrderDetails(orderJobDetailsRes?.data?.jobDetails);
          setJobDetailsData(orderJobDetailsRes?.data);
          setOrderStatusHistory(orderStatusHistoryRes?.data);
          setOrderDetailsLoading(false);
        } catch (error) {
          const errorMessage =
            error?.response?.data?.message || "Something went wrong";
          message.error(errorMessage);
        }
      } else {
        setOrderDetails([]);
        setOrderStatusHistory([]);
      }
    };

    fetchOrderDetails();
  }, [identifier, updateStatus, isDrawerOpen]);

  const handleNextOrderFunction = (index, statusDetails, Type) => {
    const updatedLoadingStates = [...loadingStates];
    updatedLoadingStates[index] = true;
    setLoadingStates(updatedLoadingStates);

    OrderManagementService.manageOrderStatusData(statusDetails.ordJobID, Type)
      .then((res) => {
        setUpdateStatus(!updateStatus);
        message.success("Status Updated Successfully");
        refresh();
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 404) {
          // TODO: Need to fix these via backend
          if (
            error?.response?.data?.message.split(":")?.[0] ===
            "Response Not Found"
          ) {
            message.info("Enter the details to continue.");
            setIsChecklistDrawerOpen({
              open: true,
              data: {
                ...jobDetailsData,
                checklistID:
                  Number(error?.response?.data?.message.split(":")?.[1]) || 0,
              },
            });
            return;
          }

          if (error?.response?.data?.message === "Attachment Not Found") {
            message.info("Attachment is required to move this status.");
            setIsAttachmentDrawerOpen({
              open: true,
              data: statusDetails?.orderStatusName,
            });
            return;
          }
        }

        error?.response?.data?.message
          ? message.error(error?.response?.data?.message)
          : message.error("Something went wrong");
      })
      .finally(() => {
        const updatedLoadingStates = [...loadingStates];
        updatedLoadingStates[index] = false;
        setLoadingStates(updatedLoadingStates);
      });
  };

  const columns = [
    {
      title: "Status",
      dataIndex: "orderStatusName",
      key: "orderStatusName",
      align: "left",
      render: (_, record) => {
        return (
          <Tag className="text-white bg-sky-800">{record.orderStatusName}</Tag>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "30%",
      align: "center",
      render: (_, record, index) => {
        return (
          <div className="flex items-center justify-center gap-3">
            {record.hasDuration && record.showStart && (
              <CustomButton
                icon={<PlayCircleOutlined />}
                loading={loadingStates[index]}
                onClick={() => handleNextOrderFunction(index, record, "Start")}
                disabled={jobDetailsData?.disableAction}
              >
                Start
              </CustomButton>
            )}
            {record.hasDuration && (
              <CustomButton
                loading={loadingStates[index]}
                icon={<CheckCircleOutlined />}
                onClick={() => handleNextOrderFunction(index, record, "Done")}
                disabled={!record.showDone || jobDetailsData?.disableAction}
              >
                Complete
              </CustomButton>
            )}

            {!record.hasDuration && (
              <CustomButton
                loading={loadingStates[index]}
                icon={<CheckCircleOutlined />}
                onClick={() => handleNextOrderFunction(index, record, "Done")}
                disabled={jobDetailsData?.disableAction}
              >
                Next
              </CustomButton>
            )}

            <Popconfirm
              title="Are you sure you want to skip?"
              onConfirm={() => handleNextOrderFunction(index, record, "Skip")}
              okText="Yes"
              cancelText="No"
            >
              <CustomButton
                disabled={jobDetailsData?.disableAction}
                loading={loadingStates[index]}
                icon={<StepForwardOutlined />}
              >
                Skip
              </CustomButton>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Drawer
        closeIcon={false}
        title={
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-4">
              <CloseOutlined onClick={onCancel} />
              <div className="flex items-center gap-2 divide-x-2">
                <p className="flex items-center gap-2">
                  Reference Number :{" "}
                  <Tag color={"#0275d8"}>{orderRefNumber}</Tag>
                </p>

                {jobDetailsData?.jobNumber && (
                  <p className="flex items-center gap-2 pl-3">
                    Job Number :{" "}
                    <Tag color={"#0275d8"}>{jobDetailsData?.jobNumber}</Tag>
                  </p>
                )}
              </div>
            </div>
            <Card
              bordered={orderStatusDrawerType === "read" ? false : true}
              style={{ marginTop: "1rem" }}
              title={
                orderStatusDrawerType !== "write" && (
                  <div className="flex justify-between">
                    <div className="flex gap-2">
                      Current Status :{" "}
                      {isCancelledOrder ? (
                        <Tag color="volcano">Cancelled</Tag>
                      ) : orderDetails.length > 1 ? (
                        <Tag className="flex flex-wrap flex-1 text-white rounded-md bg-sky-800">
                          {orderDetails?.map((item) => (
                            <p key={item.id}>{`${item.orderStatusName} ,`} </p>
                          ))}
                        </Tag>
                      ) : (
                        <Tag className="flex flex-wrap flex-1 text-white rounded-md bg-sky-800">
                          {orderDetails?.map((item) => (
                            <p key={item.id}>{`${item.orderStatusName}`} </p>
                          ))}
                        </Tag>
                      )}
                    </div>
                  </div>
                )
              }
            >
              {orderStatusDrawerType !== "read" && (
                <Table
                  dataSource={orderDetails}
                  columns={columns}
                  pagination={false}
                  loading={orderDetailsLoading}
                  scroll={{ x: true }}
                  tableLayout="fixed"
                />
              )}
            </Card>
          </div>
        }
        width={950}
        onClose={onCancel}
        open={isDrawerOpen}
        bodyStyle={{
          padding: "4px 16px",
        }}
        destroyOnClose={true}
      >
        <OrderStatusComponent
          data={orderStatusHistory}
          loading={orderDetailsLoading}
        />

        <OrderAttachmentDrawer
          isDrawerOpen={isAttachmentDrawerOpen.open}
          onCancel={() =>
            setIsAttachmentDrawerOpen({ open: false, data: null })
          }
          currentOrderStatus={{
            primaryIdentifier: identifier,
            ordNum: jobDetailsData?.ordNum,
          }}
          activeTab={isAttachmentDrawerOpen.data}
        />

        <OrderChecklistDrawer
          isDrawerOpen={isChecklistDrawerOpen.open}
          onClose={() => setIsChecklistDrawerOpen({ open: false, data: null })}
          orderDetails={isChecklistDrawerOpen.data}
        />
      </Drawer>
    </>
  );
};

export default OrderStatusDrawer;
