import {
  CloudDownloadOutlined,
  DeleteOutlined,
  EyeFilled,
  PlusOutlined,
} from "@ant-design/icons";
import {
  DatePicker,
  Input,
  Popconfirm,
  Row,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import CustomButton from "../../Common/CustomButton";
import { useDarkMode } from "../../Providers/DarkModeContext";
import { useScreenSize } from "../../Providers/ScreenSizeProvider";
import OrderManagementService from "../../services/OrderManagement/OrderManagementService";
import { getDarkModeColor } from "../../utils/darkModeHelper";
import { formatDateMonth } from "../../utils/dateHelper";
import OrderAttachmentDrawer from "./OrderAttachmentDrawer";
import OrderAttachmentPreviewModal from "./OrderAttachmentPreviewModal";

const { RangePicker } = DatePicker;

const SingleOrderAttachment = ({ orderDetailsData }) => {
  const { isDarkMode } = useDarkMode();
  const currentScreenSize = useScreenSize();
  const [previewModal, setPreviewModal] = useState(false);
  const [filePreviewUrl, setFilePreviewUrl] = useState(null);
  const [dateRange, setDateRange] = useState([]);
  const [isListFetching, setIsListFetching] = useState(false);
  const [loadingStates, setLoadingStates] = useState([]);
  const [isAttachmentDrawerOpen, setIsAttachmentDrawerOpen] = useState(false);

  const primaryIdentifier = orderDetailsData?.primaryIdentifierOrder;

  const [list, setList] = useState({
    request: {
      pageRequest: {
        currentPage: 1,
        pageSize: 10,
      },
      sortRequest: {
        key: "",
        direction: true,
      },
      filterRequest: {
        startDate: "",
        endDate: "",
        fileName: "",
        category: "",
      },
    },
    response: {
      records: [],
      totalRecords: 0,
    },
  });

  const handleDownloadStatement = (category, fileName, description, index) => {
    setLoadingStates((prev) => {
      prev[index] = true;
      return [...prev];
    });

    OrderManagementService.downloadDetailsData(category, fileName)
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/octet-stream",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = description;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        setLoadingStates((prev) => {
          prev[index] = false;
          return [...prev];
        });
        message.success("File downloaded succesfully");
      })
      .catch((error) => {
        setLoadingStates((prev) => {
          prev[index] = false;
          return [...prev];
        });
        console.log(error);
        message.error(error?.response?.data?.message || "Something went wrong");
      });
  };

  const handleDataFetch = async () => {
    setIsListFetching(true);
    const { request } = list;
    try {
      const { data } = await OrderManagementService.getFileDetailsData(
        primaryIdentifier,
        request
      );

      setList((prev) => {
        return {
          ...prev,
          response: data,
        };
      });
      setIsListFetching(false);
    } catch (error) {
      setIsListFetching(false);
      console.log(error);
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const handleDeleteAttachment = async (category, fileName, index) => {
    try {
      const res = await OrderManagementService.deleteAttachment(
        fileName,
        category
      );

      if (res.data) {
        handleDataFetch();
      } else {
        message.error("Failed to delete file!");
      }
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    if (orderDetailsData) {
      handleDataFetch(orderDetailsData);
    }
  }, [orderDetailsData]);

  const handleAttachmentPreview = async (category, fileName, index) => {
    setLoadingStates((prev) => {
      prev[index] = true;
      return [...prev];
    });

    OrderManagementService.previewUploadFile(category, fileName)
      .then((res) => {
        setFilePreviewUrl(res.data);
        setPreviewModal(true);
        setLoadingStates((prev) => {
          prev[index] = false;
          return [...prev];
        });
      })
      .catch((error) => {
        setLoadingStates((prev) => {
          prev[index] = false;
          return [...prev];
        });
        console.log(error);
        message.error(error?.response?.data?.message || "Something went wrong");
      });
  };

  const handleFiltersChange = (pagination, filters, sorter, extra) => {
    const { request } = list;

    const { filterRequest, sortRequest } = request;

    const requestFilters = Object.keys(filterRequest).reduce((acc, key) => {
      if (filters[key]) {
        acc[key] = filters[key][0];
      }
      return acc;
    }, filterRequest);

    if (dateRange.length === 2) {
      requestFilters.startDate = dateRange[0].format("DD-MM-YYYY");
      requestFilters.endDate = dateRange[1].format("DD-MM-YYYY");
    }

    handleDataFetch(primaryIdentifier, {
      ...request,
      filterRequest: requestFilters,
      sortRequest: {
        key: sorter.field,
        direction: sorter.order === "ascend",
      },
      pageRequest: {
        currentPage: pagination.current,
        pageSize: pagination.pageSize,
      },
    });
  };

  const handleResetFilters = (key, clearFilters) => {
    if (key === "createdOn") {
      setDateRange([]);
    }
    clearFilters();
    const { request } = list;

    const newFilterRequest = {
      ...request.filterRequest,
      [key]: "",
    };

    const tempRequest = {
      ...request.filterRequest,
      startDate: "",
      endDate: "",
    };

    setList((prev) => {
      return {
        ...prev,
        request: {
          ...prev.request,
          filterRequest: key === "createdOn" ? tempRequest : newFilterRequest,
        },
      };
    });

    handleDataFetch(primaryIdentifier, {
      ...request,
      filterRequest: key === "createdOn" ? tempRequest : newFilterRequest,
    });
  };

  const filterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    filterKey,
  }) => {
    return (
      <div className="flex flex-col w-full p-4">
        {filterKey === "createdOn" ? (
          <RangePicker
            value={selectedKeys}
            onChange={(dates) => {
              setDateRange(dates);
              setSelectedKeys(dates);
            }}
            onPressEnter={() => confirm()}
            className="mb-2 w-[14rem]"
          />
        ) : (
          <Input
            placeholder="search"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            className="block mb-2"
          />
        )}
        <Row className="justify-end gap-2 mt-2">
          <CustomButton
            type="primary"
            onClick={() => {
              confirm();
            }}
            size="small"
            className="flex-1"
          >
            Search
          </CustomButton>
          <CustomButton
            onClick={() => {
              setSelectedKeys([]);
              confirm();
              clearFilters && handleResetFilters(filterKey, clearFilters);
            }}
            size="small"
            className="flex-1"
          >
            Reset
          </CustomButton>
        </Row>
      </div>
    );
  };

  const columns = [
    {
      title: "Type",
      dataIndex: "category",
      key: "category",
      align: "center",
      render: (_, record) => {
        return <Tag>{record.category}</Tag>;
      },
      sorter: (a, b) => a.category.length - b.category.length,
      filterDropdown: (props) =>
        filterDropdown({ ...props, filterKey: "category" }),
    },
    {
      title: "File Name",
      dataIndex: "fileName",
      key: "fileName",
      align: "center",
      render: (_, record) => {
        return (
          <p>
            {orderDetailsData?.ordNum}_{record?.description}
          </p>
        );
      },
    },
    {
      title: "Upload Date",
      dataIndex: "createdOn",
      key: "createdOn",
      align: "center",
      render: (_, record) => {
        return record.createdOn ? (
          <p> {formatDateMonth(record.createdOn)}</p>
        ) : (
          "NA"
        );
      },
      filterDropdown: (props) =>
        filterDropdown({ ...props, filterKey: "createdOn" }),
      sorter: (a, b) => a.createdOn - b.createdOn,
    },
    {
      title: "Upload Time",
      dataIndex: "createdOn",
      key: "createdOn",
      align: "center",
      render: (_, record) => {
        const date = new Date(record.createdOn);
        const time = date.toLocaleTimeString([], {
          hour12: true,
          hour: "2-digit",
          minute: "2-digit",
        });
        return <p> {time}</p>;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (_, record, index) => {
        return (
          <div className="flex flex-wrap justify-center gap-2">
            <Tooltip title="Preview">
              <CustomButton
                loading={loadingStates[index]}
                onClick={() => {
                  handleAttachmentPreview(
                    record.category,
                    record.fileName,
                    index
                  );
                }}
                icon={<EyeFilled />}
                type="secondary"
              />
            </Tooltip>

            <Tooltip title="Download">
              <CustomButton
                loading={loadingStates[index]}
                onClick={() =>
                  handleDownloadStatement(
                    record.category,
                    record.fileName,
                    record.description,
                    index
                  )
                }
                icon={<CloudDownloadOutlined />}
                type="secondary"
              />
            </Tooltip>

            <Tooltip title="Delete" placement="bottom">
              <Popconfirm
                title="Are you sure you want to delete this attachment?"
                onConfirm={() =>
                  handleDeleteAttachment(
                    record.category,
                    record.fileName,
                    index
                  )
                }
              >
                <CustomButton
                  loading={loadingStates[index]}
                  icon={<DeleteOutlined />}
                  type="default"
                  danger
                />
              </Popconfirm>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Tooltip title="Add new attachment" placement="left">
        <CustomButton
          className="absolute right-0 z-10 -top-12"
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => setIsAttachmentDrawerOpen(true)}
        >
          Attachment
        </CustomButton>
      </Tooltip>

      <div className="">
        <Table
          columns={columns}
          onChange={handleFiltersChange}
          dataSource={list?.response?.records}
          scroll={{
            x: currentScreenSize < 1024 ? 800 : 1000,
          }}
          size="middle"
          loading={isListFetching}
          rowClassName={(record, index) =>
            index % 2 !== 0 ? getDarkModeColor(isDarkMode, "bg-gray-100") : ""
          }
        />
      </div>

      {previewModal && (
        <OrderAttachmentPreviewModal
          isModalOpen={previewModal}
          onCancel={() => {
            setPreviewModal(false);
            setFilePreviewUrl(null);
          }}
          previewData={filePreviewUrl}
          setPreviewModal={setPreviewModal}
        />
      )}

      <OrderAttachmentDrawer
        isDrawerOpen={isAttachmentDrawerOpen}
        onCancel={() => {
          setIsAttachmentDrawerOpen(false);
        }}
        currentOrderStatus={{ primaryIdentifier }}
        onSuccess={() => {
          handleDataFetch();
        }}
      />
    </>
  );
};

export default SingleOrderAttachment;
