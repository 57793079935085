import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  EnvironmentOutlined,
  EyeOutlined,
  HistoryOutlined,
  LeftOutlined,
  MailOutlined,
  PhoneOutlined,
  PrinterOutlined,
  RightOutlined,
  TagOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Card,
  Descriptions,
  Divider,
  Input,
  Modal,
  Space,
  Tabs,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import CustomBreadcrumb from "../../Common/CustomBreadcrumb";
import CustomButton from "../../Common/CustomButton";
import Markdown from "../../Common/Markdown";
import { useDarkMode } from "../../Providers/DarkModeContext";
import OrderManagementService from "../../services/OrderManagement/OrderManagementService";
import OrderAttachmentDrawer from "./OrderAttachmentDrawer";
import OrderChecklistDrawer from "./OrderChecklistDrawer";
import OrderStatusDrawer from "./OrderStatusDrawer";
import PrintReceipt from "./PrintReceipt";
import SingleOrderAttachment from "./SingleOrderAttachment";
import SingleOrderDetails from "./SingleOrderDetails";

const { Search } = Input;
const { Title, Text } = Typography;

const items = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Order Management",
    path: "/order/management",
  },
  {
    title: "Order Enquiry",
  },
];

const tabsItem = [
  {
    key: "Details",
    label: "Details",
  },
  {
    key: "Attachment",
    label: "Attachment",
  },
];

const OrderDetails = () => {
  const { isDarkMode } = useDarkMode();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams({
    activeTab: "Details",
    navigationType: "last",
  });

  const [orderDetailsData, setOrderDetailsData] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [orderStatusDrawerType, setOrderStatusDrawerType] = useState(null);
  const [isOrderConverting, setIsOrderConverting] = useState(false);
  const [viewOrderModal, setViewOrderModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchVal, setSearchVal] = useState("");

  const [isAttachmentDrawerOpen, setIsAttachmentDrawerOpen] = useState({
    open: false,
    data: null,
  });
  const [isChecklistDrawerOpen, setIsChecklistDrawerOpen] = useState({
    open: false,
    data: null,
  });

  const printComponentRef = useRef();

  const activeTab = searchParams.get("activeTab");
  const orderNumber = searchParams.get("key");
  const navigationType = searchParams.get("navigationType");
  const isEnquiryPage = window.location.pathname === "/orderManagement/enquiry";

  const getOrderDetailsFunction = (orderNumber, navType) => {
    setIsLoading(true);
    OrderManagementService.getOrderDetailsData(orderNumber, navType)
      .then((res) => {
        setOrderDetailsData(res.data);
        setIsLoading(false);
        setSearchVal(res.data.ordNum);
        setSearchParams(
          {
            key: res.data.ordNum,
            activeTab: "Details",
            navigationType: navType || "last",
          },
          { replace: true }
        );
      })

      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        error?.response?.data?.message
          ? message.error(error?.response?.data?.message)
          : message.error("Something went wrong");
      });
  };

  useEffect(() => {
    getOrderDetailsFunction(orderNumber, navigationType);
  }, []);

  const handleOrderSearch = (value) => {
    if (value && value !== orderNumber) {
      getOrderDetailsFunction(value.trim(), navigationType);
    }
  };

  const convertToOrder = async () => {
    setIsOrderConverting(true);
    try {
      await OrderManagementService.convertToOrder(
        orderDetailsData.primaryIdentifierOrder
      );

      setIsOrderConverting(false);
      getOrderDetailsFunction(
        navigationType === "last" && navigationType === "first"
          ? null
          : orderNumber,
        navigationType
      );
      message.success("Converted to order successfully");
    } catch (error) {
      setIsOrderConverting(false);

      if (error?.response?.status === 404) {
        // TODO: Need to fix these via backend
        if (
          error?.response?.data?.message.split(":")?.[0] ===
          "Response Not Found"
        ) {
          message.info("Enter the details to continue.");
          setIsChecklistDrawerOpen({
            open: true,
            data: {
              ...orderDetailsData,
              checklistID:
                Number(error?.response?.data?.message.split(":")?.[1]) || 0,
            },
          });
          return;
        }

        if (error?.response?.data?.message === "Attachment Not Found") {
          message.info("Attachment is required to move this status.");
          setIsAttachmentDrawerOpen({
            open: true,
            data: orderDetailsData?.jobStatusDescription,
          });
          return;
        }
      }

      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went wrong!"
      );
    }
  };

  const viewOrderFunction = () => {
    new Promise((resolve) => {
      setSearchParams(
        {
          key: orderNumber,
          activeTab: activeTab,
          navigationType: "current",
        },
        { replace: true }
      );
      resolve();
    }).then(() => {
      navigate(`order-details?key=${orderDetailsData.primaryIdentifierOrder}`);
    });
  };

  return (
    <>
      <div className="p-2 mt-4">
        <CustomBreadcrumb
          items={items.filter((i) =>
            isEnquiryPage ? i.title !== "Order Management" : i
          )}
        />

        <div className="flex flex-wrap items-start justify-between gap-4 mt-4">
          <Search
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
            size="middle"
            disabled={isLoading}
            onSearch={handleOrderSearch}
            placeholder="Search your order number"
            loading={isLoading}
            enterButton
            className="lg:w-[25rem]"
            suffix={
              <>
                <Tooltip title="First">
                  <CustomButton
                    disabled={isLoading || navigationType === "first"}
                    type="text"
                    onClick={() => getOrderDetailsFunction(null, "first")}
                    icon={<DoubleLeftOutlined />}
                    className="-my-0.5"
                  />
                </Tooltip>

                <Tooltip title="Previous">
                  <CustomButton
                    disabled={isLoading || navigationType === "first"}
                    type="text"
                    onClick={() =>
                      getOrderDetailsFunction(orderNumber, "previous")
                    }
                    icon={<LeftOutlined />}
                    className="-my-0.5"
                  />
                </Tooltip>

                <Tooltip title="Next">
                  <CustomButton
                    disabled={isLoading || navigationType === "last"}
                    type="text"
                    onClick={() => getOrderDetailsFunction(orderNumber, "next")}
                    icon={<RightOutlined />}
                    className="-my-0.5"
                  />
                </Tooltip>

                <Tooltip title="Last">
                  <CustomButton
                    disabled={isLoading || navigationType === "last"}
                    type="text"
                    onClick={() => getOrderDetailsFunction(null, "last")}
                    icon={<DoubleRightOutlined />}
                    className="-my-0.5"
                  />
                </Tooltip>
              </>
            }
          />

          <div className="flex flex-wrap items-center gap-3">
            {orderDetailsData?.orderItem?.itemStatus !== 0 &&
              orderDetailsData?.orderItem?.itemStatus < 200 && (
                <CustomButton
                  type="primary"
                  onClick={convertToOrder}
                  loading={isOrderConverting}
                  size="middle"
                  disabled={isLoading}
                  icon={<TagOutlined />}
                >
                  Convert to order
                </CustomButton>
              )}

            <CustomButton
              size="middle"
              disabled={isLoading}
              type="secondary"
              onClick={viewOrderFunction}
              icon={<EyeOutlined />}
            >
              View Order
            </CustomButton>

            <CustomButton
              onClick={() => {
                setIsDrawerOpen(true);
                setOrderStatusDrawerType("read");
              }}
              icon={<HistoryOutlined />}
              size="middle"
              disabled={isLoading}
              type="secondary"
            >
              History
            </CustomButton>

            <ReactToPrint
              content={() => printComponentRef.current}
              trigger={() => (
                <CustomButton
                  icon={<PrinterOutlined />}
                  size="middle"
                  disabled={isLoading}
                  type="secondary"
                >
                  Print
                </CustomButton>
              )}
            />
          </div>
        </div>

        <Divider className="my-4" />

        <Descriptions
          bordered
          column={{ xs: 1, sm: 1, lg: 2, xl: 3 }}
          size="small"
          className="my-5"
          labelStyle={{
            width: "14.5rem",
            color: isDarkMode ? "white" : "black",
          }}
          contentStyle={{ fontWeight: 500, width: "16rem" }}
        >
          <Descriptions.Item label="Created By">
            {isLoading ? (
              "..."
            ) : (
              <Tag color="orange">
                {orderDetailsData?.enteredByUser?.name || "N/A"}
              </Tag>
            )}
          </Descriptions.Item>

          <Descriptions.Item label="Platform Order Number">
            {isLoading ? (
              "..."
            ) : (
              <Tag color="processing">{orderDetailsData?.ordNum || "N/A"}</Tag>
            )}
          </Descriptions.Item>

          <Descriptions.Item label="Reference Number">
            {isLoading ? (
              "..."
            ) : (
              <Tag color="cyan">{orderDetailsData?.quoteRefNum || "N/A"}</Tag>
            )}
          </Descriptions.Item>

          <Descriptions.Item label="Job Number">
            {isLoading ? (
              "..."
            ) : (
              <Tag color="cyan">{orderDetailsData?.jobRefNum || "N/A"}</Tag>
            )}
          </Descriptions.Item>

          <Descriptions.Item label="Vin / Stock Number">
            {isLoading ? (
              "..."
            ) : (
              <Tag color="magenta">
                {orderDetailsData?.internalIdentificationNum || "N/A"}
              </Tag>
            )}
          </Descriptions.Item>

          <Descriptions.Item label="Current Status">
            {isLoading ? (
              "..."
            ) : (
              <Tag color="#075985">
                {orderDetailsData?.jobStatusDescription || "N/A"}
              </Tag>
            )}
          </Descriptions.Item>
        </Descriptions>

        <div className="grid -mx-0.5 grid-cols-1 gap-4 mb-4 md:grid-cols-2 xl:grid-cols-3 place-items-start">
          <Card
            type="inner"
            loading={isLoading}
            title="Company"
            className={`w-full ${
              isLoading ? "" : "overflow-y-auto h-[9.5rem]"
            }`}
          >
            <Space direction="vertical">
              <div>
                <Tag color="blue">{orderDetailsData?.accountID}</Tag>
              </div>

              <Title level={5}>{orderDetailsData?.company}</Title>
            </Space>
          </Card>

          <Card
            type="inner"
            loading={isLoading}
            title="Billing Address"
            className={`w-full ${
              isLoading ? "" : "overflow-y-auto h-[9.5rem]"
            }`}
          >
            <a
              className="text-blue-500 cursor-pointer"
              target="blank"
              href={`https://www.google.com/maps?q=${orderDetailsData?.billingAddress?.country}, ${orderDetailsData?.billingAddress?.state}, ${orderDetailsData?.billingAddress?.city}, ${orderDetailsData?.billingAddress?.postCode}`}
            >
              <Space direction="vertical">
                <Text className="text-blue-500">
                  <EnvironmentOutlined className="mr-2" />

                  {`${orderDetailsData?.billingAddress?.addressLine1}, ${orderDetailsData?.billingAddress?.addressLine2}`}
                </Text>

                <Text className="text-blue-500">{`${
                  orderDetailsData?.billingAddress?.country || ""
                }, ${orderDetailsData?.billingAddress?.state || ""}, ${
                  orderDetailsData?.billingAddress?.city || ""
                }, ${orderDetailsData?.billingAddress?.postCode || ""}`}</Text>
              </Space>
            </a>
          </Card>

          <Card
            type="inner"
            loading={isLoading}
            title="Shipping Address"
            className={`w-full ${
              isLoading ? "" : "overflow-y-auto h-[9.5rem]"
            }`}
          >
            <a
              className="text-blue-500 cursor-pointer"
              target="blank"
              href={`https://www.google.com/maps?q=${orderDetailsData?.shippngAddress?.country}, ${orderDetailsData?.shippngAddress?.state}, ${orderDetailsData?.shippngAddress?.city}, ${orderDetailsData?.shippngAddress?.postCode}`}
            >
              <Space direction="vertical">
                <Text className="text-blue-500">
                  <EnvironmentOutlined className="mr-2" />
                  {`${orderDetailsData?.shippngAddress?.addressLine1}, ${orderDetailsData?.shippngAddress?.addressLine2}`}
                </Text>

                <Text className="text-blue-500">
                  {`${orderDetailsData?.shippngAddress?.country || ""}, ${
                    orderDetailsData?.shippngAddress?.state || ""
                  }, ${orderDetailsData?.shippngAddress?.city || ""}, ${
                    orderDetailsData?.shippngAddress?.postCode || ""
                  }`}
                </Text>
              </Space>
            </a>
          </Card>

          <Card
            type="inner"
            loading={isLoading}
            title="Contact"
            className={`w-full ${
              isLoading ? "" : "overflow-y-auto h-[9.5rem]"
            }`}
          >
            <Space direction="vertical">
              {orderDetailsData?.contact?.name && (
                <Text>
                  <UserOutlined className="mr-2" />
                  {orderDetailsData?.contact?.name}
                </Text>
              )}

              {orderDetailsData?.contact?.email && (
                <Text>
                  <MailOutlined className="mr-2" />
                  {orderDetailsData?.contact?.email}
                </Text>
              )}

              {orderDetailsData?.contact?.mobile && (
                <Text>
                  <PhoneOutlined className="mr-2" />
                  {orderDetailsData?.contact?.mobile}
                </Text>
              )}
            </Space>
          </Card>

          <Card
            type="inner"
            loading={isLoading}
            title="Notes"
            className={`w-full ${
              isLoading ? "" : "overflow-y-auto h-[9.5rem]"
            }`}
          >
            {orderDetailsData?.notes ? (
              <Markdown value={orderDetailsData.notes} previewOrder="preview" />
            ) : (
              <Text type="secondary">No notes available</Text>
            )}
          </Card>
        </div>

        <Tabs
          type="card"
          className="!mb-0 mt-4"
          items={tabsItem}
          activeKey={searchParams.get("activeTab")}
          onChange={(activeTab) => {
            searchParams.set("activeTab", activeTab);
            setSearchParams(searchParams);
          }}
        />

        {searchParams.get("activeTab") === "Details" ? (
          <SingleOrderDetails
            orderDetailsData={orderDetailsData}
            loading={isLoading}
          />
        ) : null}
        {searchParams.get("activeTab") === "Attachment" ? (
          <div className="relative w-full">
            <SingleOrderAttachment
              orderDetailsData={orderDetailsData}
              loading={isLoading}
            />
          </div>
        ) : null}

        <OrderStatusDrawer
          isDrawerOpen={isDrawerOpen}
          onCancel={() => {
            setIsDrawerOpen(false);
            setOrderStatusDrawerType(null);
          }}
          orderNumber={orderDetailsData?.ordNum}
          orderStatusDrawerType={orderStatusDrawerType}
          identifier={orderDetailsData?.primaryIdentifierOrder}
        />
      </div>

      <div className="sr-only">
        <PrintReceipt
          orderDetails={orderDetailsData}
          componenetRef={printComponentRef}
        />
      </div>

      <Modal
        open={viewOrderModal}
        width={900}
        onCancel={() => setViewOrderModal(false)}
        footer={false}
      >
        <PrintReceipt
          orderDetails={orderDetailsData}
          componenetRef={printComponentRef}
        />
      </Modal>

      <OrderAttachmentDrawer
        isDrawerOpen={isAttachmentDrawerOpen.open}
        onCancel={() => setIsAttachmentDrawerOpen({ open: false, data: null })}
        currentOrderStatus={{
          primaryIdentifier: orderDetailsData?.primaryIdentifierOrder,
          ordNum: orderNumber,
        }}
        activeTab={isAttachmentDrawerOpen.data}
      />

      <OrderChecklistDrawer
        isDrawerOpen={isChecklistDrawerOpen.open}
        onClose={() => setIsChecklistDrawerOpen({ open: false, data: null })}
        orderDetails={isChecklistDrawerOpen.data}
      />
    </>
  );
};

export default OrderDetails;
