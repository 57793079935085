import {
  CheckOutlined,
  CloseOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Divider,
  Empty,
  Form,
  Input,
  Popconfirm,
  Popover,
  Row,
  Select,
  Table,
  Tooltip,
  message,
} from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomButton from "../../../Common/CustomButton";
import ToolTipButton from "../../../Common/ToolTipButton";
import ProductionService from "../../../services/Production/ProductionServices";
import {
  formatDate,
  formatDateMonth,
  getCurrentDate,
  isWeekend,
} from "../../../utils/dateHelper";
import ConfigureCapactiyDrawer from "./ConfigureCapactiyDrawer";
import DefaultCapacityDrawer from "./DefaultCapacityDrawer";
import CustomBreadcrumb from "../../../Common/CustomBreadcrumb";
import { useDarkMode } from "../../../Providers/DarkModeContext";

const items = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Capacity",
  },
];

const CapacityPage = () => {
  const { isDarkMode } = useDarkMode();
  const [form] = useForm();
  const [list, setList] = useState([]);
  const [isButtonEnabled, setIsButtonEnabled] = useState({
    next: false,
    prev: false,
  });
  const [dropdownList, setDropdownList] = useState([]);
  const [productGroupList, setProductGroupList] = useState([]);
  const [isLoading, setIsLoading] = useState(null);
  const [product, setProduct] = useState(null);
  const [configureDrawer, setConfigureDrawer] = useState(false);
  const [defaultDrawer, setDefaultDrawer] = useState(false);
  const [managePopconfirm, setManagePopconfirm] = useState({
    open: false,
    dataIndex: null,
    data: null,
  });
  const [tableRows, setTableRows] = useState([]);
  const [currentPage, setCurrentPage] = useState({
    navigation: "current",
    date: getCurrentDate(),
  });

  const [prodGroupData, setProdGroupData] = useState([]);

  const fetchList = async (id, date, navigation) => {
    setIsLoading(true);
    try {
      const res = await ProductionService.getProductionCapacityByDates(
        id,
        date,
        navigation
      );

      let allProcess = res.data.productionDates.reduce((acc, item) => {
        return [...acc, ...item.processLines];
      }, []);

      const data = allProcess.reduce((acc, item) => {
        let recordIndex = acc.findIndex(
          (r) => r.processLineName === item.processLineName
        );

        if (recordIndex === -1) {
          acc.push({
            processLineName: item.processLineName,
            default: item,
            [item.productionDate]: item,
          });
        } else {
          acc[recordIndex] = {
            ...acc[recordIndex],
            [item.productionDate]: item,
          };
        }

        return acc;
      }, []);

      setTableRows(data);

      setList(res?.data.productionDates);
      setIsButtonEnabled({
        next: res?.data.isNextEnabled,
        prev: res?.data.isPreviousEnabled,
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went Wrong"
      );
    }
  };

  const fetchDropdownList = async (id) => {
    setIsLoading(true);
    try {
      const res = await ProductionService.getProcessLineDropdown(id);

      const filteredList = res?.data
        .filter((item) => item.name)
        .map((item) => ({
          label: item.name,
          value: item.statusID,
        }));

      setDropdownList(filteredList);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went Wrong"
      );
    }
  };

  const fetchProductGroupList = async () => {
    setIsLoading(true);
    try {
      const res = await ProductionService.getProductGroupList();

      const group = localStorage.getItem("prodGroup")
        ? JSON.parse(localStorage.getItem("prodGroup"))
        : res.data;

      setProductGroupList(group[0]);
      setProduct(group[0].productGroupID);

      fetchList(
        group[0].productGroupID,
        currentPage.date,
        currentPage.navigation
      );
      fetchDropdownList(group[0].productGroupID);

      handleProductGroupChange(group[0].productGroupID);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      message.error(error.message ? error.message : "Something went Wrong");
    }
  };

  useEffect(() => {
    form.setFieldsValue(managePopconfirm.data);
  }, [managePopconfirm]);

  useEffect(() => {
    fetchProductGroupList();
  }, []);

  const handleButtonClick = (data, index) => {
    setManagePopconfirm({ open: true, dataIndex: index, data: data });
  };

  const closePopover = () => {
    setManagePopconfirm({
      open: false,
      dataIndex: null,
      data: null,
    });
  };

  const handleSubmit = async (values) => {
    const currentDate = values.productionDate
      ? formatDate(values.productionDate)
      : null;

    const payload = {
      startDate: currentDate,
      endDate: currentDate,
      processLineIDs: [values.processLineID],
      processLineName: values.processLineName,
      productionDates: [""],
      capacity: values.capacity,
      autoRefill: "60",
      productGroupID: product,
    };

    setIsLoading(true);
    try {
      await ProductionService.changeCapacityForSingleDay(payload);
      setIsLoading(false);
      fetchList(product, currentPage.date, currentPage.navigation);
      closePopover();
      message.success("Updated successfully!");
    } catch (error) {
      setIsLoading(false);
      console.error(error?.response?.data?.message);
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went wrong!"
      );
    }
  };

  const handleUpdateDefaultCapacity = async (values) => {
    const currentDate = values.productionDate
      ? formatDate(values.productionDate)
      : null;

    const payload = {
      startDate: currentDate,
      endDate: currentDate,
      processLineIDs: [values.processLineID],
      processLineName: values.processLineName,
      capacity: values.capacity,
      productionDates: values.productionDate ? [values.productionDate] : [""],
      autoRefill: "60",
      default: true,
      productGroupID: product,
    };

    setIsLoading(true);
    try {
      await ProductionService.updateDefaultCapacity(payload);
      setIsLoading(false);
      fetchList(product, currentPage.date, currentPage.navigation);
      closePopover();
      message.success("Updated successfully!");
    } catch (error) {
      setIsLoading(false);
      console.error(error?.response?.data?.message);
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went wrong!"
      );
    }
  };

  const handleNavigation = (navigation, date) => {
    fetchList(product, date, navigation);
    setCurrentPage({ navigation: navigation, date });
  };

  const onFinish = (process, type) => {
    form
      .validateFields()
      .then((values) => {
        if (values.capacity == process.capacity) {
          return message.info("Nothing to update!");
        }

        type === "default"
          ? handleUpdateDefaultCapacity({
              ...process,
              capacity: values.capacity,
            })
          : handleSubmit({ ...process, capacity: values.capacity });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const configureDefaultButton = () => {
    if (list.length > 0) {
      return {
        filterDropdown: () => <></>,
        filterIcon: () => (
          <Tooltip trigger="hover" title="Configure default capacities">
            <CustomButton
              id="defaultCapacity"
              className="absolute z-50 right-1 w-fit "
              type="primary"
              onClick={() => setDefaultDrawer(true)}
              icon={<SettingOutlined />}
              size="small"
            />
          </Tooltip>
        ),
      };
    }
  };

  const columns = [
    {
      title: "Status",
      dataIndex: "processLineName",
      render: (processLineName) => (
        <p className="font-medium">{processLineName}</p>
      ),
      width: "15%",
      fixed: "left",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        b.processLineName.split(".")[0] - a.processLineName.split(".")[0],
    },
    {
      title: "Default",
      width: "13%",
      align: "center",
      render: (item, _, index) => (
        <Popover
          title={`Update default capacity`}
          onOpenChange={(open) => {
            if (!open) {
              closePopover();
            }
          }}
          open={
            managePopconfirm.open &&
            managePopconfirm.data &&
            managePopconfirm.data.productionDate === null &&
            managePopconfirm.dataIndex === index
          }
          trigger="click"
          content={
            <Form form={form} layout="vertical" onFinish={onFinish}>
              <Form.Item
                name="capacity"
                initialValue={item.default.capacity}
                rules={[
                  {
                    required: true,
                    message: "Please enter capacity",
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Enter capacity"
                  size="middle"
                  min={0}
                  onPressEnter={(e) => {
                    e.preventDefault();
                  }}
                />
              </Form.Item>

              <Row className="justify-end gap-3 mt-4">
                <CustomButton
                  loading={isLoading}
                  type="primary"
                  onClick={() => onFinish(item.default, "default")}
                  icon={<CheckOutlined />}
                >
                  Submit
                </CustomButton>

                <CustomButton
                  disabled={isLoading}
                  type="cancel"
                  icon={<CloseOutlined />}
                  onClick={closePopover}
                >
                  Cancel
                </CustomButton>
              </Row>
            </Form>
          }
        >
          <CustomButton
            onClick={() => {
              handleButtonClick(item.default, index);
            }}
            type="link"
            className="font-medium hover:underline"
          >
            {item.default.default ? item.default.capacity : 0}
          </CustomButton>
        </Popover>
      ),
      ...configureDefaultButton(),
    },
    ...list
      ?.filter((item) => item.productionDate)
      .map((i) => ({
        title: formatDateMonth(i.productionDate),
        dataIndex: i.productionDate,
        width: "60",
        align: "center",
        render: (item, _, index) => (
          <Popover
            onOpenChange={(open) => {
              if (!open) {
                closePopover();
              }
            }}
            open={
              managePopconfirm.open &&
              managePopconfirm.data &&
              item?.productionDate === managePopconfirm.data.productionDate &&
              managePopconfirm.dataIndex === index
            }
            title={`Update ${formatDateMonth(item?.productionDate)} capacity`}
            trigger="click"
            content={
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                  name="capacity"
                  initialValue={item?.capacity}
                  rules={[
                    {
                      required: true,
                      message: "Please enter capacity",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Enter capacity"
                    size="middle"
                    min={0}
                    onPressEnter={(e) => {
                      e.preventDefault();
                    }}
                  />
                </Form.Item>

                <Row className="justify-end gap-3 mt-4">
                  {isWeekend(i?.productionDate) ? (
                    <Popconfirm
                      title="Are you sure?"
                      placement="bottom"
                      description="This date is a weekend. Do you still want to update the capacity?"
                      onConfirm={() => onFinish(item, "capacity")}
                      okText="Yes"
                      cancelText="No"
                      color={isDarkMode && "#282928"}
                    >
                      <CustomButton
                        loading={isLoading}
                        type="primary"
                        icon={<CheckOutlined />}
                      >
                        Submit
                      </CustomButton>
                    </Popconfirm>
                  ) : (
                    <CustomButton
                      loading={isLoading}
                      type="primary"
                      icon={<CheckOutlined />}
                      onClick={() => onFinish(item, "capacity")}
                    >
                      Submit
                    </CustomButton>
                  )}

                  <CustomButton
                    type="cancel"
                    disabled={isLoading}
                    icon={<CloseOutlined />}
                    onClick={closePopover}
                  >
                    Cancel
                  </CustomButton>
                </Row>
              </Form>
            }
          >
            <CustomButton
              onClick={() => handleButtonClick(item, index)}
              type="link"
              className="font-medium hover:underline"
            >
              {item?.capacity}
            </CustomButton>
          </Popover>
        ),
      })),
  ];

  const handleCloseDefaultDrawer = () => {
    setDefaultDrawer(false);
  };

  const handleCloseConfigureDrawer = () => {
    setConfigureDrawer(false);
  };

  const handleProductGroupChange = (value) => {
    if (!value) {
      setList([]);
      setTableRows([]);
      return;
    }
    setProduct(value);
    fetchList(value, currentPage.date, currentPage.navigation);
    fetchDropdownList(value);
  };

  return (
    <div className="p-2 mt-4">
      <CustomBreadcrumb items={items} />

      <div className="grid gap-2 mt-2 mb-2 mr-4 justify-items-end">
        <div className="flex items-center justify-center ">
          {list.length > 0 && (
            <>
              <CustomButton
                type="link"
                className="flex items-center justify-center "
                disabled={!isButtonEnabled.prev}
                onClick={() =>
                  handleNavigation("prev", formatDate(list[1]?.productionDate))
                }
              >
                <LeftCircleOutlined className="text-[1.4rem]" />
              </CustomButton>

              <CustomButton
                type="link"
                className="flex items-center justify-center mr-5"
                disabled={!isButtonEnabled.next}
                onClick={() =>
                  handleNavigation(
                    "next",
                    formatDate(list[list.length - 1].productionDate)
                  )
                }
              >
                <RightCircleOutlined className="text-[1.4rem]" />
              </CustomButton>
            </>
          )}

          {product ? (
            <ToolTipButton
              id="configureCapacity"
              tooltipHeader="Configure capacity"
              tooltipPlacement="top"
              type="primary"
              onClick={() => setConfigureDrawer(true)}
              size="small"
              icon={<SettingOutlined />}
            >
              Configure
            </ToolTipButton>
          ) : (
            <ToolTipButton
              tooltipHeader="Select product group first!"
              tooltipPlacement="left"
              disabled={!product}
              type="primary"
              onClick={() => setConfigureDrawer(true)}
              size="small"
              icon={<SettingOutlined />}
            >
              Configure
            </ToolTipButton>
          )}
        </div>
      </div>

      <Divider className="!mt-3" />

      <div className="px-3 pb-6">
        <Table
          dataSource={tableRows}
          columns={columns}
          pagination={false}
          loading={isLoading}
          scroll={{ x: 1000 }}
          bordered
          locale={{
            emptyText: (
              <Empty
                className="py-8"
                description={
                  !product
                    ? "Select product group to view capacity."
                    : "No data found for selected group."
                }
              />
            ),
          }}
        />
      </div>

      <ConfigureCapactiyDrawer
        open={configureDrawer}
        onClose={handleCloseConfigureDrawer}
        dropdownList={dropdownList}
        fetchList={() =>
          fetchList(product, currentPage.date, currentPage.navigation)
        }
        product={product}
      />

      <DefaultCapacityDrawer
        open={defaultDrawer}
        onClose={handleCloseDefaultDrawer}
        dropdownList={dropdownList}
        fetchList={() =>
          fetchList(product, currentPage.date, currentPage.navigation)
        }
        product={product}
      />
    </div>
  );
};

export default CapacityPage;
