import axios from "../axios";

class ProductService {
  static Instance() {
    return new ProductService();
  }

  getProductBuilderGroupData(payload) {
    return axios.post("api/stock/getStockGroups", payload);
  }

  getProductPageData(payload) {
    return axios.post("api/product/list", payload);
  }

  getProductDataList() {
    return axios.get(`api/product/list`);
  }

  getSingleProductData(productID) {
    return axios.get(`/api/product/one?productID=${productID}`);
  }

  getProductWithConfigDataList(accountID) {
    return axios.get(
      `/api/sales/order/product-builder/v1/configuration/by-product?accountID=${accountID}`
    );
  }

  getEachProductDataList(id) {
    return axios.get(`api/product/list/productgroup?ProductGroupID=${id}`);
  }

  getProductGroupPageData() {
    return axios.get("api/product/group/list");
  }

  addProductGroupPageData(payload) {
    return axios.post("/api/product/group", payload);
  }

  editProductGroupPageData(payload) {
    return axios.put("/api/product/group", payload);
  }

  getStockGroupFilterData(payload) {
    return axios.post("api/stock/getStockGroups", payload);
  }

  getProductStatus(activeTab, payload) {
    return axios.post(`/api/workflow/list?Type=${activeTab}`, payload);
  }

  getProductConfigData(productId) {
    return axios.get(`api/product/configuration/list?productID=${productId}`);
  }

  editProductOrderStatus(payload) {
    return axios.put("/api/workflow/order-status", payload);
  }

  editProductJobStaus(payload) {
    return axios.put("/api/workflow/job-status", payload);
  }

  saveProductData(payload) {
    return axios.post("/api/product", payload, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
  }

  editProductData(payload) {
    return axios.put("/api/product", payload, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
  }

  updateOrderDetails(payload) {
    return axios.put("/api/workflow/order-status", payload);
  }

  getAllOrderStatus(productID) {
    return axios.get(`/api/workflow/order-status?ProductGroupID=${productID}`);
  }

  addOrderStatusFlow(payload) {
    return axios.post("/api/workflow/order-status", payload);
  }

  addWorkFlow(payload) {
    return axios.put("/api/workflow/order-status", payload);
  }

  editWorkFlow(payload) {
    return axios.put("/api/workflow", payload);
  }

  validateStatus(statusId) {
    return axios.post(`/api/workflow/validate?StatusID=${statusId}`);
  }

  getActionsList() {
    return axios.get("api/action/list");
  }

  createNewAction(payload) {
    return axios.post("api/action/list", payload);
  }

  createNewActionTask(payload) {
    return axios.post("api/action/list", payload);
  }

  updateAction(payload) {
    return axios.put("api/action/list", payload);
  }

  updateActionTask(payload) {
    return axios.put("api/action/list", payload);
  }

  deleteAction(payload) {
    return axios.delete("api/action/list", payload);
  }

  deleteActionTask(payload) {
    return axios.delete("api/action/list", payload);
  }

  getOrderDetails(PrimaryIdentifier) {
    return axios.get(
      `api/order/view/details?PrimaryIdentifier=${PrimaryIdentifier}`
    );
  }

  finalizeFlow(productGroupID) {
    return axios.put(`/api/order/job/flow/finalize?ID=${productGroupID}`);
  }

  disableStatusCheck(payload) {
    return axios.put(`/api/workflow/disable/allowed`, payload);
  }

  addNewProductConfig(payload) {
    return axios.post(`/api/product/configuration`, payload);
  }

  addNewConfigOption(payload) {
    return axios.post(`/api/product/configuration/option`, payload);
  }

  updateProductConfig(payload) {
    return axios.put(`/api/product/configuration`, payload);
  }

  updateConfigOption(payload) {
    return axios.put(`/api/product/configuration/option`, payload);
  }

  deleteProductConfig(productConfigId) {
    return axios.delete(
      `/api/product/configuration?productConfigurationID=${productConfigId}`
    );
  }

  deleteConfigOption(optionId) {
    return axios.delete(
      `/api/product/configuration/option?productConfigurationOptionID=${optionId}`
    );
  }

  getProductOptionsList(payload) {
    return axios.post(`/api/product/configuration/option/list`, payload);
  }
}
export default ProductService.Instance();
