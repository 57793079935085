import axios from "../axios";
class OrderManagementService {
  static Instance() {
    return new OrderManagementService();
  }

  saveOrderCreateData(payload) {
    return axios.post("api/order", payload);
  }

  updateOrderCreateData(payload, PrimaryIdentifier) {
    return axios.put(
      `api/order?PrimaryIdentifier=${PrimaryIdentifier}`,
      payload
    );
  }

  getOrderViewListPublic() {
    return axios.get("/api/orderview/list/all");
  }

  getOrderViewList() {
    return axios.get("/api/orderview/list");
  }

  getOrderTableData(payload, primaryIdentifier) {
    return axios.post(
      `api/order/gateway?PrimaryIdentifier=${primaryIdentifier}`,
      payload
    );
  }

  updateOrderViewData(payload) {
    return axios.put("/api/orderview", payload);
  }
  saveOrderViewData(payload) {
    return axios.post("/api/orderview", payload);
  }

  deleteOrderViewData(PrimaryIdentifier, ID) {
    return axios.delete(
      `/api/orderview/?PrimaryIdentifier=${PrimaryIdentifier}&ID=${ID}`
    );
  }

  manageOrderStatusData(jobId, type) {
    return axios.post(
      `/api/order/job/update/status?OrdJobID=${jobId}&ActionName=${type}`
    );
  }

  cancelOrderData(payload) {
    return axios.post(`/api/order/job/cancel?OrdNum=${payload}`);
  }

  getOrderDetailsData(orderNumber, navigationType) {
    return axios.post(
      `api/order/one?ordNum=${orderNumber}&navigation=${navigationType}`
    );
  }

  getFileDetailsData(primaryIdentifier, payload) {
    return axios.post(
      `api/attachment/list?PrimaryIdentifier=${primaryIdentifier}`,
      payload
    );
  }

  downloadDetailsData(Type, FileName) {
    return axios.get(`api/file/download?Type=${Type}&FileName=${FileName}`, {
      responseType: "blob", // This indicates that you're expecting a binary response
    });
  }

  deleteAttachment(fileName, category) {
    return axios.delete(
      `/api/attachment?FileName=${fileName}&Category=${category}`
    );
  }

  getPageDetails() {
    return axios.get("/api/pageDetails?type=orderManagement");
  }

  getOrderCountData(payload) {
    return axios.post("/api/order/gateway/count/by-status", payload);
  }

  previewUploadFile(type, fileName) {
    return axios.get(`/api/file/preview?Type=${type}&FileName=${fileName}`);
  }

  getOrderJobDetails(primaryIdentifier) {
    return axios.get(
      `/api/order/job/details?PrimaryIdentifier=${primaryIdentifier}`
    );
  }

  getOrderStatusHistory(primaryIdentifier) {
    return axios.get(
      `/api/order/job/status/timeline?PrimaryIdentifier=${primaryIdentifier}`
    );
  }

  saveOrderAttachment(payload, primaryIdentifier) {
    return axios.post(
      `api/attachment/save?PrimaryIdentifier=${primaryIdentifier}`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  getSampleTemplate() {
    return axios.get("/api/order/import/template/download", {
      responseType: "arraybuffer",
    });
  }

  exportOrderList(primaryIdentifier) {
    return axios.get(
      `/api/order/list/export?primaryIdentifier=${primaryIdentifier}`,
      {
        responseType: "arraybuffer",
      }
    );
  }

  importOrdersList(payload) {
    return axios.post("/api/order/import", payload, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
  }

  createOrdersFromList(primaryIdentifier, payload) {
    return axios.post(
      `/api/attachment/save?PrimaryIdentifier=${primaryIdentifier}`,
      payload,
      {
        headers: {
          "Content-type": "multipart/form-data",
        },
      }
    );
  }

  getStatusTypeData() {
    return axios.get(`/api/attachment/document`);
  }
  getCancelOrderReasonsList() {
    return axios.get(`/api/order/cancel/list`);
  }

  CancelOrderData(payload) {
    return axios.post(`/api/order/job/cancel`, payload);
  }
  getOrderDetails(Ordnum) {
    return axios.get("api/order/gateway/details?Ordnum=" + Ordnum);
  }
  getOrderDetailsList(refNum) {
    return axios.get("api/order/gateway/details?refNum=" + refNum);
  }
  getQuoteRefNumExists(quoteRefNum) {
    return axios.get(`api/order/quote-ref/exists?quoteRefNum=${quoteRefNum}`);
  }
  getJobRefNumExists(jobRefNum) {
    return axios.get(`api/order/job-ref/exists?jobRefNum=${jobRefNum}`);
  }
  checkVinNumberExists(vimNumber) {
    return axios.get(`api/order/intidentnum/exists?IntIdentNum=${vimNumber}`);
  }

  convertToOrder(primaryIdentifier) {
    return axios.put(
      `api/order/job/convert/to/order?PrimaryIdentifier=${primaryIdentifier}&ActionName=Done`
    );
  }

  getOrderNotes(orderNum, activeTab) {
    return axios.get(
      `api/order/notes?orderNum=${orderNum}&activeTab=${activeTab}`
    );
  }

  updatedOrderNote(orderNum, payload) {
    return axios.put(`api/order/notes?orderNum=${orderNum}`, payload);
  }

  getChecklistResponse(checkListID) {
    return axios.get(`/api/checklist/id?checkListID=${checkListID}`);
  }

  getChecklistDetails(checkListID) {
    return axios.get(`/api/checklist/id?checkListID=${checkListID}`);
  }

  submitChecklistDetails(payload) {
    return axios.post(`/api/checklist/options/response`, payload);
  }

  getAttachedChecklists(primaryIdentifier) {
    return axios.get(
      `api/checklist/order?orderPrimaryIdentifier=${primaryIdentifier}`
    );
  }

  getAttachedChecklistResponse(primaryIdentifier, checkListID, statusID) {
    return axios.get(
      `/api/checklist/order/response?orderPrimaryIdentifier=${primaryIdentifier}&checkListID=${checkListID}&statusID=${statusID}`
    );
  }
}
export default OrderManagementService.Instance();
