import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Checkbox, Drawer, Form, Input, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import CitySearch from "../../Common/CitySearch";
import CountrySelect from "../../Common/CountrySelect";
import CustomButton from "../../Common/CustomButton";
import CustomerService from "../../services/CustomerService/CustomerService";
import { validateTextInput } from "../../utils/validationsHelper";
import StateSelect from "../../Common/StateSelect";

const { Option } = Select;

const ManageCustomerAccount = ({
  onSuccess,
  editAction,
  editData,
  onClose,
}) => {
  let accountID;
  let ULID;

  if (editAction === "edit") {
    accountID = editData?.debtorResponse?.accountID;
    ULID = editData?.debtorResponse?.primaryIdentifier;
  }
  const [form] = Form.useForm();
  const [IsLoading, setIsLoading] = useState(false);
  const [selectCity, setSelectCity] = useState("");
  const [cityData, setCityData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [stateId, setStateId] = useState("");
  const [countrySelected, setCountrySelected] = useState("");
  const [selectedCountryValue, setSelectedCountryValue] = useState(null);
  const [stateDropDownData, setStateDropDownData] = useState({});

  useEffect(() => {
    fetchCustomerCategoryList();

    if (editAction === "edit") {
      form.setFieldsValue({
        ...editData,
        companyName: editData?.debtorResponse?.company,
        categoryID: editData?.debtorResponse?.categoryID,
        addressLine1: editData?.addressResponse?.addressLine1,
        addressLine2: editData?.addressResponse?.addressLine2,
        addressCity: editData?.addressResponse?.city,
        state: editData?.addressResponse?.state,
        postCode: editData?.addressResponse?.postCode,
        country: editData?.addressResponse?.country,
        email: editData?.addressResponse?.emailAddress,
        mobile: editData?.addressResponse?.phone,
        fax: editData?.addressResponse?.fax,
        referenceNumber: editData?.debtorResponse?.refNum,
      });
      setSelectedCountryValue(editData?.addressResponse?.country);
    } else {
      form.setFieldValue("makePrimaryContact", true);
    }
    return () => form.setFieldsValue(null);
  }, [editData, editAction]);

  const fetchCustomerCategoryList = async () => {
    setIsLoading(true);
    try {
      setIsLoading(false);

      const res = await CustomerService.getCustomerCategoryList();

      const filteredList = res?.data.map((i) => ({
        label: i.name,
        value: i.id,
      }));

      setCategoryList(filteredList);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error?.response?.data?.message);
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went wrong!"
      );
    }
  };

  const handleClearCountry = () => {
    form.resetFields(["country", "state"]);
    setSelectedCountryValue(null);
  };

  const handleCountrySelect = (countryValue, stateData) => {
    form.setFieldValue("country", countryValue);
    setSelectedCountryValue(countryValue);
    setStateDropDownData(stateData);
  };

  const handleClose = () => {
    onClose();
    form.resetFields();
    setSelectedCountryValue(null);
    setStateDropDownData({});
  };

  const onFinish = (values) => {
    if (editAction === "edit") {
      const payload = {
        companyName: values.companyName,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        categoryID: values.categoryID,
        addressCity: values.addressCity,
        state: values.state,
        postCode: values.postCode,
        country: selectedCountryValue ? selectedCountryValue : values.country,
        firstName: values.firstName,
        lastName: values.lastName,
        jobTitle: values.jobTitle,
        mobile: values.mobile,
        email: values.email,
        fax: values.fax,
        primaryIdentifier: ULID,
        referenceNumber: values?.referenceNumber,
      };

      setIsLoading(true);
      CustomerService.updateCustomerDetails(payload)
        .then((res) => {
          onSuccess();
          setIsLoading(false);
          message.success("Account Updated successfully");
          handleClose();
        })
        .catch((error) => {
          setIsLoading(false);
          message.error(
            error?.response?.data?.message || "Something went wrong!"
          );
        });
    } else {
      const payload = {
        companyName: values.companyName,
        categoryID: values.categoryID,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        addressCity: values.addressCity,
        state: values.state,
        postCode: values.postCode,
        country: selectedCountryValue,
        firstName: values.firstName,
        lastName: values.lastName,
        jobTitle: values.jobTitle,
        mobile: values.mobile,
        email: values.email,
        fax: values.fax,
        referenceNumber: values?.referenceNumber,
        makePrimaryContact: values?.makePrimaryContact,
      };

      setIsLoading(true);
      CustomerService.saveDebtorCustomerAccountData(payload)
        .then((res) => {
          setIsLoading(false);
          onSuccess();
          message.success("Account Created successfully");
          handleClose();
        })
        .catch((error) => {
          setIsLoading(false);
          message.error(
            error?.response?.data?.message || "Something went wrong!"
          );
        });
    }
  };

  return (
    <Drawer
      title={
        editAction === "edit"
          ? "Edit Customer Details"
          : editAction === "add"
          ? "Create New Account"
          : ""
      }
      width={400}
      onClose={handleClose}
      open={!!editAction}
      bodyStyle={{
        padding: "10px 8px",
      }}
    >
      <div className="flex flex-col gap-6 px-4 rounded-md">
        <div className="flex flex-col gap-2">
          <Form
            form={form}
            name="form_item_path"
            layout="vertical"
            onFinish={onFinish}
            size="small"
            labelWrap={true}
          >
            <Form.Item
              name="companyName"
              label="Company"
              rules={[
                {
                  required: true,
                  message: "Enter company name",
                },
                {
                  validator: validateTextInput("Company name", 2, 50),
                },
              ]}
            >
              <Input size="medium" placeholder="Enter company name" />
            </Form.Item>

            <Form.Item
              name="referenceNumber"
              label="Reference Number"
              rules={[
                {
                  required: false,
                  message: "Enter reference number",
                },
                {
                  validator: validateTextInput("Reference Number", 4),
                },
              ]}
            >
              <Input size="medium" placeholder="Enter reference number" />
            </Form.Item>

            <Form.Item
              name="addressLine1"
              label="Address Line 1"
              rules={[
                {
                  required: true,
                  message: "Enter address",
                },
                { validator: validateTextInput("Address Line 1", 10, 100) },
              ]}
            >
              <Input
                maxLength={100}
                size="medium"
                type="text"
                placeholder="Enter address"
              />
            </Form.Item>

            <Form.Item
              name="addressLine2"
              label="Address Line 2"
              rules={[
                {
                  required: false,
                  message: "Enter address",
                },
                { validator: validateTextInput("Address Line 2", 10, 100) },
              ]}
            >
              <Input
                maxLength={100}
                size="medium"
                type="text"
                placeholder="Enter address"
              />
            </Form.Item>

            <Form.Item
              name="country"
              label="Country"
              rules={[
                {
                  required: true,
                  message: "Select country",
                },
              ]}
            >
              <CountrySelect
                onSelect={handleCountrySelect}
                currentStatus={editAction}
                form={form}
              />
            </Form.Item>

            <Form.Item
              name="state"
              label="State"
              rules={[
                {
                  required: true,
                  message:
                    stateDropDownData.isStateExists === true
                      ? "Select state"
                      : "Enter state",
                },
              ]}
            >
              <StateSelect
                options={
                  stateDropDownData
                    ? stateDropDownData?.stateResponse?.map((item) => ({
                        label: item.name,
                        value: item.name,
                      }))
                    : []
                }
                isStateExists={stateDropDownData.isStateExists}
              />
            </Form.Item>

            <Form.Item
              name="addressCity"
              label="City"
              rules={[
                {
                  required: true,
                  message: "Enter city",
                },
                { validator: validateTextInput("City", 4) },
              ]}
              dependencies={["companyState", stateId]}
            >
              <CitySearch
                stateID={stateId}
                value={selectCity}
                onChange={setSelectCity}
                cityData={cityData}
                setCityData={setCityData}
              />
            </Form.Item>

            <Form.Item
              name="postCode"
              label="Post Code"
              rules={[
                {
                  required: false,
                  message: "Enter post code",
                },
                {
                  pattern: /^[0-9]*$/,
                  message: "Please enter a valid post code",
                },
              ]}
            >
              <Input
                type="number"
                size="medium"
                placeholder="Enter post code"
              />
            </Form.Item>

            <Form.Item
              name="categoryID"
              label="Category"
              rules={[
                {
                  required: true,
                  message: "Select category",
                },
              ]}
            >
              <Select
                size="medium"
                placeholder="Select category"
                options={categoryList}
              />
            </Form.Item>

            {editAction == "edit" ? (
              ""
            ) : (
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: "Enter first name",
                  },
                  { validator: validateTextInput("First name") },
                ]}
              >
                <Input
                  value={""}
                  size="medium"
                  type="name"
                  placeholder="Enter first name"
                />
              </Form.Item>
            )}
            {editAction == "edit" ? (
              ""
            ) : (
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  {
                    required: false,
                    message: "Enter last name",
                  },
                  { validator: validateTextInput("Last name") },
                ]}
              >
                <Input
                  value={""}
                  size="medium"
                  type="name"
                  placeholder="Enter last name"
                />
              </Form.Item>
            )}
            {editAction == "edit" ? (
              ""
            ) : (
              <Form.Item
                name="jobTitle"
                label="Job Title"
                rules={[
                  {
                    required: false,
                    message: "Enter job title",
                  },
                  { validator: validateTextInput("Job title") },
                ]}
              >
                <Input
                  type="text"
                  value={""}
                  size="medium"
                  placeholder="Enter job title"
                />
              </Form.Item>
            )}

            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Enter email",
                },
                {
                  pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Invalid email address",
                },
              ]}
            >
              <Input type="email" size="medium" placeholder="Enter email" />
            </Form.Item>

            <Form.Item
              name="mobile"
              label="Phone Number"
              rules={[
                {
                  required: true,
                  message: "Enter phone number",
                },
                {
                  pattern: /^[0-9]*$/,
                  message: "Please enter a valid phone number",
                },
              ]}
            >
              <Input
                type="number"
                size="medium"
                minLength={10}
                maxLength={10}
                placeholder="Enter phone number"
              />
            </Form.Item>

            <Form.Item
              name="fax"
              label="Fax"
              rules={[
                {
                  required: false,
                  message: "Enter fax",
                },
              ]}
            >
              <Input type="number" size="medium" placeholder="Enter fax" />
            </Form.Item>

            {editAction !== "edit" && (
              <Form.Item
                className="mt-4"
                initialValue={true}
                valuePropName="checked"
                name="makePrimaryContact"
              >
                <Checkbox defaultChecked>
                  Do you want to make this a primary contact
                </Checkbox>
              </Form.Item>
            )}

            <div className="flex justify-end gap-2 mt-8">
              <CustomButton
                type="primary"
                htmlType="submit"
                icon={<CheckOutlined />}
                loading={IsLoading}
              >
                Submit
              </CustomButton>
              <CustomButton
                type="cancel"
                onClick={onClose}
                icon={<CloseOutlined />}
              >
                Cancel
              </CustomButton>
            </div>
          </Form>
        </div>
      </div>
    </Drawer>
  );
};

export default ManageCustomerAccount;
