import { EditOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../Common/CustomBreadcrumb";
import CustomButton from "../../Common/CustomButton";
import CustomSwitch from "../../Common/CustomSwitch";
import { useDarkMode } from "../../Providers/DarkModeContext";
import ChecklistService from "../../services/Checklist/ChecklistService";
import { getDarkModeColor } from "../../utils/darkModeHelper";
import { formatDate } from "../../utils/dateHelper";
import OrderChecklistDrawer from "../OrderManagement/OrderChecklistDrawer";
import ManageOptionsDrawer from "./ManageOptionDrawer";

const items = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Checklist Configuration",
    path: "/settings/checklist/configuration",
  },
  {
    title: "Options",
  },
];

const ChecklistOptionsPage = () => {
  const { isDarkMode } = useDarkMode();
  const navigate = useNavigate();
  const location = useLocation();

  const checklistData = location?.state?.checklistData;

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState("");

  const [manageDrawer, setManageDrawer] = useState({
    openType: null,
    initalValue: null,
  });

  const fetchList = async () => {
    if (!checklistData?.id) {
      navigate(-1);
      return;
    }

    setLoading("list-fetch");

    try {
      const { data } = await ChecklistService.getOptionsList(checklistData.id);

      setList(data);
    } catch (error) {
      console.log(error);
      message.error(
        error?.response?.data?.message ||
          "Unable to fetch options right now. Try again later!"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchList();
  }, []);

  const handleStatusChange = async (record, updatedStatus) => {
    setLoading(`status-update`);

    try {
      await ChecklistService.updateChecklistOption({
        ...record,
        isActive: updatedStatus,
      });

      fetchList();
    } catch (error) {
      console.log(error);
      message.error(
        error?.response?.data?.message ||
          "Unable to update status right now. Try again later!"
      );
    } finally {
      setLoading("");
    }
  };

  const columns = [
    {
      title: "Question",
      dataIndex: "description",
      key: "description",
      width: 500,
    },
    {
      title: "Answer type",
      dataIndex: "checkListType",
      key: "checkListType",
    },
    {
      title: "Added on",
      dataIndex: "dateAdded",
      key: "dateAdded",
      render: (text) => formatDate(text),
    },
    {
      title: "Added by",
      dataIndex: "addedBy",
      key: "addedBy",
    },
    {
      title: "Modified on",
      dataIndex: "modifiedDate",
      key: "modifiedDate",
      render: (text) => formatDate(text),
    },
    {
      title: "Modified by",
      dataIndex: "modifiedBy",
      key: "modifiedBy",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      align: "center",
      width: 100,
      render: (text, record) => (
        <CustomSwitch
          active={text}
          onChange={() => handleStatusChange(record, !text)}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 100,
      render: (_, record, index) => {
        return (
          <div className="flex justify-center gap-2">
            <CustomButton
              onClick={() => {
                setManageDrawer({
                  openType: "edit",
                  initalValue: record,
                });
              }}
              icon={<EditOutlined />}
              type="primary"
            >
              Edit
            </CustomButton>
          </div>
        );
      },
    },
  ];

  return (
    <div className="p-2 my-4">
      <div className="flex items-start justify-between w-full mb-2">
        <CustomBreadcrumb items={items} />

        <div className="flex gap-2">
          <CustomButton
            onClick={() => {
              setManageDrawer({
                openType: "preview",
                initalValue: {
                  ...checklistData,
                  checklistID: checklistData?.id,
                },
              });
            }}
            icon={<EyeOutlined />}
          >
            Preview
          </CustomButton>

          <CustomButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setManageDrawer({
                openType: "add",
                initalValue: {
                  checkListID: checklistData?.id,
                  ...checklistData,
                },
              });
            }}
          >
            Add
          </CustomButton>
        </div>
      </div>

      <Table
        bordered={"1px solid black"}
        loading={loading === "list-fetch"}
        dataSource={list}
        columns={columns}
        tableLayout="fixed"
        scroll={{ x: 1200 }}
        rowClassName={(record, index) =>
          index % 2 !== 0 ? getDarkModeColor(isDarkMode, "bg-gray-100") : ""
        }
        pagination={false}
      />

      <ManageOptionsDrawer
        open={
          manageDrawer.openType === "add" || manageDrawer.openType === "edit"
        }
        openType={manageDrawer.openType}
        initalData={manageDrawer.initalValue}
        onClose={() =>
          setManageDrawer({
            openType: null,
            initalValue: null,
          })
        }
        refetch={() => {
          fetchList();
        }}
      />

      <OrderChecklistDrawer
        isDrawerOpen={manageDrawer.openType === "preview"}
        orderDetails={manageDrawer.initalValue}
        onClose={() =>
          setManageDrawer({
            openType: null,
            initalValue: null,
          })
        }
        type="view"
      />
    </div>
  );
};

export default ChecklistOptionsPage;
