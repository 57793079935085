import React, { memo } from "react";
import { Pie } from "@ant-design/plots";
import { Empty } from "antd";
import ChartLoading from "../../Common/ChartLoading";
import { useDarkMode } from "../../Providers/DarkModeContext";
import { useScreenSize } from "../../Providers/ScreenSizeProvider";

const CancelledReasonChart = memo(({ data, height, loading }) => {
  const { isDarkMode } = useDarkMode();

  if (loading) {
    return <ChartLoading height={height} />;
  }

  if (!data || data.length === 0) {
    return (
      <div
        className={`flex items-center justify-center w-full font-semibold`}
        style={{ height: height }}
      >
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No data found"
        />
      </div>
    );
  }

  const total = data.reduce((acc, curr) => acc + curr.count, 0);

  const config = {
    data: data.map((i) => ({ ...i, equal: 1 })).filter((i) => i.value !== null),
    appendPadding: 40,
    angleField: "equal",
    colorField: "name",
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: "inner",
      offset: "-50%",
      formatter: (data) => data?.count,
      style: {
        textAlign: "center",
        fontSize: 14,
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: {
        offsetY: -4,
        style: {
          color: isDarkMode && "white",
        },
        customHtml: () => "TOTAL",
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: "32px",
          color: isDarkMode && "white",
        },
        customHtml: () => {
          return total;
        },
      },
    },
    tooltip: {
      showTitle: false,
      formatter: (d) => {
        const obj = data
          .filter((i) => i.value !== null)
          .find((i) => i.name === d.name);

        return {
          name: obj.name,
          value: obj.count,
        };
      },
    },
    legend:false
  };
  return <Pie {...config} />;
});

export default CancelledReasonChart;
