import { Bar } from "@ant-design/charts";
import { Empty, Slider } from "antd";
import React, { memo, useState } from "react";
import ChartLoading from "../../Common/ChartLoading";

const ProductionLinePerformanceChart = memo(
  ({ width, height, data, loading }) => {
    const [barCount, setBarCount] = useState(7);
    const transformedData = [];

    if (loading) {
      return <ChartLoading height={height} />;
    }

    if (!data || data.length === 0) {
      return (
        <div
          className={`flex items-center justify-center w-full font-semibold`}
          style={{ height: height }}
        >
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No data found"
          />
        </div>
      );
    }

    data?.forEach((item) => {
      let completedPercentage = 0;
      let remainingPercentage = 0;

      if (item.value.completed === 0) {
        completedPercentage = 0;
      } else {
        completedPercentage = Math.floor(
          (item.value.completed / (item.value.target + item.value.completed)) *
            100
        );
      }

      if (item.value.target === 0) {
        remainingPercentage = 0;
      } else {
        remainingPercentage = 100 - completedPercentage;
      }

      transformedData.push({
        name: item.name,
        value: completedPercentage,
        type: "Completed",
        currValue: item.value.completed,
        originalValue: completedPercentage,
      });

      transformedData.push({
        name: item.name,
        value: remainingPercentage,
        type: "Remaining",
        currValue: item.value.target,
        originalValue: remainingPercentage,
      });
    });

    const filteredData = transformedData
      .sort((a, b) => b.value - a.value)
      .slice(0, barCount)
      .flatMap((item) => [
        transformedData.find(
          (d) => d.name === item.name && d.type === "Completed"
        ),
        transformedData.find(
          (d) => d.name === item.name && d.type === "Remaining"
        ),
      ])
      .filter(Boolean);

    const config = {
      data: filteredData,
      isStack: true,
      isPercent: true,
      xField: "value",
      yField: "name",
      seriesField: "type",
      legend: {
        position: "top-left",
      },
      xAxis: false,
      yAxis: {
        label: {
          autoRotate: false,
          formatter: (label) =>
            label.length > 20 ? `${label.slice(0, 20)}...` : label,
        },
      },
      color: ["#6adcaf", "#6395fa"],
      label: {
        position: "middle",
        content: (item) => {
          if (item.originalValue !== 0) {
            return `${item.originalValue}%`;
          }
        },
      },
    };

    const sliderMaxLength = Math.floor(transformedData.length / 2);

    return (
      <div>
        {sliderMaxLength > 7 && (
          <Slider
            min={1}
            max={sliderMaxLength}
            defaultValue={7}
            onChange={setBarCount}
            className="w-[10rem] absolute top-12 right-3 z-10"
          />
        )}
        <Bar {...config} className="select-none" loading={loading} />
      </div>
    );
  }
);

export default ProductionLinePerformanceChart;
