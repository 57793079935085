import React, { useEffect, useState } from "react";
import { Select, message } from "antd";
import ManageUserService from "../services/User/ManageUserService";

const UserCategorySearch = ({
  setCategoryData,
  categoryData,
  value,
  onChange,
}) => {
  useEffect(() => {
    ManageUserService.getUserServiceCategory()
      .then((res) => {
        setCategoryData(
          res.data.map((item) => ({
            value: item.name,
            label: item.description.toUpperCase(),
          }))
        );
      })
      .catch((error) => {
        error?.response?.data?.message
          ? message.error(error?.response?.data?.message)
          : message.error("Something went Wrong");
      });
  }, []);

  return (
    <Select
      placeholder="Search category"
      size="medium"
      mode="single"
      allowClear="true"
      options={categoryData}
      value={value}
      onChange={onChange}
    ></Select>
  );
};

export default UserCategorySearch;
