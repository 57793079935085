import React, { useEffect, useState } from "react";
import { Drawer, Popconfirm, Table, Tag, message } from "antd";
import ProductionServices from "../../services/Production/ProductionServices";
import { formatDateMonth } from "../../utils/dateHelper";
import CustomButton from "../../Common/CustomButton";

const ManageOnHold = ({
  onClose,
  reload,
  isOpenOnHoldDrawer,
  setReload,
  schedulerDataFetch,
  selectedProductGroup,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [onHoldList, setOnHoldList] = useState([]);

  const fetchOnHoldList = async () => {
    setIsLoading(true);

    try {
      const res = await ProductionServices.getOrderOnHoldList();
      setOnHoldList(res.data);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Something went Wrong";
      message.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpenOnHoldDrawer) {
      fetchOnHoldList();
    }
  }, [isOpenOnHoldDrawer]);

  const columns = [
    {
      title: "Reference Number",
      dataIndex: "quoteRefNum",
      key: "quoteRefNum",
      render: (values, records) => {
        return (
          <>
            <Tag color={"#0275d8"} type="primary">
              {values ? values : "NA"}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Job Number",
      dataIndex: "jobRefNum",
      key: "jobRefNum",
      render: (values, records) => {
        return (
          <>
            <Tag color={"#5bc0de"} type="primary">
              {values ? values : "NA"}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Order Date",
      dataIndex: "ordDate",
      key: "ordDate",
      render: (_, record) => {
        return record.ordDate ? (
          <p> {formatDateMonth(record.ordDate)}</p>
        ) : (
          "NA"
        );
      },
    },
    {
      title: "Required Date",
      dataIndex: "required",
      key: "required",
      render: (_, record) => {
        return record.required ? (
          <p> {formatDateMonth(record.required)}</p>
        ) : (
          "NA"
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      key: "action",
      render: (_, record) => {
        return (
          <>
            <Popconfirm
              className="flex flex-end"
              title={
                <p className="mb-3">
                  Are you sure you want to reschedule the Reference Number
                  <span className="font-bold ps-1">{record.quoteRefNum}</span>?
                </p>
              }
              onConfirm={() => onHoldFunction(record, "reschedule")}
              okText="Yes"
              cancelText="No"
            >
              <CustomButton type="primary" size="small">
                Reschedule
              </CustomButton>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const onHoldFunction = (record, type) => {
    ProductionServices.putOrderOnhold(record?.primaryIdentifier)
      .then((res) => {
        //  setLoadingHold(false);
        fetchOnHoldList();

        if (type === "hold") {
          message.success(
            `Reference Number ${record.quoteRefNum} is kept on Hold!`
          );
        } else {
          message.success(
            `Reference Number ${record.quoteRefNum} is rescheduled!`
          );
        }
        ProductionServices.scheduleAfterOnHold().then(() => {
          schedulerDataFetch();
        })
        setReload(!reload);
      })
      .catch((error) => {
        error?.response?.data?.message
          ? message.error(error?.response?.data?.message)
          : message.error("Something went wrong");
        //setLoadingHold(false);
      });
  };

  return (
    <Drawer
      title="On Hold List"
      width={650}
      onClose={onClose}
      open={isOpenOnHoldDrawer}
      bodyStyle={{
        padding: "16px",
      }}
      destroyOnClose={true}
    >
      <Table
        dataSource={onHoldList}
        columns={columns}
        pagination={true}
        loading={isLoading}
      />
    </Drawer>
  );
};
export default ManageOnHold;
