import React from "react";

const LabelWithInfo = ({ label, component }) => {
  return (
    <div className="flex justify-between w-full -mr-3">
      {label}&nbsp;
      {component}
    </div>
  );
};

export default LabelWithInfo;
