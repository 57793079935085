import React, { useState, useEffect } from "react";
import { Input, Select, message } from "antd";
import CustomerService from "../services/CustomerService/CustomerService";

const CitySearch = ({
  stateID,
  value,
  onChange,
  cityData,
  setCityData,
  ...other
}) => {
  const getCitySearchData = () => {
    CustomerService.getCityList(stateID)
      .then((res) => {
        setCityData(
          res.data.map((item) => ({
            value: item.name,
            label: item.name,
          }))
        );
      })
      .catch((error) => {
        error?.response?.data?.message
          ? message.error(error?.response?.data?.message)
          : message.error("Something went Wrong");
      });
  };

  useEffect(() => {
    if (stateID) {
      getCitySearchData(stateID);
    } else {
      getCitySearchData();
    }
  }, [stateID]);

  return (
    // <Select
    //   size="medium"
    //   placeholder="Select city"
    //   mode="single"
    //   allowClear="true"
    //   options={cityData}
    //   value={value}
    //   onChange={onChange}
    // ></Select>

    <Input
      size="medium"
      placeholder="Enter city"
      onChange={onChange}
      value={value}
    />
  );
};

export default CitySearch;
