import { Col, Form, Input, Row } from "antd";
import React from "react";

const AjCustomerAndChassisNo = ({ editData, previewOrder }) => {
  return (
    <Row gutter={[16, 16]} className="mb-1">
      <Col xs={2} sm={4} md={8} lg={8} xl={8}>
        <Form.Item
          name="endCustomerName"
          label="End Customer's Name"
          rules={[
            {
              required: false,
              message: "Enter customer name",
            },
          ]}
        >
          <Input
            placeholder="Enter customer name"
            size="medium"
            disabled={previewOrder === "preview" && !editData}
          />
        </Form.Item>
      </Col>

      <Col xs={2} sm={4} md={8} lg={8} xl={8}>
        <Form.Item
          name="vehicleChasisNo"
          label="Vehicle Chassis No"
          rules={[
            {
              required: false,
              message: "Enter vehicle chassis number",
            },
          ]}
        >
          <Input
            placeholder="Enter chassis number"
            size="medium"
            disabled={previewOrder === "preview" && !editData}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default AjCustomerAndChassisNo;
