import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Table, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../Providers/AuthProvider";
import ManageOrganizationService from "../../services/Organization/ManageOrganizationService";
import ManageOrganizationContact from "./ManageOrganizationContact";
import CustomButton from "../../Common/CustomButton";
import { useDarkMode } from "../../Providers/DarkModeContext";
import { getDarkModeColor } from "../../utils/darkModeHelper";
import { useScreenSize } from "../../Providers/ScreenSizeProvider";

const OrganizationContact = ({ primaryIdentifier }) => {
  const { isDarkMode } = useDarkMode();
  const currentScreenSize = useScreenSize();

  const { userDetails } = useContext(AuthContext);
  const { isExternalUser } = userDetails;
  const [isTableDataLoading, setIsTableDataLoading] = useState(false);
  const [changeStatus, setChangeStatus] = useState("");
  const [editContactData, setEditContactData] = useState(null);
  const [contactList, setContactList] = useState([]);

  let { userPrimaryRole } = useContext(AuthContext);
  let isAdmin = userPrimaryRole?.toLowerCase()?.includes("admin");
  const [accountId, setAccountId] = useState("");
  const handleMultipleContacts = () => {
    setChangeStatus("add");
    setEditContactData(null);
  };

  const fetchData = (primaryIdentifier) => {
    setIsTableDataLoading(true);
    ManageOrganizationService.getOrganizationContactList(primaryIdentifier)
      .then((res) => {
        setAccountId(res.data[0].consumerID);
        setContactList(res.data);
        setIsTableDataLoading(false);
      })
      .catch((error) => {
        error?.response?.data?.message
          ? message.error(error?.response?.data?.message)
          : message.error("Something went Wrong");
        setIsTableDataLoading(false);
      });
  };

  useEffect(() => {
    if (primaryIdentifier) {
      fetchData(primaryIdentifier);
    }
  }, [primaryIdentifier]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      align: "center",
    },
    {
      title: "Phone No.",
      dataIndex: "phone",
      align: "center",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Fax Number",
      dataIndex: "fax",
      align: "center",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Action",
      align: "center",
      dataIndex: "action",
      render: (_, record) => {
        return (
          isExternalUser === false && (
            <CustomButton
              type="primary"
              icon={<EditOutlined />}
              onClick={() => {
                setEditContactData(record);
                setChangeStatus("edit");
              }}
            >
              Edit
            </CustomButton>
          )
        );
      },
    },
  ];

  return (
    <div className="relative">
      {contactList && (
        <div>
          <div className="flex justify-end mb-2">
            {isAdmin && isExternalUser === false && (
              <CustomButton
                type="primary"
                className="absolute -top-12"
                icon={<PlusOutlined />}
                onClick={handleMultipleContacts}
              >
                Add
              </CustomButton>
            )}
          </div>
          <Table
            scroll={{
              x: currentScreenSize < 1024 && 1000,
            }}
            tableLayout="fixed"
            loading={isTableDataLoading}
            columns={columns}
            dataSource={contactList}
            rowClassName={(record, index) =>
              index % 2 !== 0 ? getDarkModeColor(isDarkMode, "bg-gray-100") : ""
            }
          />
        </div>
      )}
      <ManageOrganizationContact
        accountId={accountId}
        initialValue={editContactData}
        onClose={() => {
          setChangeStatus(null);
          setEditContactData(null);
        }}
        changeStatus={changeStatus}
        onSuccess={() => {
          if (primaryIdentifier) {
            fetchData(primaryIdentifier);
          }
        }}
      />
    </div>
  );
};

export default OrganizationContact;
