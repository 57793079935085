import { Button, Result } from "antd";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Providers/AuthProvider";

const PageNotFound = () => {
  const { userDetails } = useContext(AuthContext);

  const navigate = useNavigate();

  const mainPage =
    userDetails?.menu?.items[0]?.children?.length > 0
      ? userDetails?.menu?.items[0]?.children?.key
      : userDetails?.menu?.items[0]?.key;

  return (
    <div className="flex items-center justify-center h-screen-190px">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          mainPage && (
            <Button type="primary" onClick={() => navigate(mainPage)}>
              Back to home
            </Button>
          )
        }
      />
    </div>
  );
};

export default PageNotFound;
