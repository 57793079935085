import {
  CheckOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  FileDoneOutlined,
} from "@ant-design/icons";
import { Button, Drawer, Empty, Form, Input, Select, Tag, Tooltip, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import RoleSearch from "../../Common/RoleSearch";
import UserCategorySearch from "../../Common/UserCategorySearch";
import { AuthContext } from "../../Providers/AuthProvider";
import CustomerService from "../../services/CustomerService/CustomerService";
import ManageUserService from "../../services/User/ManageUserService";
import CustomButton from "../../Common/CustomButton";

const CustomerManage = ({
  initialValue,
  changeStatus,
  onClose,
  onSuccess,
  updatePassword,
  setUpdatePassword,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [selectCategory, setSelectedCategory] = useState("");
  const [searchAccountDetailsOptions, setSearchAccountDetailsOptions] =
    useState([]);
  const { refetchData } = useContext(AuthContext);
  const [customerList, setCustomerList] = useState([]);
  const [accountSearchTimeout, setAccountSearchTimeout] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [rolesWithAcc, setRolesWithAcc] = useState([])


  useEffect(() => {
    if (changeStatus === "edit") {
      form.setFieldsValue({
        ...initialValue,
        roles: initialValue.roles.filter((item) => item.accountID == null).map((item) => item.roleID),
      });

      const rolesWithAccount = initialValue.roles.filter(
        (item) => item.accountID !== null
      );

      const formattedRoles = rolesWithAccount.map((role) => ({
        roleName: role.roleName,
        roleID: role.roleID,
        accountID: role.accountID,
      }));

      setRolesWithAcc(formattedRoles)

    } else {
      form.resetFields();
    }
    fetchAccountList()
    return () => form.setFieldsValue(null);
  }, [initialValue, changeStatus]);

  const onFinish = (values) => {
    setIsLoading(true);

    if (changeStatus == "edit") {
      const payload = {
        ...initialValue,
        ...values,
        roles: [...values.roles.map((item) => ({ roleID: item })), ...rolesWithAcc.map((item) => ({ roleID: item.roleID, accountID: item.accountID }))],
      };
      if (payload.roles.length<1) {
        setIsLoading(false)
        message.error("role cannot be empty")
        return
      }
      ManageUserService.updateUserRoleData(payload)
        .then((res) => {
          setIsLoading(false);
          message.success("Customer Data Updated successfully");
          onSuccess();
          form.resetFields();
          onClose();
          refetchData();
        })
        .catch((error) => {
          setIsLoading(false);

          error?.response?.data?.message
            ? message.error(error?.response?.data?.message)
            : message.error("Something went Wrong");
        });
    } else if (changeStatus === "add") {
      const payload = {
        ...values,
        roles: [...values.roles.map((item) => ({ roleID: item })), ...rolesWithAcc.map((item) => ({ roleID: item.roleID, accountID: item.accountID }))],
      };
      if (payload.roles.length<1) {
        setIsLoading(false)
        message.error("role cannot be empty")
        return
      }
      ManageUserService.saveUserServicesData(payload)
        .then((res) => {
          setIsLoading(false);
          message.success("Customer Added Successfully");
          onSuccess();
          form.resetFields();
          onClose();
        })
        .catch((error) => {
          setIsLoading(false);
          error?.response?.data?.message
            ? message.error(error?.response?.data?.message)
            : message.error("Something went Wrong");
        });
    }
  };

  useEffect(() => {
    if (!!changeStatus) {
      ManageUserService.getUserServiceCategory()
        .then((res) => {
          setCategoryData(res.data);
        })
        .catch((error) => {
          error?.response?.data?.message
            ? message.error(error?.response?.data?.message)
            : message.error("Something went wrong");
        });
    }
  }, [!!changeStatus]);

  const items = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "User",
      path: "/user",
    },
    {
      title:
        changeStatus === "add"
          ? "Add User"
          : changeStatus === "edit"
            ? "Edit User"
            : "",
    },
  ];

  useEffect(() => {
    if (!!changeStatus) {
      const payload = {
        accountId: "",
      };
      CustomerService.getCustomerDataDropDownList(payload)
        .then((res) => {
          setSearchAccountDetailsOptions(
            res.data.map((item) => ({
              value: item.accountID,
              label: `${item.accountID} | ${item.accountName}`,
            }))
          );
        })
        .catch((error) => {
          error?.response?.data?.message
            ? message.error(error?.response?.data?.message)
            : message.error("Something went wrong");
        });
    }
  }, []);

  const fetchAccountList = async () => {
    try {
      const res = await CustomerService.getAccountList();

      const filteredlist = res?.data?.map((i) => ({
        label: `${i.accountID} | ${i.accountName}`,
        value: i.accountID,
        key: i.primaryIdentifier,
      }));

      setCustomerList(filteredlist);
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message || "Something went wrong!");
    }
  };
  const handleSearchAccount = async (value) => {
    const debounce = (func, delay) => {
      return function (...args) {
        clearTimeout(accountSearchTimeout);

        let newTimeoutId = setTimeout(() => func.apply(this, args), delay);

        setAccountSearchTimeout(newTimeoutId);
      };
    };

    const debouncedSearch = debounce(async (accountId) => {
      try {
        const res = await CustomerService.getCustomerDataDropDownList({
          accountId: accountId,
        });

        const filteredReq = res?.data?.map((i) => ({
          label: `${i.accountID} | ${i.accountName}`,
          value: `${i.accountID} | ${i.accountName}`,
          key: i.primaryIdentifier,
          accountID: i.accountID,
        }));

        setCustomerList(filteredReq);
      } catch (error) {
        console.log(error);
        message.error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : "Something went wrong!"
        );
      }
    }, 500);

    debouncedSearch(value);
  };


  const handleCompanySpecificRoleSubmit = async () => {
    try {
      const values = await form.validateFields(["roles_acc", "accountID"]);
      const { roles_acc, accountID } = values;


      const formattedRoles = roles_acc.map(role => ({
        roleName: role.label,
        roleID: role.id,
        accountID: accountID
      }));

      setRolesWithAcc(prevRoles => [...prevRoles, ...formattedRoles]);

      if (drawerVisible) {
        form.resetFields(["roles_acc", "accountID"])
        setDrawerVisible(!drawerVisible)
      }

    } catch (error) {
      console.log("Validation Failed:", error);
    }
  };

  return (
    <Drawer
      title={
        changeStatus === "add"
          ? "Add Customer"
          : changeStatus === "edit"
            ? "Edit Customer"
            : ""
      }
      width={400}
      destroyOnClose={true}
      onClose={onClose}
      open={!!changeStatus}
      bodyStyle={{
        padding: "4px 16px",
      }}
    >
      <div className="rounded-md border-black-600 ">
        <div className="mt-3">
          <Form
            form={form}
            name="form_item_path"
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: "Enter first name",
                },
              ]}
            >
              <Input size="medium" placeholder="Enter first name" />
            </Form.Item>

            <Form.Item name="lastName" label="Last Name">
              <Input size="medium" placeholder="Enter last name" />
            </Form.Item>

            <Form.Item
              name="emailAddress"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Enter email",
                },
              ]}
            >
              <Input size="medium" placeholder="Enter email" />
            </Form.Item>

            <Form.Item
              name="userCategory"
              label="Category"
              rules={[
                {
                  required: true,
                  message: "Select category",
                },
              ]}
            >
              <UserCategorySearch
                value={selectCategory}
                onChange={setSelectedCategory}
                categoryData={categoryData}
                setCategoryData={setCategoryData}
              />
            </Form.Item>

            <Form.Item
              name="roles"
              label="Role"
              rules={[
                {
                  required: false,
                  message: "Select role",
                },
              ]}
            >
              <RoleSearch />
            </Form.Item>

            <Form.Item
              label="Roles with Specific Account"
              tooltip="These are roles associated with specific accounts"
            >
              <Select
                size="medium"
                mode="multiple"
                value={rolesWithAcc.map(
                  (role) => `${role.roleName} - ${role.accountID}`
                )}
                onDeselect={(value) => {
                  const [roleName, accountID] = value.split(" - ");
                  const updatedRoles = rolesWithAcc.filter(
                    (role) => !(role.roleName === roleName && role.accountID === accountID)
                  );
                  setRolesWithAcc(updatedRoles);
                }}
                tagRender={(props) => {
                  const { value, closable, onClose } = props;
                  const [roleName, accountID] = value.split(" - ");
                  return (
                    <Tag
                      closable={closable}
                      onClose={onClose}
                      style={{ marginRight: 3 }}
                    >
                      {`${roleName} - ${accountID}`}
                    </Tag>
                  );
                }}
                placeholder="No specific roles assigned yet"
                open={false}
                disabled={rolesWithAcc.length === 0}
              />

            </Form.Item>

            <Button type="dashed" size="medium" className="w-full my-2" onClick={() => setDrawerVisible(true)}>
              Add company specific role
            </Button>

            <Drawer
              title="Select Role with Company"
              width={400}
              onClose={() => setDrawerVisible(!drawerVisible)}
              open={drawerVisible}
              destroyOnClose={true}
              bodyStyle={{
                padding: "4px 16px",
              }}
            >
              <div className="rounded-md border-black-600">
                <Form.Item
                  name="roles_acc"
                  label="Role"
                  rules={[
                    {
                      required: true,
                      message: "Select role",
                    },
                  ]}
                >
                  <CustomRoleSearch />
                </Form.Item>

                <Form.Item
                  name="accountID"
                  label="Account"
                  rules={[
                    {
                      required: true,
                      message: "Select customer account!",
                    }
                  ]}
                >
                  <Select
                    showSearch
                    size="medium"
                    placeholder="Select an account"
                    className="!w-full"
                    options={customerList}
                    onSearch={handleSearchAccount}
                  />
                </Form.Item>
                <div className="flex justify-end gap-2 mt-5">
                  <Button
                    htmlType="button"
                    type="primary"
                    onClick={handleCompanySpecificRoleSubmit}
                    loading={isLoading}
                    icon={<CheckOutlined />}
                  >
                    Submit
                  </Button>
                  <Button
                    type="default"
                    onClick={() => setDrawerVisible(!drawerVisible)}
                    icon={<CloseOutlined />}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Drawer>

            {changeStatus === "edit" && (
              <Tooltip
                title={
                  updatePassword
                    ? "Do you want to cancel the password update"
                    : "Would you like to update your password"
                }
              >
                <CustomButton
                  className="m-1"
                  onClick={() => setUpdatePassword(!updatePassword)}
                >
                  {updatePassword ? "Cancel" : "Update Password"}
                </CustomButton>
              </Tooltip>
            )}

            {changeStatus === "add" && (
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: changeStatus == "edit" ? false : true,
                    message: changeStatus == "add" ? "Enter password" : "",
                  },
                  {
                    validator: (_, value) => {
                      const containsNumeric = /[0-9]/.test(value);
                      const containsAlphabetic = /[a-zA-Z]/.test(value);

                      if (containsNumeric && containsAlphabetic) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        "Password must contain both alphabetic and numeric characters"
                      );
                    },
                  },
                ]}
              >
                <Input.Password
                  size="medium"
                  minLength={8}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  type="password"
                  placeholder="Enter password"
                />
              </Form.Item>
            )}
            {changeStatus === "edit" && updatePassword && (
              <Form.Item
                name="password"
                label="New Password"
                rules={[
                  {
                    required: true,
                    message: "Enter new password",
                  },
                  {
                    validator: (_, value) => {
                      const containsNumeric = /[0-9]/.test(value);
                      const containsAlphabetic = /[a-zA-Z]/.test(value);

                      if (containsNumeric && containsAlphabetic) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        "Password must contain both alphabetic and numeric characters"
                      );
                    },
                  },
                ]}
              >
                <Input.Password
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  size="medium"
                  placeholder="Enter new password"
                  minLength={8}
                  type="password"
                />
              </Form.Item>
            )}

            <div className="flex justify-end gap-2 mt-5">
              <CustomButton
                type="primary"
                htmlType="submit"
                loading={isLoading}
                icon={<CheckOutlined />}
              >
                Submit
              </CustomButton>
              <CustomButton
                type="cancel"
                onClick={onClose}
                icon={<CloseOutlined />}
              >
                Cancel
              </CustomButton>
            </div>
          </Form>
        </div>
      </div>
    </Drawer>
  );
};

export default CustomerManage;

const CustomRoleSearch = ({ value, onChange }) => {
  const [roleData, setRoleData] = useState([]);

  useEffect(() => {
    ManageUserService.getUserRoleData()
      .then((res) => {
        setRoleData(
          res.data.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        );
      })
      .catch((error) => {
        error?.response?.data?.message
          ? message.error(error?.response?.data?.message)
          : message.error("Something went Wrong");
      });
  }, []);

  const filterOption = (input, option) =>
    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const handleChange = (selectedValues) => {
    const selectedRoles = selectedValues.map((value) => {
      const selectedRole = roleData.find((role) => role.value === value);
      return selectedRole ? { id: selectedRole.value, label: selectedRole.label } : null;
    });
    onChange(selectedRoles);
  };

  return (
    <Select
      size="medium"
      placeholder="Select role"
      mode="multiple"
      showSearch
      className="!w-full"
      allowClear
      options={roleData}
      value={value ? value.map((item) => item.id) : []}
      onChange={handleChange}
      filterOption={filterOption}
    />
  );
};