import {
  CheckOutlined,
  CloseOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { Drawer, Form, Radio, message } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import ViewInfoButton from "../../Common/ViewInfoButton";
import ProductionServices from "../../services/Production/ProductionServices";
import CustomButton from "../../Common/CustomButton";

const ManageReSchedule = ({
  onClose,
  changeStatus,
  productGroupOptionValue,
  onSuccess,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = useForm();

  const onFinish = async (values) => {
    if (values.range === undefined) {
      return message.error("Please select the days!");
    }

    const payload = {
      isRebuild: !!values.includeToday,
      scheduleDays: values.range,
      productGroupID: productGroupOptionValue,
    };

    setIsLoading(true);
    try {
      await ProductionServices.reScheduleProductionLine(
        payload.isRebuild,
        payload.scheduleDays,
        payload.productGroupID
      );
      onSuccess();
      message.success("Reschedule Successfully");
      onClose();
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    form.resetFields();
    return () => form.setFieldsValue(null);
  }, [changeStatus]);

  return (
    <Drawer
      title={changeStatus === "Reschedule" ? "Reschedule" : ""}
      width={300}
      onClose={onClose}
      open={!!changeStatus}
      bodyStyle={{ padding: "4px 16px" }}
      destroyOnClose={true}
    >
      <Form
        form={form}
        name="form_item_path"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ range: 7 }}
      >
        <Form.Item className="mt-3" valuePropName="checked" name="includeToday">
          <Radio value={true}>Include Today</Radio>
        </Form.Item>
        <Form.Item
          label={
            <span>
              Scheduling Range&nbsp;
              <ViewInfoButton
                placement="left"
                title="Select Scheduling Order Range"
              />
            </span>
          }
          name="range"
        >
          <Radio.Group name="range" className="flex flex-col gap-3">
            {[7, 14, 21, 30].map((days) => (
              <Radio key={days} value={days}>
                {`${days} Days`}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <div className="flex justify-end gap-2 mt-5">
          <CustomButton
            type="primary"
            htmlType="submit"
            size="small"
            loading={isLoading}
            icon={<CheckOutlined />}
          >
            Submit
          </CustomButton>
          <CustomButton
            type="cancel"
            onClick={onClose}
            size="small"
            icon={<CloseOutlined />}
          >
            Cancel
          </CustomButton>
              </div>&nbsp;
              <div>
                  <p>Note: If you select include today, rescheduling will be done from third day onwards</p>
              </div>
      </Form>
    </Drawer>
  );
};

export default ManageReSchedule;
