import { EyeOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Result, Table } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Common/CustomButton";

const OrderSuccessPage = ({
  refreshFunction,
  orderResponseData,
  createNewOrder,
  orderDetailsData,
}) => {
  const navigate = useNavigate();

  let orderCategory = "";

  if (orderResponseData?.statusID === 100) {
    orderCategory = "Quote";
  } else {
    orderCategory = "Order";
  }

  const dataSource = [
    {
      key: "1",
      value: orderResponseData?.quoteRefNum,
      type: "referenceNumber",
    },
    {
      key: "2",
      value: orderResponseData?.ordNum,
      type: "platformOrderNumber",
    },
  ];

  const renderButton = (text) => (
    <CustomButton
      type="primary"
      className="w-full"
      onClick={() =>
        navigate(
          `/enquiry?key=${orderResponseData?.ordNum}&navigationType=current`
        )
      }
    >
      {text ? text : "-"}
    </CustomButton>
  );

  const columns = [
    {
      title: "Attribute",
      dataIndex: "type",
      key: "type",
      width: 220,
      render: (text) => (
        <span className="text-base font-semibold">
          {text === "referenceNumber"
            ? "Reference Number"
            : "Platform Order Number"}
        </span>
      ),
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (text, record) => (
        <span>
          {record.type === "referenceNumber"
            ? renderButton(text)
            : renderButton(text)}
        </span>
      ),
    },
  ];

  return (
    <Result
      status="success"
      className="flex flex-col items-center justify-center w-full"
      title={`${orderCategory} ${
        !orderDetailsData ? "Created" : "Updated"
      } Successfully`}
      subTitle={
        <Table
          className="mt-2"
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          bordered
          showHeader={false}
        />
      }
      extra={[
        <CustomButton
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={() => {
            navigate("/order-details", {
              state: {
                isEditOrder: null,
              },
            });
            createNewOrder();
            refreshFunction();
          }}
        >
          Create New
        </CustomButton>,

        <CustomButton
          type="primary"
          icon={<EyeOutlined />}
          onClick={() => {
            navigate(
              `/enquiry?key=${orderResponseData?.ordNum}&navigationType=current`
            );
            refreshFunction();
          }}
        >
          {`View ${orderCategory}`}
        </CustomButton>,
      ]}
    />
  );
};

export default OrderSuccessPage;
