import React, { useState } from "react";
import {
  Button,
  ConfigProvider,
  Form,
  Input,
  Statistic,
  Typography,
  message,
} from "antd";
import { useForm } from "antd/es/form/Form";
import CustomButton from "../../Common/CustomButton";

const ForgetPasswordCard = ({ setCurrentCard }) => {
  const [IsLoading, setIsLoading] = useState(false);
  const [isContinued, setIsContinued] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const [form] = useForm();

  const { Countdown } = Statistic;

  const onContinue = () => {
    form
      .validateFields()
      .then((values) => {
        setIsContinued(true);
      })
      .catch((err) => console.log(err));
  };

  const onVerify = () => {
    form
      .validateFields()
      .then((values) => {
        setCurrentCard("resetPassword");
      })
      .catch((err) => console.log(err));
  };

  const validateEmail = (_, value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value || emailRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Enter a valid email address");
  };

  const handleInputChange = (index, value) => {
    const nextIndex = index + 1;
    const prevIndex = index - 1;

    if (value && index < 6) {
      form.setFieldsValue({
        [`otp${nextIndex}`]: "",
      });
      const nextInput = form.getFieldInstance(`otp${nextIndex}`);
      if (nextInput) {
        nextInput.focus();
      }
    } else if (!value && index > 1) {
      const currentValue = form.getFieldValue(`otp${index}`);
      if (currentValue) {
        form.setFieldsValue({
          [`otp${index}`]: "",
        });
      } else {
        form.setFieldsValue({
          [`otp${prevIndex}`]: "",
        });
        const prevInput = form.getFieldInstance(`otp${prevIndex}`);
        if (prevInput) {
          prevInput.focus();
        }
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace") {
      handleInputChange(index, "");
    }
  };

  const onResendOTP = () => {
    message.success("OTP resent");
    setCountdown(30);
  };

  return (
    <>
      <Typography className="text-3xl font-semibold ">
        Forgot Password
      </Typography>

      <Typography className="mt-4 text-base md:w-[70%] flex-wrap flex font-medium ">
        Enter your email below, to confirm your account.
      </Typography>

      <Form
        form={form}
        layout="vertical"
        className="w-full md:w-[70%] mt-8 border-2-black-600"
      >
        <Form.Item
          label="Email"
          name="emailAddress"
          className="text-start"
          rules={[
            {
              required: true,
              message: "Enter email",
            },
            { validator: validateEmail },
          ]}
        >
          <Input
            disabled={isContinued}
            size="large"
            placeholder="Enter email"
          />
        </Form.Item>

        {isContinued && (
          <>
            <h2 className="mt-4 mb-1 text-start">
              <span className="text-red-500">*</span> OTP
            </h2>

            <div className="flex items-center gap-3 text-center">
              {Array.from({ length: 6 }, (_, index) => (
                <Form.Item
                  key={index}
                  name={`otp${index + 1}`}
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                    { pattern: /^[0-9]$/, message: "" },
                  ]}
                  className="text-center w-fit"
                >
                  <Input
                    size="large"
                    className="text-center"
                    placeholder="0"
                    maxLength={1}
                    onChange={(e) =>
                      handleInputChange(index + 1, e.target.value)
                    }
                    onKeyDown={(e) => handleKeyDown(index + 1, e)}
                  />
                </Form.Item>
              ))}
            </div>

            {countdown > 0 ? (
              <p className="flex items-center float-right gap-1 text-gray-500 cursor-not-allowed">
                Resend OTP in
                <Countdown
                  value={Date.now() + countdown * 1000}
                  className="font-semibold text-gray-500"
                  format="ss"
                  onFinish={() => setCountdown(0)}
                />
              </p>
            ) : (
              <CustomButton
                className="float-right !px-0 text-right text-blue-600 "
                type="link"
                onClick={onResendOTP}
              >
                <span className="text-blue-600 hover:underline">
                  Resend OTP
                </span>
              </CustomButton>
            )}
          </>
        )}

        {isContinued && (
          <CustomButton
            type="primary"
            size="large"
            htmlType="submit"
            loading={IsLoading}
            className="mt-6 !bg-[#6e71f2] hover:!bg-[#6e70f2de]"
            block
            onClick={onVerify}
          >
            Verify OTP
          </CustomButton>
        )}

        {!isContinued && (
          <CustomButton
            type="primary"
            size="large"
            loading={IsLoading}
            className="mt-10 !bg-[#6e71f2] hover:!bg-[#6e70f2de]"
            block
            onClick={onContinue}
          >
            Continue
          </CustomButton>
        )}

        <p className="!px-0 mb-3 text-center mt-3 ">
          Remember password?
          <span
            className="ml-1 text-blue-600 cursor-pointer hover:underline"
            onClick={() => setCurrentCard("login")}
          >
            login
          </span>
        </p>
      </Form>
    </>
  );
};

export default ForgetPasswordCard;
