import React, { useState } from "react";
import { Button, Form, Input, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import CustomButton from "../../Common/CustomButton";

const ResetPasswordCard = ({ setCurrentCard }) => {
  const [IsLoading, setIsLoading] = useState(false);
  const [form] = useForm();

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        setCurrentCard("login");
      })
      .catch((err) => console.log(err));
  };

  const validatePassword = (_, value) => {
    const regex = /^(?=.*[0-9])[a-zA-Z0-9]{8,}$/;
    if (!value || regex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error("Minimum 8 characters with at least 1 number.")
    );
  };

  return (
    <>
      <Typography className="text-3xl font-semibold">
        Reset your password
      </Typography>

      <Typography className="mt-4 text-base md:w-[70%] flex-wrap flex font-medium">
        Enter your new password below. Make sure it's secure and easy for you to
        remember.
      </Typography>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        className="w-full md:w-[70%] mt-6"
      >
        <Form.Item
          label="Password"
          name={"password"}
          className="!mt-4 text-start"
          rules={[
            {
              required: true,
              message: "Enter password",
            },
            { validator: validatePassword },
          ]}
        >
          <Input.Password
            size="large"
            type="password"
            placeholder="Enter password"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="confirmpassword"
          dependencies={["password"]}
          className="!mt-4 text-start"
          rules={[
            {
              required: true,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Password do not match!"));
              },
            }),
          ]}
        >
          <Input.Password
            size="large"
            type="password"
            placeholder="Enter confirm password"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>

        <CustomButton
          type="primary"
          size="large"
          loading={IsLoading}
          htmlType="submit"
          className="mt-12 !bg-[#6e71f2] hover:!bg-[#6e70f2de]"
          block
        >
          Reset
        </CustomButton>
      </Form>
    </>
  );
};

export default ResetPasswordCard;
