import dayjs from "dayjs";

export const getCurrentDate = () => {
  return dayjs(new Date()).format("DD-MM-YYYY");
};

export const getTomorrowDate = () => {
  const tomorrowDate = dayjs().add(1, "day");
  return tomorrowDate.format("DD-MM-YYYY");
};

export const formatDate = (date) => {
  if (!date) return "N/A";
  return dayjs(date).format("DD-MM-YYYY");
};

export const formatDateMonth = (date) => {
  if (!date) return "N/A";
  return dayjs(date).format("DD MMM YYYY");
};

export const formatTime12Hour = (date) => {
  if (!date) return "N/A";
  return dayjs(date).format("hh:mm A");
};

export const formatDateAndTime = (date) => {
  if (!date) return "N/A";
  return dayjs(date).format("DD-MM-YYYY | hh:mm A");
};

export const isToday = (dateString) => {
  const today = new Date();
  const date = new Date(dateString);
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const isWeekend = (date) => {
  const day = new Date(date).getDay();
  return day === 0 || day === 6;
};
