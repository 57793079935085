import { EditOutlined, PlusOutlined, SettingOutlined } from "@ant-design/icons";
import { Table, Tooltip, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Common/CustomButton";
import CustomSwitch from "../../Common/CustomSwitch";
import FilterDropdown from "../../Common/FilterDropdown";
import ToolTipButton from "../../Common/ToolTipButton";
import TotalListEntries from "../../Common/TotalListEntries";
import { AuthContext } from "../../Providers/AuthProvider";
import { useDarkMode } from "../../Providers/DarkModeContext";
import ProductService from "../../services/ProductBuilder/ProductService";
import { getDarkModeColor } from "../../utils/darkModeHelper";
import ManageProduct from "./ManageProduct";

const ProductPage = () => {
  const { isDarkMode } = useDarkMode();
  const [list, setList] = useState({
    request: {
      pageRequest: {
        currentPage: 1,
        pageSize: 10,
      },
      sortRequest: {
        key: "",
        direction: true,
      },
      filterRequest: {
        id: 0,
        name: "",
        code: "",
        isVisibleOnProductBuilderToInternalUserOnProdEnvironment: true,
        isVisibleOnProductBuilderToExternalCustomerOnProdEnvironment: true,
        isVisibleOnProductBuilderToInternalUserOnTestEnvironment: true,
        isVisibleOnProductBuilderToExternalCustomerOnTestEnvironment: true,
        productGroupID: 0,
        sortIndex: 0,
        isActive: true,
        visibleForAccountIDs: "",
        fileUploadRequest: {
          files: null,
          category: null,
        },
      },
    },
    response: {
      records: [],
      totalRecords: 0,
    },
  });
  const navigate = useNavigate();

  const [isTableDataLoading, setIsTableDataLoading] = useState(false);
  const [changeStatus, setChangeStatus] = useState("");
  const [editData, setEditData] = useState(null);
  const [productGroupDropdownData, setProductGroupDropDownData] = useState([]);
  let { userPrimaryRole } = useContext(AuthContext);
  let isAdmin = userPrimaryRole?.toLowerCase()?.includes("admin");

  const getProductData = async (request) => {
    try {
      setIsTableDataLoading(true);
      const { data } = await ProductService.getProductPageData(request);

      setList((prev) => {
        setIsTableDataLoading(false);
        return {
          ...prev,
          response: data,
        };
      });
    } catch (error) {
      error?.response?.data?.message
        ? message.error(error?.response?.data?.message)
        : message.error("Something went wrong");
    }
  };

  useEffect(() => {
    const { request } = list;

    ProductService.getProductGroupPageData()
      .then((res) => {
        setProductGroupDropDownData(
          res.data.map((item) => ({
            value: item?.productGroupID,
            label: item?.name,
          }))
        );
      })
      .catch((error) => {
        error?.response?.data?.message
          ? message.error(error?.response?.data?.message)
          : message.error("Something went wrong");
      });

    getProductData(request);
  }, []);

  const handleAddProductDrawer = () => {
    setChangeStatus("add");
    setEditData(null);
  };

  const handleEditFunction = (row) => {
    setChangeStatus("edit");
    setEditData(row);
  };

  const handleStatusChange = (values, updatedStatus) => {
    const payload = {
      ...values,
      fileUploadRequest: {
        files: null,
        category: "Product",
      },
      isActive: updatedStatus,
    };
    ProductService.editProductData(payload)
      .then(() => {
        const { request } = list;
        getProductData(request);
      })
      .catch((error) => {
        error?.response?.data?.message
          ? message.error(error?.response?.data?.message)
          : message.error("Something went wrong");
      });
  };

  const handleFiltersChange = (pagination, filters, sorter, extra) => {
    const { request } = list;

    const requestFilters = Object.keys(filters).reduce((acc, key) => {
      if (filters[key]) {
        acc[key] = filters[key][0];
      } else {
        acc[key] = "";
      }
      return acc;
    }, filters);

    setList((prev) => ({
      ...prev,
      request: {
        ...prev.request,
        pageRequest: {
          currentPage: pagination.current,
          pageSize: pagination.pageSize,
        },
      },
    }));

    getProductData({
      ...request,
      filterRequest: requestFilters,
      sortRequest: { key: sorter.field, direction: sorter.order === "ascend" },
      pageRequest: {
        currentPage: pagination.current,
        pageSize: pagination.pageSize,
      },
    });
  };

  const columns = [
    {
      title: "Product Name",
      dataIndex: "name",
      width: 200,
      filterDropdown: (props) => (
        <FilterDropdown {...props} placeholder="Search name" />
      ),
    },

    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      filterDropdown: (props) => (
        <FilterDropdown {...props} placeholder="Search code" />
      ),
      width: 250,
      align: "left",
    },
    {
      title: "Product Group",
      dataIndex: "productGroupName",
      key: "productGroupName",
      align: "left",
      width: 200,
    },
    {
      title: (
        <p>
          Production Capacity
          <span className="ml-1 italic">(per day)</span>
        </p>
      ),
      dataIndex: "capacity",
      key: "capacity",
      align: "center",
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      align: "center",
      sorter: (a, b) => a.isActive - b.isActive,
      width: 200,
      render: (text, row) => (
        <CustomSwitch
          className="-ml-3"
          active={text}
          onChange={() => handleStatusChange(row, !text)}
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "sortIndex",
      key: "sortIndex",
      render: (_, record) => {
        return (
          <div className="flex items-center justify-center gap-2">
            <Tooltip
              trigger="hover"
              title="Edit product details"
              placement="top"
            >
              <CustomButton
                type="primary"
                icon={<EditOutlined />}
                onClick={() => handleEditFunction(record)}
              >
                Edit
              </CustomButton>
            </Tooltip>

            <Tooltip
              trigger="hover"
              title={
                record.isActive
                  ? "Configure flow for this product"
                  : "Cannot configure inactive product!"
              }
              placement="top"
            >
              <CustomButton
                type="primary"
                icon={<SettingOutlined />}
                onClick={() =>
                  navigate("/products/configure-group-status", {
                    state: {
                      ...record,
                      name: record.productGroupName,
                      selectedProduct: record.code,
                    },
                  })
                }
                disabled={!record.isActive}
              >
                Configure
              </CustomButton>
            </Tooltip>
          </div>
        );
      },
      width: 200,
      align: "center",
    },
  ];

  let tableStartingIndex =
    (list.response.currentPage - 1) * list.response.pageSize + 1;

  if (
    list.response.totalRecords <
    list.response.pageSize * list.response.currentPage
  ) {
    tableStartingIndex =
      (list.response.currentPage - 1) * list.response.pageSize + 1;
  }

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex items-center gap-6">
          <TotalListEntries
            checkNullCondition={list.response.records.length > 0}
            startingIndex={tableStartingIndex}
            totalEntries={list.response.totalRecords}
            currentPage={list.response.currentPage}
            pageSize={list.response.pageSize}
          />
        </div>

        {isAdmin && (
          <ToolTipButton
            id="addProduct"
            tooltipHeader="Add new product"
            tooltipPlacement="left"
            onClick={handleAddProductDrawer}
            type="primary"
            className="absolute right-0 float-right mb-3 -top-12"
            icon={<PlusOutlined />}
          >
            Add
          </ToolTipButton>
        )}
      </div>

      <Table
        size="small"
        bordered={"1px solid black"}
        dataSource={list.response.records}
        columns={columns}
        onChange={handleFiltersChange}
        pagination={{
          pageSize: list.response.pageSize,
          current: list.response.currentPage,
          total: list.response.totalRecords,
          showSizeChanger: true,
        }}
        loading={isTableDataLoading}
        scroll={{ x: true }}
        tableLayout="fixed"
        rowClassName={(record, index) =>
          index % 2 !== 0 ? getDarkModeColor(isDarkMode, "bg-gray-100") : ""
        }
      />

      <ManageProduct
        initialValue={editData}
        changeStatus={changeStatus}
        getProductData={getProductData}
        productGroupDropdownData={productGroupDropdownData}
        onClose={() => {
          setEditData(null);
          setChangeStatus(null);
        }}
        onSuccess={() => {
          const { request } = list;
          getProductData(request);
        }}
      />
    </div>
  );
};

export default ProductPage;
