import { Switch, Tooltip } from "antd";
import React from "react";

const CustomSwitch = ({
  active,
  className,
  tooltipTitle,
  noTooltip,
  ...otherProps
}) => {
  return (
    <Tooltip
      trigger="hover"
      title={
        noTooltip
          ? ""
          : tooltipTitle
          ? tooltipTitle
          : `${active ? "Active" : "Inactive"}`
      }
    >
      <Switch
        checked={active}
        className={`${className} ${active ? "!bg-green-500" : "!bg-gray-500"}`}
        {...otherProps}
      />
    </Tooltip>
  );
};

export default CustomSwitch;
