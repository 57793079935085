import { Bar } from "@ant-design/charts";
import { Empty, Slider } from "antd";
import React, { memo, useState } from "react";
import ChartLoading from "../../Common/ChartLoading";

const BestCustomerChart = memo(({ data, loading, height }) => {
  const [dataCount, setDataCount] = useState(6);

  let formattedData = [];

  if (loading) {
    return <ChartLoading height={height} />;
  }

  if (data.length > 0) {
    formattedData = data.map((entry) => {
      const [company, value] = Object.entries(entry)[0];
      return {
        company: company.length > 16 ? `${company.slice(0, 16)}...` : company,
        value,
        fullName: company,
      };
    });
  } else {
    return (
      <div
        className={`flex items-center justify-center w-full font-semibold`}
        style={{ height: height }}
      >
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No data found"
        />
      </div>
    );
  }

  const maxCount = Math.max(...formattedData.map((entry) => entry.value));

  const filteredData = formattedData
    .filter((i) => i.value !== null && i.value !== 0)
    .sort((a, b) => b?.value - a?.value)
    .slice(0, dataCount);

  const config = {
    data: filteredData,
    xField: "value",
    yField: "company",
    yAxis: { label: { formatter: (v) => `${v}` } },
    color: ({ company }) => {
      const obj = filteredData.find((i) => i.company === company);
      return obj.value === maxCount ? "#62daab" : "#5B8FF9";
    },
    tooltip: {
      showTitle: false,
      formatter: (data) => {
        const obj = filteredData.find((i) => i.company === data.company);

        return {
          name: obj.fullName,
          value: obj.value,
        };
      },
    },
  };

  const sliderMaxLength = formattedData.filter(
    (i) => i.value !== null && i.value !== 0
  ).length;

  return (
    <div className="relative">
      {sliderMaxLength > 6 && (
        <Slider
          min={1}
          max={sliderMaxLength}
          defaultValue={6}
          onChange={(value) => setDataCount(value)}
          className="w-[10rem] absolute -top-12 right-3 z-10"
        />
      )}

      <Bar {...config} height={height} />
    </div>
  );
});

export default BestCustomerChart;
