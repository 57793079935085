export const validateTextInput =
  (fieldName, minValue = 2, maxValue = 20) =>
  (_, value) => {
    if (!value) {
      return Promise.resolve();
    }

    if (value && value.trim().length === 0) {
      return Promise.reject(new Error(`${fieldName} cannot be just spaces.`));
    }
    const trimmedLength = value ? value.trim().length : 0;

    if (trimmedLength < minValue) {
      return Promise.reject(
        new Error(
          `${fieldName} should be at least ${minValue} characters long.`
        )
      );
    }
    if (trimmedLength > maxValue) {
      return Promise.reject(
        new Error(`Maximum ${maxValue} character are allowed.`)
      );
    }
    return Promise.resolve();
  };

export const validateEmail = (rule, value) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!value || emailRegex.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject("Enter a valid email address");
};
