import { CheckOutlined, CloseOutlined, FileOutlined } from "@ant-design/icons";
import { Collapse, Drawer, Input, message, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import CustomButton from "../../Common/CustomButton";
import OrderManagementService from "../../services/OrderManagement/OrderManagementService";

const OrderNotesDrawer = ({ open, onClose, initalData }) => {
  const [notesLoading, setNotesLoading] = useState(false);
  const [isSaving, setIsSaving] = useState("");
  const [notesData, setNotesData] = useState([]);
  const [notesDataImmutable, setNotesDataImmutable] = useState([]);
  const [activeTabKeys, setActiveTabKeys] = useState([]);

  const fetchNotes = async (activeKey) => {
    setNotesLoading(true);

    try {
      const res = await OrderManagementService.getOrderNotes(
        initalData?.ordNum,
        initalData?.activeTab
      );

      setNotesData(res?.data || []);
      setNotesDataImmutable(res?.data || []);
      setActiveTabKeys(activeKey ? [activeKey] : []);
    } catch (error) {
      message.error("Failed to load notes");
    } finally {
      setNotesLoading(false);
    }
  };

  useEffect(() => {
    if (open && initalData?.ordNum) {
      fetchNotes(initalData?.activeTab);
    }
  }, [open]);

  const handleSave = async (index) => {
    if (
      notesData[index].notes.trim() === "" &&
      notesData[index].notes.length !== 0
    ) {
      return;
    }
    setIsSaving(index);

    const payload = notesData[index];

    try {
      const res = await OrderManagementService.updatedOrderNote(
        initalData?.ordNum,
        payload
      );

      fetchNotes(initalData?.activeTab);
      message.success("Notes saved successfully");
    } catch (error) {
      console.log(error);
      message.error("Failed to save notes");
    } finally {
      setIsSaving("");
    }
  };

  const handleDiscard = async (tabName, index) => {
    fetchNotes(tabName);
    setIsSaving("");
  };

  const handleClose = () => {
    setActiveTabKeys([]);
    onClose();
  };

  return (
    <Drawer open={open} width={400} onClose={handleClose} title="Notes">
      {notesLoading
        ? Array(6)
            .fill("")
            .map((_, index) => (
              <Skeleton.Input
                key={index}
                className="!w-full mb-2"
                style={{ height: 40 }}
                active
              />
            ))
        : notesData.map((item, index) => (
            <Collapse
              key={index}
              className="mb-4"
              expandIconPosition="right"
              activeKey={activeTabKeys}
              onChange={(keys) => setActiveTabKeys(keys)}
              items={[
                {
                  label: (
                    <div className="flex justify-between w-full">
                      {item?.tabName}{" "}
                      {notesDataImmutable[index].notes?.length > 0 && (
                        <FileOutlined />
                      )}
                    </div>
                  ),
                  key: item?.tabName,
                  children: (
                    <>
                      <Input.TextArea
                        rows={8}
                        disabled={!item?.isEditable}
                        value={item.notes}
                        placeholder="Add Notes"
                        onChange={(e) =>
                          setNotesData((prev) => {
                            const updatedNotesData = [...prev];

                            updatedNotesData[index] = {
                              ...updatedNotesData[index],
                              notes: e.target.value,
                            };

                            return updatedNotesData;
                          })
                        }
                      />

                      {item?.isEditable && (
                        <div className="flex justify-end gap-2 mt-5 mb-1">
                          <CustomButton
                            type="primary"
                            htmlType="button"
                            loading={isSaving === index}
                            onClick={() => handleSave(index)}
                            icon={<CheckOutlined />}
                          >
                            Save
                          </CustomButton>

                          <CustomButton
                            type="cancel"
                            icon={<CloseOutlined />}
                            disabled={isSaving === index}
                            onClick={() => handleDiscard(item.tabName, index)}
                          >
                            Discard
                          </CustomButton>
                        </div>
                      )}
                    </>
                  ),
                },
              ]}
            />
          ))}
    </Drawer>
  );
};

export default OrderNotesDrawer;
