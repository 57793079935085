import React, { memo, useState } from "react";
import { Handle, Position } from "reactflow";
import { getDarkModeColor } from "../../../utils/darkModeHelper";
import { useDarkMode } from "../../../Providers/DarkModeContext";

const StartingNode = memo(({ data }) => {
  const { isDarkMode } = useDarkMode();

  return (
    <>
      <Handle
        type="source"
        id="right"
        position={Position.Right}
        style={{ background: "#555" }}
        isConnectable={false}
      />

      <span
        className={`p-3 rounded-full ${getDarkModeColor(
          isDarkMode,
          "bg-gray-50",
          "text-white bg-[#1b1d1e] border-gray-600"
        )} text-center text-[16px] border-black font-semibold border-[1.5px] shadow custom-node`}
      >
        {data.label}
      </span>
    </>
  );
});

export default StartingNode;
