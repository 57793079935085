import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import {
  DatePicker,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Tooltip,
  message,
} from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import ProductionService from "../../../services/Production/ProductionServices";
import dayjs from "dayjs";
import "./styles.css";
import ViewInfoButton from "../../../Common/ViewInfoButton";
import CustomButton from "../../../Common/CustomButton";
import { getDarkModeColor } from "../../../utils/darkModeHelper";
import { useDarkMode } from "../../../Providers/DarkModeContext";

// Todo : add by days capacity and prev, next week option

const weekdays = [
  { label: "Monday", value: "Monday" },
  { label: "Tuesday", value: "Tuesday" },
  { label: "Wednesday", value: "Wednesday" },
  { label: "Thursday", value: "Thursday" },
  { label: "Friday", value: "Friday" },
  { label: "Saturday", value: "Saturday" },
  { label: "Sunday", value: "Sunday" },
];

const refillOptions = [
  { label: "15 Days", value: "15" },
  { label: "30 Days", value: "30" },
  { label: "60 Days", value: "60" },
  { label: "90 Days", value: "90" },
];

const ConfigureCapactiyDrawer = ({
  open,
  onClose,
  dropdownList,
  fetchList,
  product,
}) => {
  const { isDarkMode } = useDarkMode();

  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const { RangePicker } = DatePicker;

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const handleUpdate = async (values) => {
    const payload = {
      startDate: dayjs(values.productionDates[0]).format("DD-MM-YYYY"),
      endDate: dayjs(values.productionDates[1]).format("DD-MM-YYYY"),
      processLineIDs: values.processLineID,
      capacity: values.capacity,
      processLineName: "",
      productionDates: values.productionDates,
      autoRefill: "60",
      productGroupID: product,
    };

    setIsLoading(true);
    try {
      await ProductionService.saveByDays(payload);
      setIsLoading(false);
      fetchList(product);
      handleClose();
      message.success("Updated capacity successfully!");
    } catch (error) {
      setIsLoading(false);
      console.error(error?.response?.data?.message);
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went wrong!"
      );
    }
  };

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        handleUpdate(values);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const disabledDate = (current) => {
    const tomorrow = dayjs().add(1, "day");
    const isWeekend = current.day() === 0 || current.day() === 6;

    return current && (current < tomorrow.startOf("day") || isWeekend);
  };

  const handleSelectAll = () => {
    const allProcessLines = dropdownList.map((line) => line.value);
    form.setFieldValue("processLineID", allProcessLines);
  };

  const handleDeselectAll = () => {
    form.setFieldValue("processLineID", []);
  };

  const filterDropdownList =
    dropdownList.length > 2
      ? [{ label: "Select All", value: "all" }, ...dropdownList]
      : dropdownList;

  const markNonWeekdays = (current) => {
    const isWeekday = current.day() >= 1 && current.day() <= 5;
    return isWeekday
      ? ""
      : getDarkModeColor(isDarkMode, "bg-amber-50", "bg-gray-700");
  };

  const filterOption = (input, option) => {
    if (!option) return false;

    const searchValue = input.toLowerCase();
    const optionLabel = option.label.toLowerCase();
    const optionValue = String(option.value);

    return (
      optionLabel.includes(searchValue) || optionValue.includes(searchValue)
    );
  };

  return (
    <Drawer
      open={open}
      width={320}
      onClose={handleClose}
      title="Configure Capacity"
    >
      <Form
        name="capacityForm"
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          name="processLineID"
          label="Status"
          rules={[
            {
              required: true,
              message: "Please select select status",
            },
          ]}
        >
          <Select
            size="medium"
            mode="multiple"
            placeholder="Select status"
            options={filterDropdownList}
            allowClear={{
              clearIcon: (
                <Tooltip trigger="hover" title="Remove All">
                  <DeleteOutlined className="text-blue-600" />
                </Tooltip>
              ),
            }}
            onSelect={(value) => {
              if (value === "all") {
                handleSelectAll();
              }
            }}
            onDeselect={(value) => {
              if (value === "all") {
                handleDeselectAll();
              }
            }}
            showSearch
            filterOption={filterOption}
          />
        </Form.Item>

        <Form.Item
          name="capacity"
          label="Capacity"
          rules={[
            {
              required: true,
              message: "Please enter capactiy",
            },
          ]}
        >
          <Input
            size="medium"
            type="number"
            min={0}
            placeholder="Enter capacity"
          />
        </Form.Item>

        {/* needed later 
        <Form.Item
          name="weekdays"
          label="Select days"
          rules={[
            {
              required: true,
              message: "Please select days",
            },
          ]}
        >
          <Select
            size="medium"
            mode="multiple"
            placeholder="Select days"
            options={weekdays}
            allowClear
          />
        </Form.Item> */}

        <Form.Item
          name="productionDates"
          label="Select range"
          rules={[
            {
              required: true,
              message: "Please select range",
            },
          ]}
        >
          <RangePicker size="medium" disabledDate={disabledDate} />
        </Form.Item>

        {/* needed later
        <Form.Item name="autofill" label="Automatic refill">
          <Select
            size="medium"
            mode="multiple"
            placeholder="Select refill days"
            options={refillOptions}
            allowClear
          />
        </Form.Item> */}

        <Row className="justify-end w-full gap-3 mt-10">
          <CustomButton
            type="primary"
            loading={isLoading}
            htmlType="submit"
            icon={<CheckOutlined />}
          >
            Submit
          </CustomButton>

          <CustomButton
            type="cancel"
            onClick={handleClose}
            icon={<CloseOutlined />}
          >
            Cancel
          </CustomButton>
        </Row>
      </Form>
    </Drawer>
  );
};

export default ConfigureCapactiyDrawer;
