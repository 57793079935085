import { EyeFilled, FolderOpenFilled } from "@ant-design/icons";
import { Alert, Drawer, Image, message, Spin, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import CustomButton from "../../Common/CustomButton";
import TicketService from "../../services/Ticket/TicketService";
import { previewableFileFormats } from "../../utils/commonUtils";

const TicketsAttachmentDrawer = ({ open, data, onClose, ticketsList }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [fileLoading, setFileLoading] = useState("");

  const [attachmentsList, setAttachmentsList] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (open && data) {
      fetchTicketAttachments();
    }
  }, [open, data]);

  const fetchTicketAttachments = async () => {
    if (!data?.attachmentIDs) return;

    setIsLoading(true);

    try {
      const res = await TicketService.getTicketList(ticketsList.request);

      if (res?.data?.length) {
        const attachmentPromises = res.data
          .filter((ticket) => ticket?.attachmentIDs === data?.attachmentIDs)
          .map((ticket) => TicketService.getAttachments(ticket.attachmentIDs));

        const attachments = await Promise.all(attachmentPromises);

        const allAttachments = attachments
          .map((attachment) => attachment?.data || [])
          .flat();

        setAttachmentsList(allAttachments);
      }
    } catch (error) {
      console.log(error);
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went Wrong"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setAttachmentsList([]);
    onClose();
  };

  const getImage = async (data, index) => {
    setFileLoading(index);

    try {
      const res = await TicketService.getPreviewFile(
        "SUPPORT_TICKET",
        data?.fileName
      );

      setImageUrl(res?.data);

      if (
        !previewableFileFormats.some((i) => res?.data.toLowerCase().includes(i))
      ) {
        message.info("Preview is not available for this file format.");
      } else {
        setImageModalOpen(true);
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went wrong!"
      );
    } finally {
      setFileLoading("");
    }
  };

  const getImageUrl = async (data, index) => {
    setFileLoading(index);

    try {
      const res = await TicketService.getPreviewFile(
        "SUPPORT_TICKET",
        data?.fileName
      );

      setImageUrl(res?.data);
      openAttachment(res?.data);
    } catch (error) {
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went wrong!"
      );
    } finally {
      setFileLoading("");
    }
  };

  const openAttachment = (url) => {
    if (url) {
      window.open(url, "_blank");
    } else {
      message.info("unable to open attachment in new tab");
    }
  };

  const columns = [
    {
      title: "File Name",
      dataIndex: "description",
      key: "description",
      align: "center",
      render: (text) => (
        <Tooltip title={text}>
          {text.length > 16 ? `${text.slice(0, 16)}...` : text}
        </Tooltip>
      ),
    },
    {
      title: "File",
      dataIndex: "file",
      key: "custRequiredDate",
      align: "center",
      render: (_, record, index) => (
        <div className="flex flex-col gap-1">
          <CustomButton
            loading={fileLoading === index}
            icon={<EyeFilled />}
            onClick={() => getImage(record, index)}
          >
            View
          </CustomButton>
          <CustomButton
            loading={fileLoading === index}
            icon={<FolderOpenFilled />}
            onClick={() => getImageUrl(record, index)}
          >
            Open
          </CustomButton>
        </div>
      ),
    },
  ];

  return (
    <Drawer
      title="All Attachments"
      open={open}
      onClose={handleClose}
      destroyOnClose
    >
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spin />
        </div>
      ) : attachmentsList.length === 0 ? (
        <Alert
          type="info"
          message="No attachment found for this ticket"
          showIcon
        />
      ) : (
        <Table
          bordered
          dataSource={attachmentsList}
          columns={columns}
          pagination={false}
        />
      )}

      {isImageModalOpen && (
        <Image
          preview={{
            visible: isImageModalOpen,
            src: imageUrl,
            onVisibleChange: (value) => {
              setImageModalOpen(value);
            },
          }}
          alt={data?.description}
          style={{ maxWidth: "100%" }}
          className="invisible object-center"
        />
      )}
    </Drawer>
  );
};

export default TicketsAttachmentDrawer;
