import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Drawer, Form, Input, Select, message } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import CitySearch from "../../Common/CitySearch";
import StateSelect from "../../Common/StateSelect";
import CountrySelect from "../../Common/CountrySelect";
import CustomButton from "../../Common/CustomButton";
import CustomerService from "../../services/CustomerService/CustomerService";
import {
  validateEmail,
  validateTextInput,
} from "../../utils/validationsHelper";

const ManageCustomerAddresses = ({
  changeStatus,
  onClose,
  initialValue,
  onSuccess,
  accountId,
}) => {
  const { Option } = Select;

  const [selectCity, setSelectCity] = useState("");
  const [cityData, setCityData] = useState([]);
  const [stateId, setStateId] = useState("");
  const [IsLoading, setIsLoading] = useState(false);
  const [form] = useForm();

  const [selectedCountryValue, setSelectedCountryValue] = useState(null);
  const [stateDropDownData, setStateDropDownData] = useState({});

  const handleClearCountry = () => {
    form.resetFields(["country", "state"]);
    setSelectedCountryValue(null);
  };

  const handleCountrySelect = (countryValue, stateData) => {
    form.setFieldValue("country", countryValue);
    setSelectedCountryValue(countryValue);
    setStateDropDownData(stateData);
  };

  useEffect(() => {
    if (changeStatus === "edit") {
      form.setFieldsValue({ ...initialValue });
      setSelectedCountryValue(initialValue?.country);
    } else {
      form.resetFields();
      setSelectedCountryValue(null);
    }

    return () => form.setFieldsValue(null);
  }, [initialValue, changeStatus]);

  const onFinish = (values) => {
    setIsLoading(true);

    if (initialValue) {
      const payload = {
        ...values,
        addressID: initialValue.id,
        primaryIdentifier: initialValue.primaryIdentifier,
        consumerID: accountId,
        consumerType: "",
        company: "",
        areaCode: "",
        createdBy: 0,
        fax: "",
        addressLine3: "",
        addressLine4: "",
      };

      CustomerService.updateCustomerAddressData({ ...initialValue, ...payload })
        .then((res) => {
          setIsLoading(false);
          onSuccess();
          message.success("Address Updated successfully");
          form.resetFields();
          onClose();
        })
        .catch((error) => {
          setIsLoading(false);
          error?.response?.data?.message
            ? message.error(error?.response?.data?.message)
            : message.error("Something went Wrong");
        });
    } else {
      const payload = {
        ...values,
        country: selectedCountryValue,
        addressID: 0,
        consumerID: accountId,
        consumerType: "",
        company: "",
        areaCode: "",
        createdBy: 0,
        fax: "",
        addressLine3: "",
        addressLine4: "",
        primaryIdentifier: "",
      };

      CustomerService.addCustomerAddressData(payload)
        .then((res) => {
          setIsLoading(false);
          onSuccess();
          message.success("Address added Successfully");
          form.resetFields();
          onClose();
        })
        .catch((error) => {
          setIsLoading(false);
          {
            error?.response?.data?.message
              ? message.error(error?.response?.data?.message)
              : message.error("Something went Wrong");
          }
        });
    }
  };

  return (
    <div>
      <Drawer
        title={
          changeStatus === "add"
            ? "Add Customer Address"
            : changeStatus === "edit"
            ? "Edit Customer Address"
            : ""
        }
        width={400}
        onClose={onClose}
        open={!!changeStatus}
        bodyStyle={{
          padding: "10px 18px",
        }}
      >
        <div>
          <Form
            form={form}
            name="form_item_path"
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="contactPersonName"
              label="Contact Name"
              rules={[
                {
                  required: true,
                  message: "Enter contact name",
                },
                {
                  validator: validateTextInput("Name"),
                },
              ]}
            >
              <Input
                size="medium"
                type="contactPersonName"
                placeholder="Enter contact name"
              />
            </Form.Item>
            <Form.Item
              name="emailAddress"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Enter email",
                },
                {
                  validator: validateEmail,
                },
              ]}
            >
              <Input size="medium" type="email" placeholder="Enter email" />
            </Form.Item>
            <Form.Item
              name="addressLine1"
              label="Street Address"
              rules={[
                {
                  required: true,
                  message: "Enter street address",
                },
                {
                  validator: validateTextInput("Street address", 10, 100),
                },
              ]}
            >
              <Input size="medium" type="text" placeholder="Enter address" />
            </Form.Item>
            <Form.Item
              name="addressLine2"
              label="Landmark"
              rules={[
                {
                  required: false,
                  message: "Enter landmark",
                },
                {
                  validator: validateTextInput("Landmark"),
                },
              ]}
            >
              <Input size="medium" type="text" placeholder="Enter landmark" />
            </Form.Item>
            <Form.Item
              name="country"
              label="Country"
              rules={[
                {
                  required: true,
                  message: "Enter country",
                },
              ]}
            >
              <CountrySelect
                onSelect={handleCountrySelect}
                currentStatus={changeStatus}
                form={form}
              />
            </Form.Item>

            <Form.Item
              name="state"
              label="State"
              rules={[
                {
                  required: true,
                  message:
                    stateDropDownData?.isStateExists === true
                      ? "Select state"
                      : "Enter state",
                },
                {
                  validator: validateTextInput("State"),
                },
              ]}
            >
              <StateSelect
                options={
                  stateDropDownData
                    ? stateDropDownData?.stateResponse?.map((item) => ({
                        label: item.name,
                        value: item.name,
                      }))
                    : []
                }
                isStateExists={stateDropDownData.isStateExists}
              />
            </Form.Item>

            <Form.Item
              name="city"
              label="City"
              rules={[
                {
                  required: true,
                  message: "Enter city.",
                },
                {
                  validator: validateTextInput("City"),
                },
              ]}
              dependencies={["state", stateId]}
            >
              <CitySearch
                stateID={stateId ? "" : stateId}
                value={selectCity}
                onChange={setSelectCity}
                cityData={cityData}
                setCityData={setCityData}
              />
            </Form.Item>
            <Form.Item
              size="medium"
              name="postCode"
              label="Post Code"
              rules={[
                {
                  required: false,
                  message: "Enter post code",
                },
                {
                  pattern: /^[0-9]*$/,
                  message: "Enter a valid post code",
                },
              ]}
            >
              <Input
                type="number"
                size="medium"
                placeholder="Enter post code"
              />
            </Form.Item>
            <div className="flex justify-end gap-2 mt-4">
              <CustomButton
                type="primary"
                htmlType="submit"
                loading={IsLoading}
                icon={<CheckOutlined />}
              >
                Submit
              </CustomButton>
              <CustomButton
                type="cancel"
                onClick={onClose}
                icon={<CloseOutlined />}
              >
                Cancel
              </CustomButton>
            </div>
          </Form>
        </div>
      </Drawer>
    </div>
  );
};

export default ManageCustomerAddresses;
