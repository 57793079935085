import React, { useState } from "react";
import { Modal, Typography, Skeleton } from "antd";
import CustomButton from "../../../Common/CustomButton";
import {
  CheckOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const ProductSelectModal = ({
  open,
  products,
  onCancel,
  onSelect,
  selectedProduct,
}) => {
  const [currentProduct, setCurrentProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleImageLoaded = () => {
    setLoading(false);
  };

  return (
    <Modal
      title="Select Product"
      open={open}
      onCancel={onCancel}
      destroyOnClose={false}
      footer={false}
    >
      <>
        {products.length > 0 ? (
          <div className="grid grid-cols-3 gap-4 mt-4 place-items-center justify-items-center">
            {products.map((product, index) => (
              <div
                key={index}
                onMouseEnter={() => setCurrentProduct(product)}
                onMouseLeave={() => setCurrentProduct(null)}
                onClick={() => onSelect(product)}
                onDoubleClick={() => {
                  onSelect(product);
                  onCancel();
                }}
                className={`cursor-pointer select-none w-full  ${
                  currentProduct === product && selectedProduct !== product
                    ? "border-blue-100 border"
                    : "border-2"
                } ${
                  selectedProduct === product
                    ? "border-blue-500"
                    : "border-transparent"
                } rounded p-2`}
              >
                {loading && (
                  <Skeleton.Image
                    style={{ height: "8rem", width: "100%" }}
                    className="min-w-full"
                    active={true}
                  />
                )}

                <img
                  src={product.productAttachment}
                  alt={product.label}
                  className={`object-cover border border-gray-200 shadow-md drop-shadow-sm w-full h-[8rem] rounded ${
                    loading ? "hidden" : ""
                  }`}
                  onLoad={handleImageLoaded}
                  onError={(e) => {
                    e.target.src = "/no_image.png";
                  }}
                />

                <Typography className="mt-1 text-center">
                  {product.label}
                </Typography>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center mb-4">
            <Typography className="mb-2 text-lg font-semibold">
              No products in this group
            </Typography>

            <CustomButton
              type="dashed"
              className="border-blue-400"
              icon={<PlusOutlined />}
              onClick={() =>
                navigate("/inventory/product/all?activeTab=product")
              }
            >
              Add Products
            </CustomButton>
          </div>
        )}

        {selectedProduct && (
          <div className="flex items-center justify-end gap-3 mt-4">
            <CustomButton
              type="dashed"
              className="border-blue-400"
              icon={<MinusCircleOutlined />}
              onClick={() => onSelect(null)}
            >
              Remove Selected
            </CustomButton>

            <CustomButton
              type="primary"
              icon={<CheckOutlined />}
              onClick={() => onCancel(null)}
            >
              Done
            </CustomButton>
          </div>
        )}
      </>
    </Modal>
  );
};

export default ProductSelectModal;
