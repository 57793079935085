import {
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  FileAddOutlined,
  FileExcelOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Row, Table, Tooltip, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Common/CustomButton";
import { AuthContext } from "../../Providers/AuthProvider";
import { useDarkMode } from "../../Providers/DarkModeContext";
import { useScreenSize } from "../../Providers/ScreenSizeProvider";
import CustomerService from "../../services/CustomerService/CustomerService";
import { getDarkModeColor } from "../../utils/darkModeHelper";
import ManageCustomerAddresses from "./ManageCustomerAddresses";

const CustomerAddress = ({ primaryIdentifier }) => {
  const { isDarkMode } = useDarkMode();
  const currentScreenSize = useScreenSize();
  const [isTableDataLoading, setIsTableDataLoading] = useState(false);
  const [changeStatus, setChangeStatus] = useState("");
  const [addressData, setAddressData] = useState([]);
  const [editAddressData, setEditAddressData] = useState(null);
  const [showAllColumns, setShowAllColumns] = useState(true);
  const { userDetails } = useContext(AuthContext);
  const { isExternalUser } = userDetails;
  let { userPrimaryRole } = useContext(AuthContext);
  let isAdmin = userPrimaryRole?.toLowerCase()?.includes("admin");
  const [accountId, setAccountId] = useState("");
  const [isExporting, setIsExporting] = useState(false);

  const navigate = useNavigate();

  const handleMultipleAddresses = () => {
    setChangeStatus("add");
    setEditAddressData(null);
  };

  const fetchData = (primaryIdentifier) => {
    setIsTableDataLoading(true);
    CustomerService.getCustomerAddress(primaryIdentifier)
      .then((res) => {
        setAccountId(res.data[0].consumerID);
        setAddressData(res.data);
        setIsTableDataLoading(false);
      })
      .catch((error) => {
        error?.response?.data?.message
          ? message.error(error?.response?.data?.message)
          : message.error("Something went Wrong");

        setIsTableDataLoading(false);
      });
  };

  useEffect(() => {
    if (primaryIdentifier) {
      fetchData(primaryIdentifier);
    }
  }, [primaryIdentifier]);

  const handleExport = async () => {
    setIsExporting(true);

    try {
      const response = await CustomerService.exportAddressList(
        primaryIdentifier
      );

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "ADDRESSES-LIST.xlsx";
      link.click();
      setIsExporting(false);
    } catch (error) {
      setIsExporting(false);
      console.error(error?.response?.data?.message);
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went wrong!"
      );
    }
  };

  const handleExtendedColumn = () => {
    setShowAllColumns(!showAllColumns);
  };

  const columns = [
    {
      title: "Address 1",
      dataIndex: "addressLine1",
      render: (text) =>
        text?.length > 50 ? (
          <Tooltip title={text} placement="topLeft">
            <span className="line-clamp-1">{text ? text : "-"}</span>
          </Tooltip>
        ) : (
          <span className="line-clamp-1">{text ? text : "-"}</span>
        ),
      width: 300,
    },
    {
      title: "Address 2",
      dataIndex: "addressLine2",
      render: (text) =>
        text?.length > 50 ? (
          <Tooltip title={text} placement="topLeft">
            <span className="line-clamp-1">{text ? text : "-"}</span>
          </Tooltip>
        ) : (
          <span className="line-clamp-1">{text ? text : "-"}</span>
        ),
      hidden: showAllColumns,
      width: 300,
    },
    {
      title: "Email",
      dataIndex: "emailAddress",
    },
    {
      title: "City",
      dataIndex: "city",
    },
    {
      title: "State",
      dataIndex: "state",
    },
    {
      title: "Post Code",
      dataIndex: "postCode",
      align: "center",
    },
    {
      title: "Country",
      dataIndex: "country",
    },
    {
      title: "Action",
      align: "center",
      dataIndex: "action",
      render: (_, record) => {
        return (
          isExternalUser === false && (
            <CustomButton
              type="primary"
              icon={<EditOutlined />}
              onClick={() => {
                setEditAddressData(record);
                setChangeStatus("edit");
              }}
            >
              Edit
            </CustomButton>
          )
        );
      },
    },
  ].filter((col) => !col.hidden);

  return (
    <div className="relative ">
      {addressData && (
        <div>
          <div className="right-0 z-50 flex justify-end mb-2 xl:absolute -top-12">
            {isAdmin && isExternalUser === false && (
              <Row className="gap-3">
                <CustomButton
                  className="hover:!bg-cyan-400 hover:!border-cyan-400 hover:!text-white"
                  type="dashed"
                  onClick={() =>
                    navigate("/customer-list/enquiry/import-details", {
                      state: {
                        lastPage: "Address",
                        primaryIdentifier: primaryIdentifier,
                      },
                    })
                  }
                  icon={<FileAddOutlined />}
                >
                  Import
                </CustomButton>

                {addressData.length > 0 && (
                  <CustomButton
                    type="dashed"
                    loading={isExporting}
                    onClick={handleExport}
                    className="hover:!bg-green-400 hover:!border-green-400 hover:!text-white"
                    icon={<FileExcelOutlined />}
                  >
                    Export
                  </CustomButton>
                )}

                <CustomButton
                  onClick={handleExtendedColumn}
                  type="primary"
                  icon={
                    showAllColumns ? <EyeOutlined /> : <EyeInvisibleOutlined />
                  }
                >
                  {showAllColumns ? "Show all details" : "Hide extra details"}
                </CustomButton>

                <CustomButton
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={handleMultipleAddresses}
                >
                  Add New Address
                </CustomButton>
              </Row>
            )}
          </div>

          <Table
            scroll={{
              x: currentScreenSize < 1024 ? 1400 : 1000,
            }}
            tableLayout="fixed"
            loading={isTableDataLoading}
            columns={columns}
            dataSource={addressData}
            rowClassName={(record, index) =>
              index % 2 !== 0 ? getDarkModeColor(isDarkMode, "bg-gray-100") : ""
            }
          />
        </div>
      )}
      <ManageCustomerAddresses
        accountId={accountId}
        initialValue={editAddressData}
        onClose={() => {
          setChangeStatus(null);
          setEditAddressData(null);
        }}
        onSuccess={() => {
          if (primaryIdentifier) {
            fetchData(primaryIdentifier);
          }
        }}
        changeStatus={changeStatus}
      />
    </div>
  );
};

export default CustomerAddress;
