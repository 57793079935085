import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Avatar, Drawer, Empty, Form, Input, List, message } from "antd";
import { useForm, useWatch } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import CustomButton from "../../Common/CustomButton";
import ToolTipButton from "../../Common/ToolTipButton";
import { useDarkMode } from "../../Providers/DarkModeContext";
import TicketService from "../../services/Ticket/TicketService";
import { formatDateAndTime } from "../../utils/dateHelper";
import { validateTextInput } from "../../utils/validationsHelper";

const TicketCommentsDrawer = ({ open, initalData, onClose }) => {
  const { isDarkMode } = useDarkMode();

  const [commentsLoading, setCommentsLoading] = useState(false);
  const [commentsList, setCommentsList] = useState([]);
  const [loading, setLoading] = useState("");

  const [editComment, setEditComment] = useState(null);

  const [form] = useForm();
  const commentText = useWatch("comment", form);

  useEffect(() => {
    if (open) fetchComments();
  }, [open]);

  const fetchComments = async () => {
    if (!initalData?.primaryIdentifier) {
      setCommentsLoading(false);
      return;
    }

    setCommentsLoading(true);

    try {
      const { data } = await TicketService.getTicketComments(
        initalData?.primaryIdentifier
      );

      setCommentsList(data || []);
    } catch (error) {
      console.log(error);
      message.error(
        error?.response?.data?.message || "Failed to load comments"
      );
    } finally {
      setCommentsLoading(false);
    }
  };

  const handleCommentAction = async (values) => {
    setLoading("comment-add");

    try {
      let method;

      if (!editComment) {
        method = () =>
          TicketService.addTicketComment({ ...initalData, ...values });
      } else {
        method = () =>
          TicketService.editTicketComment({
            ...initalData,
            ...editComment,
            ...values,
          });
      }

      await method();

      message.success(
        `Comment ${!editComment ? "added" : "edited"} successfully!`
      );

      setEditComment(null);
      fetchComments();
      form.resetFields();
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message || "Failed to add comment");
    } finally {
      setLoading("");
    }
  };

  const handleClose = () => {
    onClose();
    setEditComment(null);
    setCommentsList([]);
    form.resetFields();
  };

  return (
    <Drawer
      title="Comments"
      width={600}
      open={open}
      onClose={handleClose}
      bodyStyle={{
        padding: "8px 20px",
      }}
      footer={
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => handleCommentAction(values)}
        >
          {editComment && (
            <p className="mb-2 text-xs text-gray-400">{`Editing comment by ${
              editComment?.updatedBy || editComment?.addedBy
            } on ${formatDateAndTime(
              editComment?.updatedDate || editComment?.dateAdded
            )}`}</p>
          )}

          <Form.Item
            name="comment"
            rules={[
              {
                required: false,
                message: "Enter Comment!",
              },
              { validator: validateTextInput("Comment", 2, 1024) },
            ]}
          >
            <Input.TextArea
              disabled={commentsLoading}
              onBlur={(e) => {
                if (!e.target.value) form.resetFields();
              }}
              rows={3}
              placeholder="Add a comment"
            />
          </Form.Item>

          {commentText && (
            <div className="flex justify-end gap-2 mt-4 mb-2">
              <CustomButton
                htmlType="submit"
                type="primary"
                loading={loading === "comment-add"}
                icon={<CheckOutlined />}
              >
                {!editComment ? "Add Comment" : "Edit Comment"}
              </CustomButton>

              <CustomButton
                type="cancel"
                onClick={() => {
                  form.resetFields();
                  setEditComment(null);
                }}
                icon={<CloseOutlined />}
              >
                Cancel
              </CustomButton>
            </div>
          )}
        </Form>
      }
    >
      <List
        loading={commentsLoading}
        itemLayout="horizontal"
        dataSource={commentsList}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No comments on this ticket!"
            />
          ),
        }}
        renderItem={(comment) => (
          <List.Item
            className={`duration-200 transition-all ${
              comment?.primaryIdentifier === editComment?.primaryIdentifier &&
              "!ring-2 rounded-md !p-3 !ring-blue-300"
            }`}
            actions={[
              <ToolTipButton
                disable={!comment?.isEditable}
                tooltipHeader={
                  comment?.isEditable
                    ? "Edit Comment"
                    : "This comment is non editable."
                }
                tooltipPlacement="left"
                className={`-ml-6 ${
                  !comment?.isEditable &&
                  "text-gray-300 hover:!text-gray-200 opacity-50 cursor-not-allowed"
                }`}
                icon={<EditOutlined />}
                type="link"
                onClick={() => {
                  if (!comment?.isEditable) return;

                  setEditComment(comment);
                  form.setFieldValue("comment", comment?.comment);
                }}
              />,
            ]}
          >
            <List.Item.Meta
              avatar={
                <Avatar>{comment?.addedBy?.charAt(0).toUpperCase()}</Avatar>
              }
              title={
                <div className="flex items-center justify-between text-gray-400">
                  <span>{`${comment?.addedBy}`}</span>
                  <span className="text-xs ">
                    {comment.isEdited ? (`(Edited) `) : null}
                    {formatDateAndTime(comment.isEdited ? comment.updatedDate : comment?.dateAdded)}
                  </span>
                </div>
              }
              description={
                <div>
                  <p
                    className={`${isDarkMode ? "text-white" : "text-gray-700"}`}
                  >
                    {comment?.comment}
                  </p>

                  {comment?.updatedBy && (
                    <p className="mt-2 text-xs text-right text-gray-400">
                      {`Edited by ${comment?.updatedBy} on ${formatDateAndTime(
                        comment?.updatedDate
                      )} (${comment?.timesCommentEdited} times)`}
                    </p>
                  )}
                </div>
              }
            />
          </List.Item>
        )}
      />
    </Drawer>
  );
};

export default TicketCommentsDrawer;
