import { MailOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Descriptions,
  Image,
  message,
  Skeleton,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { FaRegUser } from "react-icons/fa6";
import { RiLoginCircleLine } from "react-icons/ri";
import CustomBreadcrumb from "../../Common/CustomBreadcrumb";
import { useDarkMode } from "../../Providers/DarkModeContext";
import { useScreenSize } from "../../Providers/ScreenSizeProvider";
import ManageUserService from "../../services/User/ManageUserService";
import { formatDateMonth, formatTime12Hour } from "../../utils/dateHelper";

const UserProfile = () => {
  const [user, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  const currentScreenSize = useScreenSize();
  const { isDarkMode } = useDarkMode();

  const fetchUserDetails = async () => {
    try {
      const res = await ManageUserService.getUserSelfDetails();

      setUserDetails(res?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      error?.response?.data?.message
        ? message.error(error?.response?.data?.message)
        : message.error("Something went wrong");
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  return (
    <div className="w-full p-2 mt-6 mr-4">
      <CustomBreadcrumb
        items={[
          {
            path: "/",
            title: "Home",
          },
          {
            path: "/user/self/detail",
            title: "My profile",
          },
        ]}
      />

      <div className="flex flex-col flex-wrap items-start justify-center w-full py-8 mx-auto mt-5 gap-y-8 gap-x-12 xl:flex-row">
        <div
          className={`w-full bg-secondary/40 h-full border ${
            isDarkMode ? "border-gray-600" : "border-gray-200"
          } xl:w-[25%] gap-4 rounded-lg flex flex-col items-center justify-center py-10 px-6`}
        >
          {loading ? (
            <Skeleton.Avatar active={true} size={160} shape="circle" />
          ) : user?.profilePictureLink ? (
            <Image
              src={user?.profilePictureLink}
              className="rounded-full"
              preview={false}
              rootClassName="rounded-full w-[10rem]"
            />
          ) : (
            <Avatar
              style={{
                verticalAlign: "middle",
                cursor: "default",
                width: "10rem",
                height: 160,
                fontSize: 80,
              }}
              className="flex items-center justify-center font-serif cursor-default"
              size="large"
            >
              {user?.name ? (
                user?.name?.slice(0, 1).toUpperCase()
              ) : (
                <UserOutlined />
              )}
            </Avatar>
          )}

          <Typography className="text-xl">
            {loading ? (
              <Skeleton.Input block active />
            ) : user?.name ? (
              user?.name
            ) : (
              "-"
            )}
          </Typography>
        </div>

        <Descriptions
          className="w-full xl:w-1/2"
          contentStyle={{
            fontSize: currentScreenSize > 720 ? 18 : 14,
            borderBottom: `1px solid  ${isDarkMode ? "#4b5563" : "#00000020"}`,
            paddingBottom: 10,
          }}
          labelStyle={{
            width: currentScreenSize > 720 ? "14rem" : "",
            fontSize: currentScreenSize > 720 ? 18 : 14,
            color: isDarkMode ? "white" : "black",
          }}
          column={1}
          title={<span className="text-xl">Your Details</span>}
          size="middle"
          colon={false}
        >
          <Descriptions.Item
            label={
              <div className="flex items-center gap-3">
                <FaRegUser /> Name
              </div>
            }
          >
            {loading ? (
              <Skeleton.Input block active />
            ) : user?.name ? (
              user?.name
            ) : (
              "-"
            )}
          </Descriptions.Item>

          <Descriptions.Item
            label={
              <div className="flex items-center gap-3">
                <MailOutlined /> Email
              </div>
            }
          >
            {loading ? (
              <Skeleton.Input block active />
            ) : user?.emailAddress ? (
              user?.emailAddress
            ) : (
              "-"
            )}
          </Descriptions.Item>

          <Descriptions.Item
            label={
              <div className="flex items-center gap-3">
                <RiLoginCircleLine /> Last login
              </div>
            }
          >
            {loading ? (
              <Skeleton.Input block active />
            ) : user?.lastLoginDate ? (
              `${formatDateMonth(user?.lastLoginDate)} | ${formatTime12Hour(
                user?.lastLoginDate
              )}`
            ) : (
              "-"
            )}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </div>
  );
};

export default UserProfile;
