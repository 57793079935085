import {
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Popconfirm, Table, Tag, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import CustomButton from "../../Common/CustomButton";
import FilterDropdown from "../../Common/FilterDropdown";
import TotalListEntries from "../../Common/TotalListEntries";
import { AuthContext } from "../../Providers/AuthProvider";
import { useDarkMode } from "../../Providers/DarkModeContext";
import Roles from "../../services/Roles/Roles";
import ManageUserService from "../../services/User/ManageUserService";
import { getDarkModeColor } from "../../utils/darkModeHelper";
import { formatDateMonth } from "../../utils/dateHelper";
import ManageUserDrawer from "./ManageUserDrawer";

const User = () => {
  const { isDarkMode } = useDarkMode();
  const [editData, setEditData] = useState([]);
  const [isTableDataLoading, setIsTableDataLoading] = useState(false);
  const [isColumnsHide, setIsColumnsHide] = useState(true);
  const [changeStatus, setChangeStatus] = useState("");
  const [updatePassword, setUpdatePassword] = useState(false);
  const { userPrimaryRole, refetchData } = useContext(AuthContext);
  let isAdmin = userPrimaryRole?.toLowerCase()?.includes("admin");

  const handleExtendedColumn = () => {
    setIsColumnsHide(!isColumnsHide);
  };
  const [list, setList] = useState({
    request: {
      pageRequest: {
        currentPage: 1,
        pageSize: 10,
      },
      sortRequest: {
        key: "",
        direction: true,
      },
      filterRequest: {
        firstName: "",
        name: "",
        emailAddress: "",
        password: "",
        signupmode: "",
        roles: "",
      },
    },
    response: {
      records: [],
      totalRecords: 0,
    },
  });

  const getUserListData = async (request) => {
    try {
      setIsTableDataLoading(true);
      const { data } = await ManageUserService.getStaffServicesData(
        "Staff",
        request
      );

      const modifiedData = {
        ...data,

        records: data.records.map((record) => ({
          ...record,
          createdDate: record.createdDate
            ? formatDateMonth(record.createdDate)
            : "NA",
        })),
      };

      setList((prev) => {
        setIsTableDataLoading(false);
        return {
          ...prev,
          response: modifiedData,
        };
      });
    } catch (error) {
      setIsTableDataLoading(false);
      error?.response?.data?.message
        ? message.error(error?.response?.data?.message)
        : message.error("Something went wrong");
    }
  };

  useEffect(() => {
    const { request } = list;
    getUserListData(request);
  }, []);

  const handleUserPrimaryRole = (role, record) => {
    const RoleID = role.roleID;
    const primaryIdentifier = record.primaryIdentifier;
    Roles.updateUserRoleIsPrimary(primaryIdentifier, RoleID)
      .then((res) => {
        message.success("User Role Updated Successfully");
        let { request } = list;
        getUserListData(request);
      })
      .catch((error) => {
        error?.response?.data?.message
          ? message.error(error?.response?.data?.message)
          : message.error("Something went wrong");
      });
  };

  const handleUserIsActive = (record) => {
    const primaryIdentifier = record.primaryIdentifier;
    Roles.updateUserIsActive(primaryIdentifier)
      .then((res) => {
        message.success("User Role Updated Successfully");
        const { request } = list;
        getUserListData(request);
      })
      .catch((error) => {
        error?.response?.data?.message
          ? message.error(error?.response?.data?.message)
          : message.error("Something went wrong");
      });
  };

  const handleFiltersChange = (pagination, filters, sorter, extra) => {
    const { request } = list;

    const requestFilters = Object.keys(filters).reduce((acc, key) => {
      if (filters[key]) {
        acc[key] = filters[key][0];
      }
      return acc;
    }, filters);

    setList((prev) => ({
      ...prev,
      request: {
        ...prev.request,
        pageRequest: {
          currentPage: pagination.current,
          pageSize: pagination.pageSize,
        },
      },
    }));

    getUserListData({
      ...request,
      filterRequest: requestFilters,
      sortRequest: { key: sorter.field, direction: sorter.order === "ascend" },
      pageRequest: {
        currentPage: pagination.current,
        pageSize: pagination.pageSize,
      },
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      with: 250,
      filterDropdown: (props) => (
        <FilterDropdown {...props} placeholder="Search name" />
      ),
      sorter: (a, b) => a.firstName.length - b.firstName.length,
    },
    {
      title: "Email",
      dataIndex: "emailAddress",
      key: "emailAddress",
      width: 250,
      filterDropdown: (props) => (
        <FilterDropdown {...props} placeholder="Search email" />
      ),
      sorter: (a, b) => a.emailAddress.length - b.emailAddress.length,
    },

    {
      title: "Roles",
      dataIndex: "userRole",
      key: "userRole",
      render: (_, record) => {
        return (
          <div className="flex flex-wrap justify-center gap-1 cursor-pointer">
            {record.roles.map((role) =>
              role.isPrimary ? (
                <Tag
                  key={role.id}
                  className="text-center text-white bg-green-500"
                >
                  {role.roleName}
                </Tag>
              ) : (
                <Popconfirm
                  className="flex flex-end"
                  title="Do you want to switch to this Role?"
                  onConfirm={() => handleUserPrimaryRole(role, record)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tag key={role.id} className="text-center">
                    {role.roleName}
                  </Tag>
                </Popconfirm>
              )
            )}
          </div>
        );
      },
      width: 300,
      align: "center",
    },
    {
      title: "Last Login",
      dataIndex: "lastLoginDate",
      key: "lastLoginDate",
      render: (values) => {
        return values ? <p>{formatDateMonth(values)}</p> : "NA";
      },
      hidden: isColumnsHide,
    },
    {
      title: "Signed up on",
      dataIndex: "createdDate",
      key: "createdDate",
      hidden: isColumnsHide,
    },

    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (values, record) => (
        <Popconfirm
          title={
            <p>
              Do you want to change account status to
              <span className="ml-1 font-bold">
                {values ? "Inactive" : "Active"}
              </span>
              ?
            </p>
          }
          onConfirm={() => handleUserIsActive(record)}
          okText="Yes"
          cancelText="No"
        >
          <Tag
            className={
              "text-center " +
              (values ? "bg-green-500" : "bg-gray-500") +
              " text-white cursor-pointer"
            }
          >
            {values ? "Active" : "Inactive"}
          </Tag>
        </Popconfirm>
      ),

      align: "center",
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      key: "action",
      render: (_, record) => {
        return (
          <>
            <CustomButton
              type="primary"
              icon={<EditOutlined />}
              onClick={() => {
                setEditData(record);
                setChangeStatus("edit");
              }}
            >
              Manage
            </CustomButton>
          </>
        );
      },
    },
  ].filter((col) => !col.hidden);

  let tableStartingIndex =
    (list.response.currentPage - 1) * list.response.pageSize + 1;

  if (
    list.response.totalRecords <
    list.response.pageSize * list.response.currentPage
  ) {
    tableStartingIndex =
      (list.response.currentPage - 1) * list.response.pageSize + 1;
  }

  return (
    <div>
      <div className="flex items-start justify-between mb-2">
        <TotalListEntries
          checkNullCondition={list.response.records.length > 0}
          startingIndex={tableStartingIndex}
          totalEntries={list.response.totalRecords}
          currentPage={list.response.currentPage}
          pageSize={list.response.pageSize}
        />

        <div className="flex flex-row items-start gap-2">
          {isAdmin && (
            <CustomButton
              id="addUser"
              onClick={() => {
                setChangeStatus("add");
                setEditData(null);
              }}
              type="primary"
              block
              icon={<PlusOutlined />}
            >
              Add
            </CustomButton>
          )}
          <CustomButton
            onClick={handleExtendedColumn}
            type="primary"
            icon={isColumnsHide ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          >
            {isColumnsHide ? "Extend" : "Hide"}
          </CustomButton>
        </div>
      </div>

      <div>
        <Table
          pagination={{
            pageSize: list.response.pageSize,
            current: list.response.currentPage,
            total: list.response.totalRecords,
            showSizeChanger: true,
          }}
          size="small"
          dataSource={list.response.records}
          columns={columns}
          scroll={{ x: true }}
          loading={isTableDataLoading}
          onChange={handleFiltersChange}
          tableLayout="fixed"
          rowClassName={(record, index) =>
            index % 2 !== 0 ? getDarkModeColor(isDarkMode, "bg-gray-100") : ""
          }
        />
      </div>

      <ManageUserDrawer
        updatePassword={updatePassword}
        setUpdatePassword={setUpdatePassword}
        onSuccess={() => {
          const { request } = list;
          getUserListData(request);
        }}
        changeStatus={changeStatus}
        initialValue={editData}
        onClose={() => {
          setEditData(null);
          setChangeStatus(null);
          setUpdatePassword(false);
        }}
      />
    </div>
  );
};

export default User;
