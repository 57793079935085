import { EditTwoTone } from "@ant-design/icons";
import { Descriptions, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { getBillingAddressString } from "../services/CommonService";
import ToolTipButton from "./ToolTipButton";

const ShowAddressDetails = ({
  type,
  title,
  address,
  isAccountSelected,
  toggleAddressesDrawer,
}) => {
  return address ? (
    <Descriptions
      bordered
      column={{ sm: 1, xl: 2 }}
      className="w-full -mb-2"
      rootClassName="mb-0"
      labelStyle={{ width: "6rem" }}
    >
      <Descriptions.Item span={2}>
        <div className="flex items-center justify-between">
          <Typography className="text-base font-semibold -ml-[6rem]">
            {title}
          </Typography>

          {isAccountSelected && (
            <ToolTipButton
              tooltipHeader="Change shipping address"
              tooltipPlacement="right"
              type="link"
              icon={<EditTwoTone />}
              onClick={() => toggleAddressesDrawer(true)}
              key="edit"
            />
          )}
        </div>
      </Descriptions.Item>

      <Descriptions.Item label="Name">
        {address.contactPersonName ? address.contactPersonName : "-"}
      </Descriptions.Item>

      <Descriptions.Item label="Phone">
        <Link to={"tel:" + address.phone} className="text-blue-500">
          {address.phone ? address.phone : "-"}
        </Link>
      </Descriptions.Item>

      <Descriptions.Item label="Email">
        <Link to={"mailto:" + address.emailAddress} className="text-blue-500">
          {address.emailAddress ? address.emailAddress : "-"}
        </Link>
      </Descriptions.Item>

      <Descriptions.Item label="Company">
        {address.company ? address.company : "-"}
      </Descriptions.Item>

      <Descriptions.Item label="Address">
        <Link
          to={"http://maps.google.com/?q=" + getBillingAddressString(address)}
          target="_blank"
          className="text-blue-500"
        >
          {getBillingAddressString(address)}
        </Link>
      </Descriptions.Item>
    </Descriptions>
  ) : null;
};

export default ShowAddressDetails;
