import {
  CheckOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Card,
  Divider,
  Table,
  Tooltip,
  Typography,
  Upload,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../Common/CustomBreadcrumb";
import CustomButton from "../../Common/CustomButton";
import { AuthContext } from "../../Providers/AuthProvider";
import CustomerService from "../../services/CustomerService/CustomerService";
import { exportErrorExcel } from "../../utils/exportErrors";

const ImportEnquiryDetailsPage = () => {
  let { userPrimaryRole } = useContext(AuthContext);
  let isAdmin = userPrimaryRole?.toLowerCase()?.includes("admin");
  const navigate = useNavigate();
  const location = useLocation();

  const type = location.state ? location.state?.lastPage : "";

  const primaryIdentifier = location.state
    ? location.state.primaryIdentifier
    : "";

  useEffect(() => {
    if (!isAdmin || location.state === null) {
      return navigate(-1);
    }
  }, []);

  const items = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "Customer",
      path: "/customer/list",
    },
    {
      title: "Enquiry",
      path: "/customer/account/detail",
    },
    {
      title: `Import ${location.state ? location.state?.lastPage : ""}`,
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState("");
  const [canSelectFile, setCanSelectFile] = useState(false);
  const [errorForExcelFile, setErrorForExcelFile] = useState([]);

  const downloadSampleTemplate = async () => {
    try {
      setIsLoading(true);
      const response =
        type === "Address"
          ? await CustomerService.getSampleTemplateOfAddress()
          : await CustomerService.getSampleTemplateOfContacts();

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download =
        type === "Address"
          ? "ADDRESS-LIST-TEMPLATE.xlsx"
          : "CONTACT-LIST-TEMPLATE.xlsx";
      link.click();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error downloading file:", error);
      message.error(
        error?.response?.data?.message ||
          "Unable to download right now. Try again later!"
      );
    }
  };

  const handleCheckData = async () => {
    const formData = new FormData();

    formData.append("File", fileList[0]);
    formData.append("IsDryRun", true);

    setUploading("check-file");
    try {
      const res =
        type === "Address"
          ? await CustomerService.importAddressList(primaryIdentifier, formData)
          : await CustomerService.importContactList(
              primaryIdentifier,
              formData
            );
      if (res?.data?.hasError) {
        setErrorForExcelFile(res?.data.rows);
        exportErrorExcel(
          res?.data.rows,
          type === "Address"
            ? "ADDRESS-LIST-ERRORS.xlsx"
            : "CONTACT-LIST-ERRORS.xlsx",
          type === "Address" ? "address" : "contact"
        );
      } else {
        setCanSelectFile(true);
        message.success("The file has passed validations.");
      }
    } catch (error) {
      console.error(error);
      message.error(
        error?.response?.data?.message ||
          "Unable to check file right now, Try again later!"
      );
    } finally {
      setUploading("");
    }
  };

  const handleUpload = async () => {
    const formData = new FormData();

    formData.append("File", fileList[0]);
    formData.append("IsDryRun", false);

    setUploading("upload-file");
    try {
      type === "Address"
        ? await CustomerService.importAddressList(primaryIdentifier, formData)
        : await CustomerService.importContactList(primaryIdentifier, formData);

      message.success(`Added ${type}s successfully!`);
      setFileList([]);
      setCanSelectFile(false);
      setErrorForExcelFile([]);
    } catch (error) {
      console.error(error);
      message.error(
        error?.response?.data?.message ||
          "Unable to upload the file. Try again later!"
      );
    } finally {
      setUploading("");
    }
  };

  const addressColumns = [
    {
      title: "Error",
      dataIndex: "validationMessage",
      key: "validationMessage",
    },
    {
      title: "Address 1",
      dataIndex: "address1",
      key: "address1",
    },
    {
      title: "Address 2",
      dataIndex: "address2",
      key: "address2",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
  ];

  const contactColumns = [
    {
      title: "Error",
      dataIndex: "validationMessage",
      key: "validationMessage",
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Contact Name",
      dataIndex: "contactName",
      key: "contactName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
  ];

  return (
    <div className="p-2 mt-4">
      <CustomBreadcrumb items={items} />

      <Card
        title={`Import ${location.state ? location.state?.lastPage : ""}`}
        size="default"
        className="mt-4 drop-shadow-sm"
        extra={
          <CustomButton
            size="middle"
            icon={<DownloadOutlined />}
            loading={isLoading}
            type="secondary"
            className="!my-2"
            onClick={downloadSampleTemplate}
          >
            Download Template
          </CustomButton>
        }
      >
        <div className="flex flex-wrap items-center justify-between w-full gap-y-4 gap-x-8">
          <div className="flex items-center">
            <Upload
              accept=".xlsx,.csv"
              className="flex flex-wrap items-center gap-3"
              maxCount={1}
              fileList={fileList}
              itemRender={(_, file) => {
                return (
                  <div className="flex items-center justify-between gap-3 py-[4px] border border-gray-300 dark:border-gray-600 px-3 rounded-md">
                    <Typography.Text
                      ellipsis={{ tooltip: true }}
                      rootClassName="!max-w-[8rem] md:!max-w-[15rem]"
                    >
                      {file?.name}
                    </Typography.Text>

                    <Tooltip title="Remove file">
                      <DeleteOutlined
                        className="text-base transition duration-200 cursor-pointer hover:text-blue-400"
                        onClick={() => {
                          setFileList([]);
                          setCanSelectFile(false);
                          setErrorForExcelFile([]);
                        }}
                      />
                    </Tooltip>
                  </div>
                );
              }}
              onRemove={() => {
                setFileList([]);
                setCanSelectFile(false);
                setErrorForExcelFile([]);
              }}
              beforeUpload={(file) => {
                setFileList([file]);
                setCanSelectFile(false);
                setErrorForExcelFile([]);
                return false;
              }}
            >
              <CustomButton size="middle" icon={<CloudUploadOutlined />}>
                {fileList.length === 0 ? "Select File" : "Change File"}
              </CustomButton>
            </Upload>

            {fileList.length === 0 && (
              <div className="hidden text-blue-500 md:block">
                No file Selected
              </div>
            )}
          </div>

          {fileList.length !== 0 && (
            <div className="flex flex-wrap items-center gap-2">
              <CustomButton
                size="middle"
                type="green"
                onClick={handleCheckData}
                disabled={canSelectFile || errorForExcelFile.length > 0}
                loading={uploading === "check-file"}
                icon={
                  !canSelectFile ? <InfoCircleOutlined /> : <CheckOutlined />
                }
              >
                Check File
              </CustomButton>

              <CustomButton
                size="middle"
                type="primary"
                onClick={handleUpload}
                disabled={!canSelectFile}
                loading={uploading === "upload-file"}
                icon={<UploadOutlined />}
              >
                Upload
              </CustomButton>
            </div>
          )}
        </div>

        {errorForExcelFile.length === 0 && (
          <Typography className="mt-2 font-medium text-gray-400">
            Note: Maximum 500 rows are accepted at once
          </Typography>
        )}

        {errorForExcelFile.length > 0 && (
          <>
            <Divider orientation="center">Errors in uploaded file</Divider>

            <Table
              dataSource={errorForExcelFile}
              columns={type === "Address" ? addressColumns : contactColumns}
              pagination={false}
              bordered
              size="middle"
              scroll={{ x: 1100 }}
            />
          </>
        )}
      </Card>
    </div>
  );
};

export default ImportEnquiryDetailsPage;
