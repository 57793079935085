import React, { memo } from "react";
import { Area, Line } from "@ant-design/charts";
import { Empty, Skeleton } from "antd";
import dayjs from "dayjs";
import { DotChartOutlined } from "@ant-design/icons";
import ChartLoading from "../../Common/ChartLoading";

const ForecastingDeliveryChart = memo(({ data, loading, height }) => {
  let formattedData = [];

  if (loading) {
    return <ChartLoading height={height} />;
  }

  if (Object.keys(data).length !== 0) {
    formattedData = Object.entries(data).map(([date, value]) => {
      return {
        date: dayjs(date).format("DD-MM-YYYY"),
        value: parseFloat(value),
      };
    });
  } else {
    return (
      <div
        className="flex items-center justify-center w-full mb-6 font-semibold"
        style={{ height: height }}
      >
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No data found"
        />
      </div>
    );
  }

  const config = {
    data: formattedData
      .filter((i) => i.value !== null)
      .sort(
        (a, b) => dayjs(a.date, "DD-MM-YYYY") - dayjs(b.date, "DD-MM-YYYY")
      ),
    xField: "date",
    yField: "value",
    point: {
      size: 3,
      shape: "circle",
    },
    xAxis: {
      label: {
        autoRotate: true,
      },
    },
    tooltip: {
      showTitle: false,
      formatter: (data) => {
        const obj = formattedData.find((i) => i.date === data.date);

        return {
          name: obj.date,
          value: obj.value,
        };
      },
    },
  };

  return <Area {...config} loading={loading} />;
});

export default ForecastingDeliveryChart;
