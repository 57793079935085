import React, { useEffect, useState } from "react";
import { Button, Card, Col, Drawer, Row, message } from "antd";
import CustomerService from "../../services/CustomerService/CustomerService";
import {
  getBillingAddressString,
  isOnlyWhiteSpace,
  isWhitespaceString,
} from "../../services/CommonService";
import {
  CarTwoTone,
  CheckCircleTwoTone,
  EditTwoTone,
  LoadingOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import ManageCustomerAddresses from "../Customer/ManageCustomerAddresses";
import { useDarkMode } from "../../Providers/DarkModeContext";
import CustomButton from "../../Common/CustomButton";

function DeliveryAddressesDrawer(props) {
  const { isDarkMode } = useDarkMode();
  const [isLoading, setIsLoading] = useState(false);
  const [addressDataList, setAddressDataList] = useState([]);
  const [accountId, setAccountId] = useState();
  const [editAddressData, setEditAddressData] = useState({});
  const [changeStatus, setChangeStatus] = useState("");

  useEffect(() => {
    if (props.selectedAccount) {
      getDeliveryAddresses();
    }
  }, [props.selectedAccount]);

  const getDeliveryAddresses = async () => {
    setIsLoading(true);

    try {
      const res = await CustomerService.getCustomerAddress(
        props.selectedAccount
      );

      if (
        editAddressData.primaryIdentifier ===
        props.billingAddress.primaryIdentifier
      ) {
        let addressData = res.data.find(
          (el) =>
            el.primaryIdentifier === props.billingAddress.primaryIdentifier
        );
        props.setBillingAddress(addressData);
        props.setDeliveryAddress(addressData);
      }
      setAccountId(res.data[0]?.consumerID);
      setAddressDataList(res.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      message.error(error?.response?.data?.message || "Somthing went wrong!");
    }
  };

  return (
    <Drawer
      width={400}
      title="Select delivery address"
      placement="right"
      onClose={() => props.toggleAddressesDrawer(false)}
      open={props.openAddressListDrawer}
      destroyOnClose
      extra={
        <CustomButton
          type="secondary"
          icon={<PlusCircleOutlined />}
          onClick={() => setChangeStatus("add")}
        >
          Add new
        </CustomButton>
      }
    >
      {isLoading ? (
        <div className="flex items-center justify-center gap-3 my-2">
          <LoadingOutlined className="text-2xl" /> Loading...
        </div>
      ) : (
        <>
          <Row gutter={[16, 16]}>
            {(addressDataList || []).map((deliveryAddress, index) => {
              return (
                <Col span={24}>
                  {deliveryAddress &&
                    Object.keys(deliveryAddress).length > 0 &&
                    deliveryAddress.constructor === Object && (
                      <Card
                        size="small"
                        actions={
                          index === 0
                            ? [
                                deliveryAddress.primaryIdentifier ===
                                props.deliveryAddress.primaryIdentifier ? (
                                  <div style={{ cursor: "default" }}>
                                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                                    <span
                                      className="ms-2"
                                      style={
                                        !isDarkMode
                                          ? { color: "black" }
                                          : { color: "white" }
                                      }
                                    >
                                      Currently Selected
                                    </span>
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => {
                                      props.setDeliveryAddress(deliveryAddress);
                                      props.setIsLoading(false);
                                    }}
                                  >
                                    <CarTwoTone key="delivery" />
                                    <span
                                      className="ms-2"
                                      style={{ color: "#1677FF" }}
                                    >
                                      Use this for delivery
                                    </span>
                                  </div>
                                ),
                              ]
                            : [
                                <div
                                  onClick={() => {
                                    setChangeStatus("edit");
                                    setEditAddressData(deliveryAddress);
                                  }}
                                >
                                  <EditTwoTone key="edit" />
                                  <span
                                    className="ms-2"
                                    style={{ color: "#1677FF" }}
                                  >
                                    Edit Address
                                  </span>
                                </div>,
                                <div>
                                  {deliveryAddress.primaryIdentifier ===
                                  props.deliveryAddress.primaryIdentifier ? (
                                    <div style={{ cursor: "default" }}>
                                      <CheckCircleTwoTone twoToneColor="#52c41a" />
                                      <span
                                        className="ms-2"
                                        style={
                                          !isDarkMode
                                            ? { color: "black" }
                                            : { color: "white" }
                                        }
                                      >
                                        Currently Selected
                                      </span>
                                    </div>
                                  ) : (
                                    <div
                                      onClick={() => {
                                        props.setDeliveryAddress(
                                          deliveryAddress
                                        );
                                        props.setIsLoading(false);
                                      }}
                                    >
                                      <CarTwoTone key="delivery" />
                                      <span
                                        className="ms-2"
                                        style={{ color: "#1677FF" }}
                                      >
                                        Use this for delivery
                                      </span>
                                    </div>
                                  )}
                                </div>,
                              ]
                        }
                      >
                        {Object.keys(deliveryAddress).length > 0 ? (
                          <>
                            <div style={{ overflowY: "auto" }}>
                              <div title={"Contact Person"}>
                                {deliveryAddress.contactPersonName &&
                                !isOnlyWhiteSpace(
                                  deliveryAddress.contactPersonName
                                )
                                  ? deliveryAddress.contactPersonName
                                  : "-"}
                              </div>

                              {deliveryAddress.phone ? (
                                <div title={"Phone"}>
                                  <a
                                    href={"tel:" + deliveryAddress.phone}
                                    style={{ color: "#1890ff" }}
                                  >
                                    {deliveryAddress.phone}
                                  </a>
                                </div>
                              ) : null}
                              {deliveryAddress.emailAddress ? (
                                <div title={"Email"}>
                                  <a
                                    href={
                                      "mailto:" + deliveryAddress.emailAddress
                                    }
                                    style={{ color: "#1890ff" }}
                                  >
                                    {deliveryAddress.emailAddress}
                                  </a>
                                </div>
                              ) : null}
                              {deliveryAddress.company ? (
                                <div>{deliveryAddress.company}</div>
                              ) : null}
                              <div>
                                <a
                                  href={
                                    "http://maps.google.com/?q=" +
                                    getBillingAddressString(deliveryAddress)
                                  }
                                  target="_blank"
                                  style={{ color: "#1890ff" }}
                                >
                                  {getBillingAddressString(deliveryAddress)}
                                </a>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </Card>
                    )}
                </Col>
              );
            })}
          </Row>

          <ManageCustomerAddresses
            setEditAddressData={setEditAddressData}
            accountId={accountId}
            initialValue={changeStatus === "edit" ? editAddressData : null}
            onClose={() => {
              setChangeStatus(null);
              setEditAddressData({});
            }}
            onSuccess={() => {
              if (props.selectedAccount) {
                getDeliveryAddresses(props.selectedAccount);
              }
            }}
            changeStatus={changeStatus}
          />
        </>
      )}
    </Drawer>
  );
}

export default DeliveryAddressesDrawer;
