import {
  EditOutlined,
  FileAddOutlined,
  FileExcelOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Row, Table, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Common/CustomButton";
import { AuthContext } from "../../Providers/AuthProvider";
import { useDarkMode } from "../../Providers/DarkModeContext";
import { useScreenSize } from "../../Providers/ScreenSizeProvider";
import CustomerService from "../../services/CustomerService/CustomerService";
import { getDarkModeColor } from "../../utils/darkModeHelper";
import ManageCustomerContacts from "./ManageCustomerContacts";

const CustomerContact = ({ primaryIdentifier, customerData }) => {
  const { userDetails } = useContext(AuthContext);
  const currentScreenSize = useScreenSize();
  const { isDarkMode } = useDarkMode();
  const { isExternalUser } = userDetails;
  const [isTableDataLoading, setIsTableDataLoading] = useState(false);
  const [changeStatus, setChangeStatus] = useState("");
  const [editContactData, setEditContactData] = useState(null);
  const [contactList, setContactList] = useState([]);
  const [isExporting, setIsExporting] = useState(false);

  const navigate = useNavigate();

  let { userPrimaryRole } = useContext(AuthContext);
  let isAdmin = userPrimaryRole?.toLowerCase()?.includes("admin");
  const [accountId, setAccountId] = useState("");

  const handleMultipleContacts = () => {
    setChangeStatus("add");
    setEditContactData(null);
  };

  const fetchData = (primaryIdentifier) => {
    setIsTableDataLoading(true);
    CustomerService.getCustomerContactList(primaryIdentifier)
      .then((res) => {
        setAccountId(customerData?.debtorResponse?.accountID);
        setContactList(res.data);
        setIsTableDataLoading(false);
      })
      .catch((error) => {
        console.log(error);
        error?.response?.data?.message
          ? message.error(error?.response?.data?.message)
          : message.error("Something went Wrong");

        setIsTableDataLoading(false);
      });
  };

  useEffect(() => {
    if (primaryIdentifier) {
      fetchData(primaryIdentifier);
    }
  }, [primaryIdentifier]);

  const handleExport = async () => {
    setIsExporting(true);

    try {
      const response = await CustomerService.exportContactList(
        primaryIdentifier
      );

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "CONTACTS-LIST.xlsx";
      link.click();
      setIsExporting(false);
    } catch (error) {
      setIsExporting(false);
      console.error(error?.response?.data?.message);
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went wrong!"
      );
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      align: "center",
    },
    {
      title: "Phone No.",
      dataIndex: "phone",
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Fax",
      dataIndex: "fax",
      align: "center",
    },
    {
      title: "Action",
      align: "center",
      dataIndex: "action",
      render: (_, record) => {
        return (
          isExternalUser === false && (
            <CustomButton
              type="primary"
              icon={<EditOutlined />}
              onClick={() => {
                setEditContactData(record);
                setChangeStatus("edit");
              }}
            >
              Edit
            </CustomButton>
          )
        );
      },
    },
  ];

  return (
    <div className="relative">
      {contactList && (
        <div>
          <div className="right-0 z-50 flex justify-end mb-2 xl:absolute -top-12">
            {isAdmin && isExternalUser === false && (
              <Row className="gap-3">
                <CustomButton
                  className="hover:!bg-cyan-400 hover:!border-cyan-400 hover:!text-white"
                  type="dashed"
                  onClick={() =>
                    navigate("/customer-list/enquiry/import-details", {
                      state: {
                        lastPage: "Contact",
                        primaryIdentifier: primaryIdentifier,
                      },
                    })
                  }
                  icon={<FileAddOutlined />}
                >
                  Import
                </CustomButton>

                {contactList.length > 0 && (
                  <CustomButton
                    type="dashed"
                    onClick={handleExport}
                    loading={isExporting}
                    className="hover:!bg-green-400 hover:!border-green-400 hover:!text-white"
                    icon={<FileExcelOutlined />}
                  >
                    Export
                  </CustomButton>
                )}

                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={handleMultipleContacts}
                >
                  Add New Contact
                </Button>
              </Row>
            )}
          </div>
          <Table
            scroll={{
              x: currentScreenSize < 1024 ? 1300 : 1000,
            }}
            tableLayout="fixed"
            loading={isTableDataLoading}
            columns={columns}
            dataSource={contactList}
            rowClassName={(record, index) =>
              index % 2 !== 0 ? getDarkModeColor(isDarkMode, "bg-gray-100") : ""
            }
          />
        </div>
      )}
      <ManageCustomerContacts
        accountId={customerData?.debtorResponse?.accountID}
        initialValue={editContactData}
        onClose={() => {
          setChangeStatus(null);
          setEditContactData(null);
        }}
        onSuccess={() => {
          if (primaryIdentifier) {
            fetchData(primaryIdentifier);
          }
        }}
        changeStatus={changeStatus}
      />
    </div>
  );
};

export default CustomerContact;
