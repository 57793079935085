import { AutoComplete, Drawer, Input } from "antd";
import React from "react";

const SchedulerOrderSearchDrawer = ({ isDrawerOpen, onCancel }) => {
  return (
    <Drawer
      title={"Search"}
      width={400}
      onClose={onCancel}
      open={isDrawerOpen}
      bodyStyle={{
        padding: "4px 16px",
      }}
      destroyOnClose={true}
    >
      <AutoComplete
        className="w-full mt-4"
        placeholder={
          <div className="text-gray-400 font-semibold ">
            Search order number here
          </div>
        }
        // options={options}
        size="small"
      >
        <Input.Search size="small" />
      </AutoComplete>
    </Drawer>
  );
};

export default SchedulerOrderSearchDrawer;
