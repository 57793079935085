import {
  CheckOutlined,
  CloseOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { Drawer, Form, Input, Row, Select, message } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import CustomerService from "../../services/CustomerService/CustomerService";
import CustomButton from "../../Common/CustomButton";

const ManageOrganizationContact = ({
  changeStatus,
  onClose,
  initialValue,
  onSuccess,
  accountId,
}) => {
  const [form] = useForm();
  const [IsLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (changeStatus === "edit") {
      form.setFieldsValue({ ...initialValue });
    } else {
      form.resetFields();
    }

    return () => form.setFieldsValue(null);
  }, [initialValue, changeStatus]);

  const onFinish = (values) => {
    if (changeStatus === "edit") {
      setIsLoading(true);
      const payload = {
        ...values,
        primaryIdentifier: initialValue.primaryIdentifier,
        consumerID: accountId,
        id: initialValue.id,
        consumerType: "Consumer",
        contactTypeID: 0,
        isPrimary: false,
        isEmailStatement: true,
        isEmailInvoice: true,
        isEmailDispatchNotice: true,
        isEmailMarketing: true,
        priority: 0,
        createdBy: 0,
      };

      CustomerService.updateCustomerContactData(payload)
        .then((res) => {
          setIsLoading(false);
          message.success("Contacts Details Updated Successfully");
          form.resetFields();
          onClose();
          onSuccess();
        })
        .catch((error) => {
          setIsLoading(false);

          error?.response?.data?.message
            ? message.error(error?.response?.data?.message)
            : message.error("Something went Wrong");
        });
    } else if (changeStatus === "add") {
      const payload = {
        ...values,
        id: 0,
        consumerID: accountId,
        consumerType: "Consumer",
        contactTypeID: 0,
        isPrimary: false,
        isEmailStatement: true,
        isEmailInvoice: true,
        isEmailDispatchNotice: true,
        isEmailMarketing: true,
        priority: 0,
        createdBy: 0,
        primaryIdentifier: "",
      };

      CustomerService.addCustomerContactData(payload)
        .then((res) => {
          setIsLoading(false);
          message.success("Contacts Details Added Successfully");
          form.resetFields();
          onClose();
          onSuccess();
        })
        .catch((error) => {
          setIsLoading(false);
          error?.response?.data?.message
            ? message.error(error?.response?.data?.message)
            : message.error("Something went wrong");
        });
    }
  };

  return (
    <Drawer
      title={
        changeStatus === "add"
          ? "Add Contact"
          : changeStatus === "edit"
          ? "Edit Contact"
          : ""
      }
      destroyOnClose={true}
      width={400}
      onClose={onClose}
      open={!!changeStatus}
      bodyStyle={{
        padding: "10px 18px",
      }}
    >
      <Form
        form={form}
        name="form_item_path"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          name="firstName"
          label="First Name"
          rules={[
            {
              required: true,
              message: "Enter first name",
            },
          ]}
        >
          <Input size="medium" type="name" placeholder="Enter first name" />
        </Form.Item>

        <Form.Item name="lastName" label="Last Name">
          <Input size="medium" type="name" placeholder="Enter last name" />
        </Form.Item>

        <Form.Item
          name="jobTitle"
          label="Job Title"
          rules={[
            {
              required: true,
              message: "Enter job title",
            },
          ]}
        >
          <Input type="text" size="medium" placeholder="Enter job title" />
        </Form.Item>

        <Form.Item
          name="mobile"
          label="Mobile Number"
          rules={[
            {
              required: true,
              message: "Enter mobile number",
            },
          ]}
        >
          <Input
            type="number"
            size="medium"
            placeholder="Enter mobile number"
          />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: "Enter email",
            },
          ]}
        >
          <Input type="email" size="medium" placeholder="Enter email" />
        </Form.Item>

        <Form.Item name="phone" label="Landline Number">
          <Input
            type="number"
            size="medium"
            placeholder="Enter landline number"
          />
        </Form.Item>

        <Form.Item name="fax" label="Fax Number">
          <Input type="number" size="medium" placeholder="Enter fax number" />
        </Form.Item>

        <div className="flex justify-end gap-2 mt-4">
          <CustomButton
            type="primary"
            htmlType="submit"
            loading={IsLoading}
            icon={<CheckOutlined />}
          >
            Submit
          </CustomButton>
          <CustomButton
            type="cancel"
            onClick={onClose}
            icon={<CloseOutlined />}
          >
            Cancel
          </CustomButton>
        </div>
      </Form>
    </Drawer>
  );
};

export default ManageOrganizationContact;
