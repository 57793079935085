import axios from "../axios";

class ChecklistService {
  static Instance() {
    return new ChecklistService();
  }

  getList() {
    return axios.get("/api/checklist");
  }

  deleteChecklist() {
    return axios.delete("/api/checklist");
  }

  createChecklist(payload) {
    return axios.post(`/api/checklist`, payload);
  }

  updateChecklist(payload) {
    return axios.put(`/api/checklist`, payload);
  }

  getOptionsList(checkListId) {
    return axios.get(`/api/checklist/options/${checkListId}`);
  }

  createChecklistOption(payload) {
    return axios.post(`/api/checklist/options`, payload);
  }

  updateChecklistOption(payload) {
    return axios.put(`/api/checklist/options`, payload);
  }

  getOptionTypes() {
    return axios.get(`/api/checklist/options/types`);
  }

  getChecklistDropdown() {
    return axios.get("/api/checklist/dropdown");
  }
}

export default ChecklistService.Instance();
