import { CloseCircleFilled, SearchOutlined } from "@ant-design/icons";
import { Card, Drawer, Empty, Input, Spin } from "antd";
import React, { useState } from "react";
import CustomButton from "../../Common/CustomButton";
import OrderManagementService from "../../services/OrderManagement/OrderManagementService";
import { formatDateMonth } from "../../utils/dateHelper";

function OrderManagementSearchOrderNum({
  searchText,
  setSearchText,
  isOpen,
  toggle,
  getOrderTabDetails,
  setIsLoading,
}) {
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [message, setMessage] = useState("Search your orders.");
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    setOrdersLoading(true);

    clearTimeout(timeoutId);

    const newTimeout = setTimeout(() => {
      searchOrders(e.target.value);
    }, 500);

    setTimeoutId(newTimeout);
  };

  const searchOrders = async (value) => {
    if (!value.trim().length > 0) {
      setOrdersLoading(false);
      return;
    }

    let refNum = value.toString();

    try {
      setOrdersLoading(true);
      const { data } = await OrderManagementService.getOrderDetailsList(refNum);

      if (data.length) {
        setFilteredOrders(data);
      } else {
        setMessage("No orders found!");
        setFilteredOrders([]);
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message ||
          "Unable to fetch orders. Try again later!"
      );
    } finally {
      setOrdersLoading(false);
    }
  };

  return (
    <Drawer
      title="Search Order"
      placement="right"
      onClose={() => {
        toggle(!isOpen);
        setMessage("Search your orders.");
      }}
      open={isOpen}
      destroyOnClose={true}
    >
      <div className="mb-4">
        <Input
          onChange={handleSearch}
          addonBefore={<SearchOutlined />}
          placeholder="Search"
          name="searchText"
          size="medium"
          value={searchText}
          allowClear={{
            clearIcon: (
              <CloseCircleFilled onClick={() => setFilteredOrders([])} />
            ),
          }}
        />
      </div>

      {ordersLoading ? (
        <div className="flex items-center justify-center mt-6">
          <Spin size="small" />
        </div>
      ) : filteredOrders.length === 0 ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={message} />
      ) : (
        filteredOrders?.map((order, orderIndex) => {
          return (
            <Card key={orderIndex} className={"my-3"}>
              {order.quoteRefnum && (
                <div className={"mb-2"}>
                  <strong className="me-2">Reference Number :</strong>
                  <CustomButton
                    type="primary"
                    size="small"
                    onClick={() => {
                      getOrderTabDetails(order);
                      setIsLoading(true);
                      setOrdersLoading(false);
                      setSearchText("");
                    }}
                  >
                    {order.quoteRefnum}
                  </CustomButton>
                </div>
              )}

              {order.ordnum && (
                <div className={"mb-2"}>
                  <strong className="me-2">Platform Order Number :</strong>
                  <CustomButton
                    type="primary"
                    size="small"
                    onClick={() => {
                      setIsLoading(true);
                      getOrderTabDetails(order);
                      setOrdersLoading(false);
                    }}
                  >
                    {order.ordnum}
                  </CustomButton>
                </div>
              )}

              {order.jobNumber && (
                <div className={"mb-2"}>
                  <strong className="me-2">Job number :</strong>
                  <CustomButton
                    type="primary"
                    size="small"
                    onClick={() => {
                      setIsLoading(true);
                      getOrderTabDetails(order);
                      setOrdersLoading(false);
                    }}
                  >
                    {order.jobNumber}
                  </CustomButton>
                </div>
              )}

              {order.internalIdentificationNum && (
                <div className={"mb-2"}>
                  <strong className="me-2">Vim/Stock number :</strong>
                  <CustomButton
                    type="primary"
                    size="small"
                    onClick={() => {
                      setIsLoading(true);
                      getOrderTabDetails(order);
                      setOrdersLoading(false);
                    }}
                  >
                    {order.internalIdentificationNum}
                  </CustomButton>
                </div>
              )}

              {order.orderDate && (
                <div className={"mb-2"}>
                  <strong className="me-2">Order Date :</strong>
                  {order.orderDate ? formatDateMonth(order.orderDate) : "NA"}
                </div>
              )}

              {order.statusName && (
                <div className={"mb-2"}>
                  <strong className="me-2">Status :</strong>
                  {order.statusName}
                </div>
              )}
            </Card>
          );
        })
      )}
    </Drawer>
  );
}
export default OrderManagementSearchOrderNum;
