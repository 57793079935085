import { EyeFilled } from "@ant-design/icons";
import { Alert, Drawer, message, Spin, Table, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import CustomButton from "../../Common/CustomButton";
import OrderManagementService from "../../services/OrderManagement/OrderManagementService";
import OrderChecklistDrawer from "./OrderChecklistDrawer";

const ViewOrderChecklistsDrawer = ({ open, onClose, data }) => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState("");
  const [manageDrawer, setManageDrawer] = useState({
    open: null,
    data: null,
  });

  const handleClose = () => {
    onClose();
    setList([]);
    setIsLoading("");
  };

  const fetchList = async () => {
    if (!data.primaryIdentifier) {
      return;
    }

    setIsLoading("list-fetch");
    try {
      const res = await OrderManagementService.getAttachedChecklists(
        data.primaryIdentifier
      );

      setList(res?.data?.checkLists || []);
    } catch (error) {
      console.log(error);
      message.error(
        error?.response?.data?.message ||
          "Unable to fetch quality checklist. Try again later!"
      );
    } finally {
      setIsLoading("");
    }
  };

  useEffect(() => {
    if (open && data) {
      fetchList();
    }
  }, [open, data]);

  const columns = [
    {
      title: "Name",
      dataIndex: ["checkList", "name"],
      key: "name",
      align: "center",
      render: (text) => (
        <Typography.Text ellipsis={{ tooltip: text }}>{text}</Typography.Text>
      ),
    },
    {
      title: "Filled By",
      dataIndex: "addedBy",
      key: "addedBy",
      align: "center",
      render: (text) => text || "-",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 100,
    },
    {
      title: "Response",
      dataIndex: "file",
      key: "custRequiredDate",
      align: "center",
      render: (_, record, index) => (
        <Tooltip
          title={
            !record.addedBy ? "This checklist has not been responded." : ""
          }
        >
          <CustomButton
            loading={isLoading === index}
            icon={<EyeFilled />}
            disabled={!record.addedBy}
            onClick={() =>
              setManageDrawer({
                open: "checklist",
                data: record,
              })
            }
          >
            View
          </CustomButton>
        </Tooltip>
      ),
      width: 100,
    },
  ];

  return (
    <Drawer
      open={open}
      width={600}
      onClose={handleClose}
      title="Quality Checklists"
      destroyOnClose
    >
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spin />
        </div>
      ) : list?.length === 0 ? (
        <Alert
          type="info"
          message="No checklist submitted in the selected order"
          showIcon
        />
      ) : (
        <Table
          bordered
          dataSource={list}
          columns={columns}
          pagination={false}
          tableLayout="fixed"
        />
      )}

      <OrderChecklistDrawer
        isDrawerOpen={manageDrawer.open === "checklist"}
        onClose={() => setManageDrawer({ open: null, data: null })}
        type="response"
        orderDetails={{
          ...manageDrawer.data,
          ...data,
          checklistID: manageDrawer.data?.checkList?.id,
        }}
      />
    </Drawer>
  );
};

export default ViewOrderChecklistsDrawer;
