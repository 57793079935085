import { Breadcrumb, Card } from "antd";
import React, { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import UserRoles from "./UserRoles";
import CustomBreadcrumb from "../../Common/CustomBreadcrumb";

const Role = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    activeTab: "Roles",
  });

  const items = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "Roles",
    },
  ];

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.has("activeTab")) {
      searchParams.set("activeTab", "Roles");
      const newurl = `${window.location.protocol}//${window.location.host}${
        window.location.pathname
      }?${searchParams.toString()}`;
      window.history.replaceState({ path: newurl }, "", newurl);
    }
  }, []);

  //   hiding the privleges
  // const productsAllTabs = [
  //   {
  //     label: "Roles",
  //     key: "Roles",
  //   },
  //   {
  //     label: "Privilege",
  //     key: "Privilege",
  //   },
  // ];

  return (
    <div className="p-2 mt-4">
      <CustomBreadcrumb items={items} />
      {/* <Tabs
        onChange={(activeTab) => {
          searchParams.set("activeTab", activeTab);
          setSearchParams(searchParams);
        }}
        activeKey={searchParams.get("activeTab")}
        titleFontSize={30}
        type="card"
        items={productsAllTabs}
      /> */}
      <Card>
        <UserRoles />
        {/* {searchParams.get("activeTab") === "Roles" ? <UserRoles /> : null} */}
        {/* We are not showing privileges to the frontend */}
        {/* {searchParams.get("activeTab") === "Privilege" ? <Privilege /> : null} */}
      </Card>
    </div>
  );
};

export default Role;
