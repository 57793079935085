import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Drawer, Form, Input, message } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import CustomButton from "../../Common/CustomButton";
import ChecklistService from "../../services/Checklist/ChecklistService";
import { validateTextInput } from "../../utils/validationsHelper";

const ManageChecklistDrawer = ({
  open,
  openType,
  initalData,
  onClose,
  refetch,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [form] = useForm();

  useEffect(() => {
    if (openType === "edit" && initalData) {
      form.setFieldsValue(initalData);
    }
  }, [open, initalData]);

  const handleClose = () => {
    onClose();
    setIsSubmitting(false);
    form.resetFields();
  };

  const onFinish = async (values) => {
    try {
      let method;

      if (openType === "add") {
        values.isActive = true;
        method = () => ChecklistService.createChecklist(values);
      } else {
        method = () =>
          ChecklistService.updateChecklist({ ...initalData, ...values });
      }

      await method();

      handleClose();
      refetch();
      message.success(
        `${openType === "add" ? "Added" : "Updated"} checklist successfully!`
      );
    } catch (error) {
      console.log(error);
      message.error(
        error?.response?.data?.message ||
          `Unable to ${
            openType === "add" ? "create" : "edit"
          } checklist right now. Try again later!`
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Drawer
      title={openType === "add" ? "Add Checklist" : `Edit Checklist`}
      open={open}
      onClose={handleClose}
      bodyStyle={{
        padding: "12px 20px",
      }}
      destroyOnClose
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please enter name",
            },
            { validator: validateTextInput("Name", 2, 30) },
          ]}
        >
          <Input size="medium" placeholder="Enter name" />
        </Form.Item>

        {/* Might need later <Form.Item
          name="statusID"
          label="Status Id"
          rules={[
            {
              required: true,
              message: "Please enter status id",
            },
            {
              type: "number",
              min: 0,
              message: "Status Id cannot be less than 0.",
            },
          ]}
        >
          <InputNumber
            className="w-full"
            type="number"
            size="medium"
            placeholder="Enter status id"
          />
        </Form.Item> */}

        <div className="flex justify-end gap-2 mt-10">
          <CustomButton
            type="primary"
            htmlType="submit"
            loading={isSubmitting}
            icon={<CheckOutlined />}
          >
            Submit
          </CustomButton>

          <CustomButton
            type="cancel"
            onClick={handleClose}
            disabled={isSubmitting}
            icon={<CloseOutlined />}
          >
            Cancel
          </CustomButton>
        </div>
      </Form>
    </Drawer>
  );
};

export default ManageChecklistDrawer;
