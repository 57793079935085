import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Form, Input, Typography, message } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Providers/AuthProvider";
import AuthServices from "../../services/Authentication/AuthServices";
import ProductionServices from "../../services/Production/ProductionServices";
import CustomButton from "../../Common/CustomButton";

const LoginCard = ({ setCurrentCard }) => {
  const [IsLoading, setIsLoading] = useState(false);
  const [form] = useForm();
  const { refetchData } = useAuth();
  const navigate = useNavigate();

  const validateEmail = (_, value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value || emailRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Enter a valid email address");
  };

  const onFinish = async (values) => {
    setIsLoading(true);

    try {
      const loginResponse = await AuthServices.getLoginFunction(values);

      if (loginResponse.data.token) {
        try {
          const prodGroupRes = await ProductionServices.getAllProductGroup(loginResponse.data.token);
          const prodGroups = prodGroupRes.data;

          localStorage.setItem("prodGroup", JSON.stringify(prodGroups));
          message.success("Logged in successfully", 3);

          setIsLoading(false);
          form.resetFields();

          localStorage.setItem("APS-TOKEN", loginResponse.data.token);
          refetchData();

          navigate(
            !loginResponse?.data?.menu?.items[0]?.children?.length > 0
              ? loginResponse?.data?.menu?.items[0]?.key
              : loginResponse?.data?.menu?.items[0].children[0].key,
            { replace: true, state: { prodGroups: prodGroups } }
          );
        } catch (error) {
          setIsLoading(false);
          message.error("Failed to fetch product groups");
        }
      }
    } catch (error) {
      setIsLoading(false);
      message.error("Invalid username or password");
    }
  };

  return (
    <>
      <Typography className="text-3xl font-semibold ">
        Welcome to
        <span className="font-secondary ml-2 font-semibold text-[#6e71f2]">
          APS
        </span>
      </Typography>

      <Typography className="mt-4 text-base font-medium ">
        Please enter your credentials to login
      </Typography>

      <Form
        form={form}
        layout="vertical"
        className="w-full md:w-[70%] mt-12"
        onFinish={onFinish}
      >
        <Form.Item
          label="Email"
          name="emailAddress"
          className="text-start"
          rules={[
            {
              required: true,
              message: "Enter email",
            },
            { validator: validateEmail },
          ]}
        >
          <Input size="large" placeholder="Enter email" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          className="!mt-4 text-start"
          rules={[
            {
              required: true,
              message: "Enter password",
            },
          ]}
        >
          <Input.Password
            size="large"
            type="password"
            placeholder="Enter password"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>

        {/* Will add once we add the forgot password functionality
        
        <CustomButton
          className="float-right !px-0 mb-3 text-right text-blue-600 "
          type="link"
          onClick={() => setCurrentCard("forgetPassword")}
        >
          <p className="hover:underline">Forgot Password?</p>
        </CustomButton> */}

        <CustomButton
          type="primary"
          size="large"
          loading={IsLoading}
          htmlType="submit"
          className="mt-8 !bg-[#6e71f2] hover:!bg-[#6e70f2de]"
          block
        >
          Login
        </CustomButton>
      </Form>
    </>
  );
};

export default LoginCard;
