import React, { useState } from "react";
import { Modal, Collapse } from "antd";

const { Panel } = Collapse;

const faqData = [
  { question: "What is order status?", answer: "Answer 1" },
  { question: "What is job status?", answer: "Answer 2" },
];

const ProductGroupFaqModal = ({ open, onCancel }) => {
  const [activeKey, setActiveKey] = useState(null);

  const handleCollapseChange = (keys) => {
    setActiveKey(keys.length ? keys[0] : null);
  };

  return (
    <Modal title="FAQ's" open={open} onCancel={onCancel} footer={null}>
      <Collapse
        className="mt-3"
        activeKey={activeKey}
        expandIconPosition="end"
        onChange={handleCollapseChange}
        accordion
      >
        {faqData.map((faq, index) => (
          <Panel header={faq.question} key={index.toString()}>
            <p>{faq.answer}</p>
          </Panel>
        ))}
      </Collapse>
    </Modal>
  );
};

export default ProductGroupFaqModal;
