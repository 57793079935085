import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Drawer, Form, Input, Select, Tooltip, message } from "antd";
import { useWatch } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import CustomButton from "../../Common/CustomButton";
import OrderManagementService from "../../services/OrderManagement/OrderManagementService";
import { validateTextInput } from "../../utils/validationsHelper";

const AddEditOrderConfigure = ({
  changeStatus,
  recordData,
  onClose,
  dataList,
  drawerOpen,
  refetch,
}) => {
  const [form] = Form.useForm();
  const [isloading, setIsLoading] = useState(false);

  useEffect(() => {
    if (changeStatus == "edit") {
      form.setFieldsValue(recordData);
    } else if (changeStatus === "add") {
      form.setFieldsValue({
        type: "Running",
        tabOrder: dataList.length + 1,
      });
    }
  }, [drawerOpen]);

  const onFinish = (values) => {
    setIsLoading(true);
    if (changeStatus === "add") {
      const payload = {
        ...values,
        primaryIdentifier: "",
      };
      OrderManagementService.saveOrderViewData(payload)
        .then((res) => {
          message.success("View added successfully");
          form.resetFields();
          refetch();
          handleClose();
          setIsLoading(false);
        })
        .catch((error) => {
          error?.response?.data?.message
            ? message.error(error?.response?.data?.message)
            : message.error("Something went wrong");
          setIsLoading(false);
        });
    }

    if (changeStatus === "edit") {
      const payload = {
        ...values,
        primaryIdentifier: recordData ? recordData.primaryIdentifier : "",
      };
      OrderManagementService.updateOrderViewData(payload)
        .then((res) => {
          message.success("View updated successfully");
          form.resetFields();
          refetch();
          handleClose();
          setIsLoading(false);
        })
        .catch((error) => {
          error?.response?.data?.message
            ? message.error(error?.response?.data?.message)
            : message.error("Something went wrong");
          setIsLoading(false);
        });
    }
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const type = useWatch("type", form);
  const fromStatus = useWatch("fromStatus", form);

  return (
    <div>
      <Drawer
        title={
          changeStatus === "add" ? "Add configuration" : "Update configuration"
        }
        placement="right"
        onClose={handleClose}
        open={drawerOpen}
        bodyStyle={{
          padding: "4px 16px",
        }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item
            name="type"
            label="Type"
            rules={[
              {
                required: true,
                message: "Please select type",
              },
            ]}
          >
            <Select
              size="medium"
              options={[
                { label: "Running Status", value: "Running" },
                { label: "Cancelled Status", value: "Cancelled" },
                { label: "Complete Status", value: "Complete" },
              ]}
              onChange={(value) => {
                if (value === "Cancelled") {
                  form.setFieldsValue({
                    name: "Cancelled",
                    fromStatus: 0,
                    toStatus: 0,
                    isHidden: true,
                  });
                } else if (value === "Complete") {
                  form.setFieldsValue({
                    name: "Complete",
                    fromStatus: 1000,
                    toStatus: 1000,
                    isHidden: true,
                  });
                } else {
                  form.setFieldsValue({
                    name: undefined,
                    fromStatus: undefined,
                    toStatus: undefined,
                    isHidden: false,
                  });
                }
              }}
            />
          </Form.Item>

          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please enter name",
              },
              { validator: validateTextInput("Name") },
            ]}
          >
            <Input size="medium" placeholder="Enter name" />
          </Form.Item>

          <Form.Item
            name="fromStatus"
            label="Status Lower Limit"
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  return new Promise((resolve, reject) => {
                    if (value || value === 0) {
                      if (type === "Running") {
                        if (value >= 100 && value <= 1000) {
                          resolve();
                        } else if (value > 1000) {
                          reject(
                            "Status lower limit cannot be greater than 1000."
                          );
                        } else {
                          reject("Status lower limit cannot be less than 100.");
                        }
                      } else {
                        if (value >= 0 && value <= 1000) {
                          resolve();
                        } else if (value > 1000) {
                          reject(
                            "Status lower limit cannot be greater than 1000."
                          );
                        } else {
                          reject("Status lower limit cannot be less than 0.");
                        }
                      }
                    } else {
                      reject("Please enter status lower limit");
                    }
                  });
                },
              },
            ]}
          >
            <Input
              placeholder="Enter status lower limit"
              size="medium"
              type="number"
              min={0}
              disabled={type !== "Running"}
              style={{
                width: "100%",
              }}
            />
          </Form.Item>

          <Form.Item
            name="toStatus"
            label="Status Upper Limit"
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  return new Promise((resolve, reject) => {
                    if (value || value === 0) {
                      if (value >= 0 && value <= 1000) {
                        if (
                          parseInt(fromStatus) !== undefined &&
                          value < parseInt(fromStatus)
                        ) {
                          reject(
                            `Status upper limit cannot be less than ${fromStatus}`
                          );
                        } else {
                          resolve();
                        }
                      } else if (value > 1000) {
                        reject(
                          "Status upper limit cannot be greater than 1000."
                        );
                      } else {
                        reject("Status upper limit cannot be less than 0.");
                      }
                    } else {
                      reject("Please enter status upper limit");
                    }
                  });
                },
              },
            ]}
          >
            {changeStatus !== "edit" && fromStatus === undefined ? (
              <Tooltip title="Enter lower limit first!">
                <Input
                  placeholder="Enter status upper limit"
                  size="medium"
                  type="number"
                  disabled={!fromStatus}
                  style={{
                    width: "100%",
                  }}
                />
              </Tooltip>
            ) : (
              <Input
                placeholder="Enter status upper limit"
                size="medium"
                type="number"
                disabled={type !== "Running"}
                style={{
                  width: "100%",
                }}
              />
            )}
          </Form.Item>

          <Form.Item
            name="tabOrder"
            label="Tab Sequence"
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  return new Promise((resolve, reject) => {
                    if (value) {
                      if (value >= 11 && value <= 20) {
                        resolve();
                      } else if (value > 20) {
                        reject("Tab sequence cannot be greater than 20.");
                      } else {
                        reject("Tab sequence cannot be less than 11.");
                      }
                    } else {
                      reject("Please enter tab sequence");
                    }
                  });
                },
              },
            ]}
          >
            <Input
              placeholder="Enter tab sequence"
              size="medium"
              type="number"
              min={1}
              style={{
                width: "100%",
              }}
            />
          </Form.Item>

          <div className="flex justify-end gap-2 mt-5">
            <CustomButton
              type="primary"
              htmlType="submit"
              loading={isloading}
              icon={<CheckOutlined />}
            >
              Submit
            </CustomButton>

            <CustomButton
              type="cancel"
              onClick={handleClose}
              icon={<CloseOutlined />}
            >
              Cancel
            </CustomButton>
          </div>
        </Form>
      </Drawer>
    </div>
  );
};

export default AddEditOrderConfigure;
