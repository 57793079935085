import {
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import {
  Card,
  Collapse,
  ColorPicker,
  Drawer,
  Form,
  Input,
  Select,
  message,
} from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useState } from "react";
import CitySearch from "../../Common/CitySearch";
import CountrySelect from "../../Common/CountrySelect";
import CustomButton from "../../Common/CustomButton";
import ViewInfoButton from "../../Common/ViewInfoButton";
import ManageOrganizationService from "../../services/Organization/ManageOrganizationService";
import {
  validateEmail,
  validateTextInput,
} from "../../utils/validationsHelper";
import StateSelect from "../../Common/StateSelect";
const AddOrganizationDrawer = ({ onSuccess, changeStatus, onClose }) => {
  const [form] = useForm();
  const [IsLoading, setIsLoading] = useState(false);
  const [addressSelectCity, setAddressSelectCity] = useState("");
  const [addressCityData, AddressSetCityData] = useState([]);
  const [addressSelectState, setAddressSelectState] = useState("");
  const [addressSelectStateId, setAddressSelectStateId] = useState("");
  const [addressStateData, AddressSetStateData] = useState([]);
  const [isCompanyDetailCollapse, setIsCompanyDetailCollapse] = useState(false);
  const [isContactDetailsCollapse, setIsContactDetailsCollapse] =
    useState(false);
  const [isAddressDetailsCollapse, setIsAddressDetailsCollapse] =
    useState(false);
  const [logoFile, setLogoFile] = useState(null);
  const [stateSearchValue, setStateSearchValue] = useState("");

  const [countryList, setCountryList] = useState([]);
  const [selectedCountryValue, setSelectedCountryValue] = useState(null);
  const [stateDropDownData, setStateDropDownData] = useState({});

  const handleClearCountry = () => {
    form.resetFields(["country", "state"]);
    setSelectedCountryValue(null);
  };

  const handleCountrySelect = (countryValue, stateData) => {
    form.setFieldValue("country", countryValue);
    setSelectedCountryValue(countryValue);
    setStateDropDownData(stateData);
  };

  const openAllDrawers = () => {
    setIsCompanyDetailCollapse(true);
    setIsContactDetailsCollapse(true);
    setIsAddressDetailsCollapse(true);
  };

  const onFinish = (values) => {
    const payload = {
      addressRequest: {
        addressID: 1,
        consumerID: "",
        consumerType: "",
        contactPersonName: "",
        company: "",
        addressLine1: values?.addressAddressline1,
        addressLine2: values?.addressAddressline2,
        addressLine3: "",
        addressLine4: "",
        city: values?.addressCity,
        state: values?.addressState,
        postCode: values?.addressPostCode,
        country: selectedCountryValue,
        phone: values?.addressPhone,
        fax: values?.addressFaxId,
        emailAddress: values?.addressEmail,
        areaCode: "",
        createdBy: 0,
        primaryIdentifier: "",
      },
      organisationRequest: {
        parentOrganisationID: "",
        child: "",
        name: values?.companyName,
        countryID: 0,
        currencyType: "AUD",
        isChild: true,
        searchKey: "",
        isInternal: true,
        primaryIdentifier: "",
        colors: [
          ...values?.colors.map((item) =>
            typeof item !== "string" ? item.toHexString() : item
          ),
        ],
      },
      contactRequest: {
        id: 0,
        consumerID: "",
        consumerType: "",
        contactTypeID: 0,
        jobTitle: values?.userjobTitle,
        firstName: values?.userfirstName,
        lastName: values?.userlastName,
        mobile: values?.userMobileNo,
        phone: values?.userPhoneNo,
        fax: values?.userFaxId,
        email: values?.userEmail,
        isPrimary: true,
        isEmailStatement: true,
        isEmailInvoice: true,
        isEmailDispatchNotice: true,
        isEmailMarketing: true,
        priority: 0,
        createdBy: 0,
        primaryIdentifier: "",
      },
    };

    ManageOrganizationService.addOrganizationData(payload).then(async (res) => {
      const formData = new FormData();
      formData.append("Files", logoFile);
      formData.append("Category", "LOGO");
      const primaryIdentifier = await res?.data;
      ManageOrganizationService.uploadLogoFile(primaryIdentifier, formData)
        .then((res) => {
          setIsLoading(false);
          message.success("Organization Added successfully");
          form.resetFields();
          onClose();
          onSuccess();
        })
        .catch((error) => {
          setIsLoading(false);
          const errorMessage =
            error?.response?.data?.message || "Something went wrong";
          message.error(errorMessage);
        });
    });
  };

  const handleCancel = () => {
    setIsCompanyDetailCollapse(false);
    setIsContactDetailsCollapse(false);
    setIsAddressDetailsCollapse(false);
    form.resetFields();
    onClose();
    setSelectedCountryValue(null);
  };

  return (
    <Drawer
      title={"Add Organization"}
      width={400}
      onClose={() => handleCancel()}
      open={changeStatus}
      bodyStyle={{
        padding: "10px 8px",
      }}
    >
      <div className="flex flex-col gap-6 rounded-md ">
        <div className="flex flex-col gap-2">
          <Form
            form={form}
            name="form_item_path"
            layout="vertical"
            onFinish={onFinish}
            size="small"
            initialValue={{
              colors: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
            }}
          >
            <Collapse
              expandIcon={({ isActive }) =>
                isActive ? <UpOutlined /> : <DownOutlined />
              }
              onChange={() =>
                setIsCompanyDetailCollapse(!isCompanyDetailCollapse)
              }
              activeKey={isCompanyDetailCollapse ? ["1"] : []}
              className="mb-2"
              size="small"
              items={[
                {
                  key: "1",
                  label: (
                    <div className="font-semibold">Organization Details</div>
                  ),
                  children: (
                    <>
                      <Form.Item
                        name="companyName"
                        label="Organization Name"
                        rules={[
                          {
                            required: true,
                            message: "Enter organization name",
                          },
                          { validator: validateTextInput("Organization name") },
                        ]}
                      >
                        <Input
                          size="medium"
                          placeholder="Enter organization name"
                        />
                      </Form.Item>

                      <Form.Item
                        name="organizationLogo"
                        label="Logo"
                        rules={[
                          {
                            required: true,
                            message: "Upload organization logo",
                          },
                        ]}
                      >
                        <Input
                          style={{ padding: "3px" }}
                          type="file"
                          onChange={(event) => {
                            const file = event.target.files[0];
                            setLogoFile(file);
                          }}
                          accept=".xlsx, .xls, .pdf, .jpg, .jpeg, .png"
                        />
                      </Form.Item>

                      <Form.List
                        name="colors"
                        initialValue={["ffffff", "ffffff", "ffffff", "ffffff"]}
                      >
                        {(fields) => (
                          <Card
                            type="inner"
                            title={
                              <span className="flex items-center gap-2">
                                Colours
                              </span>
                            }
                            extra={
                              <ViewInfoButton
                                placement="left"
                                title="Selected colours will be used on the whole website, like in the navigation bar."
                              />
                            }
                            className="mt-4 border border-gray-600/20"
                          >
                            <div className="grid grid-cols-2 place-items-center gap-x-6 gap-y-2">
                              {fields.map((field, index) => (
                                <Form.Item
                                  key={field.key}
                                  {...field}
                                  label={
                                    index === 0
                                      ? "Primary"
                                      : index === 1
                                      ? "Secondary"
                                      : index === 2
                                      ? "Tertiary"
                                      : "Quaternary"
                                  }
                                  className={
                                    index % 2 === 0
                                      ? "justify-self-center"
                                      : "justify-self-start"
                                  }
                                  name={[field.name]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select colour",
                                    },
                                  ]}
                                >
                                  <ColorPicker
                                    showText={(color) => (
                                      <span>Colour {color.toHexString()}</span>
                                    )}
                                    value={form.getFieldValue([
                                      "colors",
                                      index,
                                    ])}
                                  />
                                </Form.Item>
                              ))}
                            </div>
                          </Card>
                        )}
                      </Form.List>
                    </>
                  ),
                },
              ]}
            />

            <Collapse
              expandIcon={({ isActive }) =>
                isActive ? <UpOutlined /> : <DownOutlined />
              }
              activeKey={isContactDetailsCollapse ? ["1"] : []}
              onChange={() =>
                setIsContactDetailsCollapse(!isContactDetailsCollapse)
              }
              className="mb-2"
              size="small"
              items={[
                {
                  key: "1",
                  label: <div className="font-semibold">Contact Details</div>,
                  children: (
                    <>
                      <Form.Item
                        name="userfirstName"
                        label="First Name"
                        rules={[
                          {
                            required: true,
                            message: "Enter first name",
                          },
                          { validator: validateTextInput("First name") },
                        ]}
                      >
                        <Input
                          size="medium"
                          type="name"
                          placeholder="Enter first name"
                        />
                      </Form.Item>
                      <Form.Item
                        name="userlastName"
                        label="Last Name"
                        rules={[
                          { required: false },
                          { validator: validateTextInput("Last name") },
                        ]}
                      >
                        <Input
                          size="medium"
                          type="name"
                          placeholder="Enter last name"
                        />
                      </Form.Item>
                      <Form.Item
                        name="userjobTitle"
                        label="Job Title"
                        rules={[
                          {
                            required: false,
                            message: "Enter job title",
                          },
                          { validator: validateTextInput("Job title") },
                        ]}
                      >
                        <Input
                          type="text"
                          size="medium"
                          placeholder="Enter job title"
                        />
                      </Form.Item>
                      <Form.Item
                        name="userMobileNo"
                        label="Mobile Number"
                        rules={[
                          {
                            required: true,
                            message: "Enter mobile number",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          size="medium"
                          placeholder="Enter mobile number"
                        />
                      </Form.Item>
                      <Form.Item
                        name="userEmail"
                        label="Email"
                        rules={[
                          {
                            required: true,
                            message: "Enter email",
                          },
                          { validator: validateEmail },
                        ]}
                      >
                        <Input
                          type="email"
                          size="medium"
                          placeholder="Enter email"
                        />
                      </Form.Item>
                      <Form.Item name="userPhoneNo" label="Landline number">
                        <Input
                          type="number"
                          size="medium"
                          placeholder="Enter landline number"
                        />
                      </Form.Item>
                      <Form.Item name="userFaxId" label="Fax Number">
                        <Input
                          type="number"
                          size="medium"
                          placeholder="Enter fax number"
                        />
                      </Form.Item>
                    </>
                  ),
                },
              ]}
            />

            <Collapse
              expandIcon={({ isActive }) =>
                isActive ? <UpOutlined /> : <DownOutlined />
              }
              activeKey={isAddressDetailsCollapse ? ["1"] : []}
              onChange={() =>
                setIsAddressDetailsCollapse(!isAddressDetailsCollapse)
              }
              className="mb-4"
              size="small"
              items={[
                {
                  key: "1",
                  label: <div className="font-semibold">Address Details</div>,
                  children: (
                    <>
                      <Form.Item
                        name="addressEmail"
                        label="Email"
                        rules={[
                          {
                            required: true,
                            message: "Enter email",
                          },
                          { validator: validateEmail },
                        ]}
                      >
                        <Input
                          size="medium"
                          type="email"
                          placeholder="Enter email"
                        />
                      </Form.Item>
                      <Form.Item
                        name="addressAddressline1"
                        label="Street Address"
                        rules={[
                          {
                            required: true,
                            message: "Enter address",
                          },
                          {
                            validator: validateTextInput(
                              "Street address",
                              10,
                              100
                            ),
                          },
                        ]}
                      >
                        <Input
                          size="medium"
                          type="text"
                          placeholder="Enter address"
                        />
                      </Form.Item>
                      <Form.Item
                        name="addressAddressline2"
                        label="Landmark"
                        rules={[
                          {
                            required: true,
                            message: "Enter landmark",
                          },
                          {
                            validator: validateTextInput("Landmark"),
                          },
                        ]}
                      >
                        <Input
                          size="medium"
                          type="text"
                          placeholder="Enter landmark"
                        />
                      </Form.Item>

                      <Form.Item
                        name="country"
                        label="Country"
                        rules={[
                          {
                            required: true,
                            message: "Select country",
                          },
                        ]}
                      >
                        <CountrySelect
                          onSelect={handleCountrySelect}
                          currentStatus={"add"}
                          form={form}
                        />
                      </Form.Item>

                      <Form.Item
                        name="state"
                        label="State"
                        rules={[
                          {
                            required: true,
                            message:
                              stateDropDownData.isStateExists === true
                                ? "Select state"
                                : "Enter state",
                          },
                          {
                            validator: validateTextInput("State"),
                          },
                        ]}
                      >
                        <StateSelect
                          options={
                            stateDropDownData
                              ? stateDropDownData?.stateResponse?.map(
                                  (item) => ({
                                    label: item.name,
                                    value: item.name,
                                  })
                                )
                              : []
                          }
                          isStateExists={stateDropDownData.isStateExists}
                        />
                      </Form.Item>

                      <Form.Item
                        name="addressCity"
                        label="City"
                        rules={[
                          {
                            required: true,
                            message: "Enter city",
                          },
                          {
                            validator: validateTextInput("City"),
                          },
                        ]}
                        dependencies={["addressState", addressSelectStateId]}
                      >
                        <CitySearch
                          stateID={addressSelectStateId}
                          value={addressSelectCity}
                          onChange={setAddressSelectCity}
                          cityData={addressCityData}
                          setCityData={AddressSetCityData}
                        />
                      </Form.Item>
                      <Form.Item
                        name="addressPostCode"
                        label="Post Code"
                        rules={[
                          {
                            required: true,
                            message: "Enter post code",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          size="medium"
                          placeholder="Enter post code"
                        />
                      </Form.Item>
                      <Form.Item
                        name="addressPhone"
                        label="Phone Number"
                        rules={[
                          {
                            required: true,
                            message: "Enter phone number",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          size="medium"
                          placeholder="Enter phone number"
                        />
                      </Form.Item>
                      <Form.Item name="addressFaxId" label="Fax Number">
                        <Input
                          type="number"
                          size="medium"
                          placeholder="Enter fax number"
                        />
                      </Form.Item>
                    </>
                  ),
                },
              ]}
            />

            <div className="flex justify-end gap-2 mt-2">
              <CustomButton
                type="primary"
                htmlType="submit"
                icon={<CheckOutlined />}
                loading={IsLoading}
                onClick={() => {
                  openAllDrawers();
                }}
              >
                Submit
              </CustomButton>
              <CustomButton
                type="cancel"
                onClick={() => handleCancel()}
                icon={<CloseOutlined />}
              >
                Cancel
              </CustomButton>
            </div>
          </Form>
        </div>
      </div>
    </Drawer>
  );
};

export default AddOrganizationDrawer;
