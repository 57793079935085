import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { ConfigProvider, Space, Table, Tag, Typography } from "antd";
import dayjs from "dayjs";
import React from "react";
import { NumericFormat } from "react-number-format";
import { useScreenSize } from "../../Providers/ScreenSizeProvider";
import { formatDateMonth } from "../../utils/dateHelper";

const SingleOrderDetails = ({ orderDetailsData, loading }) => {
  const currentScreenSize = useScreenSize();

  let dataSource = [];
  let priceData = [];

  if (orderDetailsData) {
    dataSource.push({
      name: orderDetailsData?.orderItem?.product?.name,
      productCode: orderDetailsData?.orderItem?.product?.code,
      createdDate: orderDetailsData?.orderItem?.createdDate,
      quantity: orderDetailsData?.orderItem?.qty || 1,
      unitPrice: orderDetailsData?.unitPrice,
      totalPrice: orderDetailsData?.orderItem?.orderedPriceTotal,
      expectedCompletionDate:
        orderDetailsData?.expectedCompletionDate?.expectedCompletionDate,
      requiredDate: orderDetailsData?.required,
      productInfo:
        orderDetailsData?.productInfo &&
        orderDetailsData?.productInfo.length > 0
          ? [
              {
                make: orderDetailsData?.productInfo?.[0]?.make,
                model: orderDetailsData?.productInfo?.[0]?.model,
                barFinish: orderDetailsData?.productInfo?.[0]?.barFinish,
                integratedLights:
                  orderDetailsData?.productInfo?.[0]?.integratedLights,
                blackingPlate:
                  orderDetailsData?.productInfo?.[0]?.blackingPlate,
                numberPlate: orderDetailsData?.productInfo?.[0]?.numberPlate,
                indicators: orderDetailsData?.productInfo?.[0]?.indicators,
              },
            ]
          : [],
      accessoriesInfo: orderDetailsData?.accessoriesInfo
        ? JSON.parse(orderDetailsData?.accessoriesInfo)
        : [],
    });

    priceData = [
      {
        name: "Sub-total",
        price: orderDetailsData?.subTotal || 0,
      },
      {
        name: "Packaging & Handling Charges",
        price: orderDetailsData?.handlingCharges || 0,
      },
      { name: "GST", price: orderDetailsData?.tax || 0 },
      {
        name: "Order Total",
        price: orderDetailsData?.total || 0,
      },
    ];
  }

  const columns = [
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
      align: "left",
      render: (_, record) => {
        return <Tag>{record.name}</Tag>;
      },
    },
    {
      title: "Product Code",
      dataIndex: "productCode",
      key: "productCode",
      align: "center",
      render: (_, record) => {
        return <div>{record.productCode}</div>;
      },
    },
    {
      title: "Order Date",
      dataIndex: "createdDate",
      key: "createdDate",
      align: "center",
      render: (_, record) => {
        return record.createdDate ? (
          <p> {formatDateMonth(record.createdDate)}</p>
        ) : (
          "NA"
        );
      },
    },
    {
      title: "Estimated Delivery Date",
      dataIndex: "expectedCompletionDate",
      key: "expectedCompletionDate",
      align: "center",
      render: (text) => dayjs(text).format("DD-MM-YYYY"),
    },
    {
      title: "Required Date",
      dataIndex: "requiredDate",
      key: "requiredDate",
      align: "center",
      render: (text) => (text ? dayjs(text).format("DD-MM-YYYY") : "N/A"),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      render: (_, record) => {
        return <p>{record.quantity ? record.quantity : 1}</p>;
      },
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      align: "right",
      width: 165,
      render: (text) => {
        return (
          <NumericFormat
            prefix={"$"}
            value={text ? text : 0}
            displayType={"text"}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={true}
          />
        );
      },
    },
  ];

  const priceColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: currentScreenSize > 1024 ? "90%" : "70%",
      render: (text) => <span className="font-semibold">{text}</span>,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "right",
      width: 165,
      render: (text) => {
        return (
          <NumericFormat
            prefix={"$"}
            value={text}
            className="mr-2 font-semibold"
            displayType={"text"}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={true}
          />
        );
      },
    },
  ];

  const expandedRowRender = (record) => {
    const extraDetailsProductColumns = [
      {
        title: "Make",
        dataIndex: "make",
        key: "make",
        width: 100,
      },
      {
        title: "Model",
        dataIndex: "model",
        key: "model",
        width: 350,
      },
      {
        title: "Bar Finish",
        dataIndex: "barFinish",
        key: "barFinish",
      },
      {
        title: "Integrated Lights",
        dataIndex: "integratedLights",
        key: "integratedLights",
      },
      {
        title: "Blacking Plate",
        dataIndex: "blackingPlate",
        key: "blackingPlate",
      },
      {
        title: "Number Plate",
        dataIndex: "numberPlate",
        key: "numberPlate",
      },
      {
        title: "Indicators",
        dataIndex: "indicators",
        key: "indicators",
      },
    ];

    const attachmentsColumns = [
      { title: "Accessory Name", dataIndex: "accessories", key: "accessories" },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Location",
        dataIndex: "lightLocation",
        key: "lightLocation",
      },
      {
        title: "Number",
        dataIndex: "number",
        key: "number",
      },
      {
        title: "Layout",
        dataIndex: "lightLayout",
        key: "layout",
      },
      {
        title: "Mounting",
        dataIndex: "lightMounting",
        key: "lightMounting",
      },
      {
        title: "Cost",
        dataIndex: "price",
        key: "price",
        align: "right",
        width: 156,
        render: (text) => {
          return (
            <NumericFormat
              prefix={"$"}
              value={text}
              displayType={"text"}
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={true}
            />
          );
        },
      },
    ];

    return (
      <>
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBg: "#ffffff",
              },
            },
          }}
        >
          <Table
            columns={extraDetailsProductColumns}
            dataSource={record.productInfo}
            pagination={false}
            bordered
            tableLayout="fixed"
          />

          {record.accessoriesInfo.length > 0 ? (
            <Table
              className="mt-4"
              columns={attachmentsColumns}
              dataSource={record.accessoriesInfo}
              pagination={false}
              bordered
              tableLayout="fixed"
            />
          ) : (
            <Typography className="w-full mt-2 italic text-center">
              ( No accessories in this order )
            </Typography>
          )}
        </ConfigProvider>
      </>
    );
  };

  const customExpandIcon = ({ expanded, onExpand, record }) => (
    <Space
      onClick={(e) => onExpand(record, e)}
      className="px-2 py-1 text-white bg-[#4096ff] rounded-md cursor-pointer select-none"
    >
      {expanded ? <MinusCircleOutlined /> : <PlusCircleOutlined />}
    </Space>
  );

  return (
    <>
      <Table
        columns={columns}
        dataSource={dataSource}
        expandable={{
          expandedRowRender,
          rowExpandable: (record) => record?.productInfo?.length > 0,
          expandIcon: customExpandIcon,
          showExpandColumn: dataSource?.[0]?.productInfo?.length > 0,
        }}
        scroll={{
          x: currentScreenSize < 1024 ? 1400 : 1000,
        }}
        defaultExpandAllRows
        size="middle"
        bordered
        pagination={false}
        style={{
          borderBottom: "1px solid #00000050",
        }}
        className="relative z-50"
        tableLayout="fixed"
        loading={loading}
      />

      <Table
        columns={priceColumns}
        dataSource={priceData}
        pagination={false}
        showHeader={false}
        bordered
        className="mb-5 -mt-1"
        tableLayout="fixed"
        size="middle"
      />
    </>
  );
};

export default SingleOrderDetails;
