import axios from "./axios";
import dayjs from "dayjs";

export function getBillingAddressString(item) {
  return (
    (item.addressLine1 ? item.addressLine1 + ", " : "") +
    (item.addressLine2 ? item.addressLine2 + ", " : "") +
    (item.addressLine3 ? item.addressLine3 + ", " : "") +
    (item.addressLine4 ? item.addressLine4 + ", " : "") +
    (item.city ? item.city + ", " : "") +
    (item.state ? item.state + ", " : "") +
    (item.postCode ? item.postCode : "")
  );
}

export function isOnlyWhiteSpace(str) {
  return !str.replace(/\s/g, "").length;
}

export const getErrorExcel = (moduleName, payload) => {
    return axios.post(`/api/${moduleName}/export/error/sheet`, payload, {
    responseType: "arraybuffer",
  });
};
export function getDeliveryAddressString(item) {
  //   console.log(item)
  return (
    (item.addressLine1 ? item.addressLine1 + ", " : "") +
    (item.addressLine2 ? item.addressLine2 + ", " : "") +
    (item.addressLine3 ? item.addressLine3 + ", " : "") +
    (item.addressLine4 ? item.addressLine4 + ", " : "") +
    (item.city ? item.city + ", " : "") +
    (item.state ? item.state + ", " : "") +
    (item.postCode ? item.postCode : "")
  );
}

export function getChildAccountID(accountID) {
  let childAccountID = "";
  if (accountID) {
    let arr = accountID.split("-");
    if (arr.length > 1) {
      childAccountID = arr[1];
    }
  }
  return childAccountID;
}

export function findItems(array, attr, value) {
  let result = [];
  for (let i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      result.push(array[i]);
    }
  }
  return result;
}

export function validateEmail(mail) {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    mail
  );
}

export function getDistinct(array, attr) {
  let unique = [];
  let distinct = [];
  for (let i = 0; i < array.length; i++) {
    if (!unique[array[i][attr]]) {
      distinct.push(array[i]);
      unique[array[i][attr]] = 1;
    }
  }
  return distinct;
}
export function getDateString(dateObj, targetFormat) {
  if (dateObj) {
    return dayjs(dateObj).format(targetFormat);
  }
  return "";
}
export function groupBy(objectArray, property, optionalWrapperParam) {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    // Add object to list for given key's value
    if (optionalWrapperParam) {
      if (!acc[key][optionalWrapperParam]) {
        acc[key][optionalWrapperParam] = [];
      }
      acc[key][optionalWrapperParam].push(obj);
    } else {
      acc[key].push(obj);
    }
    return acc;
  }, {});
}
export function getDistinctValues(array, attr) {
  let unique = [];
  let distinct = [];
  for (let i = 0; i < array.length; i++) {
    if (!unique[array[i][attr]]) {
      distinct.push(array[i][attr]);
      unique[array[i][attr]] = 1;
    }
  }
  return distinct;
}

export function scrollTo(id, type) {
  if (id) {
    switch (id) {
      case "top":
        window.scrollTo(0, 0);
        break;
      default:
        let element = document.getElementById(id);
        if (element) {
          switch (type) {
            case "left":
              element.scrollLeft = 0;
              break;
            case "right":
              element.scrollRight = 0;
              break;
            default:
              element.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
          }
        }
    }
  }
}

export function compare(
  a,
  b,
  attr1,
  isAttr1Ascending,
  attr2,
  isAttr2Ascending,
  attr3,
  isAttr3Ascending,
  attr4,
  isAttr4Ascending
) {
  let result = collate(a, b, attr1, isAttr1Ascending);
  //  console.log(result)
  if (result === 0 && attr2) {
    result = collate(a, b, attr2, isAttr2Ascending);
  }
  if (result === 0 && attr3) {
    result = collate(a, b, attr3, isAttr3Ascending);
  }
  if (result === 0 && attr4) {
    result = collate(a, b, attr4, isAttr4Ascending);
  }
  return result;
}

function collate(a, b, attr, ascending) {
  if (a[attr] > b[attr] || b[attr] === null) {
    if (ascending) {
      return 1;
    } else {
      return -1;
    }
  }
  if (b[attr] > a[attr] || a[attr] === null) {
    if (ascending) {
      return -1;
    } else {
      return 1;
    }
  }
  return 0;
}

export function getSorted(
  array,
  attr1,
  isAttr1Ascending,
  attr2,
  isAttr2Ascending,
  attr3,
  isAttr3Ascending,
  attr4,
  isAttr4Ascending
) {
  array.sort((a, b) =>
    compare(
      a,
      b,
      attr1,
      isAttr1Ascending,
      attr2,
      isAttr2Ascending,
      attr3,
      isAttr3Ascending,
      attr4,
      isAttr4Ascending
    )
  );
  return array;
}
export function arrayToDictionary(objectArray, property) {
  return objectArray.reduce((a, x) => ({ ...a, [x[property]]: x }), {});
}
export function findIndex(array, attr, value) {
  if (!array) {
    return -1;
  }
  for (let i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}

export function handleErrorMessage(error) {
  if (error.response) {
    if (error.response.data) {
      if (error.response.data.Message) {
        return error.response.data.Message;
      } else {
        /*  if (error.response.status === UNAUTHORIZED) {
                  return "Your session has expired, redirecting to the login page";
              }
              if (error.response.status === FORBIDDEN) {
                  return "Access denied!";
              }*/

        return "Something went wrong!";
      }
    } else {
      /* if (error.response.status === UNAUTHORIZED) {
              return "Your session has expired, redirecting to the login page";
          }
          if (error.response.status === FORBIDDEN) {
              return "Access denied!";
          }*/

      return "Something went wrong!";
    }
  } else if (error.Message) {
    return error.Message;
  } else if (!error.message && !error.response) {
    return "Something went wrong!";
  }
}
