import React, { useCallback, useEffect, useState } from "react";
import { useReactFlow } from "reactflow";
import CustomButton from "../../../Common/CustomButton";
import { useDarkMode } from "../../../Providers/DarkModeContext";
import { getDarkModeColor } from "../../../utils/darkModeHelper";

const findIndexOfNode = (arr, nodeId) => {
  return arr.findIndex((item) => item.id == nodeId);
};

const findPrevStatus = (arr, clickedIndex) => {
  return arr[clickedIndex - 1] !== undefined ? arr[clickedIndex - 1] : null;
};

const findNextStatus = (arr, clickedIndex) => {
  return arr[clickedIndex + 1] !== undefined ? arr[clickedIndex + 1] : null;
};

export default function OptionMenu({
  id,
  top,
  left,
  right,
  bottom,
  setOrderStatusModal,
  setJobStatusModal,
  addPrevOrderStatus,
  addStatusAbove,
  onDeleteOrderStatus,
  onDeleteJobStatus,
  nodeList,
  orderStatusList,
  hideOptions,
  handleDisabledNode,
  handleEnableNode,
  selectedProduct,
  ...props
}) {
  const { getNode, addNodes } = useReactFlow();
  const node = getNode(id);
  const [validatePriorOrderStatus, setValidatePriorOrderStatus] =
    useState(false);

  const { isDarkMode } = useDarkMode();

  const [showAddAboveButton, setShowAddAboveButton] = useState(false);
  const [aboveStatusData, setAboveStatusData] = useState(null);

  const indexOfOrderStatus = findIndexOfNode(orderStatusList, node?.id);
  const indexOfJobStatus = findIndexOfNode(nodeList, node?.id);

  const prevOrderStatus = findPrevStatus(orderStatusList, indexOfOrderStatus);
  const currentOrderStatus = node;

  const prevJobStatus = findPrevStatus(nodeList, indexOfJobStatus);
  const nextJobStatus = findNextStatus(nodeList, indexOfJobStatus);

  const verifyAboveStatus = (clickedNode) => {
    let jobStatusArray = nodeList
      .filter((item) => item.id === clickedNode?.orderStatusId?.toString())
      .flatMap((item) => item.jobStatus);

    const indexOfClickedStatus = jobStatusArray.findIndex(
      (item) => item.id == clickedNode.id
    );

    const prevStatusNode = jobStatusArray[indexOfClickedStatus - 1];
    const nextStatusNode = jobStatusArray[indexOfClickedStatus + 1];

    if (prevStatusNode === undefined) {
      const orderStatusNode = nodeList.filter(
        (item) => item.id === clickedNode.orderStatusId
      );
      if (parseInt(clickedNode.id) - parseInt(clickedNode.orderStatusId) > 1) {
        return {
          visible: true,
          type: "between",
          data: {
            prev: {
              ...clickedNode,
              id: clickedNode.orderStatusId,
              position: {
                ...orderStatusNode.position,
                ...(orderStatusNode.y = +130),
              },
            },
            current: clickedNode,
            next: clickedNode,
            currentStatusIndex: indexOfClickedStatus,
          },
        };
      } else {
        return false;
      }
    }

    const gap = clickedNode.id - prevStatusNode.id;

    if (gap > 1) {
      return {
        visible: true,
        type: "between",
        data: {
          prev: prevStatusNode,
          current: clickedNode,
          next: nextStatusNode,
          currentStatusIndex: indexOfClickedStatus,
        },
      };
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (node?.id !== "0" && node?.isOrderStatus && node?.id !== "100") {
      const idDifference =
        parseInt(currentOrderStatus?.id) - parseInt(prevOrderStatus?.id);

      if (idDifference > 100) {
        setValidatePriorOrderStatus(true);
      } else {
        setValidatePriorOrderStatus(false);
      }
    }
  }, [node, prevOrderStatus, currentOrderStatus]);

  useEffect(() => {
    if (!node?.isOrderStatus) {
      const res = verifyAboveStatus(node);

      if (res !== false) {
        setShowAddAboveButton(true);
        setAboveStatusData(res);
      }
    }
  }, [node]);

  const editNode = useCallback(() => {
    if (node.isOrderStatus) {
      setOrderStatusModal({
        visible: true,
        type: "edit",
        data: node,
      });
    } else {
      setJobStatusModal({
        visible: true,
        type: "edit",
        data: {
          ...node,
          prevNodeId: parseInt(prevJobStatus.id),
          nextNodeId: nextJobStatus ? parseInt(nextJobStatus.id) : null,
        },
      });
    }
  }, [id, getNode, addNodes]);

  const handleDelete = () => {
    if (!node.isOrderStatus) {
      onDeleteJobStatus(node);
    } else {
      onDeleteOrderStatus(node);
    }
  };

  return (
    !node?.id.includes("-") &&
    node?.id > "0" && (
      <div
        style={{ top: top + 100, left, right, bottom }}
        className={`absolute top-0 z-10 py-1 ${getDarkModeColor(
          isDarkMode,
          "bg-white"
        )} border border-solid rounded shadow-lg h-fit border-gray-600/50`}
        {...props}
      >
        <CustomButton
          type="default"
          size="medium"
          className={`block w-full text-left border-none ${getDarkModeColor(
            isDarkMode,
            "hover:bg-gray-100",
            "hover:bg-gray-700"
          )}`}
          onClick={editNode}
        >
          Edit status
        </CustomButton>

        {/* {!hideOptions ? (
          <></>
        ) : !selectedProduct ? (
          <></>
        ) : !node.data.isDisabled?.includes(selectedProduct?.code) ? (
          <CustomButton
            type="default"
            size="medium"
            className={`block w-full text-left border-none ${getDarkModeColor(
              isDarkMode,
              "hover:bg-gray-100",
              "hover:bg-gray-700"
            )}`}
            onClick={() => handleDisabledNode(node)}
          >
            Disable Status
          </CustomButton>
        ) : (
          <CustomButton
            type="default"
            size="medium"
            className={`block w-full text-left border-none ${getDarkModeColor(
              isDarkMode,
              "hover:bg-gray-100",
              "hover:bg-gray-700"
            )}`}
            onClick={() => handleEnableNode(node)}
          >
            Enable Status
          </CustomButton>
        )} */}

        {validatePriorOrderStatus && (
          <CustomButton
            type="default"
            size="medium"
            className={`block w-full text-left border-none ${getDarkModeColor(
              isDarkMode,
              "hover:bg-gray-100",
              "hover:bg-gray-700"
            )}`}
            onClick={() =>
              setOrderStatusModal({
                visible: true,
                type: "prior",
                data: {
                  prev: prevOrderStatus,
                  current: {
                    ...currentOrderStatus,
                    currentIndex: indexOfOrderStatus,
                  },
                },
              })
            }
          >
            Add prior status
          </CustomButton>
        )}

        {!selectedProduct ? (
          <></>
        ) : !node.data.isDisabled?.includes(selectedProduct?.code) ? (
          <CustomButton
            type="default"
            size="medium"
            className={`block w-full text-left border-none ${getDarkModeColor(
              isDarkMode,
              "hover:bg-gray-100",
              "hover:bg-gray-700"
            )}`}
            onClick={() => handleDisabledNode(node)}
          >
            Disable Status
          </CustomButton>
        ) : (
          <CustomButton
            type="default"
            size="medium"
            className={`block w-full text-left border-none ${getDarkModeColor(
              isDarkMode,
              "hover:bg-gray-100",
              "hover:bg-gray-700"
            )}`}
            onClick={() => handleEnableNode(node)}
          >
            Enable Status
          </CustomButton>
        )}

        {!hideOptions && showAddAboveButton && (
          <CustomButton
            type="default"
            size="medium"
            className={`block w-full text-left border-none ${getDarkModeColor(
              isDarkMode,
              "hover:bg-gray-100",
              "hover:bg-gray-700"
            )}`}
            onClick={() => setJobStatusModal(aboveStatusData)}
          >
            Add status above
          </CustomButton>
        )}

        {!hideOptions && (
          <CustomButton
            type="default"
            size="medium"
            className={`block w-full text-left border-none ${getDarkModeColor(
              isDarkMode,
              "hover:bg-gray-100",
              "hover:bg-gray-700"
            )}`}
            onClick={handleDelete}
          >
            Delete status
          </CustomButton>
        )}
      </div>
    )
  );
}
