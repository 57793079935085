import {
  CheckOutlined,
  CloseOutlined,
  FileDoneOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Drawer, Form, Input, Select, Tooltip, message } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import OrderManagementService from "../../services/OrderManagement/OrderManagementService";
import LabelWithInfo from "../../Common/LabelWithInfo";
import ViewInfoButton from "../../Common/ViewInfoButton";
import CustomButton from "../../Common/CustomButton";

const { Option } = Select;
const OrderAttachmentDrawer = ({
  activeTab,
  currentOrderStatus,
  isDrawerOpen,
  onCancel,
  onSuccess,
}) => {
  const [isStatusListLoading, setIsStatusListLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadFileList, setUploadFileList] = useState(null);
  const [statusType, setStatusType] = useState([]);
  const [form] = useForm();

  const onFinish = async (values) => {
    try {
      const primaryIdentifier = currentOrderStatus?.primaryIdentifier;
      handleUploadFile(primaryIdentifier, uploadFileList, values.type);
    } catch (error) {
      message.info("Select file");
    }
  };

  const handleUploadFile = (primaryIdentifier, uploadFileList, type) => {
    const formData = new FormData();
    formData.append("fileUploadRequest.files", uploadFileList);
    formData.append("fileUploadRequest.category", type);
    setIsLoading(true);
    OrderManagementService.saveOrderAttachment(formData, primaryIdentifier)
      .then((res) => {
        setIsLoading(false);
        message.success("Attachment Upload Successfully");
        if (onSuccess) {
          onSuccess();
        }
        handleCancel();
      })
      .catch((error) => {
        setIsLoading(false);
        error?.response?.data?.message
          ? message.error(error?.response?.data?.message)
          : message.error("Something went wrong");
      });
  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    setUploadFileList(file);
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  useEffect(() => {
    if (isDrawerOpen) {
      setIsStatusListLoading(true);
      OrderManagementService.getStatusTypeData()
        .then((res) => {
          const updatedStatusTypes = res.data.map((item) => ({
            label: item.name,
            value: item.name,
          }));
          setStatusType(updatedStatusTypes);
          setIsStatusListLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsStatusListLoading(false);
          error?.response?.data?.message
            ? message.error(error?.response?.data?.message)
            : message.error("Something went wrong");
        });
    }
  }, [isDrawerOpen]);

  useEffect(() => {
    if (isDrawerOpen && activeTab) {
      form.setFieldsValue({ type: activeTab });
    }
  }, [isDrawerOpen]);

  return (
    <Drawer
      title={`Upload Attachment`}
      width={400}
      onClose={onCancel}
      open={isDrawerOpen}
      bodyStyle={{
        padding: "4px 16px",
      }}
      destroyOnClose={true}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          label={
            <LabelWithInfo
              label="Type"
              component={
                <ViewInfoButton
                  placement="left"
                  title="The file type for your order upload"
                />
              }
            />
          }
          style={{ width: "100%" }}
          name="type"
          rules={[
            {
              required: true,
              message: "Select Type",
            },
          ]}
        >
          <Select
            disabled={activeTab !== (null || undefined)}
            loading={isStatusListLoading}
            placeholder="Select Type"
            width={"100%"}
            options={statusType}
            size={"medium"}
          />
        </Form.Item>

        <Form.Item
          label="File"
          style={{ width: "100%" }}
          name="File"
          rules={[
            {
              required: true,
              message: "Upload Attachment",
            },
          ]}
        >
          <Input
            className="p-1"
            onChange={handleChange}
            accept=".xlsx, .xls, .pdf, .jpg, .jpeg, .png"
            type="file"
          />
        </Form.Item>

        <div className="flex justify-end gap-2 mt-5">
          <CustomButton
            type="primary"
            htmlType="submit"
            loading={isLoading}
            icon={<CheckOutlined />}
          >
            Submit
          </CustomButton>
          <CustomButton
            type="cancel"
            onClick={onCancel}
            icon={<CloseOutlined />}
          >
            Cancel
          </CustomButton>
        </div>
      </Form>
    </Drawer>
  );
};

export default OrderAttachmentDrawer;
