import {
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { message, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../Common/CustomBreadcrumb";
import CustomButton from "../../Common/CustomButton";
import CustomSwitch from "../../Common/CustomSwitch";
import { useDarkMode } from "../../Providers/DarkModeContext";
import ChecklistService from "../../services/Checklist/ChecklistService";
import { getDarkModeColor } from "../../utils/darkModeHelper";
import { formatDate } from "../../utils/dateHelper";
import OrderChecklistDrawer from "../OrderManagement/OrderChecklistDrawer";
import ManageChecklistDrawer from "./ManageChecklistDrawer";

const items = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Checklist Configuration",
  },
];

const ChecklistPage = () => {
  const { isDarkMode } = useDarkMode();
  const navigate = useNavigate();

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState("");

  const [manageDrawer, setManageDrawer] = useState({
    openType: null,
    initalValue: null,
  });

  const fetchList = async () => {
    setLoading("list-fetch");

    try {
      const { data } = await ChecklistService.getList();

      setList(data);
    } catch (error) {
      console.log(error);
      message.error(
        error?.response?.data?.message ||
          "Unable to fetch list right now. Try again later!"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchList();
  }, []);

  const handleStatusChange = async (record, updatedStatus) => {
    setLoading(`status-update`);

    try {
      await ChecklistService.updateChecklist({
        ...record,
        isActive: updatedStatus,
      });

      fetchList();
    } catch (error) {
      console.log(error);
      message.error(
        error?.response?.data?.message ||
          "Unable to update status right now. Try again later!"
      );
    } finally {
      setLoading("");
    }
  };

  const handleDeleteChecklist = async (record, index) => {
    setLoading(`delete-${index}`);

    try {
      await ChecklistService.deleteChecklist(record.id);
      fetchList();
    } catch (error) {
      console.log(error);
      message.error(
        error?.response?.data?.message ||
          "Unable to delete checklist right now. Try again later!"
      );
    } finally {
      setLoading("");
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    // {
    //   title: "For Status",
    //   dataIndex: "statusID",
    //   key: "statusID",
    // },
    {
      title: "Added on",
      dataIndex: "dateAdded",
      key: "dateAdded",
      render: (text) => formatDate(text),
    },
    {
      title: "Added By",
      dataIndex: "addedBy",
      key: "addedBy",
    },
    {
      title: "Questions",
      dataIndex: "options",
      key: "options",
      width: 120,
      align: "center",
      render: (_, record) => (
        <CustomButton
          onClick={() => {
            navigate("/checklist/options", {
              state: { checklistData: record },
            });
          }}
          icon={<SettingOutlined />}
          type="secondary"
        >
          Manage
        </CustomButton>
      ),
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      align: "center",
      width: 100,
      render: (text, record) => (
        <CustomSwitch
          active={text}
          onChange={() => handleStatusChange(record, !text)}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 200,
      render: (_, record, index) => {
        return (
          <div className="flex justify-center gap-2">
            <CustomButton
              onClick={() => {
                setManageDrawer({
                  openType: "edit",
                  initalValue: record,
                });
              }}
              icon={<EditOutlined />}
              type="primary"
            >
              Edit
            </CustomButton>

            <CustomButton
              onClick={() => {
                setManageDrawer({
                  openType: "preview",
                  initalValue: { ...record, checklistID: record.id },
                });
              }}
              icon={<EyeOutlined />}
            >
              Preview
            </CustomButton>

            {/* <Popconfirm
              okText="Yes"
              cancelText="No"
              title="Are you sure you want to delete this checklist?"
              onConfirm={() => handleDeleteChecklist(record, index, "delete")}
            >
              <ToolTipButton
                tooltipHeader="Delete Checklist"
                tooltipPlacement="top"
                icon={<DeleteOutlined />}
                type="primary"
                loading={loading === `delete-${index}`}
                danger
              />
            </Popconfirm> */}
          </div>
        );
      },
    },
  ];

  return (
    <div className="p-2 my-4">
      <div className="flex items-start justify-between w-full mb-2">
        <CustomBreadcrumb items={items} />

        <CustomButton
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setManageDrawer({
              openType: "add",
              initalValue: null,
            });
          }}
        >
          Add
        </CustomButton>
      </div>

      <Table
        bordered={"1px solid black"}
        loading={loading === "list-fetch"}
        dataSource={list}
        columns={columns}
        tableLayout="fixed"
        rowClassName={(record, index) =>
          index % 2 !== 0 ? getDarkModeColor(isDarkMode, "bg-gray-100") : ""
        }
        pagination={false}
        scroll={{ x: 1000 }}
      />

      <ManageChecklistDrawer
        open={
          manageDrawer.openType === "add" || manageDrawer.openType === "edit"
        }
        openType={manageDrawer.openType}
        initalData={manageDrawer.initalValue}
        onClose={() =>
          setManageDrawer({
            openType: null,
            initalValue: null,
          })
        }
        refetch={() => {
          fetchList();
        }}
      />

      <OrderChecklistDrawer
        isDrawerOpen={manageDrawer.openType === "preview"}
        orderDetails={manageDrawer.initalValue}
        onClose={() =>
          setManageDrawer({
            openType: null,
            initalValue: null,
          })
        }
        type="view"
      />
    </div>
  );
};

export default ChecklistPage;
