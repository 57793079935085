import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Checkbox,
  Drawer,
  Form,
  Input,
  Select,
  Typography,
  message,
} from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import CustomButton from "../../Common/CustomButton";
import LabelWithInfo from "../../Common/LabelWithInfo";
import ViewInfoButton from "../../Common/ViewInfoButton";
import Roles from "../../services/Roles/Roles";
import { validateTextInput } from "../../utils/validationsHelper";

const ManageRoleDrawer = ({
  initialValue,
  changeStatus,
  onClose,
  onSuccess,
  viewsOptions,
}) => {
  const [form] = useForm();
  const [searchPrivilegeValue, setSearchPrivilegeValue] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchPrivilegeGroupOptions, setSearchPrivilegeGroupOptions] =
    useState([]);
  const [privilegeData, setPrivilegeData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [width, setWidth] = useState(600);

  useEffect(() => {
    Roles.getPriviledgesList().then((res) => {
      setPrivilegeData(
        res?.data?.map((item) => ({
          label: item.name,
          value: item.primaryIdentifier,
        }))
      );
    });
  }, []);

  useEffect(() => {
    if (changeStatus === "edit") {
      form.setFieldsValue({
        ...initialValue,
        roleType: initialValue.role.roleType,
        isExternal: initialValue.role.isExternal,
        privilegeIds: initialValue?.role?.privileges?.map((item) => item.name),
        rank: initialValue?.role?.roleRank,
        allowedOrderView: initialValue?.role?.allowedOrderView?.split(","),
      });

      const privilegeIdsLength =
        form.getFieldValue("privilegeIds")?.length || 0;

      if (privilegeIdsLength > 10) {
        const increasedWidth = Math.ceil(privilegeIdsLength / 10) * 50;
        setWidth(600 + increasedWidth);
      } else {
        setWidth(600);
      }
    } else {
      form.resetFields();
      setWidth(600);
    }
    return () => form.setFieldsValue(null);
  }, [initialValue, changeStatus]);

  const handlePrivilegeSearchValue = (text) => {
    Roles.getPrivilegeGroupSearchList(text)
      .then((res) => {
        const filteredData = res?.data?.map((i) => ({
          label: i,
          value: i,
        }));
        setSearchPrivilegeGroupOptions(filteredData);
      })
      .catch((error) => {
        error?.response?.data?.message
          ? message.error(error?.response?.data?.message)
          : message.error("Something went wrong");
      });
  };

  const handlePrivilegeGroup = async (selectedItems) => {
    try {
      const newPrivilegeIds = [];

      for (let i = 0; i < selectedItems.length; i++) {
        const response = await Roles.getPrivilegeGroupList(selectedItems[i]);
        const responseData = response?.data || [];
        const groupPrivileges = responseData.map(
          (item) => item.primaryIdentifier
        );

        newPrivilegeIds.push(
          ...privilegeData
            .filter((privilegesItem) =>
              groupPrivileges.includes(privilegesItem.value)
            )
            .map((item) => item.value)
        );

        setSearchPrivilegeValue((prevValues) => [
          ...prevValues,
          ...groupPrivileges,
        ]);

        form.setFieldsValue({
          privilegeIds: newPrivilegeIds,
        });
      }
    } catch (error) {
      error?.response?.data?.message
        ? message.error(error?.response?.data?.message)
        : message.error("Something went wrong");
    }
  };

  const onFinish = (values) => {
    let payload = {
      ...values,
      privilegeIds: [...searchPrivilegeValue, ...values.privilegeIds],
      permissionAllowed: "",
      permissionDenied: "",
      rank: Number(values.rank),
      allowedOrderView: values.allowedOrderView?.toString(),
    };

    setIsLoading(true);
    if (changeStatus == "add") {
      Roles.addUserRoleData(payload)
        .then((res) => {
          message.success("User Role Added Successfully");
          setIsLoading(false);
          onClose();
          onSuccess();
        })
        .catch((error) => {
          setIsLoading(false);
          onClose();
          error?.response?.data?.message
            ? message.error(error?.response?.data?.message)
            : message.error("Something went wrong");
        });
    } else if (changeStatus == "edit") {
      payload = {
        ...payload,
        id: initialValue.id,
        primaryIdentifier: initialValue.primaryIdentifier,
      };

      Roles.updateUserRole(payload)
        .then((res) => {
          message.success("User Role Updated Successfully");
          setIsLoading(false);
          onClose();
          onSuccess();
        })
        .catch((error) => {
          error?.response?.data?.message
            ? message.error(error?.response?.data?.message)
            : message.error("Something went wrong");
          setIsLoading(false);
          onClose();
        });
    }
  };

  useEffect(() => {
    handlePrivilegeSearchValue(searchValue);
  }, [searchValue]);

  const filterOption = (input, option) =>
    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <Drawer
      title={
        changeStatus === "add"
          ? "Add User Role"
          : changeStatus === "edit"
          ? "Edit User Role"
          : ""
      }
      width={width}
      destroyOnClose={true}
      onClose={() => {
        onClose();
        setSearchValue("");
      }}
      open={!!changeStatus}
      bodyStyle={{
        padding: "4px 16px",
      }}
    >
      <div className="rounded-md border-black-600 ">
        <div>
          <Form
            form={form}
            name="form_item_path"
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Enter name",
                },
                { validator: validateTextInput("Name", 3, 100) },
              ]}
            >
              <Input
                disabled={changeStatus === "edit" ? true : false}
                size="medium"
                placeholder="Enter name"
              />
            </Form.Item>
            {/* hiding role type from frontend */}
            {/* <Form.Item
              name="roleType"
              label={
                <span>
                  Role Type&nbsp;
                  <ViewInfoButton title="This specifies the type of role. For instance: Admin, Sales, SuperAdmin." />
                </span>
              }
              rules={[
                {
                  required: true,
                  message: "Enter role type",
                },
              ]}
            >
              <Input size="medium" placeholder="Enter role type" />
            </Form.Item> */}
            <Form.Item
              name="rank"
              label={
                <LabelWithInfo
                  label={"Role Rank"}
                  component={
                    <ViewInfoButton
                      placement="left"
                      title="Indicates the priority level of the user's assigned tasks. For example: 1 for the highest rank, allowing the user to perform any actions."
                    />
                  }
                />
              }
              rules={[
                {
                  required: true,
                  message: "Enter role rank",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || (value >= 1 && value <= 100)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "Role rank should be between 1 and 100"
                    );
                  },
                }),
              ]}
            >
              <Input
                type="number"
                size="medium"
                placeholder="Enter role rank"
              />
            </Form.Item>

            <Form.Item
              name="description"
              label={
                <LabelWithInfo
                  label={"Description"}
                  component={
                    <ViewInfoButton
                      placement="left"
                      title="Provide additional details or information about the role."
                    />
                  }
                />
              }
              rules={[
                {
                  required: true,
                  message: "Enter description",
                },
                {
                  validator: validateTextInput("Description", 10, 100),
                },
              ]}
            >
              <Input size="medium" placeholder="Enter description" />
            </Form.Item>

            {/* 
            will be needed later
            
            <Form.Item
              name="dashboardCardNames"
              label={
                <LabelWithInfo
                  label={"Dashboard"}
                  component={
                    <ViewInfoButton
                      placement="left"
                      title="The name displayed on the dashboard card."
                    />
                  }
                />
              }
              rules={[
                {
                  required: true,
                  message: "Enter dashboard card",
                },
              ]}
            >
              <Input
                size="medium"
                type="text"
                placeholder="Enter dashboard card"
              />
            </Form.Item> */}

            <Form.Item name="privilegeId" label="Privileges Group">
              <Select
                mode="multiple"
                size="medium"
                showSearch
                options={searchPrivilegeGroupOptions}
                placeholder="Search privileges group"
                onChange={handlePrivilegeGroup}
                filterOption={filterOption}
              />
            </Form.Item>

            <Form.Item
              name="privilegeIds"
              label="Privilege"
              rules={[
                {
                  required: true,
                  message: "Atleast 1 privilege is required",
                },
              ]}
            >
              <Select
                mode="multiple"
                size="medium"
                showSearch
                placeholder="Select privilege"
                options={privilegeData}
                filterOption={filterOption}
              />
            </Form.Item>

            <Form.Item label="Select Allowed Views" name="allowedOrderView">
              <Select
                mode="multiple"
                size="medium"
                showSearch
                placeholder="Select allowed views"
                options={viewsOptions}
                filterOption={filterOption}
              />
            </Form.Item>

            <Form.Item valuePropName="checked" name="isExternal">
              <Typography level={5} className="flex justify-between mt-2">
                <Checkbox>Is External</Checkbox>
                <ViewInfoButton
                  placement="left"
                  title="Indicates whether the role is for internal or external use"
                />
              </Typography>
            </Form.Item>

            <div className="flex justify-end gap-2 mt-5">
              <CustomButton
                type="primary"
                htmlType="submit"
                loading={isLoading}
                icon={<CheckOutlined />}
              >
                Submit
              </CustomButton>
              <CustomButton
                type="cancel"
                onClick={onClose}
                icon={<CloseOutlined />}
              >
                Cancel
              </CustomButton>
            </div>
          </Form>
        </div>
      </div>
    </Drawer>
  );
};

export default ManageRoleDrawer;
