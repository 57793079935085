import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Card, Collapse, Table, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDarkMode } from "../../Providers/DarkModeContext";
import { useScreenSize } from "../../Providers/ScreenSizeProvider";
import { formatDateMonth, formatTime12Hour } from "../../utils/dateHelper";

const OrderStatusComponent = ({ data, loading }) => {
  const [activeCollapseKeys, setActiveCollapseKeys] = useState([]);
  const currentScreenSize = useScreenSize();
  const { isDarkMode } = useDarkMode();

  useEffect(() => {
    const newActiveKeys = data.reduce((keys, item) => {
      const activeKeys = item.jobStatusDetails
        .filter((jobStatusData) => jobStatusData.isAccordianOpen)
        .map((activeItem) => Math.floor(activeItem.jobStatusID / 100) * 100);
      return [...keys, ...activeKeys];
    }, []);

    setActiveCollapseKeys(newActiveKeys);
  }, [data]);

  const handleCollapseChange = (keys) => {
    setActiveCollapseKeys(keys);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "jobStatusID",
      key: "jobStatusID",
      width: 60,
    },
    {
      title: "Job Status Name",
      dataIndex: "jobStatusName",
      key: "jobStatusName",
      render: (text) => text,
      width: 250,
    },
    {
      title: "Start Date",
      dataIndex: "dateStarted",
      key: "dateStarted",
      render: (text) =>
        text ? (
          <Tooltip title={formatTime12Hour(text)}>
            {formatDateMonth(text)}
          </Tooltip>
        ) : (
          "NA"
        ),
    },
    {
      title: "End Date",
      dataIndex: "dateEnded",
      key: "dateEnded",
      render: (text) =>
        text ? (
          <Tooltip title={formatTime12Hour(text)}>
            {formatDateMonth(text)}
          </Tooltip>
        ) : (
          "NA"
        ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag
          className="text-[10px] tracking-wider font-bold"
          color={status.color}
        >
          {status.text}
        </Tag>
      ),
    },
    {
      title: "Updated By",
      dataIndex: "updatedBy",
      key: "updatedBy",
      render: (text) => (text ? text : "NA"),
    },
  ];

  return (
    <Card
      style={{ marginTop: "1rem" }}
      title={
        <div className="text-lg font-semibold text-center">Status Details</div>
      }
      loading={loading}
    >
      {data.length > 0 &&
        data.map((item) => {
          const activeKeys = item.jobStatusDetails
            .filter((jobStatusData) => jobStatusData.isActive)
            .map(
              (activeItem) => Math.floor(activeItem.jobStatusID / 100) * 100
            );

          const isDisabled = item.jobStatusDetails.length === 0;

          const marginTopStyle = { marginTop: "0.75rem" };
          const panelBackground = item?.isActive
            ? { backgroundColor: isDarkMode ? "#374151" : "rgb(230,247,255)" }
            : {};

          return (
            <Collapse
              size={"small"}
              expandIcon={({ isActive }) =>
                isActive ? <UpOutlined /> : <DownOutlined />
              }
              expandIconPosition="end"
              style={{ ...panelBackground, ...marginTopStyle }}
              defaultActiveKey={activeKeys}
              activeKey={activeCollapseKeys}
              key={item.orderStatusID}
              onChange={isDisabled ? null : handleCollapseChange}
              className="select-none"
            >
              <Collapse.Panel
                showArrow={isDisabled ? false : true}
                header={
                  <div className="flex gap-3 text-lg font-semibold">
                    <p>{item.orderStatusName}</p>
                  </div>
                }
                key={item.orderStatusID}
              >
                <Table
                  columns={columns}
                  tableLayout="fixed"
                  scroll={{ x: currentScreenSize < 1000 && 1000 }}
                  dataSource={item.jobStatusDetails.map((jobStatusData) => ({
                    key: jobStatusData.jobStatusID,
                    jobStatusID: jobStatusData.jobStatusID,
                    jobStatusName: jobStatusData.jobStatusName,
                    dateStarted: jobStatusData.dateStarted,
                    dateEnded: jobStatusData.dateEnded,
                    updatedBy: jobStatusData.updatedBy,
                    status: {
                      text: jobStatusData.isActive
                        ? "In Progress"
                        : jobStatusData.isCompleted
                        ? jobStatusData.isCompleted && jobStatusData.isSkipped
                          ? "Skipped"
                          : "Completed"
                        : "Not started",
                      color: jobStatusData.isActive
                        ? "yellow-inverse"
                        : jobStatusData.isCompleted
                        ? jobStatusData.isCompleted && jobStatusData.isSkipped
                          ? "warning"
                          : "green-inverse"
                        : "grey",
                    },
                  }))}
                  pagination={false}
                  bordered={false}
                />
              </Collapse.Panel>
            </Collapse>
          );
        })}
    </Card>
  );
};

export default OrderStatusComponent;
