import { Card, Col, Form, Input, Row } from "antd";
import React from "react";
import { NumericFormat } from "react-number-format";
import ProductSelectFormItem from "../Common/ProductSelectFormItem";

const DuratrayProductDetails = ({
  previewOrder,
  editData,
  selectedAccount,
  renderConfigOptions,
  fetchForecastData,
  form,
  productList,
  productListLoading,
  forecastedDate,
  productID,
  handleQuantityChange,
  productCost,
  setAccessoriesPrice,
}) => {
  return (
    <Card type="inner" title="Product Details" className="mt-2">
      <Row gutter={[16, 4]}>
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <ProductSelectFormItem
            previewOrder={previewOrder}
            editData={editData}
            selectedAccount={selectedAccount}
            renderConfigOptions={renderConfigOptions}
            fetchForecastData={fetchForecastData}
            form={form}
            productList={productList}
            isLoading={productListLoading}
            forecastedDate={forecastedDate}
            showLabel={true}
            setAccessoriesPrice={setAccessoriesPrice}
          />
        </Col>

        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item
            name="productCost"
            label={
              <>
                Price
                <span className="ml-1 italic text-[12px]">( per unit )</span>
              </>
            }
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  return new Promise((resolve, reject) => {
                    if (value) {
                      if (value >= 0) {
                        resolve();
                      } else {
                        reject("Price cannot be less than 0.");
                      }
                    } else {
                      reject("Enter Price");
                    }
                  });
                },
              },
            ]}
          >
            <Input
              disabled={(!productID || previewOrder === "preview") && !editData}
              placeholder="Enter price"
              size="medium"
              type="number"
              step="0.01"
              min={0}
            />
          </Form.Item>
        </Col>

        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item
            name="quantity"
            label="Quantity"
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  return new Promise((resolve, reject) => {
                    if (value) {
                      if (value >= 1) {
                        resolve();
                      } else {
                        reject("Quantity cannot be less than 1.");
                      }
                    } else {
                      reject("Enter quantity");
                    }
                  });
                },
              },
            ]}
          >
            <Input
              placeholder="Enter quantity"
              // disabled={(!productID || previewOrder === "preview") && !editData}
              disabled={true}
              onChange={handleQuantityChange}
              size="medium"
              type="number"
              min={1}
            />
          </Form.Item>
        </Col>

        {/* {productID && (
          <Col xl={8} lg={8} md={8} sm={24} xs={24}>
            <div className="text-end">
              <p className="text-[15px] mt-1 mb-1">Cost</p>

              <NumericFormat
                prefix="$"
                className="font-semibold"
                value={productCost || 0}
                displayType="text"
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={true}
              />
            </div>
          </Col>
        )} */}
      </Row>
    </Card>
  );
};

export default DuratrayProductDetails;
