import { Alert, Modal, Popconfirm, Row, Table, Upload, message } from "antd";
import React, { useState } from "react";
import CustomButton from "../../../Common/CustomButton";
import {
  CheckOutlined,
  CloseOutlined,
  CloudUploadOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import OrderManagementService from "../../../services/OrderManagement/OrderManagementService";

const UploadAttachmentTable = ({
  dataSource,
  onClose,
  primaryIdentifierLists,
}) => {
  const [uploading, setUploading] = useState([]);
  const [updatedData, setUpdatedData] = useState(
    dataSource.map((i) => {
      return { ...i, isAttachmentUploaded: false };
    })
  );

  const handleUpload = async (index) => {
    const formData = new FormData();

    formData.append(
      "fileUploadRequest.files",
      updatedData[index].fileUploadRequest.files
    );
    formData.append(
      "fileUploadRequest.category",
      updatedData[index].fileUploadRequest.category
    );
    formData.append("productID", updatedData[index].productID);
    formData.append("primaryIdentifier", updatedData[index].primaryIdentifier);
    formData.append("notes", updatedData[index].notes);
    formData.append("quoteRefNum", updatedData[index].quoteRefNum);
    formData.append("jobRefNum", updatedData[index].jobRefNum);
    formData.append(
      "shippingAddressPrimaryIdentifier",
      updatedData[index].shippingAddressPrimaryIdentifier
    );
    formData.append(
      "contactPrimaryIdentifier",
      updatedData[index].contactPrimaryIdentifier
    );
    formData.append("quantity", updatedData[index].quantity);
    formData.append("price", updatedData[index].price);

    let updatedUploadingStates = [...uploading];
    updatedUploadingStates[index] = true;
    setUploading(updatedUploadingStates);

    try {
      await OrderManagementService.createOrdersFromList(
        primaryIdentifierLists[index].primaryIdentifier,
        formData
      );

      setUpdatedData((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = { ...prevData[index], isAttachmentUploaded: true };
        return updatedData;
      });

      updatedUploadingStates[index] = false;
      setUploading(updatedUploadingStates);
      message.success("Added attachment successfully!");
    } catch (error) {
      updatedUploadingStates[index] = false;
      setUploading(updatedUploadingStates);
      console.error(error);
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went wrong!"
      );
    }
  };

  const columns = [
    {
      title: "Reference Number",
      dataIndex: "quoteRefNum",
      key: "quoteRefNum",
    },
    {
      title: "Category",
      dataIndex: "fileUploadRequest",
      key: "fileUploadRequest",
      render: (item) => item.category,
    },
    {
      title: "Upload attachment",
      key: "attachment",
      width: 400,
      render: (_, item, index) => (
        <Row className="items-end justify-between">
          {!updatedData[index].isAttachmentUploaded ? (
            <Upload
              className="flex items-end gap-3"
              accept=".xlsx, .csv, .pdf, .jpg, .jpeg, .png"
              maxCount={1}
              onRemove={() => {
                setUpdatedData((prevData) => {
                  return prevData?.map((i) => {
                    if (i.jobRefNum !== item.jobRefNum) {
                      return i;
                    }

                    return {
                      ...i,
                      fileUploadRequest: {
                        ...i.fileUploadRequest,
                        files: null,
                      },
                    };
                  });
                });
              }}
              beforeUpload={(file) => {
                setUpdatedData((prevData) => {
                  return prevData?.map((i) => {
                    if (i.quoteRefNum !== item.quoteRefNum) {
                      return i;
                    }

                    return {
                      ...i,
                      fileUploadRequest: {
                        ...i.fileUploadRequest,
                        files: file,
                      },
                    };
                  });
                });

                return false;
              }}
            >
              <CustomButton
                disabled={uploading[index] && uploading[index]}
                icon={<CloudUploadOutlined />}
              >
                Select file
              </CustomButton>
            </Upload>
          ) : (
            <span>{updatedData[index].fileUploadRequest.files.name}</span>
          )}

          {!updatedData[index].isAttachmentUploaded && (
            <CustomButton
              loading={uploading[index]}
              type="primary"
              icon={<FileDoneOutlined />}
              onClick={() => handleUpload(index)}
              disabled={
                updatedData[index].fileUploadRequest.files === null ||
                updatedData[index].isAttachmentUploaded
              }
            >
              Save
            </CustomButton>
          )}
        </Row>
      ),
    },
  ];

  const unsavedAttachmentsExist = updatedData.some(
    (i) => !i.isAttachmentUploaded && i.fileUploadRequest.files
  );

  return (
    <Modal
      title="Add Attachments"
      className="relative custom-upload-tag"
      width={800}
      open={dataSource !== null}
      footer={false}
      closable={false}
    >
      <CustomButton
        className="absolute top-3 right-3"
        icon={<CloseOutlined />}
        onClick={onClose}
      />

      <Alert
        type="info"
        className="w-full"
        message={
          <p>
            <span className="mr-2 font-semibold">Note:</span>
            If the quote reference number of your new order matches any existing
            order, it will be automatically updated.
          </p>
        }
      />

      <Table
        className="mt-4"
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered
      />

      <Row className="justify-end mt-4">
        {unsavedAttachmentsExist ? (
          <Popconfirm
            title="Please save unsaved attachments otherwise they will be lost!"
            placement="bottom"
            onConfirm={onClose}
            okText="Yes"
            cancelText="No"
          >
            <CustomButton icon={<CheckOutlined />} type="primary">
              Done
            </CustomButton>
          </Popconfirm>
        ) : (
          <CustomButton
            icon={<CheckOutlined />}
            type="primary"
            onClick={onClose}
          >
            Done
          </CustomButton>
        )}
      </Row>
    </Modal>
  );
};

export default UploadAttachmentTable;
