import {
  CheckOutlined,
  CloseOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { Drawer, Form, Input, message } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import ProductService from "../../services/ProductBuilder/ProductService";
import ProductionLineService from "../../services/ProductionLine/ProductionLineService";
import ViewInfoButton from "../../Common/ViewInfoButton";
import LabelWithInfo from "../../Common/LabelWithInfo";
import CustomButton from "../../Common/CustomButton";
import { validateTextInput } from "../../utils/validationsHelper";

const ManageProductGroup = ({ editData, onClose, changeStatus, onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = useForm();

  const onFinish = (values) => {
    if (changeStatus === "add") {
      const payload = {
        ...values,
        productGroupID: 0,
      };
      setIsLoading(true);
      ProductService.addProductGroupPageData(payload)
        .then((res) => {
          setIsLoading(false);
          message.success("Product Group Added successfully");
          form.resetFields();
          handleClose();
          onSuccess();
        })
        .catch((error) => {
          setIsLoading(false);

          error?.response?.data?.message
            ? message.error(error?.response?.data?.message)
            : message.error("Something went wrong");
        });
    } else if (changeStatus === "edit") {
      const payload = {
        ...values,
        productGroupID: editData.productGroupID,
      };
      setIsLoading(true);
      ProductService.editProductGroupPageData(payload)
        .then((res) => {
          setIsLoading(false);
          message.success("Product Group Edit Successfully");
          form.resetFields();
          handleClose();
          onSuccess();
        })
        .catch((error) => {
          setIsLoading(false);
          error?.response?.data?.message
            ? message.error(error?.response?.data?.message)
            : message.error("Something went wrong");
        });
    }
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    if (changeStatus === "edit") {
      form.setFieldsValue({ ...editData });
    }
  }, [changeStatus]);

  return (
    <Drawer
      title={
        changeStatus === "add"
          ? "Add Product Group"
          : changeStatus === "edit"
          ? "Edit Product Group"
          : ""
      }
      width={350}
      onClose={handleClose}
      open={changeStatus !== null}
      bodyStyle={{
        padding: "4px 16px",
      }}
      destroyOnClose={true}
    >
      <div>
        <Form
          form={form}
          name="form_item_path"
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            name="name"
            label="Product group name"
            rules={[
              {
                required: true,
                message: "Enter product group name",
              },
              { validator: validateTextInput("Product group name") },
            ]}
          >
            <Input size="medium" placeholder="Enter product group name" />
          </Form.Item>

          <Form.Item
            name="code"
            label={
              <LabelWithInfo
                label="Product group code"
                component={
                  <ViewInfoButton title="This code will be used to identify the product group during production." />
                }
              />
            }
            rules={[
              {
                required: true,
                message: "Enter product group code",
              },
              {
                pattern: /^[A-Z]+$/,
                message: "Product group code should contain only alphabets",
              },
            ]}
          >
            <Input
              size="medium"
              placeholder="Enter product group code"
              maxLength={3}
              onChange={(e) => {
                const upperCaseValue = e.target.value.toUpperCase();
                form.setFieldsValue({ code: upperCaseValue });
              }}
            />
          </Form.Item>

          <div className="flex justify-end gap-2 mt-5">
            <CustomButton
              type="primary"
              htmlType="submit"
              loading={isLoading}
              icon={<CheckOutlined />}
            >
              Submit
            </CustomButton>
            <CustomButton
              type="cancel"
              onClick={handleClose}
              icon={<CloseOutlined />}
            >
              Cancel
            </CustomButton>
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default ManageProductGroup;
