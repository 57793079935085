import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Card,
  Checkbox,
  Drawer,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Tag,
} from "antd";
import { useForm, useWatch } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import { BsArrowUpRight } from "react-icons/bs";
import CustomButton from "../../../Common/CustomButton";
import { useDarkMode } from "../../../Providers/DarkModeContext";
import { validateTextInput } from "../../../utils/validationsHelper";

const ManageJobStatusDrawer = ({
  visible,
  onCancel,
  onAddNode,
  orderStatusName,
  orderStatusId,
  parallelNames,
  minLength,
  jobstatusNodes,
  data,
  onEditNode,
  type,
  onAddAbove,
  productList,
  isFlowFinalized,
  alertVisible,
  setAlertVisible,
  checklistOptions,
}) => {
  const { isDarkMode } = useDarkMode();

  const [form] = useForm();
  const [loading, setLoading] = useState(false);

  let prevJobStatus =
    jobstatusNodes.length !== 0
      ? jobstatusNodes[jobstatusNodes.length - 1]
      : jobstatusNodes;

  const minJobStatus = prevJobStatus.id
    ? parseInt(prevJobStatus.id) + 1
    : parseInt(minLength) + 1;

  const prevId = data
    ? data.prev !== undefined
      ? parseInt(data.prev.id)
      : 0
    : null;

  useEffect(() => {
    if (visible) {
      if (type !== "between" && data) {
        form.setFieldsValue({
          statusId: data.orderStatusId,
          statusName: data.orderStatusName,
          ...data?.data,
        });
      } else if (type === "between") {
        form.setFieldsValue({
          statusId: data.current.orderStatusId,
          statusName: data.current.orderStatusName,
        });
      } else {
        form.setFieldsValue({
          statusId: orderStatusId,
          statusName: orderStatusName,
        });
      }
    }
  }, [data, visible]);

  const validateJobStatusId = (_, value) => {
    const handleStatusValidation = async (
      resolve,
      reject,
      condition,
      errorMessage
    ) => {
      if (condition) {
        resolve();
      } else {
        reject(errorMessage);
      }
    };

    if (type === "add") {
      return new Promise((resolve, reject) => {
        handleStatusValidation(
          resolve,
          reject,
          value >= minJobStatus && value < parseInt(minLength) + 100,
          `Value must be between ${minJobStatus} - ${
            parseInt(minLength) + 100
          }.`
        );
      });
    } else if (type === "edit") {
      if (data.id == value) {
        return Promise.resolve();
      }

      if (data.nextNodeId && data.nextNodeId - data.prevNodeId <= 2) {
        return Promise.resolve();
      }

      if (data.prevNodeId && data.nextNodeId) {
        return new Promise((resolve, reject) => {
          handleStatusValidation(
            resolve,
            reject,
            value >= data.prevNodeId + 1 && value < data.nextNodeId,
            `Value must be between ${data.prevNodeId} - ${data.nextNodeId}.`
          );
        });
      } else if (!data.nextNodeId) {
        return new Promise((resolve, reject) => {
          handleStatusValidation(
            resolve,
            reject,
            value > data.prevNodeId,
            `Minimum allowed value is ${data.prevNodeId + 1}.`
          );
        });
      }
    } else if (type === "between") {
      return new Promise((resolve, reject) => {
        handleStatusValidation(
          resolve,
          reject,
          value > prevId && value < parseInt(data.current.id),
          `Value must be between ${prevId} - ${data.current.id}.`
        );
      });
    }

    return Promise.resolve("Job Status Id is required");
  };

  const handleAddNode = async (values) => {
    setLoading(true);
    try {
      const allValues = {
        ...values,
        action: "Standard",
        isCheckListCompusory: !!values?.checkListID,
      };

      if (type === "between") {
        onAddAbove({ info: data, formValues: allValues });
      } else if (!data) {
        onAddNode(allValues);
      } else {
        onEditNode(data.id, allValues);
      }
    } catch (error) {
      console.error("Validation failed:", error);
    } finally {
      setLoading(false);
      handleOnClose();
    }
  };

  const handleOnClose = () => {
    form.resetFields();
    onCancel();
  };

  const productName = useWatch("productName", form);

  const handleSelectAll = () => {
    const allProducts = productList
      .filter((item) => item.value !== "COMMON")
      .map((line) => line.value);

    form.setFieldValue("productName", allProducts);
  };

  const handleDeselectAll = () => {
    form.setFieldValue("productName", []);
  };

  return (
    <Drawer
      open={visible}
      onClose={handleOnClose}
      width={360}
      title={type === "between" || !data ? "Add Job Status" : "Edit Job Status"}
      destroyOnClose
    >
      <Card title="Actions" type="inner">
        <Row className="justify-between gap-4 flex-nowrap">
          <Tag className="w-full font-semibold text-center uppercase">
            Start
          </Tag>

          <div className="flex items-center justify-center -ml-2">
            <BsArrowUpRight className="mt-px rotate-45" size={16} />
          </div>

          <Tag className="w-full font-semibold text-center uppercase">
            Complete
          </Tag>
        </Row>
      </Card>

      <Form
        form={form}
        layout="vertical"
        className="mt-3 mb-6"
        onFinish={handleAddNode}
      >
        {/* {type === "add" && alertVisible && (
          <Alert
            type="warning"
            showIcon
            message="Please check before submitting – editing is disabled for this product group while there are active orders placed within it"
            className="!mb-4"
            closable
            afterClose={() => setAlertVisible(false)}
          />
        )} */}

        <Form.Item name="statusId" label="Order Status Id" className="flex-1">
          <InputNumber
            size="medium"
            className={`min-w-full ${
              isDarkMode ? "!text-gray-300" : "!text-black/80"
            }  `}
            type="number"
            min={1}
            placeholder="Status Id"
            disabled
          />
        </Form.Item>

        <Form.Item
          name="statusName"
          label="Order Status name"
          className="flex-1"
        >
          <Input
            size="medium"
            placeholder="Status Name"
            className={`${isDarkMode ? "!text-gray-300" : "!text-black/80"} `}
            disabled
          />
        </Form.Item>

        <Form.Item
          name="jobStatusId"
          label="Job Status Id"
          className={`${isFlowFinalized ? "sr-only" : "flex-1"}`}
          rules={[
            {
              required: true,
              validator: validateJobStatusId,
            },
          ]}
        >
          <InputNumber
            size="medium"
            step={1}
            disabled={
              type === "edit" &&
              data.nextNodeId &&
              data.nextNodeId - data.prevNodeId <= 2
            }
            className={`min-w-full ${type === "edit" && "!text-black/80"}`}
            type="number"
            placeholder="Enter Status Id"
            formatter={(value) => (value ? `${Math.floor(value)}` : "")}
          />
        </Form.Item>

        <Form.Item
          name="label"
          label="Job Status name"
          rules={[
            {
              required: true,
              message: "Status Name is required",
            },
            { validator: validateTextInput("Status Name", 2, 100) },
          ]}
        >
          <Input size="medium" placeholder="Enter Status Name" />
        </Form.Item>

        <Form.Item
          name="parallel"
          label="Parallel Status"
          className={`${isFlowFinalized ? "sr-only" : ""}`}
        >
          <Select
            mode="multiple"
            size="medium"
            placeholder="Select parallel status"
            disabled={type !== "add"}
            options={
              data !== null
                ? parallelNames &&
                  parallelNames.filter(
                    (item) => item.value.toString() !== data.id
                  )
                : parallelNames
            }
          />
        </Form.Item>

        <Form.Item name="checkListID" label="Checklist" initialValue={null}>
          <Select
            options={checklistOptions || []}
            size="medium"
            placeholder="Select checklist"
            allowClear
          />
        </Form.Item>

        <Row
          className={`flex-wrap w-full mt-4 ${
            isFlowFinalized ? "sr-only" : ""
          }`}
        >
          <Form.Item
            name="IsProductionLine"
            className="select-none"
            valuePropName="checked"
            required={false}
            initialValue={false}
          >
            <Checkbox>Enable Capacity</Checkbox>
          </Form.Item>

          <Form.Item
            name="isAttachmentCompulsory"
            valuePropName="checked"
            required={false}
            initialValue={false}
            className="select-none"
          >
            <Checkbox>Is Attachment Compulsory?</Checkbox>
          </Form.Item>
        </Row>

        <Row className="justify-end w-full gap-3 mt-10">
          <CustomButton
            size="small"
            htmlType="submit"
            loading={loading}
            icon={<CheckOutlined />}
            type="primary"
          >
            Submit
          </CustomButton>

          <CustomButton
            type="cancel"
            onClick={handleOnClose}
            icon={<CloseOutlined />}
          >
            Cancel
          </CustomButton>
        </Row>
      </Form>
    </Drawer>
  );
};

export default ManageJobStatusDrawer;
