import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Typography, notification } from "antd";
import React from "react";

const CustomNotification = ({ title, message, type }) => {
  return (
    <div
      style={{
        background: "#000000",
        width: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "left",
          marginTop: "-5px",
        }}
      >
        {type === "error" && (
          <CloseCircleOutlined style={{ color: "red", fontSize: "18px" }} />
        )}
        {type === "success" && (
          <CheckCircleOutlined style={{ color: "green", fontSize: "18px" }} />
        )}
        {type === "info" && (
          <InfoCircleOutlined style={{ color: "blue", fontSize: "18px" }} />
        )}
        {type === "loading" && <LoadingOutlined />}
        <Typography.Paragraph
          style={{
            marginLeft: "10px",
            marginTop: "-3px",
            color: "#474E68",
          }}
        >
          {message}
        </Typography.Paragraph>
      </div>
    </div>
  );
};

export const showMessage = (type, message) => {
  notification.open({
    type,
    message: message,
    position: "topRight",
    duration: 1.5,
    closeIcon: false,
    style: { padding: "10px 12px", top: "50px" },
    render: () => <CustomNotification message={message} type={type} />,
  });
};
