import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Card, ColorPicker, Drawer, Form, Input, Select, message } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import CitySearch from "../../Common/CitySearch";
import CountrySelect from "../../Common/CountrySelect";
import CustomButton from "../../Common/CustomButton";
import ViewInfoButton from "../../Common/ViewInfoButton";
import ManageOrganizationService from "../../services/Organization/ManageOrganizationService";
import {
  validateEmail,
  validateTextInput,
} from "../../utils/validationsHelper";
import StateSelect from "../../Common/StateSelect";

const ManageOrganizationEnquiry = ({
  changeStatus,
  onClose,
  editData,
  onSuccess,
}) => {
  const [selectCity, setSelectCity] = useState("");
  const [cityData, setCityData] = useState([]);
  const [selectState, setSelectState] = useState("");
  const [stateId, setStateId] = useState("");
  const [stateData, setStateData] = useState([]);
  const [IsLoading, setIsLoading] = useState(false);
  const [logoFile, setLogoFile] = useState(null);
  const [stateSearchValue, setStateSearchValue] = useState("");
  const [form] = useForm();

  const [countryList, setCountryList] = useState([]);
  const [selectedCountryValue, setSelectedCountryValue] = useState(null);
  const [stateDropDownData, setStateDropDownData] = useState({});

  const handleClearCountry = () => {
    form.resetFields(["country", "state"]);
    setSelectedCountryValue(null);
  };

  const handleCountrySelect = (countryValue, stateData) => {
    form.setFieldValue("country", countryValue);
    setSelectedCountryValue(countryValue);
    setStateDropDownData(stateData);
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    const primaryIdentifier = editData?.organisationResponse?.primaryIdentifier;
    const payload = {
      organisationRequest: {
        ...values,
        parentOrganisationID:
          changeStatus === "edit"
            ? editData?.organisationResponse.parentOrganisationID
            : "",
        child: "",
        countryID: 0,
        currencyType: "AUD",
        isChild: true,
        searchKey: "",
        isInternal: true,
        primaryIdentifier:
          changeStatus === "edit"
            ? editData?.organisationResponse?.primaryIdentifier
            : "",
        colors: [
          ...values?.colors.map((item) =>
            typeof item !== "string" ? item.toHexString() : item
          ),
        ],
      },
      addressRequest: {
        ...values,
        addressID: 0,
        country: selectedCountryValue,
        consumerID:
          changeStatus === "edit" ? editData?.addressResponse?.consumerID : "",
        consumerType: "",
        contactPersonName: "",
        addressLine3: "",
        addressLine4: "",
        fax: "",
        areaCode: "",
        createdBy: 0,
        primaryIdentifier:
          changeStatus === "edit"
            ? editData?.addressResponse?.primaryIdentifier
            : "",
        colors: [],
      },
    };

    if (changeStatus === "edit" && values.organizationLogo) {
      const formData = new FormData();
      formData.append("Files", logoFile);
      formData.append("Category", "LOGO");

      ManageOrganizationService.uploadLogoFile(primaryIdentifier, formData)
        .then((res) => {
          ManageOrganizationService.editOrgnaiztionData(payload)
            .then((res) => {
              setIsLoading(false);
              message.success("Organization Updated successfully");
              form.resetFields();
              onClose();
              onSuccess();
            })
            .catch((error) => {
              error?.response?.data?.message
                ? message.error(error?.response?.data?.message)
                : message.error("Something went wrong");
            });
        })
        .catch((error) => {
          setIsLoading(false);
          error?.response?.data?.message
            ? message.error(error?.response?.data?.message)
            : message.error("Something went wrong");
        });
    } else if (changeStatus === "edit") {
      ManageOrganizationService.editOrgnaiztionData(payload)
        .then((res) => {
          setIsLoading(false);
          message.success("Organization Updated successfully");
          form.resetFields();
          onClose();
          onSuccess();
        })
        .catch((error) => {
          error?.response?.data?.message
            ? message.error(error?.response?.data?.message)
            : message.error("Something went wrong");
        });
    }
  };

  useEffect(() => {
    if (changeStatus === "edit") {
      form.setFieldsValue({
        ...editData.addressResponse,
        ...editData.organisationResponse,
        ...{
          colors: [
            editData.organisationResponse.primaryColor,
            editData.organisationResponse.secondaryColor,
            editData.organisationResponse.tertiaryColor,
            editData.organisationResponse.quaternaryColor,
          ],
        },
      });
      setSelectedCountryValue(editData?.addressResponse?.country);
    } else {
      form.resetFields();
      setSelectedCountryValue(null);
    }

    return () => form.setFieldsValue(null);
  }, [editData, changeStatus]);

  return (
    <Drawer
      title={
        changeStatus === "edit"
          ? "Edit Organization"
          : changeStatus === "add"
          ? "Add Organization"
          : ""
      }
      width={400}
      onClose={onClose}
      open={!!changeStatus}
      bodyStyle={{
        padding: "4px 16px",
      }}
    >
      <div className="rounded-md border-black-600">
        <Form
          form={form}
          name="form_item_path"
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Enter organization name",
              },
              {
                validator: validateTextInput("Organization name"),
              },
            ]}
          >
            <Input size="medium" placeholder="Enter organization name" />
          </Form.Item>
          <Form.Item
            name="emailAddress"
            label="Email"
            rules={[
              {
                required: true,
                message: "Enter email",
              },
              {
                validator: validateEmail,
              },
            ]}
          >
            <Input size="medium" type="email" placeholder="Enter email" />
          </Form.Item>
          <Form.Item
            name="addressLine1"
            label="Street Address"
            rules={[
              {
                required: true,
                message: "Enter address",
              },
              { validator: validateTextInput("Street address", 10, 100) },
            ]}
          >
            <Input size="medium" type="text" placeholder="Enter address" />
          </Form.Item>

          <Form.Item
            name="addressLine2"
            label="Landmark"
            rules={[
              {
                required: true,
                message: "Enter landmark",
              },
              {
                validator: validateTextInput("Landmark"),
              },
            ]}
          >
            <Input size="medium" type="text" placeholder="Enter landmark" />
          </Form.Item>
          <Form.Item
            name="country"
            label="Country"
            rules={[
              {
                required: true,
                message: "Select country",
              },
            ]}
          >
            <CountrySelect
              onSelect={handleCountrySelect}
              currentStatus={changeStatus}
              form={form}
            />
          </Form.Item>

          <Form.Item
            name="state"
            label="State"
            rules={[
              {
                required: true,
                message:
                  stateDropDownData.isStateExists === true
                    ? "Select state"
                    : "Enter state",
              },
              {
                validator: validateTextInput("State"),
              },
            ]}
          >
            <StateSelect
              options={
                stateDropDownData
                  ? stateDropDownData?.stateResponse?.map((item) => ({
                      label: item.name,
                      value: item.name,
                    }))
                  : []
              }
              isStateExists={stateDropDownData.isStateExists}
            />
          </Form.Item>
          <Form.Item
            name="city"
            label="City"
            rules={[
              {
                required: true,
                message: "Please enter a city.",
              },
              {
                validator: validateTextInput("City"),
              },
            ]}
            dependencies={["state", stateId]}
          >
            <CitySearch
              stateID={stateId ? "" : stateId}
              value={selectCity}
              onChange={setSelectCity}
              cityData={cityData}
              setCityData={setCityData}
            />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: "Enter phone number",
              },
            ]}
          >
            <Input
              type="number"
              size="medium"
              minLength={10}
              maxLength={10}
              placeholder="Enter phone number"
            />
          </Form.Item>
          <Form.Item
            size="medium"
            name="postCode"
            label="Post Code"
            rules={[
              {
                required: true,
                message: "Enter post code",
              },
            ]}
          >
            <Input type="number" size="medium" placeholder="Enter post code" />
          </Form.Item>

          <Form.Item
            name="organizationLogo"
            label="Logo"
            rules={[
              {
                required: changeStatus === "add" && true,
                message: "Upload organization logo",
              },
            ]}
          >
            <Input
              style={{ padding: "3px" }}
              type="file"
              onChange={(event) => {
                const file = event.target.files[0];
                setLogoFile(file);
              }}
              accept=".xlsx, .xls, .pdf, .jpg, .jpeg, .png"
            />
          </Form.Item>

          <Form.List name="colors">
            {(fields) => (
              <Card
                type="inner"
                title={<span className="flex items-center gap-2">Colours</span>}
                extra={
                  <ViewInfoButton
                    placement="left"
                    title="Selected colours will be used on the whole website, like in the navigation bar."
                  />
                }
                className="mt-4 border border-gray-600/20"
              >
                <div className="grid grid-cols-2 place-items-center gap-x-6 gap-y-2">
                  {fields.map((field, index) => (
                    <Form.Item
                      key={field.key}
                      {...field}
                      label={
                        index === 0
                          ? "Primary"
                          : index === 1
                          ? "Secondary"
                          : index === 2
                          ? "Tertiary"
                          : "Quaternary"
                      }
                      className={
                        index % 2 === 0
                          ? "justify-self-center"
                          : "justify-self-start"
                      }
                      name={[field.name]}
                      rules={[
                        {
                          required: true,
                          message: "Please select colour",
                        },
                      ]}
                    >
                      <ColorPicker
                        showText={(color) => (
                          <span>Colour {color.toHexString()}</span>
                        )}
                        value={form.getFieldValue(["colors", index])}
                      />
                    </Form.Item>
                  ))}
                </div>
              </Card>
            )}
          </Form.List>

          <div className="flex justify-end gap-2 mt-8 mb-4">
            <CustomButton
              type="primary"
              htmlType="submit"
              loading={IsLoading}
              icon={<CheckOutlined />}
            >
              Submit
            </CustomButton>
            <CustomButton
              type="cancel"
              onClick={onClose}
              icon={<CloseOutlined />}
            >
              Cancel
            </CustomButton>
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default ManageOrganizationEnquiry;
