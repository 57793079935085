import {
  CalendarFilled,
  CheckCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  FileTextOutlined,
  FormOutlined,
} from "@ant-design/icons";
import {
  DatePicker,
  Empty,
  Form,
  Popconfirm,
  Popover,
  Tag,
  Tooltip,
  message,
} from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import React, { useState } from "react";
import { BsHourglassTop } from "react-icons/bs";
import CustomButton from "../../Common/CustomButton";
import ToolTipButton from "../../Common/ToolTipButton";
import { useDarkMode } from "../../Providers/DarkModeContext";
import ProductionServices from "../../services/Production/ProductionServices";
import {
  formatDateMonth,
  formatTime12Hour,
  isToday,
} from "../../utils/dateHelper";
import OrderAttachmentDrawer from "../OrderManagement/OrderAttachmentDrawer";
import OrderChecklistDrawer from "../OrderManagement/OrderChecklistDrawer";
import OrderNotesDrawer from "../OrderManagement/OrderNotesDrawer";
import AttachmentDrawer from "./AttachmentDrawer";
import ScheduleItem from "./ScheduleItem";

const ScheduleConfigure = ({
  activeTabLabel,
  className,
  productionScheduleData,
  schedulerDataFetch,
  selectedProductGroup,
  setReload,
  reload,
  userRole,
  productGroupID,
  showExtendedColumns,
}) => {
  const { isDarkMode } = useDarkMode();
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const [rowIndex, setRowIndex] = useState();
  const [isAttachmentDrawerOpen, setIsAttachmentDrawerOpen] = useState({
    open: false,
    data: null,
  });
  const [isNotesDrawerOpen, setIsNotesDrawerOpen] = useState({
    open: false,
    data: null,
  });
  const [reschedulePopoverOpen, setReschedulePopoverOpen] = useState(false);
  const [uploadAttachmentDrawer, setUploadAttachmentDrawer] = useState({
    open: false,
    data: null,
  });
  const [isChecklistDrawerOpen, setIsChecklistDrawerOpen] = useState({
    open: false,
    data: null,
  });

  const [rescheduleForm] = useForm();

  const configureData = productionScheduleData.filter(
    (item) => item?.name === activeTabLabel
  );

  const handleActionClick = (record, index) => {
    setLoadingStates((prev) => ({
      ...prev,
      [`${record.scheduleOn}_${index}`]: true,
    }));
    markDoneFunction(record, record.task === "Todo" ? "Start" : "Done", index);
  };

  const handleReschedule = async (record, index) => {
    setLoadingStates((prev) => ({
      ...prev,
      [`reschedule_${record.ordNum}`]: true,
    }));

    try {
      await ProductionServices.reScheduleItem({
        productionScheduleID: record.productionScheduleID,
        scheduleOn: dayjs(record.rescheduleOn).toISOString(),
      });

      schedulerDataFetch();
      setRowIndex(null);
      setReschedulePopoverOpen(false);

      message.success(
        `Successfully reschedule on ${formatDateMonth(record.rescheduleOn)}`
      );
    } catch (error) {
      console.log(error);
      error?.response?.data?.message
        ? message.error(error?.response?.data?.message)
        : message.error("Something went wrong");
    } finally {
      setLoadingStates((prev) => ({
        ...prev,
        [`reschedule_${record.ordNum}`]: false,
      }));
    }
  };

  const onHoldFunction = (record, type) => {
    ProductionServices.putOrderOnhold(record?.orderPrimaryIdentifier)
      .then((res) => {
        //  setLoadingHold(false);
        if (type === "hold") {
          message.success(
            `Reference Number ${record.quoteRefNum} is kept on Hold!`
          );
        } else {
          message.success(
            `Reference Number ${record.quoteRefNum} is rescheduled!`
          );
        }
        ProductionServices.scheduleAfterOnHold().then(() => {
          schedulerDataFetch();
        });
        setReload(!reload);
      })
      .catch((error) => {
        error?.response?.data?.message
          ? message.error(error?.response?.data?.message)
          : message.error("Something went wrong");
        //setLoadingHold(false);
      });
  };

  const markDoneFunction = (record, Type, index) => {
    ProductionServices.manageSchedulerJobUpdate(
      record?.orderPrimaryIdentifier,
      Type,
      record?.orderJobID
    )
      .then((res) => {
        message.success("Status Updated Successfully");
        schedulerDataFetch(productGroupID);
        setLoadingStatus(false);
        setLoadingStates((prev) => ({
          ...prev,
          [`${record.scheduleOn}_${index}`]: false,
        }));
      })
      .catch((error) => {
        console.log(error);

        setLoadingStates((prev) => ({
          ...prev,
          [`${record.scheduleOn}_${index}`]: false,
        }));

        if (error?.response?.status === 404) {
          // TODO: Need to fix these via backend
          if (
            error?.response?.data?.message.split(":")?.[0] ===
            "Response Not Found"
          ) {
            message.info("Enter the details to continue.");
            setIsChecklistDrawerOpen({
              open: true,
              data: {
                ...record,
                checklistID:
                  Number(error?.response?.data?.message.split(":")?.[1]) || 0,
              },
            });
            return;
          }

          if (error?.response?.data?.message === "Attachment Not Found") {
            message.info("Attachment is required to move this status.");
            setUploadAttachmentDrawer({
              open: true,
              data: record,
            });
            return;
          }
        }

        error?.response?.data?.message
          ? message.error(error?.response?.data?.message)
          : message.error("Something went wrong");
        setLoadingStatus(false);
      });
  };

  const columns = [
    {
      title: "Priority",
      render: (t, r, index) => index + 1,
      align: "center",
      hidden: activeTabLabel === "OnHold",
      width: 80,
    },
    ,
    {
      title: "Order Date",
      dataIndex: "ordDate",
      key: "ordDate",
      align: "center",
      render: (values, record) => {
        return values ? (
          <Tooltip title={formatTime12Hour(values)}>
            {formatDateMonth(values)}
          </Tooltip>
        ) : (
          "NA"
        );
      },
    },
    {
      title: "Reference Number",
      dataIndex: "quoteRefNum",
      key: "quoteRefNum",
      align: "center",
      hidden: !showExtendedColumns,
      render: (values, records) => {
        return (
          <>
            <Tag color={"#0275d8"} type="primary">
              {values ? values : "NA"}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Ref. Number",
      dataIndex: "jobRefNum",
      key: "jobRefNum",
      align: "center",
      render: (values, records) => {
        return (
          <>
            {values ? (
              <Tag color={"#5bc0de"} type="primary">
                {values}
              </Tag>
            ) : null}
          </>
        );
      },
    },
    {
      title: "Platform Order No",
      dataIndex: "ordNum",
      key: "ordNum",
      align: "center",
      hidden: !showExtendedColumns,
      render: (values, records) => {
        return (
          <>
            <Tag color={"#5bc0de"} type="primary">
              {values ? values : "NA"}
            </Tag>
          </>
        );
      },
    },
    {
      title: <Tooltip title="Expected Completion Date">ECD</Tooltip>,
      dataIndex: "expectedCompletionDate",
      key: "expectedCompletionDate",
      align: "center",
      render: (values, record) => {
        return values ? <p> {formatDateMonth(values)}</p> : "NA";
      },
    },
    {
      title: "Required Date",
      dataIndex: "custRequiredDate",
      key: "custRequiredDate",
      align: "center",
      render: (values, record) => {
        return values ? <p> {formatDateMonth(values)}</p> : "NA";
      },
    },
    {
      title: "View",
      dataIndex: "attachments",
      key: "attachments",
      align: "center",
      width: 80,
      render: (_, record) => (
        <div className="space-x-2">
          <ToolTipButton
            tooltipHeader="Attachments"
            tooltipPlacement="left"
            icon={<FileTextOutlined />}
            onClick={() =>
              setIsAttachmentDrawerOpen({
                open: true,
                data: record,
              })
            }
          />

          <ToolTipButton
            tooltipHeader="Notes"
            tooltipPlacement="right"
            icon={<FormOutlined />}
            onClick={() =>
              setIsNotesDrawerOpen({
                open: true,
                data: { ...record, activeTab: "Production" },
              })
            }
          />
        </div>
      ),
    },

    {
      title: "Product Name",
      dataIndex: "product",
      key: "product",
      align: "center",
      render: (values, records) => {
        return (
          <>
            <p>{values ? values : "NA"}</p>
          </>
        );
      },
    },

    /*   {
      title: "Product Group",
      dataIndex: "productGroupName",
      key: "productGroupName",
      render: (values, records) => {
        return (
          <>
            <p>{values ? values : "NA"}</p>
          </>
        );
      },
    }, */

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 200,
      render: (value, record, index) => {
        if (activeTabLabel === "OnHold") {
          return (
            <Popconfirm
              title={
                <p className="mb-3">
                  Are you sure you want to reschedule the Reference Number
                  <span className="font-bold ps-1">{record.quoteRefNum}</span>?
                </p>
              }
              onConfirm={() => onHoldFunction(record, "reschedule")}
              okText="Yes"
              cancelText="No"
            >
              <CustomButton type="primary" size="small">
                Reschedule
              </CustomButton>
            </Popconfirm>
          );
        }
        return (
          <div className="flex justify-center gap-2">
            {isToday(record.scheduleOn) ? (
              <CustomButton
                type="primary"
                icon={<CheckCircleOutlined />}
                size="small"
                loading={loadingStates[`${record.scheduleOn}_${index}`]}
                onClick={() => handleActionClick(record, index)}
              >
                {record.task === "Todo" ? "Start" : "Complete"}
              </CustomButton>
            ) : (
              <Popconfirm
                title="Are you sure?"
                placement="topLeft"
                description="This task is not scheduled for today. Do you still want to proceed?"
                onConfirm={() => handleActionClick(record, index)}
                okText="Yes"
                cancelText="No"
                color={isDarkMode && "#282928"}
              >
                <CustomButton
                  type="primary"
                  icon={<CheckCircleOutlined />}
                  size="small"
                  loading={loadingStates[`${record.scheduleOn}_${index}`]}
                >
                  {record.task === "Todo" ? "Start" : "Complete"}
                </CustomButton>
              </Popconfirm>
            )}

            {!userRole.includes("worker") && (
              <Popconfirm
                className="flex flex-end"
                title={
                  <p className="mb-3">
                    Are you sure you want to put the Reference Number
                    <span className="p-1 font-bold">{record.quoteRefNum}</span>
                    on Hold?
                  </p>
                }
                onConfirm={() => onHoldFunction(record, "hold")}
                okText="Yes"
                cancelText="No"
              >
                <ToolTipButton
                  tooltipHeader="On hold"
                  tooltipPlacement="bottom"
                  disabled={loadingStates[`${record.scheduleOn}_${index}`]}
                  type="primary"
                  danger
                  size="small"
                  className="flex items-center justify-center"
                  icon={<BsHourglassTop size={16} />}
                />
              </Popconfirm>
            )}

            {!userRole.includes("worker") && (
              <Popover
                placement="topLeft"
                trigger="click"
                content={
                  <RescheduleCalendar
                    form={rescheduleForm}
                    close={() => {
                      rescheduleForm.resetFields();
                      setReschedulePopoverOpen(false);
                    }}
                    onFinish={(values) =>
                      handleReschedule({ ...record, ...values }, index)
                    }
                  />
                }
                color={isDarkMode && "#282928"}
                open={reschedulePopoverOpen && rowIndex === record.ordNum}
                onOpenChange={(open) => {
                  if (open) {
                    setRowIndex(record.ordNum);
                  } else {
                    setRowIndex(null);
                  }
                  rescheduleForm.resetFields();
                  setReschedulePopoverOpen(open);
                }}
              >
                <ToolTipButton
                  tooltipHeader="Reschedule"
                  tooltipPlacement="bottom"
                  disabled={loadingStates[`${record.scheduleOn}_${index}`]}
                  loading={loadingStates[`reschedule_${record.ordNum}`]}
                  type="secondary"
                  size="small"
                  icon={<CalendarFilled />}
                />
              </Popover>
            )}
          </div>
        );
      },
    },
  ].filter((i) => !i.hidden);

  return (
    <>
      <div className={` ${className} space-y-2`} key={activeTabLabel}>
        {configureData.map((dataItem, index) =>
          dataItem?.schedulerData.length === 0 ? (
            <Empty description={"No Jobs found."} />
          ) : (
            dataItem?.schedulerData.map((item, index) => (
              <ScheduleItem
                activeTabLabel={activeTabLabel}
                schedulerDataFetch={schedulerDataFetch}
                selectedProductGroup={selectedProductGroup}
                key={`schedule_${index}`}
                item={item}
                columns={columns}
                userRole={userRole}
                index={index}
              />
            ))
          )
        )}
      </div>

      <AttachmentDrawer
        open={isAttachmentDrawerOpen.open}
        onClose={() =>
          setIsAttachmentDrawerOpen({
            open: false,
            data: null,
          })
        }
        data={isAttachmentDrawerOpen.data}
      />

      <OrderNotesDrawer
        open={isNotesDrawerOpen.open}
        onClose={() =>
          setIsNotesDrawerOpen({
            open: false,
            data: null,
          })
        }
        initalData={isNotesDrawerOpen.data}
      />

      <OrderAttachmentDrawer
        isDrawerOpen={uploadAttachmentDrawer.open}
        onCancel={() => setUploadAttachmentDrawer({ open: false, data: null })}
        currentOrderStatus={{
          primaryIdentifier:
            uploadAttachmentDrawer?.data?.primaryIdentifierOrder,
          ordNum: uploadAttachmentDrawer?.data?.ordNum,
        }}
        activeTab={activeTabLabel}
      />

      <OrderChecklistDrawer
        isDrawerOpen={isChecklistDrawerOpen.open}
        onClose={() => setIsChecklistDrawerOpen({ open: false, data: null })}
        orderDetails={isChecklistDrawerOpen.data}
      />
    </>
  );
};

export default ScheduleConfigure;

const RescheduleCalendar = ({ close, form, onFinish }) => {
  return (
    <Form form={form} layout="vertical" onFinish={(values) => onFinish(values)}>
      <Form.Item
        name="rescheduleOn"
        label="Select date to reschedule"
        rules={[{ required: true, message: "Select date" }]}
      >
        <DatePicker
          size="middle"
          className="w-full"
          disabledDate={(current) =>
            current && dayjs(current).isBefore(dayjs(), "day")
          }
        />
      </Form.Item>

      <div className="flex items-center justify-end gap-2 pt-2">
        <CustomButton
          icon={<CheckOutlined />}
          type="primary"
          htmlType="submit"
          size="small"
          className="w-1/2"
        >
          Submit
        </CustomButton>

        <CustomButton
          onClick={() => close()}
          icon={<CloseOutlined />}
          type="cancel"
          size="small"
          className="w-1/2"
        >
          Cancel
        </CustomButton>
      </div>
    </Form>
  );
};
