import { CloseOutlined, FileDoneOutlined } from "@ant-design/icons";
import { Button, Drawer, Form, Input, Select, message } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import CustomerService from "../../services/CustomerService/CustomerService";

const EditCustomerGeneralDetails = ({
  editAction,
  editData,
  editTitle,
  onClose,
  primaryIdentifier,
  fetchData,
  accountStatusList,
  classificationList,
  categoryList,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = useForm();

  const onFinish = (values) => {
    setIsLoading(true);

    let payload = editTitle === "Discount" ? editData : { ...values };

    if (editTitle === "Discount") {
      Object.entries(values).map(([label, value]) => {
        payload = payload.map((item) =>
          item.productName === label ? { ...item, discount: value } : item
        );
      });

      payload = payload.map((i) => {
        return {
          ...i,
          discount: i.discount.toString(),
        };
      });
    }

    if (editTitle === "Preview") {
      CustomerService.updatePreviewCard(primaryIdentifier, payload)
        .then((res) => {
          setIsLoading(false);
          fetchData();
          message.success("Details updated successfully");
          onClose();
          form.resetFields();
        })
        .catch((error) => {
          setIsLoading(false);
          error?.response?.data?.message
            ? message.error(error?.response?.data?.message)
            : message.error("Something went Wrong");
        });
    } else {
      CustomerService.updateDiscountCard(primaryIdentifier, payload)
        .then((res) => {
          setIsLoading(false);
          fetchData();
          message.success("Details updated successfully");
          onClose();
          form.resetFields();
        })
        .catch((error) => {
          setIsLoading(false);
          error?.response?.data?.message
            ? message.error(error?.response?.data?.message)
            : message.error("Something went Wrong");
        });
    }
  };

  useEffect(() => {
    if (editAction) {
      const convertedObject =
        editTitle === "Discount"
          ? editData.reduce((result, item) => {
              result[item.productName] = item.discount;
              return result;
            }, {})
          : editData.reduce((result, item) => {
              result[item.label] = item.value;
              return result;
            }, {});

      form.setFieldsValue({ ...convertedObject });
    } else {
      form.resetFields();
    }
    return () => form.setFieldsValue(null);
  }, [editAction]);

  return (
    <>
      <Drawer
        title={`Edit ${editTitle}`}
        destroyOnClose={true}
        width={400}
        onClose={onClose}
        open={editAction}
        bodyStyle={{
          padding: "10px 18px",
        }}
      >
        <Form
          form={form}
          name="form_item_path"
          layout="vertical"
          onFinish={onFinish}
        >
          {editTitle === "Preview"
            ? editData?.map((formValue) => {
                if (formValue.label === "Established") {
                  return;
                }

                return (
                  <Form.Item
                    name={formValue.label}
                    label={formValue.label}
                    rules={[
                      {
                        required: true,
                        message: "Enter " + formValue.label,
                      },
                    ]}
                  >
                    <Select
                      size="medium"
                      options={
                        formValue.label === "Category"
                          ? categoryList
                          : formValue.label === "Status"
                          ? accountStatusList
                          : classificationList
                      }
                      placeholder={"Select" + formValue.label.toLowerCase()}
                    />
                  </Form.Item>
                );
              })
            : editData?.map((formValue) => {
                return (
                  <Form.Item
                    name={formValue.productName}
                    label={
                      <p>
                        Discount for
                        <span className="ml-1 font-semibold">
                          {formValue.productName}
                        </span>
                        <span className="ml-1 italic">(in percentage)</span>
                      </p>
                    }
                    rules={[
                      {
                        required: true,
                        message:
                          "Enter discount for " +
                          formValue.productName.toLowerCase(),
                      },
                    ]}
                  >
                    <Input
                      size="medium"
                      type="number"
                      step="0.01"
                      min={0}
                      max={100}
                      placeholder="Enter discount"
                    />
                  </Form.Item>
                );
              })}

          <div className="flex justify-end gap-2 mt-8">
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              icon={<FileDoneOutlined />}
            >
              Submit
            </Button>
            <Button
              className="text-white bg-gray-400 hover:text-white"
              onClick={onClose}
              disabled={isLoading}
              icon={<CloseOutlined />}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
};

export default EditCustomerGeneralDetails;
