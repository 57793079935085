import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Popconfirm, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../Common/CustomBreadcrumb";
import CustomButton from "../../Common/CustomButton";
import { useDarkMode } from "../../Providers/DarkModeContext";
import { useScreenSize } from "../../Providers/ScreenSizeProvider";
import OrderManagementService from "../../services/OrderManagement/OrderManagementService";
import { getDarkModeColor } from "../../utils/darkModeHelper";
import AddEditOrderConfigure from "./AddEditOrderConfigure";

const items = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Order Settings",
  },
];

function OrderSettings() {
  const { isDarkMode } = useDarkMode();
  const currentScreenSize = useScreenSize();

  const [dataList, setDataList] = useState([]);
  const [manageDrawer, setManageDrawer] = useState({
    openType: null,
    initalValue: null,
  });
  const [isListLoading, setIsListLoading] = useState(false);
  const [loadingStates, setLoadingStates] = useState([]);

  const getOrderViewList = () => {
    setIsListLoading(true);

    try {
      OrderManagementService.getOrderViewListPublic().then((res) => {
        setDataList(res.data);
      });

      setIsListLoading(false);
    } catch (error) {
      setIsListLoading(false);
      console.log(error);
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went Wrong"
      );
    }
  };

  useEffect(() => {
    getOrderViewList();
  }, []);

  const deleteRecord = async (values, index) => {
    const updatedLoadingStates = [...loadingStates];
    updatedLoadingStates[index] = true;

    setLoadingStates(updatedLoadingStates);

    try {
      await OrderManagementService.deleteOrderViewData(
        values.primaryIdentifier,
        values.id
      );

      getOrderViewList();
      message.success("Deleted successfully");
      updatedLoadingStates[index] = false;
      setLoadingStates(updatedLoadingStates);
    } catch (error) {
      updatedLoadingStates[index] = false;
      setLoadingStates(updatedLoadingStates);
      console.log(error);
      error?.response?.data?.message
        ? message.error(error?.response?.data?.message)
        : message.error("Something went wrong");
    }
  };

  const columns = [
    {
      title: "Tab Sequence",
      dataIndex: "tabOrder",
      key: "tabOrder",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.tabOrder - b.tabOrder,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Status Lower Limit",
      dataIndex: "fromStatus",
      key: "fromStatus",
      align: "center",
    },
    {
      title: "Status Upper Limit",
      dataIndex: "toStatus",
      key: "toStatus",
      align: "center",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (_, record, index) => {
        return (
          <>
            <CustomButton
              onClick={() => {
                setManageDrawer({
                  openType: "edit",
                  initalValue: record,
                });
              }}
              disabled={index < 10}
              icon={<EditOutlined />}
              type="primary"
            >
              Edit
            </CustomButton>

            <Popconfirm
              okText="Yes"
              cancelText="No"
              title="Are you sure you want to delete this view?"
              onConfirm={() => deleteRecord(record, index)}
            >
              <CustomButton
                className="ms-2"
                loading={loadingStates[index]}
                icon={<DeleteOutlined />}
                disabled={index < 10}
                type="primary"
                danger
              >
                Delete
              </CustomButton>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <div className="p-2 mt-4">
      <div className="flex items-start justify-between w-full mb-2 mr-2">
        <CustomBreadcrumb items={items} />

        <CustomButton
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setManageDrawer({
              openType: "add",
              initalValue: null,
            });
          }}
        >
          Add
        </CustomButton>
      </div>

      <Table
        size="small"
        bordered={"1px solid black"}
        loading={isListLoading}
        dataSource={dataList}
        columns={columns}
        scroll={{
          x: currentScreenSize < 1024 && 800,
        }}
        rowClassName={(record, index) =>
          index % 2 !== 0 ? getDarkModeColor(isDarkMode, "bg-gray-100") : ""
        }
        pagination={false}
      />

      <AddEditOrderConfigure
        dataList={dataList}
        drawerOpen={manageDrawer.openType !== null}
        changeStatus={manageDrawer.openType}
        recordData={manageDrawer.initalValue}
        onClose={() =>
          setManageDrawer({
            openType: null,
            initalValue: null,
          })
        }
        refetch={getOrderViewList}
      />
    </div>
  );
}

export default OrderSettings;
