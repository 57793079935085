import { Input, Select } from "antd";
import React from "react";

const StateSelect = ({ options, value, onChange, isStateExists }) => {
  return isStateExists ? (
    <Select
      options={options}
      placeholder="Select State"
      size="medium"
      value={value}
      onChange={onChange}
    />
  ) : (
    <Input
      size="medium"
      placeholder="Enter state name"
      value={value}
      onChange={onChange}
    />
  );
};

export default StateSelect;
