import { Skeleton } from "antd";
import React from "react";
import { GrBarChart } from "react-icons/gr";

const ChartLoading = ({ height }) => {
  return (
    <Skeleton.Node active style={{ height: height }}>
      <GrBarChart
        style={{
          fontSize: 40,
          color: "#bfbfbf",
        }}
      />
    </Skeleton.Node>
  );
};

export default ChartLoading;
