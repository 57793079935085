import {
  EditOutlined,
  EnvironmentOutlined,
  MailOutlined,
  MehOutlined,
  PhoneOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Input,
  Result,
  Skeleton,
  Spin,
  Tabs,
  Typography,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import ManageOrganizationService from "../../services/Organization/ManageOrganizationService";
import { Link, useSearchParams } from "react-router-dom";
import OrganizationAddresses from "./OrganizationAddresses";
import OrganizationContact from "./OrganizationContact";
import ManageOrganizationEnquiry from "./ManageOrganizationEnquiry";
import { AuthContext, useAuth } from "../../Providers/AuthProvider";
import AddOrganizationDrawer from "./AddOrganizationDrawer";
import CustomButton from "../../Common/CustomButton";
import CustomBreadcrumb from "../../Common/CustomBreadcrumb";
import { useDarkMode } from "../../Providers/DarkModeContext";
import { getDarkModeColor } from "../../utils/darkModeHelper";

const OrganizationEnquiry = () => {
  let { userPrimaryRole, refetchData } = useContext(AuthContext);
  const { isDarkMode } = useDarkMode();
  let isAdmin = userPrimaryRole?.toLowerCase()?.includes("admin");
  const [addOrganizationData, setAddOrganizationData] = useState(false);
  const [organizationData, setOrganizationData] = useState(null);
  const [changeStatus, setChangeStatus] = useState("");
  const [searchParams, setSearchParams] = useSearchParams({
    activeTab: "Addresses",
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.has("activeTab")) {
      searchParams.set("activeTab", "Addresses");
      const newurl = `${window.location.protocol}//${window.location.host}${
        window.location.pathname
      }?${searchParams.toString()}`;
      window.history.replaceState({ path: newurl }, "", newurl);
    }
  }, []);

  const items = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "Organization",
    },
  ];
  const tabsItem = [
    {
      key: "Addresses",
      label: "Addresses",
    },
    {
      key: "Contacts",
      label: "Contacts",
    },
  ];

  const fetchData = () => {
    ManageOrganizationService.getOrganizationData()
      .then((res) => {
        let primaryIdentifier =
          res?.data["organisation"] &&
          res?.data["organisation"]["primaryIdentifier"];

        if (primaryIdentifier) {
          ManageOrganizationService.getOrganizationDetailsData(
            primaryIdentifier
          )
            .then((response) => {
              setOrganizationData(response.data);
              setIsLoading(false);
            })
            .catch((error) => {
              console.log(error);
              error?.response?.data?.message
                ? message.error(error?.response?.data?.message)
                : message.error("Something went wrong");
              setIsLoading(false);
            });
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        error?.response?.data?.message
          ? message.error(error?.response?.data?.message)
          : message.error("Something went wrong");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="p-2 mt-4">
      <div className="flex justify-between">
        <CustomBreadcrumb items={items} />
      </div>
      <div className="flex justify-center">{isLoading && <Spin />}</div>

      {!isLoading && organizationData === null && (
        <>
          <div className="flex items-center justify-center mb-2 mr-4">
            <Result
              icon={<MehOutlined />}
              title="Organization not found, please add organization"
              extra={
                <CustomButton
                  id="addOrganization"
                  onClick={() => {
                    setAddOrganizationData(true);
                  }}
                  type="primary"
                  icon={<PlusOutlined />}
                >
                  Add
                </CustomButton>
              }
            />
          </div>
        </>
      )}

      {organizationData !== null && (
        <Descriptions
          bordered
          column={1}
          className="mt-2"
          size="small"
          contentStyle={{ width: "85%" }}
        >
          <Descriptions.Item label="Organization name">
            {isLoading ? (
              <Skeleton.Input active size="small" block />
            ) : (
              <div className="flex items-center justify-between">
                <Typography className="font-semibold">
                  {organizationData?.organisationResponse?.name
                    ? organizationData?.organisationResponse?.name
                    : "NA"}
                </Typography>

                {isAdmin && (
                  <>
                    <CustomButton
                      onClick={() => {
                        setChangeStatus("edit");
                      }}
                      type="primary"
                      icon={<EditOutlined />}
                    >
                      Edit
                    </CustomButton>
                  </>
                )}
              </div>
            )}
          </Descriptions.Item>

          <Descriptions.Item label="General contact">
            {isLoading ? (
              <Skeleton.Input active size="small" block />
            ) : (
              <div className="flex items-center gap-3">
                <Typography className="flex items-center">
                  <PhoneOutlined rotate={"90"} className="mr-2" />
                  <a
                    className={getDarkModeColor(
                      isDarkMode,
                      "!text-blue-500",
                      "!text-blue-500"
                    )}
                    href={`tel:${organizationData?.addressResponse?.phone}`}
                  >
                    {organizationData?.addressResponse?.phone
                      ? organizationData?.addressResponse?.phone
                      : "NA"}
                  </a>
                </Typography>

                <Typography className="flex items-center">
                  <MailOutlined className="mr-2" />
                  <a
                    className={getDarkModeColor(
                      isDarkMode,
                      "!text-blue-500",
                      "!text-blue-500"
                    )}
                    href={`mailto:${organizationData?.addressResponse?.emailAddress}`}
                  >
                    {organizationData?.addressResponse?.emailAddress
                      ? organizationData?.addressResponse?.emailAddress
                      : "NA"}
                  </a>
                </Typography>
              </div>
            )}
          </Descriptions.Item>

          <Descriptions.Item label="Billing address">
            {isLoading ? (
              <Skeleton.Input active size="small" block />
            ) : (
              <Typography className="flex items-center">
                <EnvironmentOutlined className="mr-1" />
                <a
                  className={getDarkModeColor(
                    isDarkMode,
                    "!text-blue-500",
                    "!text-blue-500"
                  )}
                  target="_blank"
                  href={`https://www.google.com/maps?q=${organizationData?.addressResponse?.country},${organizationData?.addressResponse?.state},${organizationData?.addressResponse?.city},${organizationData?.addressResponse?.postCode}`}
                >
                  {`${
                    organizationData?.addressResponse?.country
                      ? organizationData?.addressResponse?.country
                      : "NA"
                  }, 
                ${
                  organizationData?.addressResponse?.state
                    ? organizationData?.addressResponse?.state
                    : "NA"
                }, 
              ${
                organizationData?.addressResponse?.city
                  ? organizationData?.addressResponse?.city
                  : "NA"
              }, 
              ${
                organizationData?.addressResponse?.postCode
                  ? organizationData?.addressResponse?.postCode
                  : "NA"
              }`}
                </a>
              </Typography>
            )}
          </Descriptions.Item>
        </Descriptions>
      )}

      {organizationData !== null && (
        <Tabs
          type="card"
          className="!mb-0 mt-6"
          items={tabsItem}
          activeKey={searchParams.get("activeTab")}
          onChange={(activeTab) => {
            searchParams.set("activeTab", activeTab);
            setSearchParams(searchParams);
          }}
        />
      )}

      {!isLoading && organizationData !== null && (
        <>
          {searchParams.get("activeTab") === "Addresses" ? (
            <OrganizationAddresses
              primaryIdentifier={
                organizationData?.organisationResponse?.primaryIdentifier
              }
            />
          ) : null}
          {searchParams.get("activeTab") === "Contacts" ? (
            <OrganizationContact
              primaryIdentifier={
                organizationData?.organisationResponse?.primaryIdentifier
              }
            />
          ) : null}
        </>
      )}
      <ManageOrganizationEnquiry
        onSuccess={() => {
          fetchData();
          refetchData();
        }}
        changeStatus={changeStatus}
        editData={organizationData}
        onClose={() => setChangeStatus(null)}
      />
      <AddOrganizationDrawer
        onSuccess={() => {
          fetchData();
          refetchData();
        }}
        changeStatus={addOrganizationData}
        onClose={() => setAddOrganizationData(false)}
      />
    </div>
  );
};

export default OrganizationEnquiry;
