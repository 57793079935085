import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useDarkMode } from "../Providers/DarkModeContext";

const Markdown = ({ value, onChange, previewOrder, showBorder }) => {
  const { isDarkMode } = useDarkMode();
  const [quillKey, setQuillKey] = useState(0);

  useEffect(() => {
    setQuillKey(quillKey + 1);
  }, [previewOrder]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "indent",
    "link",
    "code",
    "align",
    "color",
    "background",
  ];

  const handleOnChange = (newVal) => {
    if (previewOrder !== "preview") {
      if (newVal.replace(/<(.|\n)*?>/g, "").trim().length === 0) {
        newVal = "";
      }
      onChange(newVal);
    }
  };

  return (
    <div
      className={`${
        previewOrder === "preview" && showBorder ? "border pb-0" : ""
      } ${
        isDarkMode ? "border-[#303030]" : "border-black/5"
      } rounded-md h-full w-full`}
    >
      <ReactQuill
        key={quillKey}
        className={`xl:!h-full ${
          previewOrder === "preview" ? "max-h-[9.2rem]" : "lg:max-h-[12.7rem]"
        }`}
        theme={previewOrder === "preview" ? "bubble" : "snow"}
        value={
          !value
            ? previewOrder === "preview"
              ? "No notes for this order!"
              : ""
            : value || ""
        }
        modules={modules}
        formats={formats}
        placeholder="Additional Notes"
        onChange={handleOnChange}
        readOnly={previewOrder === "preview" ? true : false}
      />
    </div>
  );
};
export default Markdown;
