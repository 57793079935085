import {
  CheckOutlined,
  CloseOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { Drawer, Form, Input, message, Select, Space } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import OrderManagementService from "../../services/OrderManagement/OrderManagementService";
import CustomButton from "../../Common/CustomButton";

const { Option } = Select;
const ManageConfigure = ({
  tableConfigData,
  onClose,
  changeStatus,
  isLoadOnSave,
  setIsLoadOnSave,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = useForm();

  const onFinish = (values) => {
    const payload = {
      ...values,
      primaryIdentifier: tableConfigData?.primaryIdentifier,
    };

    OrderManagementService.saveOrderViewData(payload)
      .then((res) => {
        message.success("View updated successfully");
        form.resetFields();
        onClose();
        setIsLoadOnSave(!isLoadOnSave);
      })
      .catch((error) => {
        error?.response?.data?.message
          ? message.error(error?.response?.data?.message)
          : message.error("Something went wrong");
      });
  };

  useEffect(() => {
    if (changeStatus == "configure") {
      form.setFieldsValue({
        ...tableConfigData,
      });
    } else {
      form.resetFields();
    }
    return () => form.setFieldValue(null);
  }, [changeStatus, tableConfigData]);

  const validateFromStatus = (_, value) => {
    const toStatusValue = form.getFieldValue("toStatus");
    if (
      value &&
      toStatusValue &&
      parseInt(value, 10) >= parseInt(toStatusValue, 10)
    ) {
      return Promise.reject(
        new Error("From Status should be lower than To Status")
      );
    }
    return Promise.resolve();
  };

  return (
    <Drawer
      title={changeStatus === "configure" ? `Configure` : ""}
      width={350}
      onClose={onClose}
      open={!!changeStatus}
      bodyStyle={{
        padding: "4px 16px",
      }}
      destroyOnClose={true}
    >
      <Form
        form={form}
        name="form_item_path"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Enter from name",
            },
          ]}
        >
          <Input
            disabled
            name="name"
            size="medium"
            placeholder="Enter from name"
          />
        </Form.Item>

        <Space.Compact>
          <Form.Item
            name="fromStatus"
            label="From"
            rules={[
              {
                required: true,
                message: "Enter from status",
              },
              {
                validator: validateFromStatus,
              },
            ]}
          >
            <Input
              type="number"
              name="fromStatus"
              size="medium"
              placeholder="Enter from status"
            />
          </Form.Item>
          <Form.Item
            name="toStatus"
            label="To"
            rules={[
              {
                required: true,
                message: "Enter to status",
              },
            ]}
          >
            <Input
              type="number"
              name="toStatus"
              size="medium"
              placeholder="Enter to status"
            />
          </Form.Item>
        </Space.Compact>

        <div className="flex justify-end gap-2 mt-5">
          <CustomButton
            type="primary"
            htmlType="submit"
            loading={isLoading}
            icon={<CheckOutlined />}
          >
            Submit
          </CustomButton>
          <CustomButton
            type="cancel"
            onClick={onClose}
            icon={<CloseOutlined />}
          >
            Cancel
          </CustomButton>
        </div>
      </Form>
    </Drawer>
  );
};

export default ManageConfigure;
