import { Table } from "antd";
import React from "react";

const ErrorImportTable = ({ dataSource }) => {
  const columns = [
    {
      title: "Error",
      dataIndex: "validationMessage",
      key: "validationMessage",
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Reference No.",
      dataIndex: "referenceNumber",
      key: "referenceNumber",
    },
    {
      title: "Job No.",
      dataIndex: "jobNumber",
      key: "jobNumber",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "center",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
    },
    {
      title: "Category",
      dataIndex: "orderCategory",
      key: "orderCategory",
      align: "center",
    },
  ];

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      bordered
      size="middle"
      className="mt-6"
      scroll={{ x: 1200 }}
    />
  );
};

export default ErrorImportTable;
