import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Drawer, Form, Input, message, Select, Space } from "antd";
import { useForm, useWatch } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import CustomButton from "../../Common/CustomButton";
import ChecklistService from "../../services/Checklist/ChecklistService";
import { validateTextInput } from "../../utils/validationsHelper";

const ManageOptionsDrawer = ({
  open,
  openType,
  initalData,
  onClose,
  refetch,
}) => {
  const [optionTypes, setOptionTypes] = useState([]);
  const [loading, setLoading] = useState("");
  const [form] = useForm();

  const checkListType = useWatch("checkListType", form);

  const fetchOptionTypes = async () => {
    setLoading("options-fetch");

    try {
      const { data } = await ChecklistService.getOptionTypes();

      const filteredTypes = data?.map((i) => ({
        label: i.description,
        value: i.name,
      }));

      setOptionTypes(filteredTypes || []);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchOptionTypes();
    }

    if (initalData) {
      let parsedOptions;

      try {
        parsedOptions = Array.isArray(initalData.options)
          ? initalData.options
          : JSON.parse(initalData.options);
      } catch (e) {
        console.error("Failed to parse options", e);
        parsedOptions = [];
      }

      if (parsedOptions) {
        initalData.parsedOptions = parsedOptions.map((item) => ({
          option: item.value || item,
        }));
      }

      form.setFieldsValue({ ...initalData, options: initalData.parsedOptions });
    }
  }, [open, initalData]);

  const handleClose = () => {
    onClose();
    setLoading("");
    setOptionTypes([]);
    form.resetFields();
  };

  const onFinish = async (values) => {
    setLoading("submitting");

    try {
      let method;

      values.options = JSON.stringify(
        values?.options?.map((opt) => ({
          label: opt.option,
          value: opt.option,
        }))
      );

      if (openType === "add") {
        values.isActive = true;
        method = () => ChecklistService.createChecklistOption(values);
      } else {
        method = () =>
          ChecklistService.updateChecklistOption({ ...initalData, ...values });
      }

      await method();
      handleClose();
      refetch();
      message.success(
        `${openType === "add" ? "Added" : "Updated"} question successfully!`
      );
    } catch (error) {
      console.log(error);
      message.error(
        error?.response?.data?.message ||
          `Unable to ${
            openType === "add" ? "create" : "edit"
          } question right now. Try again later!`
      );
    } finally {
      setLoading("");
    }
  };

  return (
    <Drawer
      title={openType === "add" ? "Add Question" : `Edit Question`}
      open={open}
      onClose={handleClose}
      bodyStyle={{ padding: "12px 20px" }}
      destroyOnClose
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          name="checkListID"
          label="checkListID"
          rules={[{ required: false, message: "Please enter checklist id" }]}
          hidden
        >
          <Input size="medium" placeholder="Enter id" />
        </Form.Item>

        <Form.Item
          name="description"
          label="Question"
          rules={[
            { required: true, message: "Please enter question" },
            { validator: validateTextInput("Question", 2, 1000) },
          ]}
        >
          <Input.TextArea
            rows={2}
            size="medium"
            placeholder="Enter Question"
            autoSize
          />
        </Form.Item>

        <Form.Item
          name="checkListType"
          label="Answer type"
          rules={[{ required: true, message: "Please select answer type" }]}
        >
          <Select
            size="medium"
            placeholder="Select answer type"
            options={optionTypes}
            loading={loading === "options-fetch"}
            onChange={(value) => {
              if (value !== "TEXTAREA" && value !== "DATETIME") {
                form.setFieldsValue({
                  options: [{ option: "" }, { option: "" }],
                });
              } else {
                form.setFieldsValue({
                  options: [],
                });
              }
            }}
          />
        </Form.Item>

        <Form.List name="options">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  className="w-full mt-4 -mb-2"
                  align="baseline"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Form.Item
                    {...restField}
                    name={[name, "option"]}
                    rules={[
                      { required: true, message: "Please enter an option" },
                    ]}
                    className="w-[20rem]"
                  >
                    <Input
                      size="middle"
                      placeholder={`Enter option ${key + 1}`}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>

                  <DeleteOutlined onClick={() => remove(name)} />
                </Space>
              ))}

              {checkListType && (
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  size="middle"
                  className="mt-4"
                  icon={<PlusOutlined />}
                >
                  Add Option
                </Button>
              )}
            </>
          )}
        </Form.List>

        <div className="flex justify-end gap-2 mt-10">
          <CustomButton
            type="primary"
            htmlType="submit"
            loading={loading === "submitting"}
            icon={<CheckOutlined />}
          >
            Submit
          </CustomButton>
          <CustomButton
            type="cancel"
            onClick={handleClose}
            disabled={loading === "submitting"}
            icon={<CloseOutlined />}
          >
            Cancel
          </CustomButton>
        </div>
      </Form>
    </Drawer>
  );
};

export default ManageOptionsDrawer;
