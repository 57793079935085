import React, { useCallback, useEffect, useState } from "react";
import DashboardStatsCard from "../components/Dashboard/DashboardStatsCard";
import CountUp from "react-countup";
import { TbTruckDelivery } from "react-icons/tb";
import { useDarkMode } from "../Providers/DarkModeContext";
import {
  Col,
  Row,
  Select,
  Skeleton,
  Statistic,
  Tag,
  Typography,
  message,
} from "antd";
import DashboardServices from "../services/Dashboard/DashboardServices";
import BestProductChart from "../components/Dashboard/BestProductChart";
import ActualCapacityChart from "../components/Dashboard/ActualCapacityChart";
import CancelledReasonChart from "../components/Dashboard/CancelledReasonChart";
import ProductionLinePerformanceChart from "../components/Dashboard/ProductionLinePerformanceChart";
import ForecastingDeliveryChart from "../components/Dashboard/ForecastingDeliveryChart";
import BestCustomerChart from "../components/Dashboard/BestCustomerChart";
import MonthlyOrderChart from "../components/Dashboard/MonthlyOrderChart";
import { useLocation } from "react-router-dom";

const daysList = [
  { label: "7 Days", value: 7 },
  { label: "14 Days", value: 14 },
  { label: "21 Days", value: 21 },
  { label: "28 Days", value: 28 },
];

const toDateOptions = [
  { label: "MTD", value: 28 },
  { label: "YTD", value: 365 },
];

const Dashboard = () => {
  const { isDarkMode } = useDarkMode();

  const [isLoading, setIsLoading] = useState({
    prodGroups: true,
    statsCards: true,
    capacityOverview: true,
    cancelledReason: true,
    productionData: true,
    productPerformanceData: true,
    forecasting: true,
    bestCustomer: true,
    monthlyOrders: true,
  });
  const [prodGroups, setProdGroups] = useState([]);
  const [statsCardsData, setStatsCardsData] = useState(null);
  const [productPerformanceData, setProductPerformaceData] = useState([]);
  const [capacityPerformanceData, setCapacityPerformanceData] = useState([]);
  const [cancelledReasonData, setCancelledReasonData] = useState([]);
  const [productionData, setProductionData] = useState(null);
  const [productionLineDays, setProductionLineDays] = useState(28);
  const [forecastingData, setForecastingData] = useState([]);
  const [forecastingDays, setForecastingDays] = useState(28);
  const [customerData, setCustomerData] = useState([]);
  const [monthlyOrdersData, setMonthlyOrdersData] = useState([]);
  const [monthlyChartsType, setMonthlyChartsType] = useState("Units");
  const [totalTarget, setTotalTarget] = useState(0);
  const [capacityFilters, setCapacityFilters] = useState({
    toDate: 28,
    product: null,
  });

  useEffect(() => {
    fetchProductGroups();
  }, []);

  useEffect(() => {
    if (prodGroups.length > 0) {
      fetchAllDashboardData();
    }
  }, [prodGroups]);

  useEffect(() => {
    const value = calculateTotalTarget(productionData);

    setTotalTarget(value);
  }, [productionData]);

  const fetchAllDashboardData = async () => {
    try {
      const [statsRes, chartsRes] = await Promise.all([
        DashboardServices.getDashboardCardDetailsCount(),
        DashboardServices.getAllChartsData(28, prodGroups?.[0]?.productGroupID),
      ]);

      setStatsCardsData(statsRes?.data);

      setProductPerformaceData(
        chartsRes?.data?.productPerformance?.productPerformance?.[0] || []
      );

      setCapacityPerformanceData(
        chartsRes?.data?.processCapacityResponses || []
      );

      setProductionData(chartsRes?.data?.productionLinePerformances || []);

      setCancelledReasonData(chartsRes?.data?.cancelReasonResponses || []);

      setForecastingData(
        chartsRes?.data?.forecastCompletionResponse?.deliveryPerDayCount[0] ||
          []
      );

      setCustomerData(
        chartsRes?.data?.customerPerformanceResponse
          ?.customerPerformanceResponses || []
      );

      setMonthlyOrdersData(
        chartsRes?.data?.orderPerformanceResponse?.monthlyCountofOrders || []
      );
    } catch (error) {
      console.error(error);
      message.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setIsLoading((prev) => {
        const newState = Object.keys(prev).reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {});

        return newState;
      });
    }
  };

  const fetchProductGroups = async (days) => {
    try {
      const res = await DashboardServices.getProductGroups();

      setProdGroups(res?.data);
    } catch (error) {
      console.error(error);
      message.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setIsLoading((prev) => ({ ...prev, prodGroups: false }));
    }
  };

  const fetchCapacityData = async (days) => {
    setIsLoading((prev) => ({ ...prev, capacityOverview: true }));
    try {
      const res = await DashboardServices.getCapacityData(
        days,
        prodGroups?.[0]?.productGroupID
      );

      setCapacityPerformanceData(res?.data);
    } catch (error) {
      console.error(error);
      message.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setIsLoading((prev) => ({ ...prev, capacityOverview: false }));
    }
  };

  const fetchCancelledReasonData = async () => {
    setIsLoading((prev) => ({ ...prev, cancelledReason: true }));
    try {
      const res = await DashboardServices.getCancelledReasonChartData();
      setCancelledReasonData(res?.data);
    } catch (error) {
      console.error(error);
      message.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setIsLoading((prev) => ({ ...prev, cancelledReason: false }));
    }
  };

  const fetchProductionLineData = async (days) => {
    setIsLoading((prev) => ({ ...prev, productionData: true }));
    try {
      const res = await DashboardServices.getProductionLineData(
        days,
        prodGroups?.[0]?.productGroupID
      );

      setProductionData(res?.data);
    } catch (error) {
      console.error(error);
      message.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setIsLoading((prev) => ({ ...prev, productionData: false }));
    }
  };

  const fetchForecastingData = async (days) => {
    setIsLoading((prev) => ({ ...prev, forecasting: true }));
    try {
      const res = await DashboardServices.getForecastingChartData(days);

      setForecastingData(res?.data?.deliveryPerDayCount[0] || []);
    } catch (error) {
      console.error(error);
      message.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setIsLoading((prev) => ({ ...prev, forecasting: false }));
    }
  };

  const formatter = (value) => <CountUp end={value} start={0} separator="," />;

  const calculateTotalTarget = (data) => {
    return (
      data?.reduce(
        (total, item) =>
          total +
          (parseInt(item.value.completed) + parseInt(item.value.target)),
        0
      ) || 0
    );
  };

  return (
    <div className="my-8 ml-3 home-page">
      <div className="grid w-full grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-4 h-fit">
        <DashboardStatsCard
          title="Orders"
          valueType="MTD"
          count={statsCardsData?.orderCounts?.totalMonthlyOrderCount}
          value={statsCardsData?.orderCounts?.totalMonthlyPriceValue}
          valuePrefix="$"
          valueFormatter={formatter}
          percentage={statsCardsData?.orderCounts?.monthlySalesPercentage}
          percentageOutcome={statsCardsData?.orderCounts?.monthlySalesOutcome}
          icon="cart"
          loading={isLoading.statsCards}
        />

        <DashboardStatsCard
          title="Orders"
          valueType="YTD"
          count={statsCardsData?.orderCounts?.totalYearlyOrderCount}
          value={statsCardsData?.orderCounts?.totalYearlyPriceValue}
          valuePrefix="$"
          valueFormatter={formatter}
          percentage={statsCardsData?.orderCounts?.yearlySalesPercentage}
          percentageOutcome={statsCardsData?.orderCounts?.yearlySalesOutcome}
          icon="luggageCart"
          loading={isLoading.statsCards}
        />

        <DashboardStatsCard
          title="Quotes"
          valueType="MTD"
          count={statsCardsData?.quoteOrderCounts?.totalMonthlyQuoteOrderCount}
          value={statsCardsData?.quoteOrderCounts?.totalPriceValue}
          valuePrefix="$"
          valueFormatter={formatter}
          percentage={statsCardsData?.quoteOrderCounts?.monthlySalesPercentage}
          percentageOutcome={
            statsCardsData?.quoteOrderCounts?.monthlySalesOutcome
          }
          icon="documentText"
          loading={isLoading.statsCards}
        />

        <div
          className={`min-h-full hover:drop-shadow-sm hover:shadow-sm flex flex-col justify-between px-4 py-3 border  ${
            isDarkMode ? "border-gray-600" : "border-gray-200"
          } rounded-md shadow-sm`}
        >
          <Skeleton
            loading={isLoading.statsCards}
            paragraph={{ rows: 2, width: "100%", style: { marginTop: 18 } }}
            active
          >
            <div className="flex items-start justify-between">
              <p className="text-base font-semibold text-gray-400">Dispatch</p>
            </div>
            <div className="relative flex items-center justify-between mt-2">
              <div
                className={`h-[2.8rem] w-[2.8rem] rounded-full flex items-center justify-center ${
                  isDarkMode ? "!bg-gray-800" : `bg-cyan-50`
                }`}
              >
                <TbTruckDelivery className="text-cyan-500" size={24} />
              </div>

              <div
                className={`absolute right-0 flex items-center gap-6 divide-x-2 ${
                  isDarkMode ? "divide-gray-600" : "divide-gray-200"
                } bottom-1`}
              >
                <div className="text-center">
                  <p className="text-sm font-semibold text-gray-400">Today</p>

                  <div className="mt-4">
                    <Statistic
                      value={statsCardsData?.dispatchCount?.todayCount || 0}
                      valueStyle={{ fontSize: 30 }}
                      className="font-semibold"
                    />
                  </div>
                </div>

                <div className="pl-6 text-center">
                  <p className="text-sm font-semibold text-gray-400">Month</p>

                  <div className="mt-4">
                    <Statistic
                      value={statsCardsData?.dispatchCount?.monthlyCount || 0}
                      valueStyle={{ fontSize: 30 }}
                      className="font-semibold"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Skeleton>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-5 mt-5 xl:grid-cols-2 place-items-start">
        <div
          className={`w-full h-full border rounded-md shadow-sm ${
            isDarkMode ? "border-gray-600" : "border-gray-200"
          }`}
        >
          <div className="p-4">
            <Typography className="mb-2 text-lg font-semibold">
              Product orders
            </Typography>

            <BestProductChart
              data={productPerformanceData || []}
              width={400}
              loading={isLoading.productPerformanceData}
              height={385}
            />
          </div>
        </div>

        <div
          className={`relative w-full border ${
            isDarkMode ? "border-gray-600" : "border-gray-200"
          } rounded-md shadow-sm`}
        >
          <div className="p-4">
            <Row justify="space-between">
              <Typography className="mb-2 text-lg font-semibold">
                Production Line Performance
              </Typography>

              <div className="absolute flex items-center gap-2 right-[10px] top-3">
                <Select
                  size="middle"
                  value={productionLineDays}
                  onChange={(value) => {
                    setProductionLineDays(value);
                    fetchProductionLineData(value);
                  }}
                  options={daysList}
                  disabled={isLoading.productionData}
                />
                {productionData && (
                  <Tag className="flex items-center justify-center gap-1 py-1 text-sm">
                    <span className="font-semibold">Target:</span>
                    <CountUp start={0} end={totalTarget} />
                  </Tag>
                )}
              </div>
            </Row>

            <ProductionLinePerformanceChart
              width={400}
              loading={isLoading.productionData}
              height={385}
              data={productionData || []}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col items-start w-full h-full gap-5 mt-5 xl:flex-row">
        <div
          className={`w-full xl:w-1/3 border ${
            isDarkMode ? "border-gray-600" : "border-gray-200"
          } rounded-md shadow-sm`}
        >
          <div className="p-4">
            <Typography className="text-lg font-semibold">
              Cancelled orders <span className="italic">( with reasons )</span>
            </Typography>

            <CancelledReasonChart
              data={cancelledReasonData || []}
              width={400}
              loading={isLoading.cancelledReason}
              height={393}
            />
          </div>
        </div>

        <div
          className={`w-full xl:w-2/3 h-full border rounded-md shadow-sm ${
            isDarkMode ? "border-gray-600" : "border-gray-200"
          }`}
        >
          <div className="relative p-4">
            <Typography className="text-lg font-semibold">
              Capacity overview
            </Typography>

            <div
              className={`absolute ${
                isLoading.capacityOverview ? "hidden" : "flex"
              } items-center gap-3 top-3 right-[10px]`}
            >
              <Select
                size="middle"
                placeholder="Select days"
                value={capacityFilters.toDate}
                onChange={(value) => {
                  setCapacityFilters((prev) => ({
                    ...prev,
                    toDate: value,
                  }));
                  fetchCapacityData(value);
                }}
                options={toDateOptions}
                disabled={isLoading.capacityOverview}
              />
            </div>

            <ActualCapacityChart
              data={capacityPerformanceData || []}
              width={400}
              loading={isLoading.capacityOverview}
              height={393}
            />
          </div>
        </div>
      </div>

      <div className="w-full mt-5">
        <div
          className={`p-4 relative border ${
            isDarkMode ? "border-gray-600" : "border-gray-200"
          } rounded-md shadow-sm`}
        >
          <div className="flex items-start justify-between w-full mb-4">
            <Typography className="text-lg font-semibold ">
              Forecasted Delivery Dates
            </Typography>

            <Select
              className="absolute top-3 right-[10px]"
              size="middle"
              value={forecastingDays}
              onChange={(value) => {
                setForecastingDays(value);
                fetchForecastingData(value);
              }}
              options={daysList}
              disabled={isLoading.forecasting}
            />
          </div>

          <ForecastingDeliveryChart
            data={forecastingData || []}
            width={800}
            height={385}
            loading={isLoading.forecasting}
          />
        </div>
      </div>

      <div className="grid h-full grid-cols-1 gap-5 mt-5 xl:grid-cols-2 place-items-start">
        <div
          className={`p-4 w-full border ${
            isDarkMode ? "border-gray-600" : "border-gray-200"
          } rounded-md shadow-sm`}
        >
          <Typography className="mb-4 text-lg font-semibold">
            Top Customer Orders
          </Typography>

          <BestCustomerChart
            data={customerData || []}
            width={400}
            loading={isLoading.bestCustomer}
            height={400}
          />
        </div>

        <div className="w-full h-full">
          <div
            className={`relative p-4 w-full border ${
              isDarkMode ? "border-gray-600" : "border-gray-200"
            } rounded-md shadow-sm`}
          >
            <div className="flex items-center justify-between">
              <Typography className="mb-4 text-lg font-semibold">
                Monthly orders
              </Typography>

              {/* might need later
              
              <Select
                className="absolute top-3 right-[10px] min-w-[8rem]"
                size="middle"
                value={monthlyChartsType}
                onChange={(value) => setMonthlyChartsType(value)}
                options={[
                  { label: "Units", value: "Units" },
                  { label: "Percentage", value: "Percentage" },
                ]}
                disabled={isLoading.monthlyOrders}
              /> */}
            </div>

            <MonthlyOrderChart
              showIn={monthlyChartsType}
              data={monthlyOrdersData || []}
              width={400}
              loading={isLoading.monthlyOrders}
              height={400}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
