import { CloseOutlined } from "@ant-design/icons";
import { Popconfirm, Row, Spin, Tooltip, Typography, message } from "antd";
import { useForm } from "antd/es/form/Form";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FaRedo, FaUndo } from "react-icons/fa";
import { useIndexedDB } from "react-indexed-db-hook";
import { useLocation, useNavigate } from "react-router-dom";
import ReactFlow, {
  Background,
  ControlButton,
  Controls,
  useEdgesState,
} from "reactflow";
import "reactflow/dist/style.css";
import CustomButton from "../../../Common/CustomButton";
import DarkModeToggler from "../../../Common/DarkModeToggler";
import { useDarkMode } from "../../../Providers/DarkModeContext";
import ChecklistService from "../../../services/Checklist/ChecklistService";
import ProductService from "../../../services/ProductBuilder/ProductService";
import { getDarkModeColor } from "../../../utils/darkModeHelper";
import { showMessage } from "../../../utils/notificationHelper";
import CustomEdge from "./CustomEdge";
import CustomJobStatusNode from "./CustomJobStatusNode";
import CustomOrderStatusNode from "./CustomOrderStatusNode";
import FiltersDrawer from "./FiltersDrawer";
import ManageJobStatusDrawer from "./ManageJobStatusDrawer";
import ManageOrderStatusDrawer from "./ManageOrderStatusDrawer";
import OptionMenu from "./OptionMenu";
import ProductGroupFaqModal from "./ProductGroupFaqModal";
import ProductGroupSettingsDrawer from "./ProductGroupSettingsDrawer";
import ProductSelectModal from "./ProductSelectModal";
import StartingNode from "./StartingNode";
import TabsNode from "./TabsNode";

const startingNode = {
  id: "0",
  data: { label: "Start", isDisabled: [] },
  position: { x: 100, y: 120 },
  type: "circleNode",
  sourcePosition: "right",
  jobStatus: [],
  allParallels: [],
  isOrderStatus: true,
};

const startingEdge = {
  id: "0-2",
  source: "0",
  sourceHandle: "0",
  target: "-1",
  type: "step",
};

const allViews = [
  { label: "Sales", value: "Sales" },
  { label: "Supply", value: "Supply" },
  { label: "Design", value: "Design" },
  { label: "Production", value: "Production" },
  { label: "Prep Fitting", value: "Prep Fitting" },
  { label: "Assembly And Fitting", value: "Assembly And Fitting" },
  { label: "Shipping", value: "Shipping" },
];

const ConfigureProductGroupPage = () => {
  const { isDarkMode } = useDarkMode();
  const [form] = useForm();
  const [orderStatusModal, setOrderStatusModal] = useState({
    visible: false,
    type: "add",
    data: null,
  });

  const [jobStatusModal, setJobStatusModal] = useState({
    visible: false,
    type: "add",
    data: null,
  });
  const [selectedNode, setSelectedNode] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [checklistOptions, setChecklistOptions] = useState([]);
  const [alertVisible, setAlertVisible] = useState(true);
  const [isFaqModalOpen, setIsFaqModalOpen] = useState(false);
  const [isSettingsDrawerOpen, setIsSettingsDrawerOpen] = useState(false);
  const [isFlowFinalized, setisFlowFinalized] = useState(false);
  const [isFlowCreated, setIsFlowCreated] = useState(null);
  const [isProductSelectModalOpen, setIsProductSelectModalOpen] =
    useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [filters, setFilters] = useState({
    modal: false,
    capacityEnabled: false,
    filterView: null,
  });

  const [nodes, setNodes] = useState([startingNode]);
  const [edges, setEdges] = useEdgesState([startingEdge]);
  const [menu, setMenu] = useState(null);
  const [indexes, setIndexes] = useState({
    undo: 1,
    redo: 0,
  });

  const location = useLocation();
  const navigate = useNavigate();
  const { getAll, add, clear: clearDB } = useIndexedDB("history");

  const ref = useRef(null);

  const productgroupID = location?.state?.productGroupID;

  const productGroupName = location?.state?.name;

  const currentProduct = location?.state?.selectedProduct;

  const onPaneClick = useCallback(() => setMenu(null), [setMenu]);

  const onNodeContextMenu = useCallback(
    (event, node) => {
      event.preventDefault();

      setMenu({
        id: node.id,
        top: event.clientY - 150,
        left: event.clientX - 20,
      });
    },
    [setMenu]
  );

  const nodeTypes = useMemo(
    () => ({
      circleNode: StartingNode,
      selectorNode: (props) => (
        <CustomJobStatusNode
          capacityFilter={filters.capacityEnabled}
          setMenu={setMenu}
          isFlowFinalized={isFlowFinalized}
          currentProduct={selectedProduct}
          {...props}
        />
      ),
      startingNode: (props) => (
        <CustomOrderStatusNode
          capacityFilter={filters.capacityEnabled}
          selectedNode={selectedNode}
          isFlowFinalized={isFlowFinalized}
          currentProduct={selectedProduct}
          {...props}
        />
      ),
      tabsNode: (props) => <TabsNode {...props} />,
    }),
    [selectedNode, menu, selectedProduct, filters]
  );

  const edgeType = useMemo(
    () => ({
      customEdge: CustomEdge,
    }),
    [edges, selectedNode, menu, selectedProduct, filters]
  );

  const orderStatusId = selectedNode && selectedNode.id;
  const orderStatusName = selectedNode && selectedNode.data.label;

  let updatedNodes = nodes.reduce((acc, item) => {
    return [...acc, item, ...item.jobStatus];
  }, []);

  const fetchAllStatus = async () => {
    if (productgroupID) {
      setIsListLoading(true);
      try {
        const res = await ProductService.getAllOrderStatus(productgroupID);

        const statusList = res.data.orderStatus
          ? JSON.parse(res.data.orderStatus).filter((item) => item.id !== "0")
          : [];

        const defaultStatus = res.data.defaultStatus
          ? JSON.parse(res.data.defaultStatus)
          : [];

        const defaultEdges = res.data.defaultEdges
          ? JSON.parse(res.data.defaultEdges)
          : [];

        const edgesList = res.data.jsonAttribute
          ? JSON.parse(res.data.jsonAttribute)
          : [];

        const startingTabs = [];

        if (res?.data?.isFlowConfigured) {
          setNodes([...startingTabs, startingNode, ...statusList]);

          setEdges([startingEdge, ...edgesList]);
        } else {
          setNodes([...startingTabs, startingNode, ...defaultStatus]);

          setEdges([startingEdge, ...defaultEdges]);
        }

        setIsFlowCreated(res?.data?.isFlowConfigured);

        // setisFlowFinalized(res?.data?.isFlowFinalize);

        setIsListLoading(false);
      } catch (error) {
        setIsListLoading(false);
        console.log(error);
        showMessage(
          "error",
          error?.response?.data?.message
            ? error?.response?.data?.message
            : "Something went Wrong"
        );
      }
    } else {
      navigate(-1);
    }
  };

  const fetchProductList = async () => {
    setIsListLoading(true);
    try {
      const res = await ProductService.getEachProductDataList(productgroupID);

      const filteredData = res?.data.map((data) => {
        return {
          label: data.name,
          value: data.code,
          code: data.code,
          productAttachment: data.productAttachment,
          key: data.id,
        };
      });

      if (filteredData.length > 0) {
        setProductList([
          {
            label: "Select all",
            value: "COMMON",
          },
          ...filteredData,
        ]);

        setSelectedProduct(
          filteredData.find((i) => i.value === currentProduct)
        );
      } else {
        setProductList([]);
      }

      setIsListLoading(false);
    } catch (error) {
      setIsListLoading(false);
      console.log(error);
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went Wrong"
      );
    }
  };

  const fetchChecklistOptions = async () => {
    try {
      const { data } = await ChecklistService.getChecklistDropdown();

      const filteredOptions = data?.map((i) => ({
        label: i.name,
        value: i.id,
      }));

      setChecklistOptions(filteredOptions);
    } catch (error) {
      console.log(error);
    }
  };

  const checkHistory = async () => {
    const history = await getAll();

    if (history.length) {
      clearDB();
    }
  };

  useEffect(() => {
    fetchAllStatus();
    fetchProductList();
    fetchChecklistOptions();
    checkHistory();
  }, []);

  useEffect(() => {
    if (filters.filterView) {
      setNodes((prevNodes) => {
        let minRange, maxRange, exception;
        switch (filters.filterView) {
          case "Sales":
            minRange = 100;
            maxRange = 299;
            exception = -1;
            break;
          case "Supply":
            minRange = 300;
            maxRange = 399;
            exception = -2;
            break;
          case "Design":
            minRange = 400;
            maxRange = 499;
            exception = -3;
            break;
          case "Production":
            minRange = 500;
            maxRange = 699;
            exception = -4;
            break;
          case "Prep Fitting":
            minRange = 700;
            maxRange = 799;
            exception = -5;
            break;
          case "Assembly And Fitting":
            minRange = 800;
            maxRange = 899;
            exception = -6;
            break;
          case "Shipping":
            minRange = 900;
            maxRange = 999;
            exception = -7;
            break;
          default:
            minRange = 0;
            maxRange = 0;
        }

        const filteredNodes = prevNodes.filter((node) => {
          const nodeStatus = parseInt(node.id);
          return (
            (nodeStatus >= minRange && nodeStatus <= maxRange) ||
            node.id == `${exception}`
          );
        });

        return filteredNodes;
      });

      document?.querySelector("button.react-flow__controls-fitview")?.click();
    }
  }, [filters]);

  useEffect(() => {
    // to show the alert for unsaved changes
    window.addEventListener("beforeunload", handleUnsavedChanges);

    return () => {
      window.removeEventListener("beforeunload", handleUnsavedChanges);
    };
  }, [unsavedChanges]);

  useEffect(() => {
    // Keyboard shortcuts
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "z") {
        event.preventDefault();
        handleUndo();
      } else if (event.ctrlKey && event.shiftKey && event.key === "Z") {
        event.preventDefault();
        handleRedo();
      } else if (event.ctrlKey && event.key === "o") {
        event.preventDefault();
        showModal("order");
      } else if (event.ctrlKey && event.key === "j") {
        event.preventDefault();
        showModal("job");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [indexes]);

  const handleUnsavedChanges = (event) => {
    if (unsavedChanges) {
      event.returnValue =
        "All your changes will be unsaved. Are you sure you want to leave?";
    }
  };

  const handleParallelChanges = (values) => {
    if (values.parallelTo) {
      setNodes((prevNodes) =>
        prevNodes.map((item) => {
          if (item.id !== values.statusId) {
            return item;
          }

          const updatedStatus = {
            ...item,
            allParallels: Array.from(
              new Set([
                ...item.allParallels,
                values.jobStatusId,
                ...values.parallelTo,
              ])
            ),
          };

          const updatedParallelStatus = {
            ...item,
            allParallels: updatedStatus.allParallels,
            jobStatus: item.jobStatus.map((status) => {
              if (updatedStatus.allParallels.includes(parseInt(status.id))) {
                return {
                  ...status,
                  data: {
                    ...status.data,
                    parallel: updatedStatus.allParallels?.filter(
                      (i) => i != status.id
                    ),
                  },
                };
              }
              return status;
            }),
          };

          return updatedParallelStatus;
        })
      );
    }
  };

  const addOrderStatus = async (values) => {
    setUnsavedChanges(true);
    await add({ nodes: [...nodes], edges: [...edges], action: "Undo" });

    const newNodeId = values.orderStatusId.toString();
    const lastNode = nodes[nodes.length - 1];

    const newNode = {
      id: newNodeId,
      data: {
        ...values,
        isTracking: false,
        isDisabled: [],
        hasDuration: values.isProductionLine,
      },
      position: {
        x:
          nodes.length === 1
            ? lastNode.position.x + 150
            : lastNode.position.x + 300,
        y: nodes.length === 1 ? lastNode.position.y - 10 : lastNode.position.y,
      },
      type: "startingNode",
      jobStatus: [],
      allParallels: [],
      isOrderStatus: true,
      isStatusHidden: false,
    };

    const newEdge =
      lastNode.id == 0
        ? {
            id: `${lastNode.id}-${newNodeId}`,
            source: lastNode.id,
            sourceHandle: "right",
            target: newNodeId,
            type: "step",
          }
        : {
            id: `${lastNode.id}-${newNodeId}`,
            source: lastNode.id,
            sourceHandle: "right",
            target: newNodeId,
            type: "customEdge",
            data: {
              label: lastNode.jobStatus.length,
            },
          };

    let dbEntry = { nodes: [], edges: [], action: "Redo" };

    setNodes((prevNodes) => {
      const updatedNodes = [...prevNodes, newNode];
      dbEntry.nodes = updatedNodes;
      return updatedNodes;
    });

    setEdges((prevEdges) => {
      const updatedEdges = [...prevEdges, newEdge];
      dbEntry.edges = updatedEdges;
      return updatedEdges;
    });

    await add(dbEntry);

    showMessage("success", "Added status successfully");
  };

  const addJobStatus = async (values) => {
    setUnsavedChanges(true);
    await add({ nodes: [...nodes], edges: [...edges], action: "Undo" });

    let jobStatusArray = nodes
      .filter((item) => item.id === values.statusId)
      .flatMap((item) => item.jobStatus);

    const lastNode =
      jobStatusArray.length !== 0
        ? jobStatusArray[jobStatusArray.length - 1]
        : jobStatusArray;

    const isLastNodeEmpty = lastNode.length === 0;

    const position = isLastNodeEmpty
      ? { x: selectedNode.position.x, y: selectedNode.position.y + 160 }
      : { x: lastNode.position.x, y: lastNode.position.y + 160 };

    const newNode = {
      id: values.jobStatusId.toString(),
      data: {
        ...values,
        isFirst: isLastNodeEmpty,
        parallel: values.parallel || [],
        isTracking: false,
        isDisabled: [],
        variants: productgroupID,
      },
      position,
      type: "selectorNode",
      isJobStatus: true,
      orderStatusId: values.statusId,
      orderStatusName: values.statusName,
      isStatusHidden: false,
    };

    const newEdge = {
      id: `${isLastNodeEmpty ? selectedNode.id : lastNode.id}-${
        values.jobStatusId
      }`,
      source: isLastNodeEmpty ? selectedNode.id : lastNode.id,
      target: values.jobStatusId.toString(),
      sourceHandle: isLastNodeEmpty ? "bottom" : undefined,
      type: "customEdge",
      data: {
        label: values.action,
        actions:
          values.action === "Standard" ? ["Start", "Complete"] : ["Yes", "No"],
      },
    };

    jobStatusArray.push(newNode);
    let dbEntry = { nodes: [], edges: [], action: "Redo" };

    setNodes((prev) => {
      const updatedNodes = prev.map((node) => {
        if (node.id != values.statusId) {
          return node;
        }

        node.jobStatus = jobStatusArray;

        return node;
      });

      dbEntry.nodes = updatedNodes;

      return updatedNodes;
    });

    if (values.parallelTo) {
      handleParallelChanges(values);
    }

    setEdges((prevEdges) => {
      let newEdges = prevEdges.map((edge) => {
        if (edge.source === selectedNode.id) {
          if ((edge.source - edge.target) % 100 === 0) {
            return {
              ...edge,
              data: { label: parseInt(edge.data.label) + 1 },
            };
          }
        }
        return edge;
      });

      newEdges.push(newEdge);

      dbEntry.edges = newEdges;

      return newEdges;
    });

    await add(dbEntry);

    showMessage("success", "Added status successfully");
  };

  const addPriorOrderStatus = async (data) => {
    setUnsavedChanges(true);
    await add({ nodes: [...nodes], edges: [...edges], action: "Undo" });

    const orderStatusArray = nodes;

    const newNodeId = data.orderStatusId.toString();
    const prevStatus = data.prev;
    const currentStatus = data.current;

    let currentJobStatusList = nodes
      .filter((item) => item.id === currentStatus.id.toString())
      .flatMap((item) => item.jobStatus);

    const newStatus = {
      id: newNodeId,
      data: {
        ...data,
        hasDuration: data.isProductionLine,
        isAttachmentCompulsory: data.isAttachmentCompulsory,
        isTracking: false,
        isDisabled: [],
      },
      position: {
        x: prevStatus.position.x + (prevStatus.id === "0" ? 150 : 300),
        y: prevStatus.position.y + (prevStatus.id === "0" ? -10 : 0),
      },
      type: "startingNode",
      jobStatus: [],
      isOrderStatus: true,
      isStatusHidden: false,
    };

    const newEdge = {
      id: `${newNodeId}-${currentStatus.id}`,
      source: newNodeId,
      sourceHandle: "right",
      target: currentStatus.id,
      type: "customEdge",
      data: {
        label: 0,
      },
    };

    const currentIndex = currentStatus.currentIndex;
    for (let i = currentIndex; i < orderStatusArray.length; i++) {
      const status = orderStatusArray[i];
      if (status) {
        status.position.x = status.position.x + 300;
        if (status.jobStatus.length > 0) {
          status.jobStatus = status.jobStatus.map((item) => ({
            ...item,
            position: { y: item.position.y, x: item.position.x + 300 },
          }));
        }
      }
    }

    if (currentJobStatusList.length > 0) {
      for (let i = 0; i <= currentJobStatusList.length; i++) {
        const status = currentJobStatusList[i];
        if (status) {
          status.position.x = status.position.x + 300;
        }
      }
    }

    let dbEntry = { nodes: [], edges: [], action: "Redo" };

    orderStatusArray.push(newStatus);

    orderStatusArray.sort((a, b) => parseInt(a.id) - parseInt(b.id));

    dbEntry.nodes = orderStatusArray;
    setNodes(orderStatusArray);

    setEdges((prev) => {
      const updatedEdges = prev.map((edge) => {
        if (edge.target == currentStatus.id) {
          edge.id = `${prevStatus.id}-${newNodeId}`;
          edge.target = newNodeId;
        }

        return edge;
      });

      dbEntry.edges = updatedEdges;

      return updatedEdges;
    });

    setEdges((prevEdges) => [...prevEdges, newEdge]);

    await add(dbEntry);
  };

  const addStatusAbove = async (data) => {
    setUnsavedChanges(true);
    await add({ nodes: [...nodes], edges: [...edges], action: "Undo" });

    let currentJobStatusList = nodes
      .filter((item) => item.id === data.info.current.orderStatusId.toString())
      .flatMap((item) => item.jobStatus);

    let formValues = data.formValues;

    const currentStatusPosition = data.info.current.positionAbsolute;

    const newStatus = {
      id: formValues.jobStatusId.toString(),
      data: {
        ...formValues,
        isFirst: false,
        parallel: formValues.parallel || [],
        isTracking: false,
        isDisabled: [],
        variants: productgroupID,
      },
      position: currentStatusPosition,
      type: "selectorNode",
      isJobStatus: true,
      orderStatusId: formValues.statusId,
      orderStatusName: formValues.statusName,
      isStatusHidden: false,
    };

    const newEdge = {
      id: `${formValues.jobStatusId}-${data.info.current.id}`,
      source: formValues.jobStatusId.toString(),
      target: data.info.current.id.toString(),
      type: "customEdge",
      data: {
        label: formValues.action,
        actions:
          formValues.action === "Standard"
            ? ["Todo", "In Progress", "Done"]
            : ["Yes", "No"],
      },
    };

    const currentIndex = data.info.currentStatusIndex;
    for (let i = currentIndex; i <= currentJobStatusList.length; i++) {
      const status = currentJobStatusList[i];
      if (status) {
        status.position.y = status.position.y + 160;
      }
    }

    currentJobStatusList.push(newStatus);

    currentJobStatusList.sort((a, b) => parseInt(a.id) - parseInt(b.id));

    let dbEntry = { nodes: [], edges: [], action: "Redo" };

    setNodes((prev) => {
      const updatedNodes = prev.map((item) => {
        if (item.id === data.info.current.orderStatusId) {
          return { ...item, jobStatus: currentJobStatusList };
        }

        return item;
      });

      dbEntry.nodes = updatedNodes;
      return updatedNodes;
    });

    setEdges((prevEdge) => {
      const updatedEdges = prevEdge.filter((edge) => {
        if (edge.source == data.info.prev.id) {
          if (parseInt(edge.target) - parseInt(edge.source) !== 100) {
            edge.id = `${edge.source}-${data.formValues.jobStatusId}`;
            edge.target = data.formValues.jobStatusId.toString();
          }
        }
        return edge;
      });

      dbEntry.edges = updatedEdges;
      return updatedEdges;
    });

    setEdges((prevEdges) => [...prevEdges, newEdge]);

    await add(dbEntry);
    showMessage("success", "Added status successfully");
  };

  const editOrderStatus = async (orderStatusId, newData) => {
    setUnsavedChanges(true);
    await add({ nodes: [...nodes], edges: [...edges], action: "Undo" });

    let dbEntry = { nodes: [], edges: [...edges], action: "Redo" };

    setNodes((prevNodes) => {
      const updatedNodes = prevNodes.map((node) => {
        if (node.id === orderStatusId) {
          return {
            ...node,
            id: newData.orderStatusId.toString(),
            data: {
              ...node.data,
              ...newData,
              orderStatusId: newData.orderStatusId.toString(),
              label: newData.label,
              isProductionLine: newData.isProductionLine,
              isTracking: false,
              hasDuration: newData.isProductionLine,
              isAttachmentCompulsory: newData.isAttachmentCompulsory,
            },
          };
        }
        return node;
      });

      dbEntry.nodes = updatedNodes;
      return updatedNodes;
    });

    await add(dbEntry);
    showMessage("success", "Updated status successfully");
  };

  const editJobStatus = async (jobStatusId, newData) => {
    setUnsavedChanges(true);
    await add({ nodes: [...nodes], edges: [...edges], action: "Undo" });

    let dbEntry = { nodes: [], edges: [], action: "Redo" };

    setNodes((prev) => {
      const updatedNodes = prev.map((node) => {
        if (node.id !== newData.statusId) {
          return node;
        }

        node.jobStatus = [...node.jobStatus].map((jobStatusNode) => {
          if (jobStatusNode.id === jobStatusId) {
            return {
              ...jobStatusNode,
              id: newData.jobStatusId.toString(),
              data: newData,
            };
          } else {
            return jobStatusNode;
          }
        });

        return node;
      });

      dbEntry.nodes = updatedNodes;
      return updatedNodes;
    });

    if (jobStatusId == newData.jobStatusId) {
      setEdges((prevEdges) => {
        const updatedEdges = prevEdges.map((item) => {
          if (item.target == newData.jobStatusId) {
            return {
              ...item,
              data: {
                ...item.data,
                label: newData.action,
              },
            };
          }
          return item;
        });

        dbEntry.edges = updatedEdges;
        return updatedEdges;
      });
    } else {
      setEdges((prevEdges) => {
        const updatedEdges = prevEdges.map((item) => {
          if (item.target == jobStatusId) {
            return {
              ...item,
              target: newData.jobStatusId.toString(),
            };
          }
          if (item.source == jobStatusId) {
            return {
              ...item,
              source: newData.jobStatusId.toString(),
            };
          }
          return item;
        });

        dbEntry.edges = updatedEdges;
        return updatedEdges;
      });
    }

    await add(dbEntry);
    showMessage("success", "Updated status successfully");
  };

  const onHideOrderStatus = (clickedNode, value) => {
    setUnsavedChanges(true);
    add({ nodes: nodes, edges: edges, action: "Undo" });

    let allOrderStatus = nodes;

    const indexOfClickedStatus = allOrderStatus.findIndex(
      (item) => item.id == clickedNode.id
    );

    let prevStatusNode = null;
    for (let i = indexOfClickedStatus - 1; i >= 0; i--) {
      if (!allOrderStatus[i].isStatusHidden) {
        prevStatusNode = allOrderStatus[i];
        break;
      }
    }

    let nextStatusNode = null;
    for (let i = indexOfClickedStatus + 1; i < allOrderStatus.length; i++) {
      if (!allOrderStatus[i].isStatusHidden) {
        nextStatusNode = allOrderStatus[i];
        break;
      }
    }

    if (nextStatusNode) {
      for (let i = indexOfClickedStatus + 1; i <= allOrderStatus.length; i++) {
        const status = allOrderStatus[i];
        if (status) {
          status.position.x = !value
            ? status.position.x + 300
            : status.position.x - 300;
          status.jobStatus.forEach((item) => {
            item.position.x = !value
              ? item.position.x + 300
              : item.position.x - 300;
          });
        }
      }

      setEdges((prevEdges) => {
        const updatedEdges = prevEdges.map((item) => {
          if (value) {
            if (
              item.source === prevStatusNode.id &&
              item.target === clickedNode.id
            ) {
              return {
                ...item,
                id: `${prevStatusNode.id}-${nextStatusNode.id}`,
                target: `${nextStatusNode.id}`,
              };
            }
          } else {
            if (
              item.source === prevStatusNode.id &&
              item.target === nextStatusNode.id
            ) {
              return {
                ...item,
                id: `${prevStatusNode.id}-${clickedNode.id}`,
                target: `${clickedNode.id}`,
              };
            }
          }

          return item;
        });

        return updatedEdges;
      });
    }

    add({ nodes: nodes, edges: edges, action: "Redo" });
  };

  const onDeleteOrderStatus = async (clickedNode) => {
    setUnsavedChanges(true);
    await add({ nodes: [...nodes], edges: [...edges], action: "Undo" });

    let allOrderStatus = [...nodes];

    const indexOfClickedStatus = allOrderStatus.findIndex(
      (item) => item.id == clickedNode.id
    );

    const prevStatusNode = allOrderStatus[indexOfClickedStatus - 1];
    const nextStatusNode = allOrderStatus[indexOfClickedStatus + 1];

    let dbEntry = { nodes: [], edges: [], action: "Redo" };

    setNodes((prevNode) => {
      const updatedNodes = prevNode.filter(
        (node) => node.id !== clickedNode.id
      );

      dbEntry.nodes = updatedNodes;
      return updatedNodes;
    });

    if (nextStatusNode) {
      for (let i = indexOfClickedStatus + 1; i <= allOrderStatus.length; i++) {
        const status = allOrderStatus[i];
        if (status) {
          status.position.x = status.position.x - 300;
          status.jobStatus.forEach((item) => {
            item.position.x -= 300;
          });
        }
      }

      setEdges((prevEdges) => {
        const filteredEdges = prevEdges.filter(
          (item) =>
            item.source !== clickedNode.id && item.target !== nextStatusNode.id
        );

        const updatedEdges = filteredEdges.map((item) => {
          if (
            item.source === prevStatusNode.id &&
            item.target === clickedNode.id
          ) {
            return {
              ...item,
              id: `${prevStatusNode.id}-${nextStatusNode.id}`,
              target: `${nextStatusNode.id}`,
            };
          }

          return item;
        });

        dbEntry.edges = updatedEdges;
        return updatedEdges;
      });
    }

    await add(dbEntry);
    showMessage("success", "Deleted status successfully");
  };

  const onDeleteJobStatus = async (clickedNode) => {
    setUnsavedChanges(true);
    await add({ nodes: [...nodes], edges: [...edges], action: "Undo" });

    let jobStatusArray = nodes
      .filter((item) => item.id === clickedNode.orderStatusId.toString())
      .flatMap((item) => item.jobStatus);

    const indexOfClickedStatus = jobStatusArray.findIndex(
      (item) => item.id == clickedNode.id
    );

    const prevStatusNode = jobStatusArray[indexOfClickedStatus - 1];
    const nextStatusNode = jobStatusArray[indexOfClickedStatus + 1];

    let dbEntry = { nodes: [], edges: [], action: "Redo" };

    if (nextStatusNode) {
      const newEdge =
        prevStatusNode === undefined
          ? {
              id: `${clickedNode.orderStatusId}-${nextStatusNode.id}`,
              source: clickedNode.orderStatusId,
              target: nextStatusNode.id,
              sourceHandle: "bottom",
              type: "customEdge",
              data: {
                label: nextStatusNode.data.action,
              },
            }
          : {
              id: `${prevStatusNode.id}-${nextStatusNode.id}`,
              source: prevStatusNode.id,
              target: nextStatusNode.id,
              type: "customEdge",
              data: {
                label: nextStatusNode.data.action,
              },
            };

      setEdges((prevEdge) => {
        const updatedEdges = [...prevEdge, newEdge];

        dbEntry.edges = updatedEdges;
        return updatedEdges;
      });
    }

    setNodes((prevNode) => {
      const updatedNodes = prevNode.map((item) => {
        if (item.jobStatus) {
          return {
            ...item,
            jobStatus: item.jobStatus.filter(
              (node) => node.id !== clickedNode.id
            ),
          };
        }
        return item;
      });

      dbEntry.nodes = updatedNodes;
      return updatedNodes;
    });

    if (nextStatusNode) {
      for (let i = indexOfClickedStatus + 1; i <= jobStatusArray.length; i++) {
        const status = jobStatusArray[i];
        if (status) {
          status.position.y = status.position.y - 160;
        }
      }
    }

    setEdges((prevEdges) => {
      const filteredEdges = prevEdges.filter(
        (item) =>
          item.source !== clickedNode.id && item.target !== clickedNode.id
      );

      const updatedEdges = filteredEdges.map((edge) => {
        if (edge.source === clickedNode.orderStatusId) {
          if ((edge.source - edge.target) % 100 === 0) {
            return {
              ...edge,
              data: { label: parseInt(edge.data.label) - 1 },
            };
          }
        }
        return edge;
      });

      dbEntry.edges = updatedEdges;
      return updatedEdges;
    });

    await add(dbEntry);
    showMessage("success", "Deleted status successfully");
  };

  const onDisableNode = async (currentNode) => {
    const payload = {
      current: currentNode,
      product: selectedProduct?.key,
    };

    try {
      const res = await ProductService.disableStatusCheck(payload);

      if (res.data.isDisabled) {
        setUnsavedChanges(true);
        add({ nodes: nodes, edges: edges, action: "Undo" });

        setNodes((prevNodes) =>
          prevNodes.map((node) => {
            if (currentNode.isOrderStatus) {
              if (
                currentNode.id !== node.id ||
                node.data.isDisabled?.includes(selectedProduct?.code)
              ) {
                return node;
              }

              const updatedNode = {
                ...node,
                data: {
                  ...node.data,
                  isDisabled: [...node.data.isDisabled, selectedProduct?.code],
                },
              };

              if (node.jobStatus && node.jobStatus.length > 0) {
                updatedNode.jobStatus = node.jobStatus.map((i) => ({
                  ...i,
                  data: {
                    ...i.data,
                    isDisabled: [
                      ...node.data.isDisabled,
                      selectedProduct?.code,
                    ],
                  },
                }));
              }

              return updatedNode;
            } else {
              const updatedNode = {
                ...node,
                jobStatus: node.jobStatus.map((i) => {
                  if (i.id === currentNode.id) {
                    return {
                      ...i,
                      data: {
                        ...i.data,
                        isDisabled: [
                          ...node.data.isDisabled,
                          selectedProduct?.code,
                        ],
                      },
                    };
                  }
                  return i;
                }),
              };

              return updatedNode;
            }
          })
        );

        add({ nodes: nodes, edges: edges, action: "Redo" });
      } else {
        showMessage(
          "info",
          res?.data?.message || "Cannot disable status as order is there."
        );
      }
    } catch (error) {
      console.error(error);
      showMessage(
        "error",
        error?.response?.data?.message || "Something went Wrong"
      );
    }
  };

  const onEnableNode = async (currentNode) => {
    add({ nodes: nodes, edges: edges, action: "Undo" });

    setNodes((prevNodes) =>
      prevNodes.map((node) => {
        setUnsavedChanges(true);

        if (currentNode.isOrderStatus) {
          if (
            currentNode.id !== node.id ||
            !node.data.isDisabled?.includes(selectedProduct?.code)
          ) {
            return node;
          }

          const updatedNode = {
            ...node,
            data: {
              ...node.data,
              isDisabled: node.data.isDisabled?.filter(
                (i) => i !== selectedProduct?.code
              ),
            },
          };

          if (node.jobStatus && node.jobStatus.length > 0) {
            updatedNode.jobStatus = node.jobStatus.map((i) => ({
              ...i,
              data: {
                ...i.data,
                isDisabled: node.data.isDisabled?.filter(
                  (i) => i !== selectedProduct?.code
                ),
              },
            }));
          }

          return updatedNode;
        } else {
          const updatedNode = {
            ...node,
            data: {
              ...node.data,
              isDisabled: node.data.isDisabled?.filter(
                (i) => i !== selectedProduct?.code
              ),
            },
            jobStatus: node.jobStatus.map((i) => {
              if (i.id === currentNode.id) {
                return {
                  ...i,
                  data: {
                    ...i.data,
                    isDisabled: node.data.isDisabled?.filter(
                      (i) => i !== selectedProduct?.code
                    ),
                  },
                };
              }
              return i;
            }),
          };

          return updatedNode;
        }
      })
    );

    add({ nodes: nodes, edges: edges, action: "Redo" });
  };

  const createMasteFlow = async () => {
    const currentNodes = nodes.filter(
      (node) => node.id !== "0" && !node.id.includes("-")
    );

    const payload = {
      productGroupID: productgroupID,
      orderStatus: currentNodes,
      jsonAttribute: JSON.stringify(edges),
    };

    setIsUploading(true);
    try {
      await ProductService.addOrderStatusFlow(payload);
      await ProductService.addWorkFlow(payload);

      fetchAllStatus();
      setIsUploading(false);
      setUnsavedChanges(false);
      showMessage("success", "Created Workflow Successfully!");
    } catch (error) {
      setIsUploading(false);
      console.error(error?.response?.data?.message);
      showMessage(
        "error",
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went Wrong"
      );
    }
  };

  const handleUpdateFlow = async () => {
    const currentNodes = nodes.filter(
      (node) => node.id !== "0" && !node.id.includes("-")
    );

    const payload = {
      productGroupID: productgroupID,
      orderStatus: currentNodes,
      jsonAttribute: JSON.stringify(edges),
    };

    setIsUploading(true);
    try {
      await ProductService.addWorkFlow(payload);
      await ProductService.editWorkFlow(payload);
      setIsUploading(false);
      setUnsavedChanges(false);
      showMessage("success", "Updated Workflow Successfully!");
    } catch (error) {
      setIsUploading(false);
      console.error(error?.response?.data?.message);
      showMessage(
        "error",
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went Wrong"
      );
    }
  };

  const makeFlowFinalized = async () => {
    setIsUploading(true);
    try {
      if (unsavedChanges) {
        await handleUpdateFlow();
      }

      await ProductService.finalizeFlow(productgroupID);

      fetchAllStatus();

      setSelectedNode(null);

      setIsUploading(false);
      showMessage("success", "Finalized Workflow Successfully!");
    } catch (error) {
      setIsUploading(false);
      console.error(error?.response?.data?.message);
      showMessage(
        "error",
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went Wrong"
      );
    }
  };

  const handleDiscard = () => {
    fetchAllStatus();

    setSelectedNode(null);
    setUnsavedChanges(false);
    clearDB();
  };

  const onNodeSelect = (event, element) => {
    if (element.isJobStatus !== true) {
      if (element.id !== "0") {
        setSelectedNode(element);
      } else {
        event.preventDefault();
      }
    }
  };

  const showModal = (type) => {
    if (type === "order") {
      setOrderStatusModal({
        visible: true,
        type: "add",
        data: null,
      });
    } else if (type === "job") {
      setJobStatusModal({
        visible: true,
        type: "add",
        data: null,
      });
    }
  };

  const toggleFilterModal = () => {
    setFilters((prev) => ({
      ...prev,
      modal: !prev.modal,
    }));
  };

  const handleClosePage = async () => {
    navigate(-1);
    await clearDB();
  };

  const handleDrawerClose = () => {
    setOrderStatusModal({
      visible: false,
      type: "add",
      data: null,
    });

    setJobStatusModal({
      visible: false,
      type: "add",
      data: null,
    });
  };

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
    setSelectedNode(null);
  };

  const handleUndo = async () => {
    const allDbEntries = (await getAll()).filter((i) => i.action === "Undo");

    const lastEntry = allDbEntries[allDbEntries.length - indexes.undo] || null;

    if (!allDbEntries.length || !lastEntry) {
      console.log("No entry found", allDbEntries, indexes.undo);
      return;
    }

    setNodes(lastEntry.nodes);
    setEdges(lastEntry.edges);

    setIndexes((prev) => ({
      undo: prev.undo + 1,
      redo: prev.redo + 1,
    }));

    setUnsavedChanges(true);
  };

  const handleRedo = async () => {
    const allDbEntries = (await getAll()).filter((i) => i.action === "Redo");

    const lastEntry = allDbEntries[allDbEntries.length - indexes.redo] || null;

    if (!allDbEntries.length || !lastEntry) {
      console.log("No entry found", allDbEntries, indexes.undo);
      return;
    }

    setNodes(lastEntry.nodes);
    setEdges(lastEntry.edges);

    setIndexes((prev) => ({
      undo: Math.max(prev.undo - 1, 1),
      redo: Math.max(prev.redo - 1, 0),
    }));

    setUnsavedChanges(true);
  };

  return (
    <div
      className={`flex flex-col items-center justify-center w-full h-screen px-4 select-none ${getDarkModeColor(
        isDarkMode,
        "bg-white"
      )}`}
    >
      <Row
        onClick={() => setMenu(null)}
        className={`flex flex-wrap items-center justify-between w-full py-4 border-b-2 ${getDarkModeColor(
          isDarkMode,
          ""
        )} gap-y-3`}
      >
        <Row className="gap-3">
          {unsavedChanges ? (
            <Popconfirm
              title="Unsaved changes will be lost!"
              description="Are you sure you want to go back?"
              placement="bottomRight"
              onConfirm={() => handleClosePage()}
              okText="Yes"
              cancelText="No"
              popupVisible={unsavedChanges}
            >
              <CustomButton type="default" icon={<CloseOutlined />} />
            </Popconfirm>
          ) : (
            <CustomButton
              type="default"
              onClick={() => handleClosePage()}
              icon={<CloseOutlined />}
            />
          )}

          <Typography className="text-base font-semibold">
            {!isFlowFinalized ? "Configure" : "Master flow for"}
            <span className="pl-1">{productGroupName}</span>
            {selectedProduct && (
              <span className="pl-1">{`(${selectedProduct.label})`}</span>
            )}
          </Typography>
        </Row>

        <>
          <div className="flex items-center gap-4">
            {/* {isFlowFinalized && ( */}
            <Tooltip trigger="hover" title="View flow for a particular product">
              <CustomButton
                type="dashed"
                className="border-blue-400"
                onClick={() => setIsProductSelectModalOpen(true)}
              >
                {!selectedProduct ? "Select Product" : "Change Product"}
              </CustomButton>
            </Tooltip>
            {/* )} */}

            {!isFlowFinalized && (
              <Tooltip trigger="hover" title="Add new order status">
                <CustomButton
                  type="dashed"
                  className="border-blue-400"
                  onClick={() => showModal("order")}
                >
                  Add order status
                </CustomButton>
              </Tooltip>
            )}

            {selectedNode && selectedNode.id !== "0" && !isFlowFinalized && (
              <>
                <Tooltip trigger="hover" title="Add new job status">
                  <CustomButton
                    type="dashed"
                    className="border-blue-400"
                    onClick={() => showModal("job")}
                  >
                    Add job status
                  </CustomButton>
                </Tooltip>
              </>
            )}
          </div>

          <div className="flex items-center gap-3">
            <DarkModeToggler />

            <Tooltip
              trigger="hover"
              placement="bottomLeft"
              title="Discard changes"
            >
              <CustomButton
                disabled={!unsavedChanges || isUploading}
                type="default"
                onClick={handleDiscard}
              >
                Discard
              </CustomButton>
            </Tooltip>

            <Tooltip
              trigger="hover"
              title={
                isFlowCreated
                  ? `Update ${isFlowFinalized ? "Finalized" : ""} flow`
                  : "Create the flow"
              }
            >
              <CustomButton
                type="primary"
                disabled={!unsavedChanges}
                loading={isUploading}
                onClick={isFlowCreated ? handleUpdateFlow : createMasteFlow}
              >
                {isFlowCreated
                  ? `Update ${isFlowFinalized ? "Finalized" : ""} Flow`
                  : "Create Flow"}
              </CustomButton>
            </Tooltip>

            {/* {isFlowCreated && !isFlowFinalized && (
              <Popconfirm
                title="You wont be able to undo this!"
                description={
                  <>
                    Once the flow is finalized, changes cannot be made.
                    <br className="mr-1" />
                    However, you have the ability to disable the status
                    <br className="mr-1" />
                    if the order is not in that particular status.
                  </>
                }
                placement="bottomRight"
                onConfirm={makeFlowFinalized}
                okText="Yes"
                cancelText="No"
              >
                <CustomButton type="primary" loading={isUploading}>
                  Finalize Flow
                </CustomButton>
              </Popconfirm>
            )}
            */}
          </div>
        </>
      </Row>

      {/* {isFlowFinalized && (
        <>
          {(filters.capacityEnabled || filters.filterView) && (
            <FloatButton
              icon={<TbFilterX />}
              onClick={() => {
                setFilters({
                  modal: false,
                  capacityEnabled: false,
                  filterView: null,
                });
                fetchAllStatus();
              }}
              tooltip="Clear Filters"
              className="bottom-[8rem] right-5 drop-shadow-md"
            />
          )}

          <FloatButton
            icon={<TbFilter />}
            onClick={() => toggleFilterModal()}
            tooltip="Filters"
            className="bottom-[4.5rem] right-5 drop-shadow-md"
          />
        </>
      )}

      <FloatButton
        icon={<SettingOutlined />}
        className="bottom-5 right-5 drop-shadow-md"
        onClick={() => setIsSettingsDrawerOpen(true)}
        tooltip="Settings"
      /> */}

      {/* React Flow starts */}
      <div className="w-full h-full">
        {isListLoading ? (
          <div className="flex items-center justify-center w-full py-10">
            <Spin size="medium" />
          </div>
        ) : (
          <ReactFlow
            ref={ref}
            nodes={updatedNodes.filter((i) => !i.isStatusHidden)}
            nodeTypes={nodeTypes}
            edges={edges}
            edgeTypes={edgeType}
            onNodeClick={onNodeSelect}
            onNodeContextMenu={onNodeContextMenu}
            onPaneClick={onPaneClick}
            edgesFocusable={false}
            deleteKeyCode="Delete"
            nodesDraggable={false}
          >
            {menu && (
              <OptionMenu
                onClick={onPaneClick}
                nodeList={updatedNodes}
                orderStatusList={nodes}
                setOrderStatusModal={setOrderStatusModal}
                setJobStatusModal={setJobStatusModal}
                addStatusAbove={addStatusAbove}
                onDeleteOrderStatus={onDeleteOrderStatus}
                onDeleteJobStatus={onDeleteJobStatus}
                hideOptions={isFlowFinalized}
                handleDisabledNode={onDisableNode}
                handleEnableNode={onEnableNode}
                selectedProduct={selectedProduct}
                {...menu}
              />
            )}

            <Background />

            <Controls
              className={`${getDarkModeColor(
                isDarkMode,
                "",
                "!bg-gray-700 text-white"
              )}`}
              showInteractive={false}
            >
              <ControlButton onClick={handleUndo}>
                <FaUndo className="dark:text-black" />
              </ControlButton>

              <ControlButton onClick={handleRedo}>
                <FaRedo className="dark:text-black" />
              </ControlButton>
            </Controls>
          </ReactFlow>
        )}
      </div>
      {/* React Flow ends */}

      <ManageOrderStatusDrawer
        visible={orderStatusModal.visible}
        data={orderStatusModal.data}
        type={orderStatusModal.type}
        onCancel={handleDrawerClose}
        onAddNode={addOrderStatus}
        onEditNode={editOrderStatus}
        addPriorOrderStatus={addPriorOrderStatus}
        form={form}
        minLength={nodes[nodes.length - 1].id}
        alertVisible={alertVisible}
        setAlertVisible={setAlertVisible}
        isFlowFinalized={isFlowFinalized}
        checklistOptions={checklistOptions}
      />

      <ManageJobStatusDrawer
        visible={jobStatusModal.visible}
        productList={productList}
        type={jobStatusModal.type}
        data={jobStatusModal.data}
        onCancel={handleDrawerClose}
        onAddNode={addJobStatus}
        onEditNode={editJobStatus}
        onAddAbove={addStatusAbove}
        orderStatusId={orderStatusId}
        orderStatusName={orderStatusName}
        isFlowFinalized={isFlowFinalized}
        menu={menu}
        setMenu={setMenu}
        minLength={selectedNode ? selectedNode.id : []}
        jobstatusNodes={
          selectedNode
            ? nodes
                .filter((item) => item.id === selectedNode.id)
                .map((item) => item.jobStatus)
                .flat()
            : []
        }
        parallelNames={
          selectedNode &&
          updatedNodes
            .filter((node) => node.orderStatusId === selectedNode.id)
            .map((item) => ({
              label: `${item.data.jobStatusId}. ${item.data.label}`,
              value: item.data.jobStatusId,
            }))
        }
        alertVisible={alertVisible}
        setAlertVisible={setAlertVisible}
        checklistOptions={checklistOptions}
      />

      <ProductGroupFaqModal
        open={isFaqModalOpen}
        onCancel={() => setIsFaqModalOpen(false)}
      />

      <ProductGroupSettingsDrawer
        open={isSettingsDrawerOpen}
        onCancel={() => setIsSettingsDrawerOpen(false)}
        allStatuses={nodes}
        setNodes={setNodes}
        onHideOrderStatus={onHideOrderStatus}
      />

      <ProductSelectModal
        open={isProductSelectModalOpen}
        selectedProduct={selectedProduct}
        products={productList?.filter((i) => i.value !== "COMMON")}
        onCancel={() => setIsProductSelectModalOpen(false)}
        onSelect={handleProductSelect}
      />

      <FiltersDrawer
        filters={filters}
        setFilters={setFilters}
        open={filters.modal}
        onClose={toggleFilterModal}
        allViews={allViews}
        fetchAllStatus={fetchAllStatus}
      />
    </div>
  );
};

export default ConfigureProductGroupPage;
