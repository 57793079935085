import { InfoCircleOutlined } from "@ant-design/icons";
import { Drawer, Form, Select, Tooltip, message } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import OrderManagementService from "../../services/OrderManagement/OrderManagementService";
import CustomButton from "../../Common/CustomButton";

const { Option } = Select;
const OrderCancelDrawer = ({
  currentOrderStatus,
  isDrawerOpen,
  onCancel,
  setIsLoadOnSave,
  isLoadOnSave,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [cancelReasonData, setCancelReasonData] = useState([]);
  const [form] = useForm();
  const onFinish = async (values) => {
    try {
      const orderPrimaryIdentifier = currentOrderStatus?.primaryIdentifier;

      const payload = {
        primaryIdentifier: orderPrimaryIdentifier,
        cancelReasonID: values.ReasonID,
      };
      handleCancelFile(payload);
    } catch (error) {
      message.info("Select file");
    }
  };

  const handleCancelFile = (payload) => {
    OrderManagementService.CancelOrderData(payload)
      .then((res) => {
        setIsLoadOnSave(!isLoadOnSave);
        setIsLoading(false);
        message.success("Order Cancelled Successfully");
        form.resetFields();
        onCancel();
      })
      .catch((error) => {
        setIsLoading(false);
        error?.response?.data?.message
          ? message.error(error?.response?.data?.message)
          : message.error("Something went wrong");
      });
  };

  useEffect(() => {
    if (isDrawerOpen) {
      OrderManagementService.getCancelOrderReasonsList()
        .then((res) => {
          const cancelStatusType = res.data.map((item) => ({
            label: item.description,
            value: item.cancelReasonID,
          }));
          setCancelReasonData(cancelStatusType);
        })
        .catch((error) => {
          error?.response?.data?.message
            ? message.error(error?.response?.data?.message)
            : message.error("Something went wrong");
        });
    }
  }, [isDrawerOpen]);

  return (
    <Drawer
      title={`Cancel Order`}
      width={400}
      onClose={onCancel}
      open={isDrawerOpen}
      bodyStyle={{
        padding: "4px 16px",
      }}
      destroyOnClose={true}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          label={
            <span>
              Reason&nbsp;
              <Tooltip
                placement="right"
                title="Select the reason for cancelling the order"
              >
                <InfoCircleOutlined
                  className={"text-blue-500 cursor-pointer hover:text-blue-300"}
                />
              </Tooltip>
            </span>
          }
          style={{ width: "100%" }}
          name="ReasonID"
          rules={[
            {
              required: true,
              message: "Select Cancel Reason",
            },
          ]}
        >
          <Select
            placeholder="Select Cancel Reason"
            width={"100%"}
            options={cancelReasonData}
            size={"medium"}
          />
        </Form.Item>

        <div className="flex justify-end gap-2 mt-5">
          <CustomButton type="primary" loading={isLoading} htmlType="submit">
            Confirm
          </CustomButton>
        </div>
      </Form>
    </Drawer>
  );
};

export default OrderCancelDrawer;
