import {
  CheckOutlined,
  CloseOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { Drawer, Form, Input, message } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import CustomerService from "../../services/CustomerService/CustomerService";
import CustomButton from "../../Common/CustomButton";

const ManageCustomerContacts = ({
  changeStatus,
  onClose,
  initialValue,
  isLoadData,
  setIsLoadData,
  accountId,
  onSuccess,
}) => {
  const [form] = useForm();
  const [IsLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (changeStatus === "edit") {
      form.setFieldsValue({ ...initialValue });
    } else {
      form.resetFields();
    }

    return () => form.setFieldsValue(null);
  }, [initialValue, changeStatus]);

  const onFinish = (values) => {
    if (changeStatus === "edit") {
      setIsLoading(true);
      const payload = {
        ...values,
        primaryIdentifier: initialValue.primaryIdentifier,
        consumerID: accountId,
        id: initialValue.id,
        consumerType: "Consumer",
        contactTypeID: 0,
        isPrimary: false,
        isEmailStatement: true,
        isEmailInvoice: true,
        isEmailDispatchNotice: true,
        isEmailMarketing: true,
        priority: 0,
        createdBy: 0,
      };

      CustomerService.updateCustomerContactData(payload)
        .then((res) => {
          setIsLoading(false);
          onSuccess();
          message.success("Contacts Details Updated Successfully");
          form.resetFields();
          onClose();
        })
        .catch((error) => {
          setIsLoading(false);

          error?.response?.data?.message
            ? message.error(error?.response?.data?.message)
            : message.error("Something went Wrong");
        });
    } else if (changeStatus === "add") {
      const payload = {
        ...values,
        id: 0,
        consumerID: accountId,
        consumerType: "Consumer",
        contactTypeID: 0,
        isPrimary: false,
        isEmailStatement: true,
        isEmailInvoice: true,
        isEmailDispatchNotice: true,
        isEmailMarketing: true,
        priority: 0,
        createdBy: 0,
        primaryIdentifier: "",
      };

      CustomerService.addCustomerContactData(payload)
        .then((res) => {
          setIsLoading(false);
          onSuccess();
          message.success("Contacts Details Added Successfully");
          form.resetFields();
          onClose();
        })
        .catch((error) => {
          setIsLoading(false);
          error?.response?.data?.message
            ? message.error(error?.response?.data?.message)
            : message.error("Something went wrong");
        });
    }
  };

  return (
    <Drawer
      title={
        changeStatus === "add"
          ? "Add Customer Contact"
          : changeStatus === "edit"
          ? "Edit Customer Contact"
          : ""
      }
      destroyOnClose={true}
      width={400}
      onClose={onClose}
      open={!!changeStatus}
      bodyStyle={{
        padding: "10px 18px",
      }}
    >
      <Form
        form={form}
        name="form_item_path"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          name="firstName"
          label="First Name"
          rules={[
            {
              required: true,
              message: "Enter first name",
            },
            {
              min: 3,
              max: 20,
              message: "First Name should have 3-20 characters only!",
            },
          ]}
        >
          <Input
            value={""}
            size="medium"
            type="name"
            placeholder="Enter first name"
          />
        </Form.Item>

        <Form.Item
          name="lastName"
          label="Last Name"
          rules={[
            {
              min: 0,
              max: 20,
              message: "First Name should have 0-20 characters only!",
            },
          ]}
        >
          <Input
            value={""}
            size="medium"
            type="name"
            placeholder="Enter last name"
          />
        </Form.Item>

        <Form.Item
          name="jobTitle"
          label="Job Title"
          rules={[
            {
              required: false,
              message: "Enter job title",
            },
          ]}
        >
          <Input
            type="text"
            value={""}
            size="medium"
            placeholder="Enter job title"
          />
        </Form.Item>

        <Form.Item
          name="mobile"
          label="Mobile Number"
          rules={[
            {
              required: true,
              message: "Enter mobile number",
            },
            {
              pattern: /^[0-9]*$/,
              message: "Enter a valid mobile number",
            },
          ]}
        >
          <Input
            type="number"
            minLength={10}
            maxLength={10}
            size="medium"
            placeholder="Enter mobile number"
          />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: "Enter email",
            },
            {
              pattern: /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/,
              message: "Enter correct email",
            },
          ]}
        >
          <Input
            type="email"
            value={""}
            size="medium"
            placeholder="Enter email"
          />
        </Form.Item>

        <Form.Item
          name="phone"
          label="Phone Number"
          rules={[
            {
              required: false,
              message: "Enter phone number",
            },
            {
              pattern: /^[0-9]*$/,
              message: "Enter a valid phone number",
            },
          ]}
        >
          <Input
            type="number"
            minLength={10}
            maxLength={10}
            size="medium"
            placeholder="Enter phone number"
          />
        </Form.Item>

        <Form.Item
          name="fax"
          label="Fax Number"
          rules={[
            {
              required: false,
              message: "Enter fax number",
            },
          ]}
        >
          <Input type="number" size="medium" placeholder="Enter fax number" />
        </Form.Item>

        <div className="flex justify-end gap-2 mt-4">
          <CustomButton
            type="primary"
            htmlType="submit"
            loading={IsLoading}
            icon={<CheckOutlined />}
          >
            Submit
          </CustomButton>
          <CustomButton
            type="cancel"
            onClick={onClose}
            icon={<CloseOutlined />}
          >
            Cancel
          </CustomButton>
        </div>
      </Form>
    </Drawer>
  );
};

export default ManageCustomerContacts;
