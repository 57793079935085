import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "../pages/Authentication/LoginPage";
import ChecklistOptionsPage from "../pages/Checklist/ChecklistOptionsPage";
import ChecklistPage from "../pages/Checklist/ChecklistPage";
import CustomerEnquiry from "../pages/Customer/CustomerEnquiry";
import CustomerListPage from "../pages/Customer/CustomerListPage";
import ImportCustomerPage from "../pages/Customer/ImportCustomerPage";
import ImportEnquiryDetailsPage from "../pages/Customer/ImportEnquiryDetailsPage";
import Dashboard from "../pages/Dashboard";
import ImportOrdersPage from "../pages/OrderManagement/ImportOrders/ImportOrdersPage";
import OrderDetails from "../pages/OrderManagement/OrderDetails";
import OrderManagement from "../pages/OrderManagement/OrderManagement";
import OrderQuoteCreate from "../pages/OrderManagement/OrderQuoteCreate";
import OrderSettings from "../pages/OrderManagement/OrderSettings";
import OrganizationEnquiry from "../pages/Organization/OrganizationEnquiry";
import PageNotFound from "../pages/PageNotFound";
import ConfigureProductGroupPage from "../pages/Product/ConfigureProductGroup/ConfigureProductGroupPage";
import ProductConfigBuilderPage from "../pages/Product/ProductConfigBuilder/ProductConfigBuilderPage";
import ProductConfigOptionsPage from "../pages/Product/ProductConfigBuilder/ProductConfigOptionsPage";
import Products from "../pages/Product/Products";
import CapacityPage from "../pages/Production/Capacity/CapacityPage";
import Role from "../pages/Roles/Role";
import ProductionSchedule from "../pages/Scheduler/ProductionSchedule";
import TicketsPage from "../pages/Ticket/TicketsPage";
import TourPage from "../pages/Tour/TourPage";
import UserDetails from "../pages/User/UserDetails";
import UserProfile from "../pages/User/UserProfile";
import { useAuth } from "../Providers/AuthProvider";
import { useDarkMode } from "../Providers/DarkModeContext";
import PrivateRoute from "./PrivateRoute";

const extractKeysFromMenu = (menu) => {
  let keys = [];

  const extract = (items) => {
    items?.forEach((item) => {
      if (item.key) {
        keys.push(item.key);
      }
      if (item["privatePaths"] && item["privatePaths"].length > 0) {
        keys.push(...item["privatePaths"]);
      }
      if (item.children && item.children.length > 0) {
        extract(item.children);
      }
    });
  };

  extract(menu);

  return keys;
};

export const routesConfig = [
  { path: "/", breadcrumb: "Home", component: Dashboard },
  {
    path: "/customer-list",
    breadcrumb: "Customer List",
    component: CustomerListPage,
  },
  {
    path: "/customer-list/enquiry",
    breadcrumb: "Customer Enquiry",
    component: CustomerEnquiry,
  },
  {
    path: "/customer-enquiry",
    breadcrumb: "Customer Enquiry",
    component: CustomerEnquiry,
  },
  {
    path: "/customer-list/enquiry/order-enquiry",
    breadcrumb: "Order Enquiry",
    component: OrderDetails,
  },
  {
    path: "/customer-list/import-customer",
    breadcrumb: "Import",
    component: ImportCustomerPage,
  },
  {
    path: "/customer-list/enquiry/import-details",
    breadcrumb: "Import",
    component: ImportEnquiryDetailsPage,
  },
  {
    path: "/customer-enquiry/import-details",
    breadcrumb: "Import",
    component: ImportEnquiryDetailsPage,
  },
  {
    path: "/customer-enquiry/order-enquiry",
    breadcrumb: "Order Details",
    component: OrderQuoteCreate,
  },
  {
    path: "/customer-enquiry/order-enquiry/order-details",
    breadcrumb: "Order Details",
    component: OrderQuoteCreate,
  },
  {
    path: "/customer-list/enquiry/order-enquiry/order-details",
    breadcrumb: "Order Details",
    component: OrderQuoteCreate,
  },
  { path: "/users", breadcrumb: "Users", component: UserDetails },
  { path: "/roles", breadcrumb: "Roles", component: Role },
  {
    path: "/products",
    breadcrumb: "Product Configuration",
    component: Products,
  },
  {
    path: "/products/configure-group-status",
    breadcrumb: "Configure Product Group",
    component: ConfigureProductGroupPage,
  },
  {
    path: "/products/product-builder",
    breadcrumb: "Product Builder",
    component: ProductConfigBuilderPage,
  },
  {
    path: "/products/product-builder/options",
    breadcrumb: "Product Builder Options",
    component: ProductConfigOptionsPage,
  },
  {
    path: "/order-details",
    breadcrumb: "Order",
    component: OrderQuoteCreate,
  },
  {
    path: "/import-orders",
    breadcrumb: "Import Orders",
    component: ImportOrdersPage,
  },
  {
    path: "/order-management",
    breadcrumb: "Order Management",
    component: OrderManagement,
  },
  {
    path: "/order-management/enquiry",
    breadcrumb: "Order Enquiry",
    component: OrderDetails,
  },
  {
    path: "/order-management/enquiry/order-details",
    breadcrumb: "Order Details",
    component: OrderQuoteCreate,
  },
  {
    path: "/enquiry/order-details",
    breadcrumb: "Order Details",
    component: OrderQuoteCreate,
  },
  {
    path: "/enquiry",
    breadcrumb: "Order Enquiry",
    component: OrderDetails,
  },
  {
    path: "/production-schedule",
    breadcrumb: "Production Schedule",
    component: ProductionSchedule,
  },
  {
    path: "/capacity",
    breadcrumb: "Capacity",
    component: CapacityPage,
  },
  {
    path: "/organization-enquiry",
    breadcrumb: "Organization Enquiry",
    component: OrganizationEnquiry,
  },
  {
    path: "/order-settings",
    breadcrumb: "Order Settings",
    component: OrderSettings,
  },
  {
    path: "/checklist",
    breadcrumb: "Checklist",
    component: ChecklistPage,
  },
  {
    path: "/checklist/options",
    breadcrumb: "Checklist Options",
    component: ChecklistOptionsPage,
  },
  {
    path: "/profile",
    breadcrumb: "Profile",
    component: UserProfile,
  },
  { path: "/tour", breadcrumb: "Tour", component: TourPage },
  { path: "/ticket", breadcrumb: "Tickets", component: TicketsPage },
  { path: "*", breadcrumb: "", component: PageNotFound },
];

const RoutesConfig = (props) => {
  const { userDetails, currentURL } = useAuth();

  let menuKeys = [];
  let firstMenuItemPath = null;

  if (userDetails) {
    menuKeys = extractKeysFromMenu(userDetails?.menu?.items || []);
    firstMenuItemPath =
      userDetails?.menu?.items?.[0]?.children.length > 0
        ? userDetails?.menu?.items?.[0]?.children?.[0]?.key
        : userDetails?.menu?.items?.[0]?.key;
  }

  const specialRoutes = ["*", "/profile", "/tour"];

  if (!userDetails) {
    return (
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="*" element={<LoginPage />} />
      </Routes>
    );
  }

  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });

  return (
    <Routes>
      {!menuKeys.includes("/") && firstMenuItemPath && (
        <Route path="/" element={<Navigate to={firstMenuItemPath} replace />} />
      )}

      {routesConfig.map((route) =>
        menuKeys.includes(route.path) || specialRoutes.includes(route.path) ? (
          <Route
            key={route.path}
            path={route.path}
            element={
              <PrivateRoute>
                <route.component />
              </PrivateRoute>
            }
          />
        ) : null
      )}
    </Routes>
  );
};

export default RoutesConfig;

const SuspenseWrapper = ({ children }) => {
  const { isDarkMode } = useDarkMode();

  return (
    <Suspense
      fallback={
        <div
          className={`flex items-center justify-center h-screen-190px ${
            isDarkMode ? "!bg-[#1b1d1e]" : ""
          }`}
        >
          <Spin indicator={<LoadingOutlined />} />
        </div>
      }
    >
      {children}
    </Suspense>
  );
};
