import {
  CalendarOutlined,
  DownOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Dropdown,
  Empty,
  Radio,
  Select,
  Skeleton,
  Space,
  Tabs,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../Common/CustomBreadcrumb";
import CustomButton from "../../Common/CustomButton";
import { useDarkMode } from "../../Providers/DarkModeContext";
import ProductService from "../../services/ProductBuilder/ProductService";
import ProductionServices from "../../services/Production/ProductionServices";
import SearchResultDrawer from "./JobSearchResultDrawer";
import ManageOnHold from "./ManageOnHold";
import ManageReSchedule from "./ManageReSchedule";
import ScheduleConfigure from "./ScheduleConfigure";
import SchedulerOrderSearchDrawer from "./SchedulerOrderSearchDrawer";
const breadcrumbsItems = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Production Schedule",
  },
];
const ProductionSchedule = () => {
  const { isDarkMode } = useDarkMode();

  const [productGroupOptionData, setProductGroupOptionData] = useState([]);
  const [productOptionData, setProductOptionData] = useState([]);
  const [selectedProductGroup, setSelectedProductGroup] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState("all");
  const [productionScheduleData, setProductionScheduleData] = useState([]);
  const [isViewsLoading, setIsViewsLoading] = useState(false);
  const [isOpenOrderItemsDrawer, setIsOpenOrderItemsDrawer] = useState(false);
  const [isOpenOnHoldDrawer, setIsOpenOnHoldDrawer] = useState(false);
  const [searchResultDrawer, setSearchResultDrawer] = useState(false);
  const [reload, setReload] = useState(false);
  const [scheduleStatus, setScheduleStatus] = useState(null);
  const [activeTabLabel, setActiveTabLabel] = useState("");
  const [animationClassName, setAnimationClassName] = useState("");
  const [showExtendedColumns, setShowExtendedColumns] = useState(false);

  let prdGroupData = JSON.parse(localStorage.getItem("prodGroup"));
  let userRole = localStorage.getItem("userRole");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productGroupResponse =
          await ProductionServices.getProductGroupList();

        setProductGroupOptionData(productGroupResponse.data);

        handleProductGroupChange(
          prdGroupData
            ? prdGroupData[0]?.productGroupID
            : productGroupResponse?.data[0]?.productGroupID
        );

        const productDataResponse = await ProductService.getProductDataList();

        setProductOptionData(
          productDataResponse.data.map((item) => ({
            label: item.name,
            value: item.name,
          }))
        );
      } catch (error) {
        message.error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : "Something went wrong"
        );
      }
    };

    fetchData();
  }, []);

  const handleProductChange = (value) => {
    setSelectedProduct(value);
    // initalSchedulerDataFetch(value);
  };

  const handleProductGroupChange = (value) => {
    setAnimationClassName("");
    if (value) {
      setSelectedProductGroup(value);
      initalSchedulerDataFetch(value);
    } else {
      setSelectedProductGroup(null);
    }
  };

  const initalSchedulerDataFetch = (value) => {
    setIsViewsLoading(true);
    ProductionServices.getProductionScheduleData(
      value ? value : productGroupOptionData[0]?.productGroupID
    )
      .then((response) => {
        const withCountProcess = response?.data?.processLines.map((i) => ({
          ...i,
          count: i.schedulerData.reduce(
            (acc, schedule) => acc + schedule.productionScheduleOrders.length,
            0
          ),
        }));

        const onHoldItem = withCountProcess.find((i) => i.name === "OnHold");

        if (onHoldItem) {
          setProductionScheduleData([
            onHoldItem,
            ...withCountProcess.filter((i) => i.name !== "OnHold"),
          ]);
        } else {
          setProductionScheduleData(withCountProcess);
        }

        if (activeTabLabel === "") {
          setActiveTabLabel(response?.data?.processLines[0]?.name || "");
        }
        setIsViewsLoading(false);
      })
      .catch((error) => {
        setIsViewsLoading(false);
        message.error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : "Something went wrong"
        );
      });
  };

  const schedulerDataFetch = (value) => {
    ProductionServices.getProductionScheduleData(
      value ? value : productGroupOptionData[0]?.productGroupID
    )
      .then((response) => {
        const withCountProcess = response?.data?.processLines.map((i) => ({
          ...i,
          count: i.schedulerData.reduce(
            (acc, schedule) => acc + schedule.productionScheduleOrders.length,
            0
          ),
        }));

        const onHoldItem = withCountProcess.find((i) => i.name === "OnHold");

        if (onHoldItem) {
          setProductionScheduleData([
            onHoldItem,
            ...withCountProcess.filter((i) => i.name !== "OnHold"),
          ]);
        } else {
          setProductionScheduleData(withCountProcess);
        }

        if (activeTabLabel === "") {
          setActiveTabLabel(response?.data?.processLines[0]?.name || "");
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : "Something went wrong"
        );
      });
  };

  const handleTabChange = (activeTab) => {
    setActiveTabLabel((prevTab) => {
      const prevTabIndex = productionScheduleData.findIndex(
        (tab) => tab.name === prevTab
      );
      const activeTabIndex = productionScheduleData.findIndex(
        (tab) => tab.name === activeTab
      );
      if (prevTabIndex < activeTabIndex) {
        setAnimationClassName("tab-right-to-left");
      } else {
        setAnimationClassName("tab-left-to-right");
      }
      return activeTab;
    });
  };

  const goToNextTab = () => {
    const currentIndex = productionScheduleData.findIndex(
      (item) => item.name === activeTabLabel
    );
    const nextIndex = (currentIndex + 1) % productionScheduleData.length;
    setActiveTabLabel(productionScheduleData[nextIndex].name);
  };

  const goToPreviousTab = () => {
    const currentIndex = productionScheduleData.findIndex(
      (item) => item.name === activeTabLabel
    );
    const prevIndex =
      (currentIndex - 1 + productionScheduleData.length) %
      productionScheduleData.length;
    setActiveTabLabel(productionScheduleData[prevIndex].name);
  };

  const getFilteredData = (arr) => {
    if (arr.length === 0) {
      return arr;
    } else if (selectedProduct === "all") {
      return arr;
    } else if (selectedProduct === "Steel Tray") {
      return arr.slice(0, 8);
    } else if (selectedProduct === "Aluminium Tray") {
      return [arr[0], ...arr.slice(8)];
    } else {
      return arr;
    }
  };

  useEffect(()=>{          
    const newFilteredArr = getFilteredData(productionScheduleData);
    if (newFilteredArr.length > 1) {
      setActiveTabLabel(newFilteredArr[1].name);
    }},[selectedProduct])

  return (
    <div className="p-2 mt-4">
      <div className="flex flex-wrap items-center justify-between w-full mb-3">
        <CustomBreadcrumb items={breadcrumbsItems} />
        <div className="flex items-center gap-3">
          {!userRole.includes("worker") && productOptionData.length > 1 && (
            <Select
              className="min-w-[10rem]"
              size="small"
              placeholder="Select product"
              onChange={handleProductChange}
              value={selectedProduct}
              options={[{ label: "All", value: "all" }, ...productOptionData]}
            />
          )}

          {!userRole.includes("worker") && (
            <CustomButton
              type="secondary"
              size="small"
              onClick={() => {
                setShowExtendedColumns(!showExtendedColumns);
              }}
              icon={
                !showExtendedColumns ? (
                  <EyeOutlined />
                ) : (
                  <EyeInvisibleOutlined />
                )
              }
            >
              {!showExtendedColumns
                ? "Show Extend Columns"
                : "Hide Extended Columns"}
            </CustomButton>
          )}

          {!userRole.includes("worker") && (
            <CustomButton
              type="primary"
              size="small"
              onClick={() => {
                setSearchResultDrawer(true);
              }}
              icon={<SearchOutlined />}
              disabled={selectedProductGroup == null ? true : false}
            >
              Search
            </CustomButton>
          )}

          {/* {!userRole.includes("worker") && (
            <CustomButton
              type="primary"
              size="small"
              onClick={() => {
                setIsOpenOnHoldDrawer(true);
              }}
              icon={<FileTextOutlined />}
              disabled={selectedProductGroup == null ? true : false}
            >
              On Hold List
            </CustomButton>
          )} */}

          {!userRole.includes("worker") && (
            <CustomButton
              type="primary"
              size="small"
              onClick={() => {
                setScheduleStatus("Reschedule");
              }}
              icon={<CalendarOutlined />}
              disabled={selectedProductGroup == null ? true : false}
            >
              Reschedule
            </CustomButton>
          )}
        </div>
      </div>

      {selectedProductGroup && (
        <>
          {isViewsLoading ? (
            Array(5)
              .fill("")
              .map((i) => (
                <Skeleton.Button
                  active={true}
                  size="default"
                  className="mx-0.5 mb-[10px]"
                />
              ))
          ) : productionScheduleData.length > 0 ? (
            <>
              <div className="flex gap-3 select-none">
                {/* {productionScheduleData.length > 1 && (
                  <CustomButton
                    className="mt-[6px] w-fit"
                    onClick={goToPreviousTab}
                    icon={<LeftOutlined />}
                  />
                )} */}

                <div className="w-full">
                  <Tabs
                    activeKey={activeTabLabel}
                    onChange={handleTabChange}
                    type="card"
                    renderTabBar={(props) => {
                      return (
                        <div className="flex flex-wrap items-center w-full mb-2 gap-x-1 gap-y-[6px]">
                          {props.panes.map((i) => (
                            <div
                              className={`px-3 py-2 font-semibold ${
                                props.activeKey === i.key
                                  ? `${
                                      isDarkMode ? "bg-[#161217]" : "bg-white"
                                    } text-[#4898fa]`
                                  : `${isDarkMode ? "#252628" : "bg-[#fafafa]"}`
                              } cursor-pointer rounded-t-md  ${
                                isDarkMode
                                  ? "border-[#37303a]"
                                  : "border-[#e5e7eb]"
                              } border border-b-0`}
                              data-node-key={i.key}
                              onClick={() => props.onTabClick(i.key)}
                            >
                              {i.props.tab.props.children}
                            </div>
                          ))}
                        </div>
                      );
                    }}
                    items={
                      getFilteredData(productionScheduleData).length > 0 &&
                      getFilteredData(productionScheduleData).map((item) => ({
                        label: (
                          <>
                            <span>{item?.name}</span>
                            <Badge
                              count={item?.count || 0}
                              showZero
                              color="blue"
                              style={{
                                borderRadius: "4px",
                                color: "white",
                                marginLeft: "4px",
                              }}
                            />
                          </>
                        ),
                        key: item?.name,
                      }))
                    }
                  />
                </div>

                {/* {productionScheduleData.length > 1 && (
                  <CustomButton
                    className="mt-[6px] w-fit"
                    onClick={goToNextTab}
                    icon={<RightOutlined />}
                  />
                )} */}
              </div>
              <Card>
                <ScheduleConfigure
                  schedulerDataFetch={schedulerDataFetch}
                  selectedProductGroup={selectedProductGroup}
                  className={animationClassName}
                  activeTabLabel={activeTabLabel}
                  productionScheduleData={productionScheduleData}
                  setReload={setReload}
                  reload={reload}
                  userRole={userRole}
                  productGroupID={productGroupOptionData[0]?.productGroupID}
                  showExtendedColumns={showExtendedColumns}
                />
              </Card>
            </>
          ) : (
            <Empty />
          )}
        </>
      )}

      <ManageReSchedule
        productGroupOptionValue={selectedProductGroup}
        changeStatus={scheduleStatus}
        onClose={() => {
          setScheduleStatus(null);
        }}
        onSuccess={() => handleProductGroupChange(selectedProductGroup)}
      />

      <SchedulerOrderSearchDrawer
        isDrawerOpen={isOpenOrderItemsDrawer}
        onCancel={() => setIsOpenOrderItemsDrawer(false)}
      />

      {!userRole.includes("worker") && (
        <ManageOnHold
          isOpenOnHoldDrawer={isOpenOnHoldDrawer}
          onClose={() => setIsOpenOnHoldDrawer(false)}
          reload={reload}
          setReload={setReload}
          schedulerDataFetch={schedulerDataFetch}
        />
      )}

      <SearchResultDrawer
        isOpen={searchResultDrawer}
        onClose={() => setSearchResultDrawer(false)}
      />
    </div>
  );
};
export default ProductionSchedule;
