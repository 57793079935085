import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../../Common/CustomBreadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import ProductService from "../../../services/ProductBuilder/ProductService";
import {
  Checkbox,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Table,
  message,
} from "antd";
import { CheckOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import CustomButton from "../../../Common/CustomButton";
import { getDarkModeColor } from "../../../utils/darkModeHelper";
import { useDarkMode } from "../../../Providers/DarkModeContext";
import { isEqual } from "lodash";
import { validateTextInput } from "../../../utils/validationsHelper";
import { useForm, useWatch } from "antd/es/form/Form";
import TotalListEntries from "../../../Common/TotalListEntries";

const { TextArea } = Input;

const ProductConfigOptionsPage = () => {
  const [listLoading, setListLoading] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState({});
  const [initialList, setInititalList] = useState([]);
  const [list, setList] = useState({
    request: {
      pageRequest: {
        currentPage: 1,
        pageSize: 10,
      },
      sortRequest: {
        key: "",
        direction: true,
      },
      filterRequest: {},
    },
    response: {
      records: [],
      totalRecords: 0,
    },
  });

  const navigate = useNavigate();
  const location = useLocation();
  const { isDarkMode } = useDarkMode();

  const [form] = useForm();

  const condition1 = useWatch("condition1", form);
  const condition2 = useWatch("condition2", form);
  const condition3 = useWatch("condition3", form);
  const optionKey = useWatch("optionKey", form);
  const optionLabel = useWatch("optionLabel", form);
  const parentOption = useWatch("parentOption", form);
  const unitPrice = useWatch("unitPrice", form);
  const isActive = useWatch("isActive", form);

  const productInfo = location?.state?.productInfo;
  const configInfo = location?.state?.configInfo;

  useEffect(() => {
    if (productInfo && configInfo) {
      fetchList(list.request);
    }
  }, [productInfo, configInfo]);

  useEffect(() => {
    // to show the alert for unsaved changes
    window.addEventListener("beforeunload", handleUnsavedChanges);

    return () => {
      window.removeEventListener("beforeunload", handleUnsavedChanges);
    };
  }, [unsavedChanges]);

  const handleUnsavedChanges = (event) => {
    if (unsavedChanges) {
      event.returnValue =
        "All your changes will be unsaved. Are you sure you want to leave?";
    }
  };

  if (!productInfo || !configInfo) {
    navigate(-1);
    return null;
  }

  const items = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "Products",
      path: "/inventory/product/all?activeTab=product",
    },
    {
      title: `Product Builder`,
      path: `/inventory/product/product-builder?productId=${productInfo?.value}`,
    },
    {
      title: `${configInfo?.attributeLabel || ""} Options`,
    },
  ];

  const fetchList = async (request) => {
    setListLoading(true);
    try {
      const condition1 = [];
      const condition2 = [];
      const condition3 = [];
      const optionKey = [];
      const optionLabel = [];
      const parentOption = [];
      const unitPrice = [];
      const isActive = [];

      const { data } = await ProductService.getProductOptionsList({
        ...request,
        filterRequest: {
          productConfigurationId: configInfo?.id,
        },
      });

      setInititalList(data?.records || []);

      setList((prev) => ({
        request: {
          ...prev?.request,
          filterRequest: {
            ...prev?.request?.filterRequest,
            productConfigurationId: configInfo?.id,
          },
        },
        response: data,
      }));

      data?.records?.forEach((item, index) => {
        condition1[index] = item.condition1;
        condition2[index] = item.condition2;
        condition3[index] = item.condition3;
        optionKey[index] = item.optionKey;
        optionLabel[index] = item.optionLabel;
        parentOption[index] = item.parentOption;
        unitPrice[index] = item.unitPrice;
        isActive[index] = item.isActive;
      });

      form.setFieldsValue({
        condition1,
        condition2,
        condition3,
        optionKey,
        optionLabel,
        parentOption,
        unitPrice,
        isActive,
      });
    } catch (error) {
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went wrong"
      );
    } finally {
      setListLoading(false);
    }
  };

  const handlePageChange = (page, pageSize) => {
    let updatedReq = {
      ...list,
      request: {
        ...list.request,
        pageRequest: {
          currentPage: page,
          pageSize: pageSize,
        },
      },
    };

    setList(updatedReq);

    fetchList(updatedReq.request);
  };

  const handleAddConfig = () => {
    setUnsavedChanges(true);

    let newOption = {
      id: list?.response?.records?.length + 1,
      productConfigurationId: configInfo?.id,
      optionKey: "",
      optionLabel: "",
      description: "",
      isActive: false,
      parentOptionId: null,
      condition1: "",
      condition2: "",
      condition3: "",
      primaryAttribute: null,
      primaryAttributeMin: 0,
      primaryAttributeMax: 0,
      secondaryAttribute: null,
      secondaryAttributeMin: 0,
      secondaryAttributeMax: 0,
      unitPrice: null,
      sets: [],
      type: "Save",
    };

    setList((prev) => ({
      ...prev,
      response: {
        ...prev.response,
        records: [...prev.response.records, newOption],
      },
    }));
  };

  const handleUpdateRow = (data, index, type) => {
    form
      .validateFields([
        ["optionLabel", index],
        ["optionKey", index],
        ["unitPrice", index],
      ])
      .then(async (values) => {
        setIsSubmitting({ [`update${index}`]: true });

        const payload = { ...data };

        try {
          const { data } =
            type === "Save"
              ? await ProductService.addNewConfigOption(payload)
              : await ProductService.updateConfigOption(payload);

          if (data) {
            await fetchList(list.request);

            if (list?.length === 1) {
              setUnsavedChanges(false);
            }

            if (list?.length > 1 && list?.some((i) => i?.type !== "Save")) {
              setUnsavedChanges(false);
            }

            message.success("Updated option successfully!");
          }
        } catch (error) {
          console.log(error);
          message.error(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : "Something went wrong"
          );
        } finally {
          setListLoading(false);
          setIsSubmitting({ [`update${index}`]: false });
        }
      })
      .catch((errorInfo) => {
        const errorFields = errorInfo.errorFields;

        if (errorFields.length > 0) {
          const fieldName = errorFields[0].name;
          form.scrollToField(fieldName, {
            behavior: "smooth",
            inline: "center",
            skipOverflowHiddenElements: true,
          });
        }
      });
  };

  const handleDeleteRow = async (index) => {
    const item = list?.response?.records?.[index];

    if (!item || item.id === null) return;

    setListLoading(true);
    setIsSubmitting({ [`delete${index}`]: true });

    const optionLabel = form.getFieldsValue("attributeLabel");
    const optionKey = form.getFieldsValue("attributeKey");
    const unitPrice = form.getFieldsValue("parentConfiguration");

    const clearFormFields = () => {
      optionLabel[index] = undefined;
      optionKey[index] = undefined;
      unitPrice[index] = undefined;

      form.setFieldsValue({
        optionLabel,
        optionKey,
        unitPrice,
      });
    };

    try {
      if (item.type === "Save") {
        setList((prev) =>
          prev?.response?.records?.filter((_, i) => i !== index)
        );
        clearFormFields();

        if (list?.response?.records?.length === 1) {
          setUnsavedChanges(false);
        }

        if (
          list?.response?.records?.length > 1 &&
          list?.some((i) => i?.type !== "Save")
        ) {
          setUnsavedChanges(false);
        }

        message.success("Deleted option successfully!");
      } else {
        const res = await ProductService.deleteConfigOption(item.id);

        if (res.status === 200) {
          await fetchList(list.request);
          clearFormFields();

          message.success("Deleted option successfully!");
        }
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went wrong"
      );
    } finally {
      setIsSubmitting({ [`delete${index}`]: false });
      setListLoading(false);
    }
  };

  const getFormValueOfIndex = (index) => {
    return {
      ...list?.response?.records?.[index],
      condition1: condition1[index],
      condition2: condition2[index],
      condition3: condition3[index],
      optionKey: optionKey[index],
      optionLabel: optionLabel[index],
      parentOption: parentOption[index],
      unitPrice: unitPrice[index],
      isActive: isActive[index],
    };
  };

  const checkIsFieldsTouched = (index) => {
    return !form.isFieldsTouched([
      ["condition1", index],
      ["condition2", index],
      ["condition3", index],
      ["optionKey", index],
      ["optionLabel", index],
      ["parentOption", index],
      ["unitPrice", index],
      ["isActive", index],
    ]);
  };

  const columns = [
    {
      title: "Option Name",
      dataIndex: "optionLabel",
      key: "optionLabel",
      render: (data, _, index) => (
        <Form.Item
          name={["optionLabel", index]}
          rules={[
            { required: true, message: "Enter option name" },
            { validator: validateTextInput("Option Name", 2, 200) },
          ]}
        >
          <Input size="middle" placeholder="Enter option name" />
        </Form.Item>
      ),
    },
    {
      title: "Option Value",
      dataIndex: "optionKey",
      key: "optionKey",
      render: (data, _, index) => (
        <Form.Item
          name={["optionKey", index]}
          rules={[
            { required: true, message: "Enter option value" },
            { validator: validateTextInput("Option value", 2, 200) },
          ]}
        >
          <Input size="middle" placeholder="Enter option name" />
        </Form.Item>
      ),
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      render: (data, _, index) => (
        <Form.Item
          name={["unitPrice", index]}
          rules={[
            { required: true, message: "Enter unit price" },
            {
              validator: (_, value) => {
                if (value >= 0) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Price cannot be less than 0"));
              },
            },
          ]}
        >
          <InputNumber
            type="number"
            size="middle"
            className="w-full"
            placeholder="Enter unit price"
          />
        </Form.Item>
      ),
    },
    {
      title: "Parent option",
      dataIndex: "parentOption",
      key: "parentOption",
      render: (data, record, index) => (
        <Form.Item
          name={["parentOption", index]}
          rules={[{ required: false, message: "Enter parent option" }]}
        >
          <Select
            allowClear
            size="middle"
            placeholder="Select parent option"
            className="w-full"
            options={parentOptions.filter((i) => i.value !== record.id)}
          />
        </Form.Item>
      ),
    },
    {
      title: "Condition1",
      dataIndex: "condition1",
      key: "condition1",
      render: (data, _, index) => (
        <Form.Item
          name={["condition1", index]}
          rules={[
            { required: false, message: "Enter condition1" },
            { validator: validateTextInput("Condition1", 2, 200) },
          ]}
        >
          <TextArea rows={2} size="middle" placeholder="Enter condition1" />
        </Form.Item>
      ),
    },
    {
      title: "Condition2",
      dataIndex: "condition2",
      key: "condition2",
      render: (data, _, index) => (
        <Form.Item
          name={["condition2", index]}
          rules={[
            { required: false, message: "Enter condition2" },
            { validator: validateTextInput("Condition2", 2, 200) },
          ]}
        >
          <TextArea rows={2} size="middle" placeholder="Enter condition2" />
        </Form.Item>
      ),
    },
    {
      title: "Condition3",
      dataIndex: "condition3",
      key: "condition3",
      render: (data, _, index) => (
        <Form.Item
          name={["condition3", index]}
          rules={[
            { required: false, message: "Enter condition3" },
            { validator: validateTextInput("Condition3", 2, 200) },
          ]}
        >
          <TextArea rows={2} size="middle" placeholder="Enter condition3" />
        </Form.Item>
      ),
    },
    {
      title: "Active",
      dataIndex: "isActive",
      width: 100,
      align: "center",
      render: (text, _, index) => (
        <Form.Item name={["isActive", index]} valuePropName="checked">
          <Checkbox />
        </Form.Item>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      align: "center",
      width: 250,
      render: (d, record, index) => (
        <div className="flex items-center justify-center gap-3">
          <CustomButton
            icon={<CheckOutlined />}
            disabled={
              checkIsFieldsTouched(index) || isSubmitting[`delete${index}`]
            }
            type="primary"
            onClick={() =>
              handleUpdateRow(
                getFormValueOfIndex(index),
                index,
                record?.type === "Save" ? "Save" : "Update"
              )
            }
          >
            {record?.type === "Save" ? "Save" : "Update"}
          </CustomButton>

          <Popconfirm
            title="Are you sure?"
            placement="topLeft"
            description="This action is irreversible. Do you still want to delete?"
            onConfirm={() => handleDeleteRow(index)}
            okText="Yes"
            cancelText="No"
            color={isDarkMode && "#282928"}
          >
            <CustomButton
              disabled={isSubmitting[`update${index}`]}
              loading={isSubmitting[`delete${index}`]}
              danger
              icon={<DeleteOutlined />}
              type="primary"
            >
              Delete
            </CustomButton>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const parentOptions = list?.response?.records
    ?.map((i) => ({
      label: i.optionLabel,
      value: i.id,
    }))
    ?.filter((i) => i.value && i.label);

  let tableStartingIndex =
    (list.response.currentPage - 1) * list.response.pageSize + 1;

  if (
    list.response.totalRecords <
    list.response.pageSize * list.response.currentPage
  ) {
    tableStartingIndex =
      (list.response.currentPage - 1) * list.response.pageSize + 1;
  }

  return (
    <div className="p-2 mt-4">
      <CustomBreadcrumb items={items} />

      <Form form={form}>
        <Table
          title={() => (
            <TotalListEntries
              checkNullCondition={list?.response?.records?.length > 0}
              startingIndex={tableStartingIndex}
              totalEntries={list?.response?.totalRecords}
              currentPage={list?.response?.currentPage}
              pageSize={list?.response?.pageSize}
              className="m-0"
            />
          )}
          columns={columns}
          className="mt-4 select-none"
          rowKey="id"
          size="large"
          listLoading={listLoading}
          dataSource={list?.response?.records || []}
          tableLayout="fixed"
          scroll={{
            x: 2600,
          }}
          bordered
          pagination={{
            current: list?.request?.pageRequest?.currentPage,
            pageSize: list?.request?.pageRequest?.pageSize,
            total: list?.response?.totalRecords,
            showSizeChanger: true,
            onChange: handlePageChange,
          }}
          rowClassName={(_, index) =>
            index % 2 !== 0 ? getDarkModeColor(isDarkMode, "bg-gray-100") : ""
          }
          footer={() => (
            <CustomButton
              loading={listLoading}
              icon={<PlusOutlined />}
              type="primary"
              onClick={() => handleAddConfig()}
            >
              Add Configuration
            </CustomButton>
          )}
        />
      </Form>
    </div>
  );
};

export default ProductConfigOptionsPage;
