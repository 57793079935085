import { Typography } from "antd";
import React from "react";
import { twMerge } from "tailwind-merge";

const TotalListEntries = ({
  checkNullCondition,
  startingIndex,
  totalEntries,
  pageSize,
  currentPage,
  className = "",
}) => {
  return (
    <div className={twMerge("mb-2 ml-1", className)}>
      {checkNullCondition && (
        <Typography className="flex gap-1 text-sm">
          Showing
          <Typography className="font-semibold">{startingIndex}</Typography> to
          <Typography className="font-semibold">
            {Math.min(totalEntries, pageSize * currentPage)}
          </Typography>
          out of
          <Typography className="font-semibold">{totalEntries}</Typography>
          entries
        </Typography>
      )}
    </div>
  );
};

export default TotalListEntries;
