import { PlayCircleOutlined, SmileOutlined } from "@ant-design/icons";
import { Result } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Common/CustomButton";
import { useTour } from "../../Providers/TourProvider";

const TourPage = () => {
  const { driver, startTour, isTourStarted, endTour } = useTour();
  const navigate = useNavigate();

  useEffect(() => {
    if (isTourStarted) {
      navigate("/organization-enquiry");

      const driverObj = driver({
        showProgress: false,
        onClose: endTour(),
        steps: [
          {
            element: "#addOrganization",
            popover: {
              title: "Step-1",
              description: "Begin by adding your organization.",
              onNextClick: () => {
                navigate("/customer-list");
                setTimeout(() => {
                  driverObj.moveNext();
                }, 500);
              },
            },
          },
          {
            element: "#addCustomer",
            popover: {
              title: "Step-2",
              description: "Now, add your customers.",
              onNextClick: () => {
                navigate("/users?activeTab=Staff");
                setTimeout(() => {
                  driverObj.moveNext();
                }, 500);
              },
              onPrevClick: () => {
                navigate("/organization-enquiry");
                setTimeout(() => {
                  driverObj.movePrevious();
                }, 500);
              },
            },
          },
          {
            element: "#addUser",
            popover: {
              title: "Step-3",
              description: "Add users who will manage your system.",
              onNextClick: () => {
                navigate("/products?activeTab=product-group-page");
                setTimeout(() => {
                  driverObj.moveNext();
                }, 500);
              },
              onPrevClick: () => {
                navigate("/customer-list");
                setTimeout(() => {
                  driverObj.movePrevious();
                }, 500);
              },
            },
          },
          {
            element: "#addProductGroup",
            popover: {
              title: "Step-4",
              description: "Create product groups.",
              onPrevClick: () => {
                navigate("/users?activeTab=Staff");
                setTimeout(() => {
                  driverObj.movePrevious();
                }, 500);
              },
            },
          },
          {
            element: "#configureProductGroup",
            popover: {
              title: "Step-5",
              description:
                "Configure and finalize the details of your product groups.",
              onNextClick: () => {
                navigate("/products?activeTab=product");
                setTimeout(() => {
                  driverObj.moveNext();
                }, 500);
              },
              onPrevClick: () => {
                driverObj.movePrevious();
              },
            },
          },
          {
            element: "#addProduct",
            popover: {
              title: "Step-6",
              description: "Add products.",
              onNextClick: () => {
                navigate("/products?activeTab=product-group-page");
                setTimeout(() => {
                  driverObj.moveNext();
                }, 500);
              },
              onPrevClick: () => {
                navigate("/products?activeTab=product-group-page");
                setTimeout(() => {
                  driverObj.movePrevious();
                }, 500);
              },
            },
          },
          {
            element: "#configureProductGroup",
            popover: {
              title: "Step-7 (optional)",
              description:
                "Adjust your product group configuration based on added products.",
              onNextClick: () => {
                navigate("/capacity");
                setTimeout(() => {
                  driverObj.moveNext();
                }, 500);
              },
              onPrevClick: () => {
                navigate("/products?activeTab=product");
                setTimeout(() => {
                  driverObj.movePrevious();
                }, 500);
              },
            },
          },
          {
            element: "#defaultCapacity",
            popover: {
              title: "Step-8",
              description: "Set up default capacity for your operations.",
              onPrevClick: () => {
                navigate("/products?activeTab=product-group-page");
                setTimeout(() => {
                  driverObj.movePrevious();
                }, 500);
              },
            },
          },
          {
            element: "#configureCapacity",
            popover: {
              title: "Step-9",
              description: "Customize capacity on a day-to-day basis.",
              onNextClick: () => {
                navigate("/order-details");
                setTimeout(() => {
                  driverObj.moveNext();
                }, 500);
              },
            },
          },
          {
            element: "#createOrder",
            popover: {
              title: "Step-10",
              description:
                "Initiate the process by creating orders for your products.",
              onPrevClick: () => {
                navigate("/capacity");
                setTimeout(() => {
                  driverObj.movePrevious();
                }, 500);
              },
            },
          },
        ],
      });

      driverObj.drive();
    }
  }, [isTourStarted]);

  return (
    <div className="flex items-center justify-center w-full py-10">
      <Result
        icon={<SmileOutlined />}
        title="Welcome to APS! Let's take a step by step tour"
        extra={
          <CustomButton
            icon={<PlayCircleOutlined />}
            type="primary"
            onClick={startTour}
          >
            Start
          </CustomButton>
        }
      />
    </div>
  );
};

export default TourPage;
