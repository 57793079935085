import axios from "../axios";
class ProductionLineService {
  static Instance() {
    return new ProductionLineService();
  }

  addProductionLine(payload) {
    return axios.post("api/production/lines", payload);
  }

  editProductionLine(payload) {
    return axios.put("api/production/lines", payload);
  }
}
export default ProductionLineService.Instance();
