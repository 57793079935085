import { EditOutlined } from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { formatDate, formatDateMonth } from "../../utils/dateHelper";
import EditCustomerGeneralDetails from "./EditCustomerGeneralDetails";

const DataCard = ({
  title,
  data,
  isLoading,
  primaryIdentifier,
  fullData,
  fetchData,
  accountStatusList,
  classificationList,
  categoryList,
}) => {
  const [editAction, setEditAction] = useState(false);
  const [editData, setEditData] = useState([]);
  const [editTitle, setEditTitle] = useState("");

  const handleEditCard = (title, fullData) => {
    setEditAction(true);
    setEditData(fullData);
    setEditTitle(title);
  };

  return (
    <>
      <Card
        extra={
          (title === "Discount" || title === "Preview") && (
            <EditOutlined
              className="text-blue-700"
              onClick={() => handleEditCard(title, fullData)}
            />
          )
        }
        title={title}
        className={`${
          title === "Discount" ? "max-h-[17.5rem] overflow-y-auto" : "!h-full"
        }`}
        type="inner"
        loading={isLoading}
      >
        <Row gutter={[16, 16]}>
          {data?.map(({ label, value }) => (
            <Col
              span={24}
              className="grid flex-wrap grid-cols-2 gap-8"
              key={label}
            >
              <span className="font-medium">{label}:</span>
              <span className="text-right">{value}</span>
            </Col>
          ))}
        </Row>
      </Card>

      <EditCustomerGeneralDetails
        primaryIdentifier={primaryIdentifier}
        editAction={editAction}
        editData={editData}
        editTitle={editTitle}
        fetchData={fetchData}
        accountStatusList={accountStatusList}
        classificationList={classificationList}
        categoryList={categoryList}
        onClose={() => {
          setEditAction(false);
          setEditData([]);
          setEditTitle("");
        }}
      />
    </>
  );
};

const CustomerGeneralDetails = ({
  data,
  isLoading,
  primaryIdentifier,
  fetchData,
}) => {
  const currentMonth = dayjs(new Date()).format("MMMM");
  const currentMonthIndex =
    data &&
    Object.entries(data?.salesByMonth).findIndex(
      ([i]) => i.toLowerCase() == currentMonth.toLowerCase()
    );

  const previewData =
    data &&
    Object.entries(data?.preview).map(([label, value]) => ({
      label: label.charAt(0).toUpperCase() + label.slice(1),
      value: value
        ? label === "established"
          ? formatDate(value)
          : value.name
        : "N/A",
    }));

  const outstandingBalanceData =
    data &&
    Object.entries(data?.balance).map(([label, value]) => ({
      label: label.charAt(0).toUpperCase() + label.slice(1),
      value: `$${value}`,
    }));

  const salesByMonthData =
    data &&
    Object.entries(data?.salesByMonth)
      .slice(currentMonthIndex, currentMonthIndex + 6)
      .map(([label, value]) => ({
        label: label.charAt(0).toUpperCase() + label.slice(1),
        value: `$${value}`,
      }));

  const discountData =
    data &&
    data?.productDiscountList.map((item) => ({
      label:
        item.productName.charAt(0).toUpperCase() + item.productName.slice(1),
      value: `${item.discount}%`,
    }));

  return (
    <>
      <Row className="grid w-full grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        <Col>
          <DataCard
            isLoading={isLoading}
            primaryIdentifier={primaryIdentifier}
            title="Preview"
            data={previewData}
            fullData={
              data?.preview
                ? Object.entries(data?.preview).map(([label, value]) => ({
                    label: label.charAt(0).toUpperCase() + label.slice(1),
                    value: value
                      ? label === "established"
                        ? formatDate(value)
                        : value.id
                      : "N/A",
                  }))
                : []
            }
            fetchData={fetchData}
            accountStatusList={
              data?.accountStatusList
                ? data?.accountStatusList.map((i) => ({
                    label: i.name,
                    value: i.id,
                  }))
                : []
            }
            classificationList={
              data?.classificationList
                ? data?.classificationList.map((i) => ({
                    label: i.name,
                    value: i.id,
                  }))
                : []
            }
            categoryList={
              data?.categoryList
                ? data?.categoryList.map((i) => ({
                    label: i.name,
                    value: i.id,
                  }))
                : []
            }
          />
        </Col>
        <Col className="">
          <DataCard
            isLoading={isLoading}
            title="Outstanding Balance"
            data={outstandingBalanceData}
          />
        </Col>
        <Col className="">
          <DataCard
            isLoading={isLoading}
            title="Sales By Month"
            data={salesByMonthData}
          />
        </Col>
        <Col className="">
          <DataCard
            primaryIdentifier={primaryIdentifier}
            isLoading={isLoading}
            title="Discount"
            data={discountData}
            fullData={data?.productDiscountList}
            fetchData={fetchData}
          />
        </Col>
      </Row>
    </>
  );
};

export default CustomerGeneralDetails;
